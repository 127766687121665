import API from "../../configs/api/api.config";

const URL_GENERAR_ATS = '/generar-ats';

export const generarATSXMLRequest = async (data) => {
    const request = await API.post(URL_GENERAR_ATS + '/xml', data, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const generarATSPDFRequest = async (data) => {
    const request = await API.post(URL_GENERAR_ATS + '/pdf', data, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}