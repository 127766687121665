import React, { memo, useEffect, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { IoAdd, IoTrash } from "react-icons/io5";

export const EmailsInput = memo(
  ({
    value = "",
    onChange = () => {},
  }) => {

    const [emails, setEmails] = useState([""]);

    useEffect(() => {
      if (!value) return;
      setEmails(value?.split(";")?.map((email) => email.trim()));
    }, [value]);

    const onChangeEmail = (index, newValue) => {
      const updatedEmails = [...emails];
      updatedEmails[index] = newValue;
      setEmails(updatedEmails);
      onChange(updatedEmails.join(";"));
    };

    const addEmail = () => {
      setEmails([...emails, ""]);
      onChange([...emails, ""].join(";"));
    };

    const removeEmail = (index) => {
      const confirmRemove = window.confirm(
        `¿Esta seguro de eliminar "${emails[index]}" de la lista de correos? \n\nRecuerde Guardar los cambios para que se mantengan las modificaciones.`
      );
      if (!confirmRemove) return;
      const updatedEmails = [...emails];
      updatedEmails.splice(index, 1);
      setEmails(updatedEmails);
      onChange(updatedEmails.join(";"));
    };

    return (
      <Col>
        {emails.map((email, index) => {
          const isLast = emails.length - 1 === index;
          const isDeletable = emails.length > 1;
          return (
            <Row gutter={5} style={{ marginTop: 5 }} key={index}>
              <Col span={isLast && isDeletable ? 20 : 22}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={email}
                  onChange={(e) => onChangeEmail(index, e.target.value)}
                />
              </Col>
              {isDeletable && (
                <Col span={2}>
                  <Button
                    onClick={() => removeEmail(index)}
                    danger
                    type="text"
                    size="small"
                  >
                    <IoTrash size={18} />
                  </Button>
                </Col>
              )}
              {isLast && (
                <Col span={2}>
                  <Button
                    onClick={() => addEmail()}
                    type="primary"
                    size="small"
                  >
                    <IoAdd size={18} />
                  </Button>
                </Col>
              )}
            </Row>
          );
        })}
      </Col>
    );
  }
);
