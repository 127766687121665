import CryptoJS from 'crypto-js';

const secretKey = 'O1uHIY6UBlOWgTSB2ire';

// Función para cifrar una cadena de texto con una clave secreta
export const encryptString = (plaintext) => {
    const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
    return ciphertext;
}

// Función para descifrar una cadena de texto cifrada con la misma clave secreta
export const decryptString = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
}