import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import dayjs from "dayjs";
import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import { lineasProductosIdEmpresaRequest } from "../../../../services/api/lineas-productos";
import { medidasIdEmpresaRequest } from "../../../../services/api/medidas";
import {
  tarifasIvaRequest,
  tiposEcommerceProductoRequest,
} from "../../../../services/api/opciones";
import {
  crearServicioRequest,
  generarCodigoServicioRequest,
} from "../../../../services/api/servicios";
import { calcularTarifaRequest } from "../../../../services/api/tarifas-productos";
import { TabDatosBasicos } from "./components/TabDatosBasicos";
import { TabImagenes } from "./components/TabImagenes";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined.function";
import { parametrosContablesProductosEmpresaRequest } from "../../../../services/api/parametros-contables-productos";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

const base = [
  {
    medida: "interna",
    tarifa: "Tarifas en: ",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    id_tarifa_producto: 10,
    medida: "interna",
    tarifa: "Precio 1",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 2",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 3",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 4",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 5",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
];

export const CrearServicioPage = () => {
  const { isProductosChange } = useContext(ActionContext);
  
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    codigo: "",
    codigo_barras: "",
    descripcion: "",
    costo_actual: "",
    costo_compra: "",
    costo_estandar: "",
    activo: true,
    combustible: false,
    controla_negativos: true,
    controla_precio: true,
    compuesto: false,
    materia_prima: false,
    series: false,
    vehiculo: false,
    lotes: false,
    factor_superior: "",
    factor_inferior: "",
    tonelaje: 0,
    pasajeros: 0,
    cilindraje: 0,
    origen: "",
    marca: "",
    modelo: "",
    lado: "",
    medidas: "",
    codigo_ice: "",
    porcentaje_ice: 0,
    valor_ice: 0,
    subsidio: 0,
    cuenta_contable_inventario: null,
    cuenta_contable_ventas: null,
    cuenta_contable_costos: null,
    id_tipo_ecommerce_producto: null,
    proveedor_asignado: null,
    proveedor_compra: null,
    destacado: false,
    oferta: false,
    oferta_desde: null,
    oferta_hasta: null,
    ficha_tecnica: "",
    observaciones: "",
    imagenes: [],
    id_linea_producto: null,
    id_categoria_producto: null,
    id_sub_categoria_producto: null,
    id_sub_grupo_producto: null,
    id_iva_producto: null,
    id_medida_superior: null,
    id_medida_interna: null,
    id_medida_inferior: null,
  });

  const [lineasProductos, setLineasProductos] = useState([]);
  const [tarifasIva, setTarifasIva] = useState([]);
  const [tiposEcommerceProducto, setTiposEcommerceProducto] = useState([]);
  const [medidasProducto, setMedidasProducto] = useState([]);

  const [tarifas, setTarifas] = useState(base);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTarifas, setIsLoadingTarifas] = useState(false);

  useEffect(() => {
    Promise.all([generarCodigoServicioRequest(empresa.id_empresa)]).then(
      (res) => {
        setForm({
          ...form,
          codigo: res[0],
        });
      }
    );
  }, [empresa]);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      lineasProductosIdEmpresaRequest(empresa.id_empresa),
      tarifasIvaRequest(empresa.id_empresa),
      tiposEcommerceProductoRequest(),
      medidasIdEmpresaRequest(empresa.id_empresa),
      parametrosContablesProductosEmpresaRequest(empresa.id_empresa),
    ])
      .then(
        ([
          lineasProductosRes,
          tarifasIvaRes,
          tiposEcommerceProductoRes,
          medidasProductoRes,
        ]) => {
          setLineasProductos(lineasProductosRes);
          setTarifasIva(tarifasIvaRes);
          setTiposEcommerceProducto(tiposEcommerceProductoRes);
          setMedidasProducto(medidasProductoRes);

          if (lineasProductosRes.length > 0) {
            const findLineaProducto = lineasProductosRes.find(
              (e) => e.predeterminado
            );
            if (findLineaProducto) {
              setForm((prevForm) => ({
                ...prevForm,
                id_linea_producto: findLineaProducto.id_linea_producto,
              }));
            }
          }

          if (tarifasIvaRes.length > 0) {
            const findTarifaIva = tarifasIvaRes.find((e) => e.codigo === 2);
            if (findTarifaIva) {
              setForm((prevForm) => ({
                ...prevForm,
                id_iva_producto: findTarifaIva.id_iva,
              }));
            }
          }

          if (tiposEcommerceProductoRes.length > 0) {
            const find = tiposEcommerceProductoRes[0];
            if (find) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_ecommerce_producto: find.id_tipo_ecommerce_producto,
              }));
            }
          }

          if (medidasProductoRes.length > 0) {
            const find = medidasProductoRes.find((m) => m.predeterminado);
            if (find) {
              setForm((prevForm) => ({
                ...prevForm,
                id_medida_interna: find.id_medida_producto,
              }));
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    const id_iva_producto = form.id_iva_producto;
    if (!isNullOrUndefined(id_iva_producto) && tarifasIva.length > 0) {
      const tarifaIva = tarifasIva.find(
        (tarifaIva) => tarifaIva.id_iva === id_iva_producto
      );
      if (isNullOrUndefined(tarifaIva)) return;

      const valorTarifa = tarifaIva.valor ?? 0;
      parametrosContablesProductosEmpresaRequest(empresa.id_empresa).then(
        (resp) => {
          const cuenta_contable_iva_ventas = resp.cuenta_contable_iva_ventas;
          const cuenta_contable_ventas = resp.cuenta_contable_ventas;

          if (Number(valorTarifa) === 0) {
            setForm((prev) => ({
              ...prev,
              cuenta_contable_ventas: cuenta_contable_ventas,
            }));
          }

          if (Number(valorTarifa) > 0) {
            setForm((prev) => ({
              ...prev,
              cuenta_contable_ventas: cuenta_contable_iva_ventas,
            }));
          }
        }
      );
    }
  }, [form.id_iva_producto]);

  const onEdit = async (data, change) => {
    setError({ show: false, message: "" });

    if (change === "descuento") {
      const tarifasChange = tarifas.map((t) => {
        if (t.medida === data.medida && t.tarifa === data.tarifa) {
          return {
            ...t,
            ...data,
            descuento: data.descuento,
          };
        }

        return t;
      });
      setTarifas(tarifasChange);

      return;
    }
    setIsLoadingTarifas(true);
    const dataRequest = {
      medida: data.medida,
      costo_compra: !form.costo_compra ? 0 : Number(form.costo_compra),
      precio_iva: Number(data.precio_iva),
      utilidad_porcentaje: Number(data.utilidad_porcentaje),
      utilidad_valor: Number(data.utilidad_valor),
      id_iva: form.id_iva_producto,
      campo_calculo: change,
      factor_superior: !form.factor_superior
        ? null
        : Number(form.factor_superior),
      factor_inferior: !form.factor_inferior
        ? null
        : Number(form.factor_inferior),
    };

    const tarifa = await calcularTarifaRequest(dataRequest)
      .finally(() => setIsLoadingTarifas(false))
      .catch((err) => {
        const data = err.response.data;
        if (data && data.statusCode === 400) {
          setError({
            show: true,
            message: data.message,
          });
        }
      });
    if (!tarifa) return;

    const tarifasChange = tarifas.map((t) => {
      if (t.medida === data.medida && t.tarifa === data.tarifa) {
        return {
          ...t,
          ...tarifa,
        };
      }

      return t;
    });
    setTarifas(tarifasChange);
  };

  const items = [
    {
      key: 1,
      label: `Datos Básicos`,
      children: (
        <TabDatosBasicos
          form={form}
          onChangeForm={onChangeForm}
          lineasProductos={lineasProductos}
          tarifasIva={tarifasIva}
          tarifas={tarifas}
          tiposEcommerceProducto={tiposEcommerceProducto}
          medidasProducto={medidasProducto}
          onEdit={onEdit}
          isLoadingTarifas={isLoadingTarifas}
        />
      ),
    },

    {
      key: 2,
      label: `Imágenes`,
      children: <TabImagenes form={form} onChangeForm={onChangeForm} />,
    },
  ];

  const validarCampos = () => {
    const { codigo, descripcion } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion del servicio",
      });
      return false;
    }

    if (codigo.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese un codigo para su servicio",
      });
      return false;
    }

    return true;
  };

  const crear = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      codigo: form.codigo,
      descripcion: form.descripcion,
      costo_estandar: !form.costo_estandar ? null : Number(form.costo_estandar),
      activo: form.activo,
      controla_precio: form.controla_precio,
      compuesto: form.compuesto,
      codigo_ice: form.codigo_ice,
      porcentaje_ice: !form.porcentaje_ice ? 0 : Number(form.porcentaje_ice),
      valor_ice: !form.valor_ice ? 0 : Number(form.valor_ice),
      id_cuenta_contable_ventas:
        form.cuenta_contable_ventas?.id_cuenta_contable,
      id_tipo_ecommerce_producto: form.id_tipo_ecommerce_producto,
      imagenes: form.imagenes,
      id_linea_producto: form.id_linea_producto,
      id_iva_producto: form.id_iva_producto,
      id_medida_interna: form.id_medida_interna,
      id_empresa: empresa.id_empresa,
      tarifas_producto: tarifas
        .filter((t) => t.tarifa != "Tarifas en: ")
        .map((t) => {
          return {
            medida: t.medida,
            tarifa: t.tarifa,
            precio: !t.precio ? 0 : Number(t.precio),
            precio_iva: !t.precio_iva ? 0 : Number(t.precio_iva),
            margen: !t.margen ? 0 : Number(t.margen),
            utilidad_porcentaje: !t.utilidad_porcentaje
              ? 0
              : Number(t.utilidad_porcentaje),
            utilidad_valor: !t.utilidad_valor ? 0 : Number(t.utilidad_valor),
            descuento: !t.descuento ? 0 : Number(t.descuento),
          };
        }),
    };

    setIsLoading(true);
    await crearServicioRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../servicios/${res.id_producto}`, { replace: true });
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => setIsLoading(false));
  };


  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Servicios",
                },
                {
                  title: "Crear Servicio",
                },
              ]}
            />
          </Row>
          <Row>
            <ButtonAsync onClick={crear} type="primary" text="Crear" />
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
