import { decryptString, encryptString } from "../crypto/crypto";


export const empresaStorageSave = (data) => {
    const stringfy = encryptString(JSON.stringify(data));
    localStorage.setItem('@empresa', stringfy)
    return true;
}

export const empresaStorageGet = () => {
    const stringfy = localStorage.getItem('@empresa');
    return stringfy ? JSON.parse(decryptString(stringfy)) : null;
}

export const empresaStorageRemove = () => {
    localStorage.removeItem('@empresa');
}