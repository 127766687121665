import API from "../../configs/api/api.config";

const URL_SERVICIOS = '/servicios';

export const serviciosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.get(URL_SERVICIOS + `/empresa/${id_empresa}`);
    const response = request.data;
    return response;
}

export const generarCodigoServicioRequest = async (id_empresa) => {
    const request = await API.post(URL_SERVICIOS + '/generar-codigo', { id_empresa });
    const response = request.data;
    return response;
}


export const servicioIdRequest = async (id_servicio) => {
    const request = await API.get(URL_SERVICIOS + '/' + id_servicio);
    const response = request.data;
    return response;
}

export const crearServicioRequest = async (data) => {
    const request = await API.post(URL_SERVICIOS, data);
    const response = request.data;
    return response;
}

export const actualizarServicioRequest = async (id_servicio, data) => {
    const request = await API.patch(URL_SERVICIOS + '/' + id_servicio, data);
    const response = request.data;
    return response;
}

export const eliminarServicioRequest = async (id_servicio) => {
    const request = await API.delete(URL_SERVICIOS + '/' + id_servicio);
    const response = request.data;
    return response;
}

export const subirImagenServicioRequest = async (data) => {
    const request = await API.post(URL_SERVICIOS + '/subir-imagen', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}