import { ConfigProvider } from 'antd'
import React from 'react'
import { PRIMARY_COLOR } from '../constants/colors'

export const AppTheme = ({
    children
}) => {
  return (
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: PRIMARY_COLOR,
            },
    
        }}
    >
        {
         children
        }
    </ConfigProvider>
  )
}
