import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import {
  eliminarEmpresaRequest,
  empresaIdRequest,
} from "../../../../services/api/empresas";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import EditarEmpresaDrawer from "./components/EditarEmpresaDrawer";
import { UserContext } from "../../../../context/UserContext";
import { ActionContext } from "../../../../context/ActionContext";

export const EmpresaPage = () => {
  const { checkPermisos, checkData } = useContext(UserContext);
  const { reloadEmpresas } = useContext(ActionContext);

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [empresa, setEmpresa] = useState({});
  const [reload, setReload] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setEmpresa({});
    empresaIdRequest(id)
      .then((res) => {
        setEmpresa(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reload]);

  const onReload = () => setReload(!reload);

  const eliminar = () => {
    const id_empresa = empresa.id_empresa;
    if (!id_empresa) return;
    if (id_empresa != empresa.id_empresa) {
      alert(
        "No se encuentra en la empresa, cambie en la parte superior la empresa que desea eliminar."
      );
      return;
    }
    const confirm = window.confirm(
      "¿Esta seguro de eliminar la empresa, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarEmpresaRequest(id_empresa).then((res) => {
      reloadEmpresas();
      checkData();
      navigate(-1);
    });
  };

  return (
    <Content>
      {/* Header */}
      <Row
        align={"middle"}
        style={{ marginBottom: 5 }}
        justify={"space-between"}
      >
        <Row align={"middle"}>
          <Tooltip title={"Regresar"}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              shape="circle"
              icon={<IoArrowBack size={20} />}
              style={{ marginRight: 10 }}
            />
          </Tooltip>
          <Breadcrumb
            items={[
              {
                title: (
                  <Row align={"middle"}>
                    <IoSettingsSharp style={{ marginRight: 5 }} />
                    <span>Administración</span>
                  </Row>
                ),
              },
              {
                title: "Empresas",
              },
              {
                title: empresa.nombre_comercial_empresa,
              },
            ]}
          />
        </Row>
        <Row gutter={10} align={"middle"} justify={"space-around"}>
          <Col>
            <Button
              type="primary"
              onClick={showDrawer}
              disabled={!checkPermisos("MEMPRESAS").editar}
              icon={<EditFilled />}
            >
              Editar
            </Button>
          </Col>
          <Col>
            <Button
              danger
              type="primary"
              disabled={!checkPermisos("MEMPRESAS").eliminar}
              onClick={eliminar}
              icon={<DeleteFilled />}
            >
              Eliminar
            </Button>
          </Col>
        </Row>
      </Row>
      {/* Datos */}
      <Spin spinning={isLoading}>
        <Card
          title="Información de la Empresa"
          style={{ marginTop: 10 }}
          size="small"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Logo Empresa">
                  <Image
                    style={{
                      maxHeight: 80,
                      borderRadius: 5,
                    }}
                    src={empresa?.logo_empresa}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Identificación Empresa">
                  {empresa.identificacion_empresa}
                </Descriptions.Item>
                <Descriptions.Item label="Razón Social">
                  {empresa.razon_social_empresa}
                </Descriptions.Item>
                <Descriptions.Item label="Nombre Comercial">
                  {empresa.nombre_comercial_empresa}
                </Descriptions.Item>
                <Descriptions.Item label="Dirección">
                  {empresa.direccion_empresa}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {empresa.email_empresa}
                </Descriptions.Item>
                <Descriptions.Item label="Representante Legal">
                  {empresa.representante_legal || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Identificación Representante Legal">
                  {empresa.identificacion_representante_legal || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Nombre del Contador">
                  {empresa.nombre_contador || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Identificación del Contador">
                  {empresa.identificacion_contador || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Realiza ATS">
                  {empresa.realiza_ats ? "Sí" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Lleva Contabilidad">
                  {empresa.lleva_contabilidad ? "Sí" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Agente Retención">
                  {empresa.agente_retencion?.descripcion_agente_retencion ||
                    "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo Régimen">
                  {empresa.tipo_regimen?.descripcion_regimen || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono 1">
                  {empresa.telefono_1 || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono 2">
                  {empresa.telefono_2 || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono 3">
                  {empresa.telefono_3 || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Provincia">
                  {empresa.provincia?.nombre_provincia || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Cantón">
                  {empresa.canton?.nombre_canton || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Parroquia">
                  {empresa.parroquia?.nombre_parroquia || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      </Spin>
      {empresa.id_empresa && (
        <EditarEmpresaDrawer
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
          empresa={{
            ...empresa,
            id_agente_retencion: empresa.agente_retencion?.id_agente_retencion,
            id_tipo_regimen: empresa.tipo_regimen?.id_tipo_regimen,
            id_provincia: empresa.provincia?.id_provincia,
            id_canton: empresa.canton?.id_canton,
            id_parroquia: empresa.parroquia?.id_parroquia,
          }}
          reload={onReload}
        />
      )}
    </Content>
  );
};
