import React, { useContext, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { EditarAlmacen } from "./EditarAlmacen";
import { eliminarAlmacenRequest } from "../../../../../services/api/almacenes";

export const TablaAlmacenes = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadAlmacenes } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_almacen = record.id_almacen;
    if (!id_almacen) return;
    setId(id_almacen);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_almacen = record.id_almacen;
    if (!id_almacen) return;
    if (record.predeterminado) {
      alert("No puede eliminar el predeterminado");
      return;
    }
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este almacen, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarAlmacenRequest(id_almacen).then((res) => {
      reloadAlmacenes();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MALMACENES").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MALMACENES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "nombre_almacen",
      key: "nombre_almacen",
      width: "60%",
      ...getColumnSearchProps("nombre_almacen"),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_almacen"}
        columns={columns}
        dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
        size="small"
      />
      <EditarAlmacen
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
