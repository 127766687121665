import { Form, Input, Table, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      let change = false;
      for (const propety in values) {
        if (record[propety] != values[propety]) {
          change = true;
          break;
        }
      }
      toggleEdit();
      if (!change) return;
      handleSave(
        {
          ...record,
          ...values,  
        },
        dataIndex
      );
    } catch (errInfo) {
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          type={"number"}
          size={"small"}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export const TablaTarifas = ({
  data,
  form,
  medidasProducto,
  onEdit = () => {},
}) => {
  const renderTextMedida = (medida) => {
    if (medida === "superior") {
      const id = form?.["id_medida_superior"];
      const text =
        medidasProducto.find((m) => m.id_medida_producto === id)?.descripcion ??
        "";
      return text;
    }

    if (medida === "interna") {
      const id = form?.["id_medida_interna"];
      const text =
        medidasProducto.find((m) => m.id_medida_producto === id)?.descripcion ??
        "";
      return text;
    }

    if (medida === "inferior") {
      const id = form?.["id_medida_inferior"];
      const text =
        medidasProducto.find((m) => m.id_medida_producto === id)?.descripcion ??
        "";
      return text;
    }
  };

  const handleSave = (row, dataIndex) => {
    onEdit(row, dataIndex);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: "Tarifa",
      dataIndex: "tarifa",
      key: "tarifa",
      width: "15%",
      ellipsis: true,
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            children: (
              <div style={{ width: "100%", backgroundColor: "lightblue" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {record} {renderTextMedida(row.medida)}
                </Typography>
              </div>
            ),
            props: {
              colSpan: columns.length,
            },
          };
        }

        return record;
      },
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      width: "15%",
      ellipsis: true,
      className: "custom-column",
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
    },
    {
      title: "Precio IVA",
      dataIndex: "precio_iva",
      key: "precio_iva",
      width: "15%",
      ellipsis: true,
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "precio_iva",
        title: "Precio IVA",
        handleSave,
      }),
    },
    {
      title: "Margen",
      dataIndex: "margen",
      key: "margen",
      width: "15%",
      ellipsis: true,
      className: "custom-column",
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
    },
    {
      title: "Utilidad %",
      dataIndex: "utilidad_porcentaje",
      key: "utilidad_porcentaje",
      width: "15%",
      ellipsis: true,
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "utilidad_porcentaje",
        title: "Utilidad %",
        handleSave,
      }),
    },
    {
      title: "Utilidad $",
      dataIndex: "utilidad_valor",
      key: "utilidad_valor",
      width: "15%",
      ellipsis: true,
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "utilidad_valor",
        title: "Utilidad $",
        handleSave,
      }),
    },
    {
      title: "Desc.",
      dataIndex: "descuento",
      key: "descuento",
      width: "10%",
      ellipsis: true,
      render: (record, row) => {
        if (row.tarifa === "Tarifas en: ") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return record;
      },
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "descuento",
        title: "Desc.",
        handleSave,
      }),
    },
  ];

  const getRowClassName = (record, index) => {
    if (record.tarifa === "Tarifa en: ") {
      return "custom-row-tarifa";
    }
    return "custom-row";
  };

  return (
    <Table
      components={components}
      size="small"
      columns={columns}
      dataSource={data}
      rowClassName={getRowClassName}
      className="small-table"
      pagination={{
        position: ["topRight"],
        pageSize: 6,
        size: "small",
        itemRender: (page, type) => {
          let indicator = "";
          if (data.length === 6) {
            indicator = "Interna";
          } else {
            if (page === 1) {
              indicator = "Superior";
              const filterInterna = data.filter((t) => t.medida === "interna");
              const filterSuperior = data.filter(
                (t) => t.medida === "superior"
              );
              if (filterInterna.length === 6 && filterSuperior.length === 0) {
                indicator = "Interna";
              }
            }
            if (page === 2) {
              indicator = "Interna";
              const filter = data.filter((t) => t.medida === "inferior");
              const filterSuperior = data.filter(
                (t) => t.medida === "superior"
              );
              if (filter.length === 6 && filterSuperior.length === 0) {
                indicator = "Inferior";
              }
            }
            if (page === 3) {
              indicator = "Inferior";
            }
          }

          if (type === "page") {
            return <span style={{ marginInline: 10 }}>{indicator}</span>;
          }

          return null;
        },
        style: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );
};
