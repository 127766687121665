import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import {
  agentesVentasIdEmpresaRequest,
  validarFacturadorRequest,
} from "../../../../services/api/agentes-ventas";
import { clientePredeterminadoRequest } from "../../../../services/api/clientes";
import {
  calcularTotalesFacturaRequest,
  crearFacturaRequest,
} from "../../../../services/api/facturacion";
import { crearProductoRequest } from "../../../../services/api/productos";
import { secuenciaIdRequest } from "../../../../services/api/secuencias";
import { TabFormaCobro } from "./components/TabFormaCobro";
import { TabMantenimiento } from "./components/TabMantenimiento";
import {
  formasPagoSRIRequest,
  tarifasIvaRequest,
  tiposFormaPagoRequest,
} from "../../../../services/api/opciones";
import { CODIGOS_IMPUESTOS } from "../../../../helpers/constants/codigos-impuestos";
import {
  dateDDMMYYYYHHmm,
  dateYYYYMMDD,
} from "../../../../helpers/format/dates-formater";
import { UNIDAD_TIEMPO } from "../../../../helpers/constants/unidad-tiempo";
import { cajasIdEmpresaRequest } from "../../../../services/api/cajas";
import { bancosIdEmpresaRequest } from "../../../../services/api/bancos";
import { bancosClientesIdEmpresaRequest } from "../../../../services/api/bancos-clientes";
import { TabFormaPago } from "./components/TabFormaPago";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";
import { handleApiError } from "../../../../global/functions/handle-api-error.function";

export const CrearFacturaPage = () => {

  const { isProductosChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();
  const inputPasswordRef = useRef();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    fecha_emision: dayjs(new Date()),
    fecha_vence: dayjs(new Date()),
    secuencia: "",
    concepto: "",
    observacion_adicional: "",
    contabilizado: true,
    imprimir: false,
    id_agente_venta_facturador: null,
    id_agente_venta_vendedor: null,
    id_almacen: null,
    id_tarifa: null,
    id_secuencia: null,
    id_cliente: null,
  });

  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [errorValidate, setErrorValidate] = useState({
    show: false,
    message: false,
  });

  const [facturadores, setFacturadores] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [secuencia, setSecuencia] = useState({});

  const [cajas, setCajas] = useState([]);
  const [formasPagosSri, setFormasPagosSri] = useState([]);
  const [tiposFormasPagos, setTiposFormasPagos] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [bancosClientes, setBancosClientes] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [ivas, setIvas] = useState([]);


  const [totales, setTotales] = useState({
    sub_total: 0,
    descuento_porcentaje: 0,
    descuento_valor: 0,
    sub_total_neto: 0,
    sub_total_iva: 0,
    sub_total_iva_cero: 0,
    sub_total_no_objeto: 0,
    sub_total_exento: 0,
    total_ice: 0,
    total_iva: 0,
    propina: 0,
    total: 0,
    neto: 0,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [errorCalculos, setErrorCalculos] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetalles, setIsLoadingDetalles] = useState(false);
  const [isLoadingTotales, setIsLoadingTotales] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    if (!isValid) return;
    loadInitValues();
  }, [isValid, empresa]);

  const loadInitValues = () => {
    setIsLoading(true);
    Promise.all([
      agentesVentasIdEmpresaRequest(empresa.id_empresa),
      clientePredeterminadoRequest(empresa.id_empresa),
      formasPagoSRIRequest(),
      cajasIdEmpresaRequest(empresa.id_empresa),
      tiposFormaPagoRequest(),
      bancosIdEmpresaRequest(empresa.id_empresa),
      bancosClientesIdEmpresaRequest(empresa.id_empresa),
      tarifasIvaRequest()
    ])
      .then(
        ([
          agentesVentasRes,
          clientePredeterminado,
          formasPagoSRIRes,
          cajasRes,
          tiposFormasPagosRes,
          bancosRes,
          bancosClientesRes,
          tarifasIvaRes
        ]) => {
          setFormasPagosSri(formasPagoSRIRes);
          setCajas(cajasRes);
          setTiposFormasPagos(tiposFormasPagosRes);
          setBancos(bancosRes);
          setBancosClientes(bancosClientesRes);
          setIvas(tarifasIvaRes);

          if (agentesVentasRes.length > 0) {
            const filterFacturadores = agentesVentasRes.filter(
              (a) => a.facturador
            );
            const filterVendedores = agentesVentasRes.filter((a) => a.vendedor);

            setFacturadores(filterFacturadores);
            setVendedores(filterVendedores);

            const findFacturador = filterFacturadores[0];
            if (findFacturador) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_facturador: findFacturador.id_agente_venta,
              }));
            }

            const findVendedor = filterVendedores[0];
            if (findVendedor) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_vendedor: findVendedor.id_agente_venta,
              }));
            }
          }

          if (user.almacenes?.length > 0) {
            const findAlmacen = user.almacenes?.find((a) => a.principal);
            if (findAlmacen) {
              setForm((prev) => ({
                ...prev,
                id_almacen: findAlmacen.id_almacen,
              }));
            }
          }

          if (user.tarifas?.length > 0) {
            const findTarifa = user.tarifas?.find((a) => a.principal);
            if (findTarifa) {
              setForm((prev) => ({
                ...prev,
                id_tarifa: findTarifa.id_tarifa,
              }));
            }
          }

          if (user.secuencias?.length > 0) {
            const findSecuencia = user.secuencias?.find((a) => a.principal);
            if (findSecuencia) {
              setForm((prev) => ({
                ...prev,
                id_secuencia: findSecuencia.id_secuencia,
              }));
            }
          }

          if (clientePredeterminado) {
            setForm((prev) => ({
              ...prev,
              cliente: clientePredeterminado,
            }));
          }

          

        }
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (form.id_secuencia) {
      setIsLoading(true);
      secuenciaIdRequest(form.id_secuencia)
        .then((res) => {
          setSecuencia(res);
          setForm((prev) => ({
            ...prev,
            codigo_establecimiento: res.sucursal,
            codigo_punto_emision: res.punto_emision,
            secuencia: res.numero_actual,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [form.id_secuencia]);

  const seleccionarProducto = (p) => {
    const index = productos.findIndex((e) => e.id_producto === p.id_producto);
    setProductoSeleccionado(index);
  };

  const eliminarProducto = (p) => {
    const temporal = productos.filter(
      (producto) => producto.id_producto !== p.id_producto
    );
    calcularTotales(temporal, totales.descuento_porcentaje, totales.propina);
    setProductoSeleccionado(null);
  };

  const agregarProducto = (p) => {
    const find = productos.find(
      (producto) => producto.id_producto === p.id_producto
    );
    if (find) return;
    const detalles = [
      ...productos,
      {
        ...p,
        cantidad: 1,
        id_medida: p.medida_interna?.id_medida_producto,
        medida: p.medida_interna?.descripcion,
        precio:
          p.tarifas_productos?.find(
            (tp) =>
              tp.medida === "interna" &&
              tp.tarifa ===
              user.tarifas?.find((t) => t.id_tarifa === form.id_tarifa)
                .descripcion
          )?.precio ?? 0,
        descuento: 0,
        iva: p.iva_producto.descripcion,
      },
    ];
    calcularTotales(detalles, totales.descuento_porcentaje, totales.propina);
  };

  const validarCampos = () => {
    const { fecha_emision, fecha_vence, secuencia } = form;

    if (!dayjs(fecha_emision).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha de Emision",
      });
      return false;
    }

    if (!dayjs(fecha_vence).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!Number.isInteger(Number(secuencia)) || Number(secuencia) <= 0) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (productos.length === 0) {
      setError({
        show: true,
        message: "Agrege productos a la factura",
      });
      return false;
    }

    return true;
  };

  const crear = async () => {
    setError({ show: false, message: "" });

    const validacion = validarCampos();
    if (!validacion) return;

    const detalles_documentos = productos.map((p) => {
      return {
        codigo_principal: p.codigo,
        codigo_auxiliar: p.codigo_auxiliar,
        descripcion: p.descripcion,
        descripcion_remplazo: p.descripcion_remplazo ?? "",
        detalle_adicional_1: p.detalle_adicional_1 ?? "",
        detalle_adicional_2: p.detalle_adicional_2 ?? "",
        detalle_adicional_3: p.detalle_adicional_3 ?? "",
        cantidad: !p.cantidad ? 0 : Number(p.cantidad),
        precio_unitario: !p.precio_unitario ? 0 : Number(p.precio_unitario),
        descuento: !p.descuento ? 0 : Number(p.descuento),
        valor_subsidio: !p.valor_subsidio ? 0 : Number(p.valor_subsidio),
        precio_sin_subsidio: !p.precio_sin_subsidio
          ? 0
          : Number(p.precio_sin_subsidio),
        precio_total_sin_impuestos: !p.precio_total_sin_impuestos
          ? 0
          : Number(p.precio_total_sin_impuestos),
        codigo_iva: CODIGOS_IMPUESTOS.IVA,
        codigo_porcentaje_iva: Number(p.codigo_porcentaje_iva).toString(),
        tarifa_iva: !p.tarifa_iva ? 0 : Number(p.tarifa_iva),
        base_imponible_iva: !p.base_imponible_iva
          ? 0
          : Number(p.base_imponible_iva),
        valor_iva: !p.valor_iva ? 0 : Number(p.valor_iva),
        codigo_ice: CODIGOS_IMPUESTOS.ICE,
        codigo_porcentaje_ice: !p.codigo_porcentaje_ice
          ? null
          : Number(p.codigo_porcentaje_ice).toString(),
        tarifa_ice: !p.tarifa_ice ? 0 : Number(p.tarifa_ice),
        base_imponible_ice: !p.base_imponible_ice
          ? 0
          : Number(p.base_imponible_ice),
        valor_ice: !p.valor_ice ? 0 : Number(p.valor_ice),
        codigo_irbpnr: CODIGOS_IMPUESTOS.IRBPNR,
        codigo_porcentaje_irbpnr: !p.codigo_porcentaje_irbpnr
          ? null
          : Number(p.codigo_porcentaje_irbpnr).toString(),
        tarifa_irbpnr: !p.tarifa_irbpnr ? 0 : Number(p.tarifa_irbpnr),
        base_imponible_irbpnr: !p.base_imponible_irbpnr
          ? 0
          : Number(p.base_imponible_irbpnr),
        valor_irbpnr: !p.valor_irbpnr ? 0 : Number(p.valor_irbpnr),
        total: !p.total ? 0 : Number(p.total),
        id_producto: p.id_producto,
        id_medida_producto: p.id_medida,
      };
    });

    const pagos_documentos = pagos.map((pago) => {
      const fecha = pago.fecha ? pago.fecha : dayjs(new Date());
      const fecha_vence = pago.fecha_vence
        ? pago.fecha_vence
        : dayjs(new Date());
      const plazo = dayjs(form.fecha_vence).diff(form.fecha_emision, "day");

      return {
        plazo: plazo,
        unidad_tiempo: UNIDAD_TIEMPO.DIAS,
        total: Number(pago.total),
        fecha: dateYYYYMMDD(fecha),
        fecha_vence: dateYYYYMMDD(fecha_vence),
        numero_documento: pago.numero_documento,
        id_forma_pago_sri: pago.id_forma_pago_sri,
        id_tipo_forma_pago: pago.id_tipo_forma_pago,
        id_caja: pago.id_caja,
        id_banco: pago.id_banco,
        id_banco_cliente: pago.id_banco_cliente,
      };
    });

    const dataRequest = {
      fecha_emision: dateYYYYMMDD(form.fecha_emision),
      fecha_vence: dateYYYYMMDD(form.fecha_vence),
      secuencia: Number(form.secuencia),
      concepto: form.concepto,
      observacion_adicional: form.observacion_adicional,
      contabilizado: form.contabilizado,
      imprimir: form.imprimir,
      sub_total: !totales.sub_total ? 0 : Number(totales.sub_total),
      descuento_porcentaje: !totales.descuento_porcentaje
        ? 0
        : Number(totales.descuento_porcentaje),
      descuento_valor: !totales.descuento_valor
        ? 0
        : Number(totales.descuento_valor),
      sub_total_neto: !totales.sub_total_neto
        ? 0
        : Number(totales.sub_total_neto),
      sub_total_iva: !totales.sub_total_iva ? 0 : Number(totales.sub_total_iva),
      sub_total_iva_cero: !totales.sub_total_iva_cero
        ? 0
        : Number(totales.sub_total_iva_cero),
      sub_total_no_objeto: !totales.sub_total_no_objeto
        ? 0
        : Number(totales.sub_total_no_objeto),
      sub_total_exento: !totales.sub_total_exento
        ? 0
        : Number(totales.sub_total_exento),
      total_ice: !totales.total_ice ? 0 : Number(totales.total_ice),
      total_iva: !totales.total_iva ? 0 : Number(totales.total_iva),
      propina: !totales.propina ? 0 : Number(totales.propina),
      total: !totales.total ? 0 : Number(totales.total),
      neto: !totales.neto ? 0 : Number(totales.neto),
      id_empresa: empresa.id_empresa,
      id_agente_venta_facturador: form.id_agente_venta_facturador,
      id_agente_venta_vendedor: form.id_agente_venta_vendedor,
      id_almacen: form.id_almacen,
      id_tarifa: form.id_tarifa,
      id_secuencia: form.id_secuencia,
      id_cliente: form.cliente.id_cliente,
      detalles_documentos: detalles_documentos,
      pagos_documentos: pagos_documentos,
    };

    setIsLoading(true);

    await crearFacturaRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../facturacion/facturas/${res.id_documento}`, {
          replace: true,
        });
      })
      .catch((error) => handleApiError(error, setError))
      .finally(() => setIsLoading(false));
  };

  const onOk = (e) => {
    e?.preventDefault();

    setErrorValidate({ show: false, message: "" });

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      password: password,
    };

    setIsValidating(true);
    validarFacturadorRequest(dataRequest)
      .then((res) => {
        setUser(res);
        setIsValid(true);
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data && data.statusCode === 404) {
          setErrorValidate({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => setIsValidating(false));
  };

  useEffect(() => {
    if (!isValid) {
      inputPasswordRef.current?.focus();
    }
  }, [isValid]);

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    tarifasIvaRequest()
  }, []);

  const onChangeDetalles = (data, dataIndex) => {
    const detalles = productos.map((producto) => {
      if (producto.id_producto === data.id_producto) {
        if(dataIndex === 'iva_producto'){
          const id_iva = data.iva_producto;
          const iva = ivas.find(i => i.id_iva === id_iva);
          return {
            ...data,
            iva_producto: iva,
          }
        }
        return data;
      }
      return producto;
    });

    calcularTotales(detalles, totales.descuento_porcentaje, totales.propina);
  };

  const onChangeTotales = (data) => {
    calcularTotales(productos, data.descuento_porcentaje, data.propina);
  };

  const calcularTotales = (p, descuento_porcentaje, propina) => {
    setErrorCalculos({
      show: false,
      message: "",
    });

    const dataRequest = {
      detalles_documentos: p.map((producto) => {
        return {
          cantidad: Number(producto.cantidad),
          precio_unitario: Number(producto.precio),
          descuento: Number(producto.descuento),
          subsidio: Number(producto.subsidio),
          codigo_porcentaje_iva: Number(producto.iva_producto.codigo),
          tarifa_iva: Number(producto.iva_producto.valor),
          tarifa_ice: !producto.porcentaje_ice
            ? null
            : Number(producto.porcentaje_ice),
          tarifa_irbpnr: !producto.porcentaje_irbpnr
            ? null
            : Number(producto.porcentaje_irbpnr),
        };
      }),
      descuento_porcentaje: Number(descuento_porcentaje),
      propina: Number(propina),
    };

    setIsLoadingDetalles(true);
    setIsLoadingTotales(true);
    calcularTotalesFacturaRequest(dataRequest)
      .then((res) => {
        const detallesRes = res.detalles_documentos.map((d, index) => ({
          ...p[index],
          ...d,
        }));
        setProductos(detallesRes);
        const totalesRes = {
          sub_total: res.sub_total,
          descuento_porcentaje: res.descuento_porcentaje,
          descuento_valor: res.descuento_valor,
          sub_total_neto: res.sub_total_neto,
          sub_total_iva: res.sub_total_iva,
          sub_total_iva_cero: res.sub_total_iva_cero,
          sub_total_no_objeto: res.sub_total_no_objeto,
          sub_total_exento: res.sub_total_exento,
          total_ice: res.total_ice,
          total_iva: res.total_iva,
          propina: res.propina,
          total: res.total,
          neto: res.neto,
        };
        setTotales(totalesRes);
      })
      .catch((err) => {
        const data = err?.response?.data;
        if (data && data.statusCode === 400) {
          if (data.message.includes("propina")) {
            setTotales({
              ...totales,
              propina: 0,
            });
          }
          setErrorCalculos({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingDetalles(false);
        setIsLoadingTotales(false);
      });
  };

  const onChangeDetalle = (d) => {
    const index = productos.findIndex((e) => e.id_producto === d.id_producto);
    const arrayTemp = productos.map((e, i) => {
      if (index === i) return { ...e, ...d };
      return e;
    });

    setProductos(arrayTemp);
  };

  const onChangeObservacionAdicional = (d) => {
    setForm((prev) => ({
      ...prev,
      ...d,
    }));
  };

  const registrarPago = (pago) => {
    if (totales.total === 0) {
      alert("El total es 0 no se puede agregar un pago");
      return;
    }

    const tipo_forma_pago = tiposFormasPagos.find(
      (tipoFormaPago) =>
        tipoFormaPago.id_tipo_forma_pago === pago.id_tipo_forma_pago
    );

    const banco = bancos.find((banco) => banco.id_banco === pago.id_banco);

    const caja = cajas.find((caja) => caja.id_caja === pago.id_caja);

    const forma_pago_sri = formasPagosSri.find(
      (formaPagoSri) =>
        formaPagoSri.id_forma_pago_sri === pago.id_forma_pago_sri
    );

    const banco_cliente = bancosClientes.find(
      (bancoCliente) => bancoCliente.id_banco_cliente === pago.id_banco_cliente
    );

    const index = pagos.length;

    const nuevoPago = {
      ...pago,
      index: index,
      tipo_forma_pago: tipo_forma_pago,
      caja: caja,
      banco: banco,
      forma_pago_sri: forma_pago_sri,
      banco_cliente: banco_cliente,
    };

    setPagos((prev) => [...prev, nuevoPago]);
  };

  const eliminarPago = (pagoData) => {
    const pagosLista = pagos
      .filter((_, index) => index !== pagoData.index)
      .map((pago, index) => ({ ...pago, index: index }));
    setPagos(pagosLista);
  };

  const editarPago = (pagoData) => {
    const pagosLista = pagos.map((pago, index) => {
      if (pagoData.index === index) {
        return {
          ...pago,
          ...pagoData,
        };
      }
      return pago;
    });
    setPagos(pagosLista);
  };

  const items = [
    {
      key: 1,
      label: `Mantenimiento`,
      children: (
        <TabMantenimiento
          form={form}
          onChangeForm={onChangeForm}
          user={user}
          facturadores={facturadores}
          vendedores={vendedores}
          almacenes={user.almacenes}
          tarifas={user.tarifas}
          secuencias={user.secuencias}
          productos={productos}
          productoSeleccionado={productos[productoSeleccionado]}
          seleccionarProducto={seleccionarProducto}
          eliminarProducto={eliminarProducto}
          agregarProducto={agregarProducto}
          totales={totales}
          setTotales={setTotales}
          onChange={onChangeDetalles}
          isLoadingDetalles={isLoadingDetalles}
          isLoadingTotales={isLoadingTotales}
          onChangeTotales={onChangeTotales}
          errorCalculos={errorCalculos}
          setErrorCalculos={setErrorCalculos}
          onChangeDetalle={onChangeDetalle}
          onChangeObservacionAdicional={onChangeObservacionAdicional}
          isPuntoEmision={!!secuencia.identificacion_transporte}
          razon_social_transporte={secuencia.razon_social_transporte}
          identificacion_transporte={secuencia.identificacion_transporte}
          punto_emision={secuencia.punto_emision}
          codigo_establecimiento={secuencia.sucursal_transporte}
          codigo_punto_emision={secuencia.punto_emision_transporte}
          secuencia_text={secuencia.numero_actual_transporte}
          clave_acceso={""}
          autorizacion={secuencia.numero_autorizacion_sri_transporte}
          ivas={ivas}
        />
      ),
    },
    {
      key: 2,
      label: `Forma de cobro`,
      children: (
        <TabFormaPago
          pagos={pagos}
          totales={totales}
          formasPagosSri={formasPagosSri}
          cajas={cajas}
          bancos={bancos}
          bancosClientes={bancosClientes}
          registrarPago={registrarPago}
          eliminarPago={eliminarPago}
          editarPago={editarPago}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Facturación",
                },
                {
                  title: "Crear Factura",
                },
              ]}
            />
          </Row>
          <Row>
            <ButtonAsync onClick={crear} type="primary" text="Crear" />
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}

        <Form disabled={!isValid}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>

      <Modal
        title="Clave Facturador"
        open={!isValid}
        confirmLoading={isValidating}
        onCancel={onCancel}
        maskClosable={false}
        centered
        okText={"Continuar"}
        onOk={onOk}
      >
        {errorValidate.show && (
          <Alert
            style={{ marginBlock: 10 }}
            description={errorValidate.message}
            type="error"
            closable
            onClose={() => setErrorValidate({ show: false, message: "" })}
          />
        )}
        <form onSubmit={onOk}>
          <Input.Password
            ref={inputPasswordRef}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
      </Modal>
    </Spin>
  );
};
