import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { memo, useEffect } from "react";
import { useForm } from "../../../../../hooks/useForm";

const { TextArea } = Input;

export const ModalInformacionDetalle = memo(
  ({ detalle, onChangeDetalle = (d) => {}, isModalOpen, handleCancel }) => {
    const { form, setForm, onChangeForm, onResetForm } = useForm({
      descripcion_remplazo: detalle.descripcion_remplazo,
      detalle_adicional_1: detalle.detalle_adicional_1,
      detalle_adicional_2: detalle.detalle_adicional_2,
      detalle_adicional_3: detalle.detalle_adicional_3,
    });

    useEffect(() => {
      if (isModalOpen) {
        setForm((prev) => ({
          ...prev,
          descripcion_remplazo: detalle.descripcion_remplazo,
          detalle_adicional_1: detalle.detalle_adicional_1,
          detalle_adicional_2: detalle.detalle_adicional_2,
          detalle_adicional_3: detalle.detalle_adicional_3,
        }));
      } else {
        onResetForm();
      }
    }, [isModalOpen]);

    return (
      <Modal
        title="Información Detalle"
        open={isModalOpen}
        width={500}
        onCancel={() => {
          handleCancel();
        }}
        onOk={() => {
          onChangeDetalle({ ...detalle, ...form });
          handleCancel();
        }}
        okText={"Guardar Cambios"}
      >
        <Row style={{ marginBlock: 10, marginBottom: 20 }}>
          <Col span={24}>
            <Typography
              style={{
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {detalle.descripcion}
            </Typography>
            <Typography
              style={{
                marginTop: 5,
              }}
            >
              Reemplazar Descripción:
            </Typography>
            <TextArea
              showCount
              maxLength={300}
              value={form.descripcion_remplazo}
              onChange={(e) =>
                onChangeForm("descripcion_remplazo", e.target.value)
              }
            />
            <Typography
              style={{
                marginTop: 5,
              }}
            >
              Detalle adicional (1)
            </Typography>
            <TextArea
              showCount
              maxLength={300}
              rows={1}
              value={form.detalle_adicional_1}
              onChange={(e) =>
                onChangeForm("detalle_adicional_1", e.target.value)
              }
            />
            <Typography
              style={{
                marginTop: 5,
              }}
            >
              Detalle adicional (2)
            </Typography>
            <TextArea
              showCount
              rows={1}
              maxLength={300}
              value={form.detalle_adicional_2}
              onChange={(e) =>
                onChangeForm("detalle_adicional_2", e.target.value)
              }
            />
            <Typography
              style={{
                marginTop: 5,
              }}
            >
              Detalle adicional (3)
            </Typography>
            <TextArea
              showCount
              maxLength={300}
              rows={1}
              value={form.detalle_adicional_3}
              onChange={(e) =>
                onChangeForm("detalle_adicional_3", e.target.value)
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
);
