import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Tooltip, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

import { PermisosForm } from "./PermisosForm";
import { IoCreateOutline, IoTrash } from "react-icons/io5";
import { actualizarModulosPermisosRequest } from "../../../../../services/api/modulos";

export const TabRol = ({
  eru,
  empresaSistemas = [],
  edit,
  onChangeEmpresaSistema = () => {},
  handleGuardarCambios = () => {},
}) => {
  return (
    <Content>
      {edit && (
        <Row justify={"end"} style={{ marginBottom: 5, marginTop: 5}}>
          <Tooltip title={"Guardar cambios de permisos"}>
            <Button size="small" type="primary" onClick={handleGuardarCambios}>
              Guardar cambios
            </Button>
          </Tooltip>
        </Row>
      )}

      <Row gutter={10} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography style={{ margin: 0, fontWeight: "bold" }}>
            Modulos
          </Typography>
        </Col>
        <Col span={14}>
          <Row justify={"space-evenly"}>
            <Typography
              style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}
            >
              Leer
            </Typography>
            <Typography
              style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}
            >
              Editar
            </Typography>
            <Typography
              style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}
            >
              Crear
            </Typography>
            <Typography
              style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}
            >
              Eliminar
            </Typography>
          </Row>
        </Col>
      </Row>
      {empresaSistemas.map((empresa_sistema) => {
        return (
          <div key={empresa_sistema.id_empresa_sistema}>
            <Divider
              key={empresa_sistema.id_empresa_sistema}
              orientation="left"
              orientationMargin={0}
              style={{marginBlock: 1}}
            >
              <Typography>
                {empresa_sistema?.sistema?.nombre_sistema}
              </Typography>
            </Divider>
            <div>
              {empresa_sistema?.sistema?.modulos?.map((modulo) => {
                return (
                  <PermisosForm
                    key={modulo.id_modulo}
                    modulo={modulo}
                    id={eru.id_empresa_rol_usuario}
                    disabled={!edit}
                    onChangeEmpresaSistema={onChangeEmpresaSistema}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </Content>
  );
};
