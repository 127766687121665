import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Checkbox, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarProveedorRequest } from "../../../../../services/api/proveedores";
import { eliminarSecuenciaRequest } from "../../../../../services/api/secuencias";

export const TablaSecuencias = ({ data }) => {

  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadSecuencias } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_secuencia = record.id_secuencia;
    if (!id_secuencia) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta secuencia, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarSecuenciaRequest(id_secuencia).then((res) => {
      reloadSecuencias();
    });
  };

  const detalleSecuencia = (data) => {
    const id_secuencia = data?.id_secuencia;
    if (!id_secuencia) return;
    navigate(`./${id_secuencia}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "8%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MSECUENCIAS-TRANSPORTISTA").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleSecuencia(record)}
            />
          </Tooltip>
          
        </Row>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "17%",
      ellipsis: true,
      ...getColumnSearchProps("descripcion"),
    },
    {
      title: "Tipo Documento",
      dataIndex: "tipo_documento",
      key: "tipo_documento",
      width: "15%",
      ellipsis: true,
      render: (record) => {
        return record?.descripcion;
      },
    },
    {
      title: "Secuencial",
      dataIndex: "numero_actual_transporte",
      key: "numero_actual_transporte",
      width: "10%",
    },
    {
      title: "C. E",
      dataIndex: "sucursal_transporte",
      key: "sucursal_transporte",
      width: "5%",
    },
    {
      title: "C. PE",
      dataIndex: "punto_emision_transporte",
      key: "punto_emision_transporte",
      width: "5%",
    },
    {
      title: "Activo",
      dataIndex: "activo",
      key: "activo",
      width: "5%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "IVA",
      dataIndex: "iva",
      key: "iva",
      width: "5%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Fiscal",
      dataIndex: "fiscal",
      key: "fiscal",
      width: "5%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "D. E",
      dataIndex: "electronico",
      key: "electronico",
      width: "5%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_secuencia"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
