import React, { useEffect, useRef } from "react";
import { FormPagoEfectivo } from "./FormPagoEfectivo";
import { useForm } from "../../../../../hooks/useForm";
import { TIPOS_FORMAS_PAGO } from "../../../../../configs/constants/tipos-formas-pagos";
import { FormPagoCredito } from "./FormPagoCredito";
import dayjs from "dayjs";

export const TabPagoCredito = ({
  pendiente,
  formasPagosSri = [],
  registrarPago = () => {},
  edit = false,
}) => {
  const valorRef = useRef();

  const { form, setForm, onChangeForm, onResetForm } = useForm({
    id_tipo_forma_pago: TIPOS_FORMAS_PAGO.CREDITO,
    total: pendiente,
    fecha_vence: dayjs(new Date()),
    id_forma_pago_sri: null,
  });

  useEffect(() => {
    valorRef.current?.focus();
    setForm((prev) => ({
      ...prev,
      total: pendiente,
    }));
  }, [pendiente]);

  useEffect(() => {
    if (formasPagosSri.length > 0) {
      setForm((prev) => ({
        ...prev,
        id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
      }));
    }
  }, [formasPagosSri]);

  const registrar = () => {

    registrarPago(form);
    setForm((prev) => ({
      ...prev,
      fecha_vence: dayjs(new Date()),
      id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
    }));
  };

  return (
    <FormPagoCredito
      form={form}
      onChangeForm={onChangeForm}
      formasPagosSri={formasPagosSri}
      registrar={registrar}
      valorRef={valorRef}
    />
  );
};
