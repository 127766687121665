import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarCuentaContableRequest } from "../../../../../services/api/cuentas-contables";
import {
  EditarCodigoRetencion,
  EditarCuentaContable,
} from "./EditarCodigoRetencion";
import { eliminarCodigoRetencionRequest } from "../../../../../services/api/codigos-retenciones";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";

export const TablaCodigosRetenciones = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadCodigosRetenciones } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_codigo_retencion = record.id_codigo_retencion;
    if (!id_codigo_retencion) return;
    setId(id_codigo_retencion);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_codigo_retencion = record.id_codigo_retencion;
    if (!id_codigo_retencion) return;
    const confirm = window.confirm( 
      "¿Esta seguro de eliminar este codigo de retencion, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCodigoRetencionRequest(id_codigo_retencion).then((res) => {
      reloadCodigosRetenciones();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MCODIGOS-RETENCIONES").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MCODIGOS-RETENCIONES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      width: "10%",
    },
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "70%",
    },
    {
      title: "%",
      dataIndex: "porcentaje",
      key: "porcentaje",
      width: "10%",
      align: "center",
      render: (record) => numberFormat(record),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 500,
        }}
        rowKey={"id_codigo_retencion"}
        columns={columns}
        dataSource={data}
        size="small"
      />
      <EditarCodigoRetencion
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
