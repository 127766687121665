import API from "../../configs/api/api.config";

const URL_SECUENCIAS = '/secuencias';

export const secuenciasIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_SECUENCIAS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const secuenciaIdRequest = async (id_secuencia) => {
    const request = await API.get(URL_SECUENCIAS + '/' + id_secuencia);
    const response = request.data;
    return response;
}

export const secuenciaIdEmpresaIdentificacionTransporteRequest = async (data) => {
    const request = await API.post(URL_SECUENCIAS + '/identificacion-transporte', data);
    const response = request.data;
    return response;
}

export const crearSecuenciaRequest = async (data) => {
    const request = await API.post(URL_SECUENCIAS, data);
    const response = request.data;
    return response;
}

export const actualizarSecuenciaRequest = async (id_secuencia, data) => {
    const request = await API.patch(URL_SECUENCIAS + '/' + id_secuencia, data);
    const response = request.data;
    return response;
}

export const eliminarSecuenciaRequest = async (id_secuencia) => {
    const request = await API.delete(URL_SECUENCIAS + '/' + id_secuencia);
    const response = request.data;
    return response;
}

export const subirImagenSecuenciaRequest = async (data) => {
    const request = await API.post(URL_SECUENCIAS + '/subir-imagen', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const subirFirmaSecuenciaRequest = async (data) => {
    const request = await API.post(URL_SECUENCIAS + '/subir-firma', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const secuenciasIdEmpresaIdentificacionTransportistaRequest = async (id_empresa, identificacion_transportista) => {
    const request = await API.get(URL_SECUENCIAS + `/empresa/${id_empresa}/transportista/${identificacion_transportista}`);
    const response = request.data;
    return response;
}
