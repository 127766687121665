import { Breadcrumb, Button, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { secuenciasIdEmpresaIdentificacionTransportistaRequest, secuenciasIdEmpresaRequest } from "../../../../services/api/secuencias";
import { TablaProveedores, TablaSecuencias } from "./components/TablaSecuencias";

export const SecuenciasTransportePage = () => {

  const { isSecuenciasChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa, usuario } = useContext(UserContext);

  const navigate = useNavigate();

  const [secuencias, setSecuencias] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    secuenciasIdEmpresaIdentificacionTransportistaRequest(empresa.id_empresa, usuario.identificacion_usuario)
      .then((res) => {
        setSecuencias(res);
      })
      .finally(() => setIsLoading(false));
  }, [isSecuenciasChange]);

  const crear = () => {
    navigate("./crear-secuencia");
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Secuencias Transportista",
                },
              ]}
            />
          </Row>
          
        </Row>
        {/* Tabla */}
        <TablaSecuencias data={secuencias} />
      </Content>
    </Spin>
  );
};
