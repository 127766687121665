import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { SidebarContext } from "../../../../../context/SidebarContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { gruposProveedoresIdEmpresaRequest } from "../../../../../services/api/grupos-proveedores";
import {
  paisesRequest,
  porcentajeRetencionRequest,
  sustentoTributarioRequest,
  tipoRegimenRequest,
  tiposCompraRequest,
  tiposEntidadRequest,
  tiposFormaPagoRequest,
  tiposIdentificacionRequest,
  tiposPagoARequest,
} from "../../../../../services/api/opciones";
import { parametrosContablesComprasVentasEmpresaRequest } from "../../../../../services/api/parametros-contables-compras-ventas";
import { crearProveedorRequest } from "../../../../../services/api/proveedores";
import { FormProveedor } from "./FormProveedor";

export const ModalCrearProveedor = ({
  isOpen = true,
  close = () => {},
  identificacion = "",
  razon_social = "",
  nombre_comercial = "",
  direccion = "",
  contribuyente_especial = false,
  lleva_contabilidad = false,
  onCreate = () => {},
}) => {
  const { isProductosChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    identificacion: identificacion,
    activo: true,
    relacionado: false,
    razon_social: razon_social,
    nombre_comercial: nombre_comercial,
    direccion: direccion,
    email: "",
    telefono_1: "",
    telefono_2: "",
    contribuyente_especial: contribuyente_especial,
    lleva_contabilidad: lleva_contabilidad,
    id_tipo_identificacion: null,
    cuenta_contable_proveedor: null,
    id_grupo_proveedor: null,
    id_tipo_compra: null,
    id_tipo_entidad: null,
    id_tipo_regimen: null,
    id_sustento_tributario: null,
    id_tipo_forma_pago: null,
    id_tipo_pago_a: null,
    id_porcentaje_retencion_bienes: null,
    id_porcentaje_retencion_servicios: null,
    id_pais_pago: null,
  });

  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [cuentaContableProveedor, setCuentaContableProveedor] = useState(null);
  const [gruposProveedores, setGruposProveedores] = useState([]);
  const [tiposCompras, setTiposCompras] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [sustentoTributario, setSustentoTributario] = useState([]);
  const [tiposFormaPago, setTiposFormaPago] = useState([]);
  const [tiposPagoA, setTiposPagoA] = useState([]);
  const [porcentajeRetencion, setPorcentajeRetencion] = useState([]);
  const [paises, setPaises] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposIdentificacionRequest(),
      parametrosContablesComprasVentasEmpresaRequest(empresa.id_empresa),
      gruposProveedoresIdEmpresaRequest(empresa.id_empresa),
      tiposCompraRequest(),
      tiposEntidadRequest(),
      tipoRegimenRequest(),
      sustentoTributarioRequest(),
      tiposFormaPagoRequest(),
      tiposPagoARequest(),
      porcentajeRetencionRequest(),
      paisesRequest(),
    ])
      .then(
        ([
          tiposIdentificacionRes,
          parametrosContablesComprasVentasRes,
          gruposProveedoresIdEmpresaRes,
          tiposCompraRes,
          tiposEntidadRes,
          tiposRegimenRes,
          sustentoTributarioRes,
          tiposFormaPagoRes,
          tiposPagoARes,
          porcentajeRetencionRes,
          paisesRes,
        ]) => {
          setTiposIdentificacion(tiposIdentificacionRes);
          setGruposProveedores(gruposProveedoresIdEmpresaRes);
          setTiposCompras(tiposCompraRes);
          setTiposEntidad(tiposEntidadRes);
          setTiposRegimen(tiposRegimenRes);
          setSustentoTributario(sustentoTributarioRes);
          setTiposFormaPago(tiposFormaPagoRes);
          setTiposPagoA(tiposPagoARes);
          setPorcentajeRetencion(porcentajeRetencionRes);
          setPaises(paisesRes);

          if (tiposIdentificacionRes.length > 0) {
            const findTipoIdentificacion = tiposIdentificacionRes.find(
              (e) => e.id_tipo_identificacion === 2
            );
            if (findTipoIdentificacion) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_identificacion:
                  findTipoIdentificacion.id_tipo_identificacion,
              }));
            }
          }

          let findCuentaContableProveedor;

          if (parametrosContablesComprasVentasRes) {
            findCuentaContableProveedor =
              parametrosContablesComprasVentasRes.cuenta_contable_proveedores;
            if (!findCuentaContableProveedor) return;
            setForm((prevForm) => ({
              ...prevForm,
              cuenta_contable_proveedor: findCuentaContableProveedor,
            }));
            setCuentaContableProveedor(findCuentaContableProveedor);
          }

          if (gruposProveedoresIdEmpresaRes.length > 0) {
            const findGrupoProveedor = gruposProveedoresIdEmpresaRes.find(
              (e) => e.predeterminado
            );
            if (findGrupoProveedor) {
              setForm((prevForm) => ({
                ...prevForm,
                id_grupo_proveedor: findGrupoProveedor.id_grupo_proveedor,
              }));
            }
          }

          if (tiposCompraRes.length > 0) {
            const findTipoCompra = tiposCompraRes.find(
              (e) => e.id_tipo_compra === 1
            );
            if (findTipoCompra) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_compra: findTipoCompra.id_tipo_compra,
              }));
            }
          }

          if (tiposEntidadRes.length > 0) {
            const findTipoEntidad = tiposEntidadRes.find(
              (e) => e.id_tipo_entidad === 1
            );
            if (findTipoEntidad) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_entidad: findTipoEntidad.id_tipo_entidad,
              }));
            }
          }

          if (tiposRegimenRes.length > 0) {
            const findTipoRegimen = tiposRegimenRes.find(
              (e) => e.id_tipo_regimen === 1
            );
            if (findTipoRegimen) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_regimen: findTipoRegimen.id_tipo_regimen,
              }));
            }
          }

          if (sustentoTributarioRes.length > 0) {
            const findSustentoTributario = sustentoTributarioRes.find(
              (e) => e.id_sustento_tributario === 2
            );
            if (findSustentoTributario) {
              setForm((prevForm) => ({
                ...prevForm,
                id_sustento_tributario:
                  findSustentoTributario.id_sustento_tributario,
              }));
            }
          }

          if (tiposFormaPagoRes.length > 0) {
            const findTipoFormaPago = tiposFormaPagoRes.find(
              (e) => e.id_tipo_forma_pago === 1
            );
            if (findTipoFormaPago) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_forma_pago: findTipoFormaPago.id_tipo_forma_pago,
              }));
            }
          }

          if (tiposPagoARes.length > 0) {
            const findTipoPagoA = tiposPagoARes.find(
              (e) => e.id_tipo_pago_a === 1
            );
            if (findTipoPagoA) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_pago_a: findTipoPagoA.id_tipo_pago_a,
              }));
            }
          }

          if (porcentajeRetencionRes.length > 0) {
            const findPorcentajeRetencion = porcentajeRetencionRes.find(
              (e) => e.id_porcentaje_retencion === 1
            );
            if (findPorcentajeRetencion) {
              setForm((prevForm) => ({
                ...prevForm,
                id_porcentaje_retencion_bienes:
                  findPorcentajeRetencion.id_porcentaje_retencion,
                id_porcentaje_retencion_servicios:
                  findPorcentajeRetencion.id_porcentaje_retencion,
              }));
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    if (form.contribuyente_especial && form.lleva_contabilidad) {
      setForm((prev) => ({
        ...prev,
        id_tipo_entidad: 2,
      }));
    }
  }, [form.contribuyente_especial, form.lleva_contabilidad]);

  const validarCampos = () => {
    const { identificacion, razon_social, cuenta_contable_proveedor } = form;

    if (identificacion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la identificación ",
      });
      return false;
    }

    if (razon_social.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (!cuenta_contable_proveedor?.id_cuenta_contable) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      identificacion: form.identificacion,
      activo: form.activo,
      relacionado: form.relacionado,
      razon_social: form.razon_social,
      nombre_comercial: form.nombre_comercial,
      direccion: form.direccion,
      email: form.email,
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      contribuyente_especial: form.contribuyente_especial,
      lleva_contabilidad: form.lleva_contabilidad,
      id_tipo_identificacion: form.id_tipo_identificacion,
      id_cuenta_contable_proveedor:
        form.cuenta_contable_proveedor?.id_cuenta_contable,
      id_grupo_proveedor: form.id_grupo_proveedor,
      id_tipo_compra: form.id_tipo_compra,
      id_tipo_entidad: form.id_tipo_entidad,
      id_tipo_regimen: form.id_tipo_regimen,
      id_sustento_tributario: form.id_sustento_tributario,
      id_tipo_forma_pago: form.id_tipo_forma_pago,
      id_tipo_pago_a: form.id_tipo_pago_a,
      id_porcentaje_retencion_bienes: form.id_porcentaje_retencion_bienes,
      id_porcentaje_retencion_servicios: form.id_porcentaje_retencion_servicios,
      id_pais_pago: form.id_pais_pago,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearProveedorRequest(dataRequest)
      .then((res) => {
        onCreate(res);
        onResetForm();
        close();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={isOpen}
      width={900}
      closable
      onCancel={close}
      okButtonProps={{ style: { display: "none" } }}
    >
      <Spin spinning={isLoading}>
        <Content>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
          <Form>
            <FormProveedor
              form={form}
              onChangeForm={onChangeForm}
              tiposIdentificacion={tiposIdentificacion}
              gruposProveedores={gruposProveedores}
              tiposCompras={tiposCompras}
              tiposEntidad={tiposEntidad}
              tiposRegimen={tiposRegimen}
              sustentoTributario={sustentoTributario}
              tiposFormaPago={tiposFormaPago}
              tiposPagoA={tiposPagoA}
              porcentajeRetencion={porcentajeRetencion}
              paises={paises}
            />
          </Form>
          <Row align={"middle"} justify={"center"}>
            <Button type="primary" onClick={crear}>
              Crear Proveedor
            </Button>
          </Row>
        </Content>
      </Spin>
    </Modal>
  );
};
