import API from "../../configs/api/api.config";

const URL_TIPO_REGIMEN = '/tipo-regimen';
export const tipoRegimenRequest = async () => {
    const request = await API.get(URL_TIPO_REGIMEN);
    const response = request.data;
    return response;
}

const URL_AGENTE_RETENCION = '/agente-retencion';
export const agenteRetecionRequest = async () => {
    const request = await API.get(URL_AGENTE_RETENCION);
    const response = request.data;
    return response;
}


const URL_PROVINCIAS = '/provincias';
export const provinciasRequest = async () => {
    const request = await API.get(URL_PROVINCIAS);
    const response = request.data;
    return response;
}

const URL_CANTONES = '/cantones';
export const cantonesRequest = async ({ id_provincia }) => {
    const request = await API.get(URL_CANTONES, {
        params: {
            id_provincia: id_provincia ?? ''
        }
    });
    const response = request.data;
    return response;
}

const URL_PARROQUIAS = '/parroquias';
export const parroquiasRequest = async ({ id_canton }) => {
    const request = await API.get(URL_PARROQUIAS, {
        params: {
            id_canton: id_canton ?? ''
        }
    });
    const response = request.data;
    return response;
}

const URL_FORMULA_CALCULO_PRECIO = '/formula-calculo-precio';
export const formulasCalculoPrecioRequest = async () => {
    const request = await API.get(URL_FORMULA_CALCULO_PRECIO);
    const response = request.data;
    return response;
}

const URL_TIPO_COSTO_PRODUCCION = '/tipo-costo-produccion';
export const tiposCostoProduccionRequest = async () => {
    const request = await API.get(URL_TIPO_COSTO_PRODUCCION);
    const response = request.data;
    return response;
}

const URL_TIPO_MANEJO_PRECIO = '/tipo-manejo-precio';
export const tiposManejoPrecioRequest = async () => {
    const request = await API.get(URL_TIPO_MANEJO_PRECIO);
    const response = request.data;
    return response;
}

const URL_TIPO_CALCULO_COSTO = '/tipo-calculo-costo';
export const tiposCalculoCostoRequest = async () => {
    const request = await API.get(URL_TIPO_CALCULO_COSTO);
    const response = request.data;
    return response;
}

const URL_IVA = '/iva';
export const tarifasIvaRequest = async () => {
    const request = await API.get(URL_IVA);
    const response = request.data;
    return response;
}

const URL_TIPO_DESCUENTO_ASIGNADO = '/tipo-descuento-asignado';
export const tiposDescuentoAsignadoRequest = async () => {
    const request = await API.get(URL_TIPO_DESCUENTO_ASIGNADO);
    const response = request.data;
    return response;
}

const URL_VISUALIZACION_DESCUENTO = '/visualizacion-descuento';
export const visualizacionDescuentoRequest = async () => {
    const request = await API.get(URL_VISUALIZACION_DESCUENTO);
    const response = request.data;
    return response;
}

const URL_TIPO_SUCURSAL = '/tipo-sucursal';
export const tiposSucursalRequest = async () => {
    const request = await API.get(URL_TIPO_SUCURSAL);
    const response = request.data;
    return response;
}

const URL_ESTADO_SUCURSAL = '/estado-sucursal';
export const estadosSucursalRequest = async () => {
    const request = await API.get(URL_ESTADO_SUCURSAL);
    const response = request.data;
    return response;
}

const URL_TIPO_MODIFICACION_ASIENTO = '/tipo-modificacion-asiento';
export const tiposModificacionAsientoRequest = async () => {
    const request = await API.get(URL_TIPO_MODIFICACION_ASIENTO);
    const response = request.data;
    return response;
}

const URL_TIPO_CONTABILIZACION = '/tipo-contabilizacion';
export const tiposContabilizacionRequest = async () => {
    const request = await API.get(URL_TIPO_CONTABILIZACION);
    const response = request.data;
    return response;
}

const URL_TIPO_CONTABILIDAD = '/tipo-contabilidad';
export const tiposContabilidadRequest = async () => {
    const request = await API.get(URL_TIPO_CONTABILIDAD);
    const response = request.data;
    return response;
}

const URL_TIPO_CONTABILIDAD_NOMINA = '/tipo-contabilidad-nomina';
export const tiposContabilidadNominaRequest = async () => {
    const request = await API.get(URL_TIPO_CONTABILIDAD_NOMINA);
    const response = request.data;
    return response;
}

const URL_TIPO_ECOMMERCE_PRODUCTO = '/tipo-ecommerce-producto';
export const tiposEcommerceProductoRequest = async () => {
    const request = await API.get(URL_TIPO_ECOMMERCE_PRODUCTO);
    const response = request.data;
    return response;
}

const URL_TIPO_IDENTIFICACION = '/tipo-identificacion';
export const tiposIdentificacionRequest = async () => {
    const request = await API.get(URL_TIPO_IDENTIFICACION);
    const response = request.data;
    return response;
}

const URL_TIPO_COMPRA = '/tipo-compra';
export const tiposCompraRequest = async () => {
    const request = await API.get(URL_TIPO_COMPRA);
    const response = request.data;
    return response;
}

const URL_TIPO_ENTIDAD = '/tipo-entidad';
export const tiposEntidadRequest = async () => {
    const request = await API.get(URL_TIPO_ENTIDAD);
    const response = request.data;
    return response;
}

const URL_SUSTENTO_TRIBUTARIO = '/sustento-tributario';
export const sustentoTributarioRequest = async () => {
    const request = await API.get(URL_SUSTENTO_TRIBUTARIO);
    const response = request.data;
    return response;
}

const URL_TIPO_FORMA_PAGO = '/tipo-forma-pago';
export const tiposFormaPagoRequest = async () => {
    const request = await API.get(URL_TIPO_FORMA_PAGO);
    const response = request.data;
    return response;
}

const URL_TIPO_PAGO_A = '/tipo-pago-a';
export const tiposPagoARequest = async () => {
    const request = await API.get(URL_TIPO_PAGO_A);
    const response = request.data;
    return response;
}

const URL_PORCENTAJE_RETENCION = '/porcentaje-retencion';
export const porcentajeRetencionRequest = async () => {
    const request = await API.get(URL_PORCENTAJE_RETENCION);
    const response = request.data;
    return response;
}

const URL_PAISES = '/paises';
export const paisesRequest = async () => {
    const request = await API.get(URL_PAISES);
    const response = request.data;
    return response;
}

const URL_TIPO_DOCUMENTO = '/tipo-documento';
export const tiposDocumentoRequest = async () => {
    const request = await API.get(URL_TIPO_DOCUMENTO);
    const response = request.data;
    return response;
}

const URL_TARIFAS = '/tarifas';
export const tarifasRequest = async () => {
    const request = await API.get(URL_TARIFAS);
    const response = request.data;
    return response;
}

const URL_SEXO = '/sexo';
export const sexosRequest = async () => {
    const request = await API.get(URL_SEXO);
    const response = request.data;
    return response;
}

const URL_ESTADO_CIVIL = '/estado-civil';

export const estadosCivilRequest = async () => {
    const request = await API.get(URL_ESTADO_CIVIL);
    const response = request.data;
    return response;
}

const URL_ORIGEN_INGRESOS = '/origen-ingresos';

export const origenesIngresosRequest = async () => {
    const request = await API.get(URL_ORIGEN_INGRESOS);
    const response = request.data;
    return response;
}

const URL_FORMAS_PAGO_SRI = '/formas-pago-sri';

export const formasPagoSRIRequest = async () => {
    const request = await API.get(URL_FORMAS_PAGO_SRI);
    const response = request.data;
    return response;
}

const URL_TIPO_RETENCION = '/tipo-retencion';

export const tiposRetencionesRequest = async () => {
    const request = await API.get(URL_TIPO_RETENCION);
    const response = request.data;
    return response;
}

const URL_TIPO_CUENTA = '/tipo-cuenta';

export const tiposCuentasRequest = async () => {
    const request = await API.get(URL_TIPO_CUENTA);
    const response = request.data;
    return response;
}

const URL_TIPO_GASTO = '/tipo-gasto';

export const tiposGastosRequest = async () => {
    const request = await API.get(URL_TIPO_GASTO);
    const response = request.data;
    return response;
}

const URL_TIPO_REGIMEN_RETENCION = '/tipo-regimen-retencion';

export const tiposRegimenRetencionRequest = async () => {
    const request = await API.get(URL_TIPO_REGIMEN_RETENCION);
    const response = request.data;
    return response;
}

const URL_TIPO_REGIMEN_GENERAL = '/tipo-regimen-general';

export const tiposRegimenGeneralRequest = async () => {
    const request = await API.get(URL_TIPO_REGIMEN_GENERAL);
    const response = request.data;
    return response;
}

const URL_PARAISO_FISCAL = '/tipo-paraiso-fiscal';

export const tiposParaisoFiscalRequest = async () => {
    const request = await API.get(URL_PARAISO_FISCAL);
    const response = request.data;
    return response;
}