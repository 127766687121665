import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";

import { EMAIL_REGEX } from "../../../../../configs/constants/regex";

import { AuthContext } from "../../../../../context/AuthContext";
import { IoLockClosedOutline } from "react-icons/io5";
import { actualizarUsuarioEmpresaRequest } from "../../../../../services/api/empresas";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";

const { Option } = Select;

export const EditarUsuarioDrawer = ({
  open,
  showDrawer,
  onClose,
  usuario,
  reload = () => {},
}) => {
  const { checkAuth } = useContext(AuthContext);
  const {
    checkData,
    empresa,
    usuario: usuarioContext,
  } = useContext(UserContext);
  const { reloadUsuarios } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    identificacion_usuario: usuario.identificacion_usuario,
    email_usuario: usuario.email_usuario,
    password_usuario: usuario.password_usuario,
    nombre_usuario: usuario.nombre_usuario,
    new_password: false,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!form.new_password) {
      setForm({
        ...form,
        password_usuario: "",
      });
    }
  }, [form.new_password]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const {
      identificacion_usuario,
      email_usuario,
      password_usuario,
      nombre_usuario,
      new_password,
    } = form;

    // Verificar que los campos obligatorios estén completos
    if (
      !identificacion_usuario ||
      !email_usuario ||
      !nombre_usuario ||
      (new_password && !password_usuario)
    ) {
      return false;
    }

    if (identificacion_usuario.length < 6) {
      alert("La identificación debe tener almenos 6 caracteres");
      return false;
    }

    const emailRegex = EMAIL_REGEX;
    if (!emailRegex.test(email_usuario)) {
      alert("Revise el formato del email");
      return false;
    }

    if (new_password && password_usuario.length < 6) {
      alert("¿La contraseña debe tener 6 caracteres minimo?");
      return false;
    }

    return true;
  };

  const update = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();
    if (!validacion) {
      setError({
        show: true,
        message:
          "Por favor, revise los campos incorrectos o campos obligatorios (*) que estan vacios.",
      });
      return;
    }
    const confirm = window.confirm("¿Esta seguro de guardar los cambios?");
    if (!confirm) return;

    const dataRequest = {
      id_usuario: usuario.id_usuario,
      identificacion_usuario: form.identificacion_usuario,
      email_usuario: form.email_usuario,
      password_usuario: !form.password_usuario ? null : form.password_usuario,
      nombre_usuario: form.nombre_usuario,
      new_password: form.new_password,
    };

    setIsLoading(true);

    actualizarUsuarioEmpresaRequest(dataRequest)
      .then((res) => {
        if (usuarioContext.id_usuario == usuario.id_usuario) {
          checkData();
        }
        reloadUsuarios();
        reload();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar usuario"
      width={450}
      size="default"
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={update} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Col span={24}>
            <Form.Item label="Identificación usuario" required>
              <Input
                placeholder="Identificación"
                value={form.identificacion_usuario}
                onChange={(e) =>
                  onChangeForm("identificacion_usuario", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Nombre usuario" required>
              <Input
                placeholder="Nombre"
                value={form.nombre_usuario}
                onChange={(e) => onChangeForm("nombre_usuario", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email usuario" required>
              <Input
                placeholder="Email"
                value={form.email_usuario}
                onChange={(e) => onChangeForm("email_usuario", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Checkbox
                checked={form.new_password}
                onChange={(e) => onChangeForm("new_password", e.target.checked)}
              >
                Cambiar contraseña
              </Checkbox>
            </Form.Item>
          </Col>
          {form.new_password && (
            <Col span={24}>
              <Form.Item label="Nueva contraseña de usuario" required>
                <Input
                  disabled={!form.new_password}
                  placeholder="Contraseña"
                  value={form.password_usuario}
                  prefix={<IoLockClosedOutline color="grey" />}
                  onChange={(e) =>
                    onChangeForm("password_usuario", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
};
