import { SearchOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import {
  tiposContabilidadNominaRequest,
  tiposContabilizacionRequest,
} from "../../../../../services/api/opciones";
import {
  crearParametrosContablesProductosRequest,
  editarParametrosContablesProductosRequest,
  parametrosContablesProductosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-productos";
import { toast } from "sonner";
import { ModalCuentasContables } from "./ModalCuentasContables";
import { CuentaContableInput } from "./CuentaContableInput";
import {
  crearParametrosContablesCobrosRequest,
  editarParametrosContablesCobrosRequest,
  parametrosContablesCobrosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-cobros";
import { tiposContabilidadRequest } from "../../../../../services/api/opciones";
import {
  crearParametrosContablesCajasBancosRequest,
  editarParametrosContablesCajasBancosRequest,
  parametrosContablesCajasBancosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-cajas-bancos";
import {
  crearParametrosContablesNominaRequest,
  editarParametrosContablesNominaRequest,
  parametrosContablesNominaEmpresaRequest,
} from "../../../../../services/api/parametros-contables-nomina";

export const NominaTab = ({ onReload = () => {}, activeForm, reload }) => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const { form, onChangeForm, setForm } = useForm({
    id_parametros_contables_nomina: null,
    sueldo_basico: null,
    id_tipo_contabilidad_nomina: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposContabilidadNomina, setTiposContabilidadNomina] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    tiposContabilidadNominaRequest().then((res) =>
      setTiposContabilidadNomina(res)
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    parametrosContablesNominaEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res) {
          setForm({
            id_parametros_contables_nomina: res.id_parametros_contables_nomina,
            sueldo_basico: res.sueldo_basico,
            id_tipo_contabilidad_nomina:
              res.tipo_contabilidad_nomina?.id_tipo_contabilidad_nomina,
          });
        } else {
          setError({
            show: true,
            message:
              "Crear los parametros contables nomina antes de utilizar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const validarCampos = () => {
    const { id_tipo_contabilidad_nomina, sueldo_basico } = form;

    if (!id_tipo_contabilidad_nomina) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilidad",
      });
      return false;
    }

    if (
      sueldo_basico != null &&
      sueldo_basico != "" &&
      (!Number.isFinite(Number(sueldo_basico)) || Number(sueldo_basico) <= 0)
    ) {
      setError({
        show: true,
        message: "Ingrese un valor validado en el sueldo basico o dejelo vacio",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros contables nomina de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      sueldo_basico: !form.sueldo_basico ? null : Number(form.sueldo_basico),
      id_tipo_contabilidad_nomina: form.id_tipo_contabilidad_nomina,
      id_empresa: empresa.id_empresa,
    };
    setIsLoading(true);
    crearParametrosContablesNominaRequest(dataRequest)
      .then((res) => {
        onReload();
        toast.success("Parametros contables nomina creados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios en parametros contables nomina de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      sueldo_basico: !form.sueldo_basico ? null : Number(form.sueldo_basico),
      id_tipo_contabilidad_nomina: form.id_tipo_contabilidad_nomina,
      id_empresa: empresa.id_empresa,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    editarParametrosContablesNominaRequest(
      form.id_parametros_contables_nomina,
      dataRequest
    )
      .then((res) => {
        onReload();
        toast.success("Parametros contables nomina guardados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col>
          {/* ERROR */}
          <Row>
            {error.show && (
              <Alert
                description={error.message}
                type="error"
                closable
                onClose={() => setError({ show: false, message: "" })}
              />
            )}
          </Row>
          <Row justify={"space-between"} style={{ marginTop: 15 }}>
            <Typography>Sueldo Básico: </Typography>
            <Input
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              value={form.sueldo_basico}
              onChange={(e) => onChangeForm("sueldo_basico", e.target.value)}
            />
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Radio.Group
              value={form.id_tipo_contabilidad_nomina}
              onChange={(e) => {
                onChangeForm("id_tipo_contabilidad_nomina", e.target.value);
              }}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo de Contabilidad
              </Typography>
              <Space direction="vertical">
                {tiposContabilidadNomina.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilidad_nomina}
                      value={f.id_tipo_contabilidad_nomina}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          {/* BOTONES */}
          <Row style={{ marginTop: 50 }}>
            {form.id_parametros_contables_nomina ? (
              <Button
                type="primary"
                onClick={editar}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").editar || !activeForm
                }
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={crear}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").crear || !activeForm
                }
              >
                Crear
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
