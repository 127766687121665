import { decryptString, encryptString } from "../crypto/crypto";


export const authStorageSave = (data) => {
    const stringfy = encryptString(JSON.stringify(data));
    localStorage.setItem('@auth', stringfy);
    return true;
}

export const authStorageGet = () => {
    const stringfy = localStorage.getItem('@auth');
    return stringfy ? JSON.parse(decryptString(stringfy)) : null;
}

export const authStorageRemove = () => {
    localStorage.removeItem('@auth');
}