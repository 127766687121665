import API from "../../configs/api/api.config";

const URL_ALMACENES = '/almacenes';

export const almacenesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_ALMACENES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const almacenIdRequest = async (id_almacen) => {
    const request = await API.get(URL_ALMACENES + '/' + id_almacen);
    const response = request.data;
    return response;
}

export const crearAlmacenRequest = async (data) => {
    const request = await API.post(URL_ALMACENES, data);
    const response = request.data;
    return response;
}

export const actualizarAlmacenRequest = async (id_almacen, data) => {
    const request = await API.patch(URL_ALMACENES + '/' + id_almacen, data);
    const response = request.data;
    return response;
}

export const eliminarAlmacenRequest = async (id_almacen) => {
    const request = await API.delete(URL_ALMACENES + '/' + id_almacen);
    const response = request.data;
    return response;
}