import API from "../../configs/api/api.config";

const URL_CODIGOS_RETENCIONES = '/codigos-retenciones';

export const codigosRetencionesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_CODIGOS_RETENCIONES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const codigoRetencionIdRequest = async (id_codigo_retencion) => {
    const request = await API.get(URL_CODIGOS_RETENCIONES + '/' + id_codigo_retencion);
    const response = request.data;
    return response;
}

export const crearCodigoRetencionRequest = async (data) => {
    const request = await API.post(URL_CODIGOS_RETENCIONES, data);
    const response = request.data;
    return response;
}

export const buscarCodigoRetencionRequest = async (data) => {
    const request = await API.post(URL_CODIGOS_RETENCIONES + '/buscar', data);
    const response = request.data;
    return response;
}


export const actualizarCodigoRetencionRequest = async (id_codigo_retencion, data) => {
    const request = await API.patch(URL_CODIGOS_RETENCIONES + '/' + id_codigo_retencion, data);
    const response = request.data;
    return response;
}

export const eliminarCodigoRetencionRequest = async (id_codigo_retencion) => {
    const request = await API.delete(URL_CODIGOS_RETENCIONES + '/' + id_codigo_retencion);
    const response = request.data;
    return response;
}