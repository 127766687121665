import React, { useContext } from "react";
import { Avatar, Col, Dropdown, Image, Row, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import {
  IoChevronDown,
  IoChevronDownCircleOutline,
  IoLogOutOutline,
  IoPerson,
} from "react-icons/io5";
import { FaBuilding, FaRegBuilding } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { UserContext } from "../../context/UserContext";

export const HeaderApp = ({}) => {
  
  const { handleLogout } = useContext(AuthContext);
  const { empresas, empresa, usuario, onChangeEmpresa } =
    useContext(UserContext);
  const { identificacion_usuario, email_usuario, nombre_usuario } = usuario;

  const userMenu = [
    {
      key: 0,
      danger: "true",
      onClick: handleLogout,
      label: "Cerrar sesion",
      icon: <IoLogOutOutline />,
    },
  ];

  const empresasMenu = empresas.map((e) => {
    return {
      key: e.id_empresa,
      onClick: () => onChangeEmpresa(e.id_empresa),
      label: `${
        !e.nombre_comercial_empresa
          ? e.razon_social_empresa
          : e.nombre_comercial_empresa
      } - ${e.identificacion_empresa}`,
      icon: <FaRegBuilding size={15} />,
    };
  });

  const obtenerNombresRolesUsuario = (e) => {
    if (!e.empresa_usuarios) return "";
    const nombresRoles = e.empresa_usuarios[0]?.empresa_rol_usuarios?.map(
      (eru) => eru.empresa_rol?.nombre_empresa_rol
    );
    return nombresRoles ? nombresRoles.join(" - ") : "";
  };

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <Row justify={"space-between"}>
        <Row align={"middle"}>
          {empresas.length > 0 && (
            <>
              <Image
                style={{
                  maxHeight: 50,
                  borderRadius: 5,
                }}
                src={empresa?.logo_empresa}
              />
              <Dropdown
                menu={{
                  items: empresasMenu,
                }}
                trigger={["click"]}
              >
                <Row
                  align={"middle"}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Col>
                    <Typography
                      style={{
                        margin: 0,
                        marginLeft: 10,
                        color: "white",
                        fontSize: 14,
                      }}
                    >
                      {!empresa.nombre_comercial_empresa
                        ? empresa.razon_social_empresa
                        : empresa.nombre_comercial_empresa}
                    </Typography>
                    <Typography
                      style={{
                        margin: 0,
                        marginLeft: 10,
                        color: "white",
                        fontSize: 12,
                      }}
                    >
                      {empresa.identificacion_empresa}
                    </Typography>
                  </Col>

                  <IoChevronDown
                    style={{
                      marginLeft: 20,
                    }}
                    color="lightgray"
                    size={20}
                  />
                </Row>
              </Dropdown>
            </>
          )}
        </Row>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col>
            <Typography style={{ color: "lightgray", textAlign: "end" }}>
              {nombre_usuario} ( {obtenerNombresRolesUsuario(empresa)} )
            </Typography>
            <Typography
              style={{ color: "lightgray", textAlign: "end", fontSize: 12 }}
            >
              {email_usuario} - {identificacion_usuario}
            </Typography>
          </Col>
          <Dropdown
            menu={{
              items: userMenu,
            }}
            trigger={["click"]}
          >
            <Col
              style={{
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "grey",
                }}
                size={40}
                icon={<IoPerson />}
              />
            </Col>
          </Dropdown>
        </Row>
      </Row>
    </Header>
  );
};
