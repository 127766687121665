import { Checkbox, Col, Divider, Row, Typography } from "antd";
import React from "react";

export const PermisosForm = ({
  id,
  disabled = true,
  modulo,
  onChangeEmpresaSistema = () => {},
}) => {

  

  return (
    <Row gutter={10} style={{ height: 40}} key={modulo?.id_modulo}>
      <Col span={10}>
        <Typography>{modulo?.nombre_modulo}</Typography>
      </Col>
      <Col span={14}>
        {modulo.modulo_permisos
          ?.filter((mp) => mp.empresa_rol_usuario.id_empresa_rol_usuario == id)
          .map((permiso) => {
            return (
              <Row justify="space-evenly" key={permiso?.id_permiso}>
                <Checkbox
                  checked={permiso.leer}
                  disabled={disabled}
                  onChange={(e) =>
                    onChangeEmpresaSistema(
                      permiso?.id_permiso,
                      "leer",
                      e.target.checked
                    )
                  }
                />
                <Checkbox
                  checked={permiso.editar}
                  disabled={disabled}
                  onChange={(e) =>
                    onChangeEmpresaSistema(
                      permiso?.id_permiso,
                      "editar",
                      e.target.checked
                    )
                  }
                />
                <Checkbox
                  checked={permiso.crear}
                  disabled={disabled}
                  onChange={(e) =>
                    onChangeEmpresaSistema(
                      permiso?.id_permiso,
                      "crear",
                      e.target.checked
                    )
                  }
                />
                <Checkbox
                  checked={permiso.eliminar}
                  disabled={disabled}
                  onChange={(e) =>
                    onChangeEmpresaSistema(
                      permiso?.id_permiso,
                      "eliminar",
                      e.target.checked
                    )
                  }
                />
              </Row>
            );
          })}
      </Col>
      <Divider orientationMargin={0} style={{ margin: 1 }} />
    </Row>
  );
};
