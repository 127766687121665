import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../../pages/auth/login/LoginPage";
import AuthLayout from "../../layouts/AuthLayout";

export const AuthRoute = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<Navigate to={"/login"} />} />
      </Routes>
    </AuthLayout>
  );
};
