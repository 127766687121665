import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarServicioRequest } from "../../../../../services/api/servicios";

export const TablaServicios = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadServicios } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_producto = record.id_producto;
    if (!id_producto) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este Servicio, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarServicioRequest(id_producto).then((res) => {
      reloadServicios();
    });
  };

  const detalleServicio = (data) => {
    const id_producto = data?.id_producto;
    if (!id_producto) return;
    navigate(`./${id_producto}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "8%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MSERVICIOS").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleServicio(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MSERVICIOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "x",
      width: "10%",
      ellipsis: true,
      ...getColumnSearchProps("codigo"),
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "20%",
      ellipsis: true,
      ...getColumnSearchProps("descripcion"),
    },
    {
      title: "Linea",
      dataIndex: "linea_producto",
      key: "linea_producto",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        return <Typography>{record?.descripcion}</Typography>;
      },
    },
    {
      title: "Precio 1",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        const precio =
          record.tarifas_productos?.find(
            (t) => t.tarifa === "Precio 1" && t.medida === "interna"
          )?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 2",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        const precio =
          record.tarifas_productos?.find(
            (t) => t.tarifa === "Precio 2" && t.medida === "interna"
          )?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 3",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        const precio =
          record.tarifas_productos?.find(
            (t) => t.tarifa === "Precio 3" && t.medida === "interna"
          )?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 4",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        const precio =
          record.tarifas_productos?.find(
            (t) => t.tarifa === "Precio 4" && t.medida === "interna"
          )?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 5",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
      render: (record) => {
        const precio =
          record.tarifas_productos?.find(
            (t) => t.tarifa === "Precio 5" && t.medida === "interna"
          )?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Costo E",
      dataIndex: "costo_estandar",
      key: "costo_estandar",
      width: "6%",
      ellipsis: true,
    },
    {
      title: "Venta",
      dataIndex: "",
      key: "",
      width: "6%",
      ellipsis: true,
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_producto"}
      columns={columns}
      dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
      size="small"
    />
  );
};
