import {
  Breadcrumb,
  Button,
  Row,
  Spin,
  Tooltip
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";
import { subCategoriasProductosIdEmpresaRequest } from "../../../../services/api/sub-categorias-productos";
import { CrearSubCategoriaProducto } from "./components/CrearSubCategoriaProducto";
import { TablaSubCategoriasProductos } from "./components/TablaSubCategoriasProductos";

export const SubCategoriasProductosPage = () => {
  
  const { isSubCategoriasProductosChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [subCategoriasProductos, setSubCategoriasProductos] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    subCategoriasProductosIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setSubCategoriasProductos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isSubCategoriasProductosChange]);




  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Sub Categorias de Productos",
                },
              ]}
            />
          </Row>
          <Row align={"middle"}>
            <Button
              disabled={!checkPermisos("MSUB-CATEGORIAS-PRODUCTOS").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaSubCategoriasProductos data={subCategoriasProductos}/>
        {/* Crear Drawer */}
        <CrearSubCategoriaProducto
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
        />
      </Content>
    </Spin>
  );
};
