import { Badge, Form, Input, Select, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { isStringEmpty } from "../../../../../helpers/functions/is-string-empty.function";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  select,
  selectOptions,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      let change = false;
      for (const propety in values) {
        if (record[propety] != values[propety]) {
          change = true;
          break;
        }
      }
      toggleEdit();
      if (!change) return;
      handleSave(
        {
          ...record,
          ...values,
        },
        dataIndex
      );
    } catch (errInfo) {}
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      !select ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            type={"number"}
            size={"small"}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select
            ref={inputRef}
            size={"small"}
            onChange={save}
            onPressEnter={save}
            onBlur={save}
            options={selectOptions}
          />
        </Form.Item>
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export const TablaDetalles = ({
  data,
  productoSeleccionado = (p) => {},
  seleccionarProducto = (i) => {},
  onChange = (data, dataIndex) => {},
  ivas = [],
}) => {
  const handleSave = (data, dataIndex) => {
    onChange(data, dataIndex);
  };

  const components = {
    body: {
      row: (data) => EditableRow({ ...data, seleccionarProducto }),
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
      width: "10%",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "20%",
      ellipsis: true,
      render: (_, d) => {
        const {
          descripcion,
          descripcion_remplazo,
          detalle_adicional_1,
          detalle_adicional_2,
          detalle_adicional_3,
        } = d;

        const isDescripcionRemplazo =
          descripcion_remplazo && descripcion_remplazo?.trim()?.length > 0;

        const text = isDescripcionRemplazo ? descripcion_remplazo : descripcion;

        const countDetallesAdicionales = [
          detalle_adicional_1,
          detalle_adicional_2,
          detalle_adicional_3,
        ].filter(
          (detalle_adicional) => !isStringEmpty(detalle_adicional)
        ).length;

        return (
          <Badge count={countDetallesAdicionales} size="small">
            <Typography.Text ellipsis strong={isDescripcionRemplazo}>
              {text}
            </Typography.Text>
          </Badge>
        );
      },
    },
    {
      title: "Medida",
      dataIndex: "medida",
      key: "medida",
      width: "10%",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      width: "12%",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "cantidad",
        title: "Cantidad",
        handleSave,
      }),
      render: (n) => numberFormat(n),
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      width: "12%",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "precio",
        title: "Precio",
        handleSave,
      }),
      render: (n) => numberFormat(n, 6),
    },
    {
      title: "Desc.",
      dataIndex: "descuento",
      key: "descuento",
      width: "12%",
      ellipsis: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "descuento",
        title: "descuento",
        handleSave,
      }),
      render: (n) => numberFormat(n),
    },
    {
      title: "IVA",
      dataIndex: "iva_producto",
      key: "iva_producto",
      width: "10%",
      onCell: (record) => ({
        record,
        editable: true,
        select: true,
        selectOptions: ivas.map(iva => ({ value: iva.id_iva, label: iva.descripcion })),
        dataIndex: "iva_producto",
        title: "iva_producto",
        handleSave,
      }),
      render: (n) => n.descripcion,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "10%",
      className: "custom-column",
      render: (n) => numberFormat(n),
    },
  ];

  const getRowClassName = (record, index) => {
    if (record.tarifa === "Tarifa en: ") {
      return "custom-row-tarifa";
    }
    return "custom-row";
  };

  return (
    <Table
      components={components}
      size="small"
      columns={columns}
      dataSource={data}
      rowClassName={getRowClassName}
      className="small-table"
      onRow={(props) => ({
        ...props,
        onClick: () => {
          seleccionarProducto(props);
        },
      })}
    />
  );
};
