import { SearchOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { tiposContabilizacionRequest } from "../../../../../services/api/opciones";
import {
  crearParametrosContablesProductosRequest,
  editarParametrosContablesProductosRequest,
  parametrosContablesProductosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-productos";
import { toast } from "sonner";
import { ModalCuentasContables } from "./ModalCuentasContables";
import { CuentaContableInput } from "./CuentaContableInput";

export const ProductosTab = ({ onReload = () => {}, activeForm, reload }) => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const { form, onChangeForm, setForm } = useForm({
    id_parametros_contables_productos: null,
    cuenta_contable_inventario: null,
    cuenta_contable_ventas: null,
    cuenta_contable_costo: null,
    cuenta_contable_iva_inventario: null,
    cuenta_contable_iva_ventas: null,
    cuenta_contable_iva_costo: null,
    id_tipo_contabilizacion_ingresos: null,
    id_tipo_contabilizacion_salidas: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposContabilizacion, setTiposContabilizacion] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    tiposContabilizacionRequest().then((res) => setTiposContabilizacion(res));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    parametrosContablesProductosEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res) {
          setForm({
            id_parametros_contables_productos:
              res.id_parametros_contables_productos,
            cuenta_contable_inventario: res.cuenta_contable_inventario,
            cuenta_contable_ventas: res.cuenta_contable_ventas,
            cuenta_contable_costo: res.cuenta_contable_costo,
            cuenta_contable_iva_inventario: res.cuenta_contable_iva_inventario,
            cuenta_contable_iva_ventas: res.cuenta_contable_iva_ventas,
            cuenta_contable_iva_costo: res.cuenta_contable_iva_costo,
            id_tipo_contabilizacion_ingresos:
              res.tipo_contabilizacion_ingresos?.id_tipo_contabilizacion,
            id_tipo_contabilizacion_salidas:
              res.tipo_contabilizacion_salidas?.id_tipo_contabilizacion,
          });
        } else {
          setError({
            show: true,
            message:
              "Crear los parametros contables productos antes de utilizar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const validarCampos = () => {
    const {
      cuenta_contable_inventario,
      cuenta_contable_ventas,
      cuenta_contable_costo,
      cuenta_contable_iva_inventario,
      cuenta_contable_iva_ventas,
      cuenta_contable_iva_costo,
      id_tipo_contabilizacion_ingresos,
      id_tipo_contabilizacion_salidas,
    } = form;

    if (!cuenta_contable_inventario) {
      setError({
        show: true,
        message:
          "Seleccione la cuenta contable inventario de los productos sin IVA",
      });
      return false;
    }

    if (!cuenta_contable_ventas) {
      setError({
        show: true,
        message:
          "Seleccione la cuenta contable ventas de los productos sin IVA",
      });
      return false;
    }

    if (!cuenta_contable_costo) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable costo de los productos sin IVA",
      });
      return false;
    }

    if (!cuenta_contable_iva_inventario) {
      setError({
        show: true,
        message:
          "Seleccione la cuenta contable inventario de los productos con IVA",
      });
      return false;
    }

    if (!cuenta_contable_iva_ventas) {
      setError({
        show: true,
        message:
          "Seleccione la cuenta contable ventas de los productos con IVA",
      });
      return false;
    }

    if (!cuenta_contable_iva_costo) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable costo de los productos con IVA",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_ingresos) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de ingresos",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_salidas) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de salidas",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros contables productos de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_inventario:
        form.cuenta_contable_inventario.id_cuenta_contable,
      id_cuenta_contable_ventas: form.cuenta_contable_ventas.id_cuenta_contable,
      id_cuenta_contable_costo: form.cuenta_contable_costo.id_cuenta_contable,
      id_cuenta_contable_iva_inventario:
        form.cuenta_contable_iva_inventario.id_cuenta_contable,
      id_cuenta_contable_iva_ventas:
        form.cuenta_contable_iva_ventas.id_cuenta_contable,
      id_cuenta_contable_iva_costo:
        form.cuenta_contable_iva_costo.id_cuenta_contable,
      id_tipo_contabilizacion_ingresos: form.id_tipo_contabilizacion_ingresos,
      id_tipo_contabilizacion_salidas: form.id_tipo_contabilizacion_salidas,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearParametrosContablesProductosRequest(dataRequest)
      .then((res) => {
        onReload();
        toast.success("Parametros contables productos creados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios en parametros contables productos de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_inventario:
        form.cuenta_contable_inventario.id_cuenta_contable,
      id_cuenta_contable_ventas: form.cuenta_contable_ventas.id_cuenta_contable,
      id_cuenta_contable_costo: form.cuenta_contable_costo.id_cuenta_contable,
      id_cuenta_contable_iva_inventario:
        form.cuenta_contable_iva_inventario.id_cuenta_contable,
      id_cuenta_contable_iva_ventas:
        form.cuenta_contable_iva_ventas.id_cuenta_contable,
      id_cuenta_contable_iva_costo:
        form.cuenta_contable_iva_costo.id_cuenta_contable,
      id_tipo_contabilizacion_ingresos: form.id_tipo_contabilizacion_ingresos,
      id_tipo_contabilizacion_salidas: form.id_tipo_contabilizacion_salidas,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    editarParametrosContablesProductosRequest(
      form.id_parametros_contables_productos,
      dataRequest
    )
      .then((res) => {
        onReload();
        toast.success(
          "Parametros contables productos guardados correctamente !"
        );
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={10} justify={"space-between"}>
        <Col span={17}>
          {/* ERROR */}
          <Row>
            {error.show && (
              <Alert
                description={error.message}
                type="error"
                closable
                onClose={() => setError({ show: false, message: "" })}
              />
            )}
          </Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ margin: 0, fontSize: 14 }}
          >
            Cuentas contables productos sin IVA
          </Divider>
          <CuentaContableInput
            label="C.C. Inventario:"
            value={form.cuenta_contable_inventario}
            onChange={(v) => onChangeForm("cuenta_contable_inventario", v)}
          />
          <CuentaContableInput
            label="C.C. Ventas:"
            value={form.cuenta_contable_ventas}
            onChange={(v) => onChangeForm("cuenta_contable_ventas", v)}
          />
          <CuentaContableInput
            label="C.C. Costo:"
            value={form.cuenta_contable_costo}
            onChange={(v) => onChangeForm("cuenta_contable_costo", v)}
          />
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ margin: 0, fontSize: 14 }}
          >
            Cuentas contables productos con IVA
          </Divider>
          <CuentaContableInput
            label="C.C. Inventario:"
            value={form.cuenta_contable_iva_inventario}
            onChange={(v) => onChangeForm("cuenta_contable_iva_inventario", v)}
          />
          <CuentaContableInput
            label="C.C. Ventas:"
            value={form.cuenta_contable_iva_ventas}
            onChange={(v) => onChangeForm("cuenta_contable_iva_ventas", v)}
          />
          <CuentaContableInput
            label="C.C. Costo:"
            value={form.cuenta_contable_iva_costo}
            onChange={(v) => onChangeForm("cuenta_contable_iva_costo", v)}
          />
          
        </Col>
        <Col span={7}>
          {/* BOTONES */}
          <Row style={{ marginBottom: 10 }}>
            {form.id_parametros_contables_productos ? (
              <Button
                type="primary"
                onClick={editar}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").editar || !activeForm
                }
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={crear}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").crear || !activeForm
                }
              >
                Crear
              </Button>
            )}
          </Row>
          <Row>
            <Radio.Group
              onChange={(e) => {
                onChangeForm(
                  "id_tipo_contabilizacion_ingresos",
                  e.target.value
                );
              }}
              value={form.id_tipo_contabilizacion_ingresos}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo contabilización ingresos
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilizacion_salidas", e.target.value);
              }}
              value={form.id_tipo_contabilizacion_salidas}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo contabilización salidas
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          {/* <Row style={{ marginTop: 10 }}>
          <Button type="primary">Asignar cuentas</Button>
        </Row> */}
        </Col>
      </Row>
    </Spin>
  );
};
