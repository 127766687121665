import { Layout, Menu, Slider, Typography, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { SiderApp } from "./components/SiderApp";
import { HeaderApp } from "./components/HeaderApp";

export const AppLayout = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <SiderApp />
      <Layout>
        <HeaderApp />
        <Layout
          style={{
            padding: 10,
          }}
        >
          <Content
            style={{
              background: colorBgContainer,
              padding: 10,
              maxHeight: "calc(100vh)",
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
