import React, { useContext, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { EditarMedida } from "./EditarMedida";
import { eliminarMedidaRequest } from "../../../../../services/api/medidas";

export const TablaMedidas = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadMedidas } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_medida_producto = record.id_medida_producto;
    if (!id_medida_producto) return;
    setId(id_medida_producto);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_medida_producto = record.id_medida_producto;
    if (!id_medida_producto) return;
    if (record.predeterminado) {
      alert("No puede eliminar el predeterminado");
      return;
    }
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta medida, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarMedidaRequest(id_medida_producto).then((res) => {
      reloadMedidas();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MMEDIDAS").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MMEDIDAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },

    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "60%",
      ...getColumnSearchProps("descripcion"),
    },
    {
      title: "Descripción corta",
      dataIndex: "descripcion_corta",
      key: "descripcion_corta",
      width: "60%",
      ...getColumnSearchProps("descripcion_corta"),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_medida_producto"}
        columns={columns}
        dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
        size="small"
      />
      <EditarMedida
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
