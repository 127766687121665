import React, { useContext } from "react";
import {
  DeleteOutlined,
  FileTextOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../../../../helpers/crypto/crypto";
import { UserContext } from "../../../../../context/UserContext";
import { AuthContext } from "../../../../../context/AuthContext";
import { eliminarEmpresaRequest } from "../../../../../services/api/empresas";
import { ActionContext } from "../../../../../context/ActionContext";
import { eliminarSucursalRequest } from "../../../../../services/api/sucursales";

export const TablaSucursal = ({ data }) => {

  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadSucursales } = useContext(ActionContext);

  const navigate = useNavigate();

  const navegarSucursalDetalle = (record) => {
    if (!record.id_sucursal) return;
    const id = record.id_sucursal;
    navigate(`/administracion/sucursales/${id}`);
  };

  const eliminar = (record) => {
    const id_sucursal = record.id_sucursal;
    if (!id_sucursal) return;
   
    const confirm = window.confirm(
      "¿Esta seguro de eliminar la sucursal, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;
    eliminarSucursalRequest(id_sucursal).then((res) => {
      reloadSucursales();
      checkData();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MSUCURSALES").leer}
              shape="default"
              icon={<FileTextOutlined />}
              onClick={() => navegarSucursalDetalle(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              disabled={!checkPermisos("MSUCURSALES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Sucursal",
      dataIndex: "sucursal",
      key: "sucursal",
      width: "20%",
      ...getColumnSearchProps("sucursal"),
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      key: "direccion",
      width: "20%",
    },
    {
      title: "Tipo sucursal",
      dataIndex: "tipo_sucursal",
      key: "tipo_sucursal",
      width: "20%",
      render: (value) => value.descripcion,
    },
    {
      title: "Estado",
      dataIndex: "estado_sucursal",
      key: "estado_sucursal",
      width: "20%",
      render: (value) => value.descripcion,
    },
  ];

  return <Table columns={columns} dataSource={data} size="small" />;
};
