import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS,
  ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS,
} from "../../../../configs/constants/estados-documentos-electronicos";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { CODIGOS_IMPUESTOS } from "../../../../helpers/constants/codigos-impuestos";
import { UNIDAD_TIEMPO } from "../../../../helpers/constants/unidad-tiempo";
import { dateYYYYMMDD } from "../../../../helpers/format/dates-formater";
import { useForm } from "../../../../hooks/useForm";
import {
  agentesVentasIdEmpresaRequest,
  validarFacturadorRequest,
} from "../../../../services/api/agentes-ventas";
import {
  calcularTotalesFacturaRequest,
  documentoIdRequest,
  editarNotaCreditoRequest,
  eliminarDocumentoRequest,
} from "../../../../services/api/facturacion";
import { secuenciaIdRequest } from "../../../../services/api/secuencias";
import { OpcionesDocumento } from "./components/OpcionesDocumento";
import { TabMantenimiento } from "./components/TabMantenimiento";
import { TagDocumentoElectronico } from "./components/TagDocumentoElectronico";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";
import { handleApiError } from "../../../../global/functions/handle-api-error.function";

const base = [
  {
    medida: "interna",
    tarifa: "Tarifas en: ",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    id_tarifa_producto: 10,
    medida: "interna",
    tarifa: "Precio 1",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 2",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 3",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 4",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
  {
    medida: "interna",
    tarifa: "Precio 5",
    precio: 0,
    precio_iva: 0,
    margen: 0,
    utilidad_porcentaje: 0,
    utilidad_valor: 0,
    descuento: 0,
  },
];

export const DetalleNotaCreditoPage = () => {
  const { id } = useParams();

  const { isProductosChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();
  const inputPasswordRef = useRef();

  const [documento, setDocumento] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setEdit(false);
    setReload(!reload);
  };

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    fecha_emision: dayjs(new Date()),
    fecha_vence: dayjs(new Date()),
    secuencia: "",
    concepto: "",
    observacion_adicional: "",
    contabilizado: true,
    imprimir: false,
    id_agente_venta_facturador: null,
    id_agente_venta_vendedor: null,
    id_almacen: null,
    id_tarifa: null,
    id_secuencia: null,
    id_cliente: null,
  });

  const [isValid, setIsValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [errorValidate, setErrorValidate] = useState({
    show: false,
    message: false,
  });

  const [facturadores, setFacturadores] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);

  const [formasPagoSRI, setFormasPagoSRI] = useState([]);
  const [pagos, setPagos] = useState([]);

  const [totales, setTotales] = useState({
    sub_total: 0,
    descuento_porcentaje: 0,
    descuento_valor: 0,
    sub_total_neto: 0,
    sub_total_iva: 0,
    sub_total_iva_cero: 0,
    sub_total_no_objeto: 0,
    sub_total_exento: 0,
    total_ice: 0,
    total_iva: 0,
    propina: 0,
    total: 0,
    neto: 0,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [errorCalculos, setErrorCalculos] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetalles, setIsLoadingDetalles] = useState(false);
  const [isLoadingTotales, setIsLoadingTotales] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      documentoIdRequest(id),
      agentesVentasIdEmpresaRequest(empresa.id_empresa),
    ])
      .then(([documentoRes, agentesVentasRes]) => {
        // documento
        setDocumento(documentoRes);
        setForm({
          ...form,
          ...documentoRes,
          fecha_emision: dayjs(documentoRes.fecha_emision),
          fecha_vence: dayjs(documentoRes.fecha_vence),
          secuencia: Number(documentoRes.secuencia_text),
          observacion_adicional: documentoRes.observacion_adicional,
          contabilizado: documentoRes.contabilizado,
          imprimir: documentoRes.imprimir,
          id_almacen: documentoRes.almacen?.id_almacen,
          id_tarifa: documentoRes.tarifa?.id_tarifa,
          id_secuencia: documentoRes.secuencia?.id_secuencia,
          id_cliente: documentoRes.cliente?.id_cliente,
          cliente: documentoRes.cliente,
          id_agente_venta_facturador:
            documentoRes.agente_venta_facturador?.id_agente_venta,
          id_agente_venta_vendedor:
            documentoRes.agente_venta_vendedor?.id_agente_venta,
        });
        const productos = documentoRes.detalles_documentos.map((d) => {
          return {
            ...d,
            id_producto: d.producto?.id_producto,
            codigo: d.codigo_principal,
            precio: d.precio_unitario,
            iva_producto: d.producto?.iva_producto,
            iva: d.producto?.iva_producto?.descripcion,
            subsidio: !d.valor_subsidio ? 0 : Number(d.valor_subsidio),
            id_medida: d.medida_producto.id_medida_producto,
            medida: d.medida_producto.descripcion,
          };
        });
        setProductos(productos);
        setTotales({
          sub_total: documentoRes.sub_total,
          descuento_porcentaje: documentoRes.descuento_porcentaje,
          descuento_valor: documentoRes.descuento_valor,
          sub_total_neto: documentoRes.sub_total_neto,
          sub_total_iva: documentoRes.sub_total_iva,
          sub_total_iva_cero: documentoRes.sub_total_iva_cero,
          sub_total_no_objeto: documentoRes.sub_total_no_objeto,
          sub_total_exento: documentoRes.sub_total_exento,
          total_ice: documentoRes.total_ice,
          total_iva: documentoRes.total_iva,
          propina: documentoRes.propina,
          total: documentoRes.total,
          neto: documentoRes.neto,
        });

        setUser(documentoRes.agente_venta_facturador);
        // agentes ventas

        if (agentesVentasRes.length > 0) {
          const filterFacturadores = agentesVentasRes.filter(
            (a) => a.facturador
          );
          const filterVendedores = agentesVentasRes.filter((a) => a.vendedor);

          setFacturadores(filterFacturadores);
          setVendedores(filterVendedores);
        }
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  useEffect(() => {
    if (
      form.id_secuencia &&
      edit &&
      form.id_secuencia !== documento.secuencia?.id_secuencia
    ) {
      setIsLoading(true);
      secuenciaIdRequest(form.id_secuencia)
        .then((res) => {
          setForm((prev) => ({
            ...prev,
            codigo_establecimiento: res.sucursal,
            codigo_punto_emision: res.punto_emision,
            secuencia: res.numero_actual,
            concepto: "",
          }));
        })
        .finally(() => setIsLoading(false));
    }

    if (edit && form.id_secuencia === documento.secuencia?.id_secuencia) {
      setForm((prev) => ({
        ...prev,
        codigo_establecimiento: documento.secuencia?.sucursal,
        codigo_punto_emision: documento.secuencia?.punto_emision,
        secuencia: Number(documento.secuencia_text),
        concepto: "",
      }));
    }
  }, [form.id_secuencia]);

  const seleccionarProducto = (p) => {
    const index = productos.findIndex((e) => e.id_producto === p.id_producto);
    setProductoSeleccionado(index);
  };

  const eliminarProducto = (p) => {
    const temporal = productos.filter(
      (producto) => producto.id_producto !== p.id_producto
    );
    calcularTotales(temporal, totales.descuento_porcentaje, totales.propina);
    setProductoSeleccionado(null);
  };

  const agregarProducto = (p) => {
    const find = productos.find(
      (producto) => producto.id_producto === p.id_producto
    );
    if (find) return;
    const detalles = [
      ...productos,
      {
        ...p,
        cantidad: 1,
        id_medida: p.medida_interna?.id_medida_producto,
        medida: p.medida_interna?.descripcion,
        precio:
          p.tarifas_productos?.find(
            (tp) =>
              tp.medida === "interna" &&
              tp.tarifa ===
                user.tarifas?.find((t) => t.id_tarifa === form.id_tarifa)
                  .descripcion
          )?.precio ?? 0,
        descuento: 0,
        iva: p.iva_producto.descripcion,
      },
    ];
    calcularTotales(detalles, totales.descuento_porcentaje, totales.propina);
  };

  const validarCampos = () => {
    const { fecha_emision, fecha_vence, secuencia } = form;

    if (!dayjs(fecha_emision).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha de Emision",
      });
      return false;
    }

    if (!dayjs(fecha_vence).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!Number.isInteger(Number(secuencia)) || Number(secuencia) <= 0) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (productos.length === 0) {
      setError({
        show: true,
        message: "Agrege productos a la factura",
      });
      return false;
    }

    return true;
  };

  const guardar = async () => {
    setError({ show: false, message: "" });

    const validacion = validarCampos();
    if (!validacion) return;

    const detalles_documentos = productos.map((p) => {
      return {
        codigo_principal: p.codigo,
        codigo_auxiliar: p.codigo_auxiliar,
        descripcion: p.descripcion,
        descripcion_remplazo: p.descripcion_remplazo ?? "",
        detalle_adicional_1: p.detalle_adicional_1 ?? "",
        detalle_adicional_2: p.detalle_adicional_2 ?? "",
        detalle_adicional_3: p.detalle_adicional_3 ?? "",
        cantidad: !p.cantidad ? 0 : Number(p.cantidad),
        precio_unitario: !p.precio_unitario ? 0 : Number(p.precio_unitario),
        descuento: !p.descuento ? 0 : Number(p.descuento),
        valor_subsidio: !p.valor_subsidio ? 0 : Number(p.valor_subsidio),
        precio_sin_subsidio: !p.precio_sin_subsidio
          ? 0
          : Number(p.precio_sin_subsidio),
        precio_total_sin_impuestos: !p.precio_total_sin_impuestos
          ? 0
          : Number(p.precio_total_sin_impuestos),
        codigo_iva: CODIGOS_IMPUESTOS.IVA,
        codigo_porcentaje_iva: Number(p.codigo_porcentaje_iva).toString(),
        tarifa_iva: !p.tarifa_iva ? 0 : Number(p.tarifa_iva),
        base_imponible_iva: !p.base_imponible_iva
          ? 0
          : Number(p.base_imponible_iva),
        valor_iva: !p.valor_iva ? 0 : Number(p.valor_iva),
        codigo_ice: CODIGOS_IMPUESTOS.ICE,
        codigo_porcentaje_ice: !p.codigo_porcentaje_ice
          ? null
          : Number(p.codigo_porcentaje_ice).toString(),
        tarifa_ice: !p.tarifa_ice ? 0 : Number(p.tarifa_ice),
        base_imponible_ice: !p.base_imponible_ice
          ? 0
          : Number(p.base_imponible_ice),
        valor_ice: !p.valor_ice ? 0 : Number(p.valor_ice),
        codigo_irbpnr: CODIGOS_IMPUESTOS.IRBPNR,
        codigo_porcentaje_irbpnr: !p.codigo_porcentaje_irbpnr
          ? null
          : Number(p.codigo_porcentaje_irbpnr).toString(),
        tarifa_irbpnr: !p.tarifa_irbpnr ? 0 : Number(p.tarifa_irbpnr),
        base_imponible_irbpnr: !p.base_imponible_irbpnr
          ? 0
          : Number(p.base_imponible_irbpnr),
        valor_irbpnr: !p.valor_irbpnr ? 0 : Number(p.valor_irbpnr),
        total: !p.total ? 0 : Number(p.total),
        id_producto: p.id_producto,
        id_medida_producto: p.id_medida,
      };
    });

    const pagos_documentos = [
      {
        plazo: dayjs(form.fecha_vence).diff(form.fecha_emision, "day"),
        unidad_tiempo: UNIDAD_TIEMPO.DIAS,
        total: Number(totales.total),
        id_forma_pago_sri: 1,
      },
    ];

    const dataRequest = {
      fecha_emision: dateYYYYMMDD(form.fecha_emision),
      fecha_vence: dateYYYYMMDD(form.fecha_vence),
      secuencia: Number(form.secuencia),
      concepto: form.concepto,
      observacion_adicional: form.observacion_adicional,
      contabilizado: form.contabilizado,
      imprimir: form.imprimir,
      sub_total: !totales.sub_total ? 0 : Number(totales.sub_total),
      descuento_porcentaje: !totales.descuento_porcentaje
        ? 0
        : Number(totales.descuento_porcentaje),
      descuento_valor: !totales.descuento_valor
        ? 0
        : Number(totales.descuento_valor),
      sub_total_neto: !totales.sub_total_neto
        ? 0
        : Number(totales.sub_total_neto),
      sub_total_iva: !totales.sub_total_iva ? 0 : Number(totales.sub_total_iva),
      sub_total_iva_cero: !totales.sub_total_iva_cero
        ? 0
        : Number(totales.sub_total_iva_cero),
      sub_total_no_objeto: !totales.sub_total_no_objeto
        ? 0
        : Number(totales.sub_total_no_objeto),
      sub_total_exento: !totales.sub_total_exento
        ? 0
        : Number(totales.sub_total_exento),
      total_ice: !totales.total_ice ? 0 : Number(totales.total_ice),
      total_iva: !totales.total_iva ? 0 : Number(totales.total_iva),
      propina: !totales.propina ? 0 : Number(totales.propina),
      total: !totales.total ? 0 : Number(totales.total),
      neto: !totales.neto ? 0 : Number(totales.neto),
      id_empresa: empresa.id_empresa,
      id_agente_venta_facturador: form.id_agente_venta_facturador,
      id_agente_venta_vendedor: form.id_agente_venta_vendedor,
      id_almacen: form.id_almacen,
      id_tarifa: form.id_tarifa,
      id_secuencia: form.id_secuencia,
      id_cliente: form.cliente.id_cliente,
      detalles_documentos: detalles_documentos,
      id_documento_relacionado: documento.id_documento_relacionado,
    };

    setIsLoading(true);

    await editarNotaCreditoRequest(id, dataRequest)
      .then((res) => {
        onResetForm();
        handleReload();
      })
      .catch((error) => handleApiError(error, setError))
      .finally(() => setIsLoading(false));
  };

  const onOk = (e) => {
    e?.preventDefault();

    setErrorValidate({ show: false, message: "" });

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      password: password,
    };

    setIsValidating(true);
    validarFacturadorRequest(dataRequest)
      .then((res) => {
        setForm((prev) => ({
          ...prev,
          id_agente_venta_facturador: res.id_agente_venta,
          concepto: "",
        }));
        setUser(res);
        setIsValid(true);
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data && data.statusCode === 404) {
          setErrorValidate({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => setIsValidating(false));
  };

  useEffect(() => {
    if (!isValid) {
      inputPasswordRef.current?.focus();
    }
  }, [isValid]);

  const onCancel = () => {
    setEdit(false);
    setIsValid(true);
  };

  const onChangeDetalles = (data) => {
    const detalles = productos.map((producto) => {
      if (producto.id_producto === data.id_producto) {
        return data;
      }
      return producto;
    });

    calcularTotales(detalles, totales.descuento_porcentaje, totales.propina);
  };

  const onChangeTotales = (data) => {
    calcularTotales(productos, data.descuento_porcentaje, data.propina);
  };

  const calcularTotales = (p, descuento_porcentaje, propina) => {
    setErrorCalculos({
      show: false,
      message: "",
    });

    const dataRequest = {
      detalles_documentos: p.map((producto) => {
        return {
          cantidad: Number(producto.cantidad),
          precio_unitario: Number(producto.precio),
          descuento: Number(producto.descuento),
          subsidio: Number(producto.subsidio),
          codigo_porcentaje_iva: Number(producto.iva_producto?.codigo),
          tarifa_iva: Number(producto.iva_producto?.valor),
          tarifa_ice: !producto.porcentaje_ice
            ? null
            : Number(producto.porcentaje_ice),
          tarifa_irbpnr: !producto.porcentaje_irbpnr
            ? null
            : Number(producto.porcentaje_irbpnr),
        };
      }),
      descuento_porcentaje: Number(descuento_porcentaje),
      propina: Number(propina),
    };

    setIsLoadingDetalles(true);
    setIsLoadingTotales(true);
    calcularTotalesFacturaRequest(dataRequest)
      .then((res) => {
        const detallesRes = res.detalles_documentos.map((d, index) => ({
          ...p[index],
          ...d,
        }));
        setProductos(detallesRes);
        const totalesRes = {
          sub_total: res.sub_total,
          descuento_porcentaje: res.descuento_porcentaje,
          descuento_valor: res.descuento_valor,
          sub_total_neto: res.sub_total_neto,
          sub_total_iva: res.sub_total_iva,
          sub_total_iva_cero: res.sub_total_iva_cero,
          sub_total_no_objeto: res.sub_total_no_objeto,
          sub_total_exento: res.sub_total_exento,
          total_ice: res.total_ice,
          total_iva: res.total_iva,
          propina: res.propina,
          total: res.total,
          neto: res.neto,
        };
        setTotales(totalesRes);
      })
      .catch((err) => {
        const data = err?.response?.data;
        if (data && data.statusCode === 400) {
          if (data.message.includes("propina")) {
            setTotales({
              ...totales,
              propina: 0,
            });
          }
          setErrorCalculos({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingDetalles(false);
        setIsLoadingTotales(false);
      });
  };

  const onChangeDetalle = (d) => {
    const index = productos.findIndex((e) => e.id_producto === d.id_producto);
    const arrayTemp = productos.map((e, i) => {
      if (index === i) return { ...e, ...d };
      return e;
    });

    setProductos(arrayTemp);
  };

  const onChangeObservacionAdicional = (d) => {
    setForm((prev) => ({
      ...prev,
      ...d,
    }));
  };

  const handleEdit = () => {
    setIsValid(false);
    setEdit(true);
  };

  const handleDelete = () => {
    const id_documento = documento.id_documento;
    if (!id_documento) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este documento, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarDocumentoRequest(id_documento).then((res) => {
      navigate(-1);
    });
  };

  const items = [
    {
      key: 1,
      label: `Mantenimiento`,
      children: (
        <TabMantenimiento
          nota_credito={true}
          edit={!edit}
          form={form}
          onChangeForm={onChangeForm}
          user={user}
          facturadores={facturadores}
          vendedores={vendedores}
          almacenes={user.almacenes}
          tarifas={user.tarifas}
          secuencias={user.secuencias}
          productos={productos}
          productoSeleccionado={productos[productoSeleccionado]}
          seleccionarProducto={seleccionarProducto}
          eliminarProducto={eliminarProducto}
          agregarProducto={agregarProducto}
          totales={totales}
          setTotales={setTotales}
          onChange={onChangeDetalles}
          isLoadingDetalles={isLoadingDetalles}
          isLoadingTotales={isLoadingTotales}
          onChangeTotales={onChangeTotales}
          errorCalculos={errorCalculos}
          setErrorCalculos={setErrorCalculos}
          onChangeDetalle={onChangeDetalle}
          onChangeObservacionAdicional={onChangeObservacionAdicional}
        />
      ),
    },
    // {
    //   key: 2,
    //   label: `Forma de cobro`,
    //   children: <TabFormaCobro form={form} onChangeForm={onChangeForm} />,
    // },
  ];

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Facturación",
                },
                {
                  title: "Notas de Créditos",
                },
                {
                  title: (
                    <Row gutter={10}>
                      <Col>
                        <Typography>{documento?.numero_documento}</Typography>
                      </Col>
                      <Col>
                        {(documento.electronico ||
                          documento.estado_documento_electronico ===
                            CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO) && (
                          <TagDocumentoElectronico
                            estado={documento.estado_documento_electronico}
                          />
                        )}
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <OpcionesDocumento
                    documento={documento}
                    setIsLoading={setIsLoading}
                    handleReload={handleReload}
                  />
                </Col>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MFACTURACION").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MFACTURACION").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        {documento.codigo_error_sri && documento.error_sri && (
          <Alert
            message={
              <Typography style={{ fontSize: 12 }}>
                {
                  ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS[
                    documento.estado_documento_electronico
                  ]
                }
              </Typography>
            }
            description={`(${documento.codigo_error_sri}) ${documento.error_sri}`}
            type="error"
            // showIcon

            style={{ padding: 5, fontSize: 12 }}
          />
        )}
        <Form disabled={!edit}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
      <Modal
        title="Clave Facturador"
        open={!isValid}
        confirmLoading={isValidating}
        onCancel={onCancel}
        maskClosable={false}
        centered
        okText={"Continuar"}
        onOk={onOk}
      >
        {errorValidate.show && (
          <Alert
            style={{ marginBlock: 10 }}
            description={errorValidate.message}
            type="error"
            closable
            onClose={() => setErrorValidate({ show: false, message: "" })}
          />
        )}

        <form onSubmit={onOk}>
          <Input.Password
            ref={inputPasswordRef}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
      </Modal>
    </Spin>
  );
};
