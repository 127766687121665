import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useState } from "react";
import { subirImagenProductoRequest } from "../../../../../services/api/productos";
import { subirImagenSecuenciaRequest } from "../../../../../services/api/secuencias";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { DeleteFilled } from "@ant-design/icons";

export const TabSecuencias = memo(
  ({
    form = {},
    disabled = false,
    edit = false,
    onChangeForm = (e, d) => { },
    tiposDocumentos = [],
    tiposRegimen = [],
    agentesRetencion = [],
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const subirImagen = (e) => {
      const formData = new FormData();
      formData.append("file", e);
      setIsLoading(true);
      subirImagenSecuenciaRequest(formData)
        .then((res) => {
          onChangeForm("url_imagen", res);
        })
        .finally(() => setIsLoading(false));
    };

    const deleteImage = () => {
      onChangeForm("url_imagen", "");
    };

    return (
      <Content>
        <Form disabled={disabled | !edit}>
          <Row gutter={5}>
            <Col span={16}>
              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col span={5}>
                      <Typography>Descripción: </Typography>
                    </Col>
                    <Col span={19}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={form.descripcion}
                        onChange={(e) =>
                          onChangeForm("descripcion", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col span={5}>
                      <Typography>Dirección: </Typography>
                    </Col>
                    <Col span={19}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={form.direccion}
                        onChange={(e) =>
                          onChangeForm("direccion", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>Teléfono: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={form.telefono}
                        onChange={(e) => onChangeForm("telefono", e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={14}>
                      <Typography>Iniciales No. Fact.: </Typography>
                    </Col>
                    <Col span={10}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        disabled={form.electronico}
                        value={form.iniciales_numero_facturacion}
                        onChange={(e) =>
                          onChangeForm(
                            "iniciales_numero_facturacion",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>Tipo de Documento: </Typography>
                    </Col>
                    <Col span={14}>
                      <Select
                        size="small"
                        style={{ width: "100%" }}
                        value={form.id_tipo_documento}
                        onChange={(e) => onChangeForm("id_tipo_documento", e)}
                      >
                        {tiposDocumentos.map((a) => {
                          return (
                            <Select.Option
                              key={a.id_tipo_documento}
                              value={a.id_tipo_documento}
                            >
                              {a.descripcion}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={14}>
                      <Typography>Cant. Dígitos No. Fact.: </Typography>
                    </Col>
                    <Col span={10}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        disabled={form.electronico}
                        value={form.cantidad_digitos_numero_facturacion}
                        onChange={(e) =>
                          onChangeForm(
                            "cantidad_digitos_numero_facturacion",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>No. Aut. SRI: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        disabled={form.electronico}
                        value={form.numero_autorizacion_sri}
                        onChange={(e) =>
                          onChangeForm("numero_autorizacion_sri", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={14}>
                      <Typography>Cant. Items en Factura Física: </Typography>
                    </Col>
                    <Col span={10}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        disabled={form.electronico}
                        value={form.cantidad_items_factura_fisica}
                        onChange={(e) =>
                          onChangeForm(
                            "cantidad_items_factura_fisica",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>Sucursal: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        value={form.sucursal}
                        onChange={(e) => onChangeForm("sucursal", e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={14}>
                      <Typography>Tipo Regimen: </Typography>
                    </Col>
                    <Col span={10}>
                      <Select
                        size="small"
                        style={{ width: "100%" }}
                        value={form.id_tipo_regimen}
                        onChange={(e) => onChangeForm("id_tipo_regimen", e)}
                      >
                        {tiposRegimen.map((a) => {
                          return (
                            <Select.Option
                              key={a.id_tipo_regimen}
                              value={a.id_tipo_regimen}
                            >
                              {a.descripcion_regimen}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>Punto Emision: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        value={form.punto_emision}
                        onChange={(e) =>
                          onChangeForm("punto_emision", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={14}>
                      <Typography>Agente Retención: </Typography>
                    </Col>
                    <Col span={10}>
                      <Select
                        size="small"
                        style={{ width: "100%" }}
                        value={form.id_agente_retencion}
                        onChange={(e) => onChangeForm("id_agente_retencion", e)}
                      >
                        {agentesRetencion.map((a) => {
                          return (
                            <Select.Option
                              key={a.id_agente_retencion}
                              value={a.id_agente_retencion}
                            >
                              {a.descripcion_agente_retencion}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                        No. Actual:{" "}
                      </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type="number"
                        value={form.numero_actual}
                        onChange={(e) =>
                          onChangeForm("numero_actual", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Checkbox
                      checked={form.activo}
                      onChange={(e) => onChangeForm("activo", e.target.checked)}
                    >
                      Activo
                    </Checkbox>
                    <Checkbox
                      checked={form.iva}
                      onChange={(e) => onChangeForm("iva", e.target.checked)}
                    >
                      IVA
                    </Checkbox>
                    <Checkbox
                      checked={form.fiscal}
                      onChange={(e) => onChangeForm("fiscal", e.target.checked)}
                    >
                      Fiscal
                    </Checkbox>
                    <Checkbox
                      checked={form.electronico}
                      onChange={(e) =>
                        onChangeForm("electronico", e.target.checked)
                      }
                    >
                      Electrónico
                    </Checkbox>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Spin spinning={isLoading}>
                <Row justify={"center"}>
                  <div
                    style={{
                      height: 150,
                      width: 150,
                      position: "relative",
                    }}
                  >
                    {form.url_imagen && form.url_imagen != "" ? (
                      <>
                        <Image src={form.url_imagen} height={150} />
                        <Button
                          type={"primary"}
                          icon={<DeleteFilled />}
                          danger
                          style={{ position: "absolute" }}
                          onClick={deleteImage}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          accept="image/*"
                          multiple={false}
                          type={"file"}
                          onChange={(e) => {
                            subirImagen(e.target.files[0]);
                          }}
                          style={{
                            position: "absolute",
                            opacity: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        />
                        <div
                          style={{
                            width: "90%",
                            height: "90%",
                            borderStyle: "dashed",
                            borderWidth: 2,
                            borderColor: PRIMARY_COLOR,
                            borderRadius: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            Subir imagen
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Row>
              </Spin>
            </Col>
          </Row>
        </Form>

      </Content>
    );
  }
);
