import { memo, useEffect, useMemo, useState } from 'react';
import { DatePicker } from 'antd';
import { dateYYYYMMDD } from '../../helpers/format/dates-formater';

export const DateFilter = memo(({ onChange = () => {}, isChange }) => {

    const [dates, setDates] = useState(null);

    useEffect(() => {
        setDates(null);
    }, [isChange]);

    const onChangeRangeFilter = (dates) => {
        setDates(dates);
        if(!dates) return onChange(null);
        const [startDate, endDate] = dates;
        const datesRange = {
            start: dateYYYYMMDD(startDate.toDate()),
            end: dateYYYYMMDD(endDate.toDate()),
        }
        onChange(datesRange);
    }

    return <DatePicker.RangePicker value={dates} onChange={onChangeRangeFilter}/>

})