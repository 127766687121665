import React, { useEffect, useRef } from "react";
import { FormPagoEfectivo } from "./FormPagoEfectivo";
import { useForm } from "../../../../../hooks/useForm";
import { TIPOS_FORMAS_PAGO } from "../../../../../configs/constants/tipos-formas-pagos";
import { FormPagoCredito } from "./FormPagoCredito";
import dayjs from "dayjs";
import { FormPagoCheque } from "./FormPagoCheque";
import { FormPagoDeposito } from "./FormPagoDeposito";

export const TabPagoDeposito = ({
  pendiente,
  bancos = [],
  formasPagosSri = [],
  registrarPago = () => {},
  edit = false,
}) => {
  const valorRef = useRef();

  const { form, setForm, onChangeForm, onResetForm } = useForm({
    id_tipo_forma_pago: TIPOS_FORMAS_PAGO.DEPOSITO,
    total: pendiente,
    id_banco: null,
    numero_documento: "",
    id_forma_pago_sri: null,
  });

  useEffect(() => {
    valorRef.current?.focus();
    setForm((prev) => ({
      ...prev,
      total: pendiente,
    }));
  }, [pendiente]);

  useEffect(() => {
    if (bancos.length > 0) {
      setForm((prev) => ({
        ...prev,
        id_banco: bancos[0].id_banco,
      }));
    }
  }, [bancos]);

  useEffect(() => {
    if (formasPagosSri.length > 0) {
      setForm((prev) => ({
        ...prev,
        id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
      }));
    }
  }, [formasPagosSri]);

  const registrar = () => {

    if (!form.id_banco) {
      alert("Seleccione el Banco");
      return;
    }

    registrarPago(form);
    setForm((prev) => ({
      ...prev,
      id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
      id_banco: bancos[0].id_banco,
      numero_documento: "",
    }));
  };

  return (
    <FormPagoDeposito
      form={form}
      onChangeForm={onChangeForm}
      bancos={bancos}
      formasPagosSri={formasPagosSri}
      registrar={registrar}
      valorRef={valorRef}
    />
  );
};
