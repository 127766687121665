import React from "react";
import { Route, Routes } from "react-router-dom";

import { BancosClientesPage } from "../../pages/app/cartera/bancos-clientes/BancosClientesPage";

export const CarteraRoutes = () => {
  return (
    <Routes>
      <Route path="/bancos-clientes" element={<BancosClientesPage />} />
    </Routes>
  );
};
