import React from "react";
import { Button, Table } from "antd";
import { SelectOutlined } from "@ant-design/icons";

export const TablaSProveedores = ({ data, onSelect = () => {} }) => {
  const columns = [
    {
      title: "Selección",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          shape="default"
          size="small"
          icon={<SelectOutlined />}
          onClick={() => onSelect(record)}
        />
      ),
    },
    {
      title: "Identificación",
      dataIndex: "identificacion",
      key: "identificacion",
      width: "15%",
    },
    {
      title: "Razón Social",
      dataIndex: "razon_social",
      key: "razon_social",
      width: "30%",
    },
    {
      title: "Nombre Comercial",
      dataIndex: "nombre_comercial",
      key: "nombre_comercial",
      width: "30%",
    },
  ];

  return (
    <Table
      scroll={{
        y: 280,
      }}
      rowKey={"id_cliente"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
