import { Col, Input, Row, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { memo } from 'react'

export const InformacionTransportista = memo(({
    razon_social_transporte = "",
    identificacion_transporte = "",
    punto_emision = "",
    codigo_establecimiento = "",
    codigo_punto_emision = "",
    secuencia_text = "",
    clave_acceso = "",
    autorizacion = "",
}) => {
    return (
        <Content style={{ marginTop: 5 }}>
            <Typography.Text strong>
                PUNTO EMISION ({punto_emision})
            </Typography.Text>
            <Row gutter={10} style={{ marginTop: 2 }}>
                <Col span={16}>
                    <Row>
                        <Col span={6}>
                            <Typography.Text strong>
                                Razon social:
                            </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input
                                size="small"
                                style={{ width: "100%" }}
                                disabled
                                value={razon_social_transporte}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={6}>
                            <Typography.Text strong>
                                RUC:
                            </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input
                                size="small"
                                style={{ width: "100%" }}
                                disabled
                                value={identificacion_transporte}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Typography.Text strong>
                    Información Factura de Transportista
                </Typography.Text>
            </Row>
            <Row gutter={10} style={{ marginTop: 2 }}>
                <Col span={12}>
                    <Row>
                        <Col span={8}>
                            <Typography.Text strong>
                                Serie:
                            </Typography.Text>
                        </Col>
                        <Col span={16}>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Input
                                        size="small"
                                        style={{ width: "100%" }}
                                        disabled
                                        value={codigo_establecimiento}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        size="small"
                                        style={{ width: "100%" }}
                                        disabled
                                        value={codigo_punto_emision}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={8}>
                            <Typography.Text strong>
                                Secuencia:
                            </Typography.Text>
                        </Col>
                        <Col span={16}>
                            <Input
                                size="small"
                                style={{ width: "100%" }}
                                disabled
                                value={secuencia_text}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={4}>
                    <Typography.Text strong>
                        Clave Acceso:
                    </Typography.Text>
                </Col>
                <Col span={20}>
                    <Input
                        size="small"
                        style={{ width: "100%" }}
                        disabled
                        value={clave_acceso}
                    />
                </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 2 }}>
                <Col span={4}>
                    <Typography.Text strong>
                        Autorización:
                    </Typography.Text>
                </Col>
                <Col span={20}>
                    <Input
                        size="small"
                        style={{ width: "100%" }}
                        disabled
                        value={autorizacion}
                    />
                </Col>
            </Row>
        </Content>
    )
})
