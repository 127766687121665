import { useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext } from "react";
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";

import { UserContext } from "../../../../context/UserContext";

import { GeneralTab } from "./components/GeneralTab";
import { ProductosTab } from "./components/ProductosTab";
import { ComprasVentasTab } from "./components/ComprasVentasTab";
import { CobrosTab } from "./components/CobrosTab";
import { PagosTab } from "./components/PagosTab";
import { CajasBancosTab } from "./components/CajasBancosTab";
import { NominaTab } from "./components/NominaTab";

const almacenGeneral = {
  id_almacen: 0,
  nombre_almacen: "Almacen general",
};

export const ParametrosContablesPage = () => {
  
  const { empresa, checkPermisos } = useContext(UserContext);

  const navigate = useNavigate();

  const [activeForm, setActiveForm] = useState(false);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onReload = () => {
    setActiveForm(false);
    setReload(!reload);
  };

  const handleActiveForm = () => {
    setActiveForm(!activeForm);
  };

  const tabs = [
    {
      label: "General",
      key: 0,
      children: (
        <GeneralTab
          onReload={onReload}
          activeForm={activeForm}
          reload={reload}
        />
      ),
    },
    {
      label: "Productos",
      key: 1,
      children: (
        <ProductosTab
          onReload={onReload}
          activeForm={activeForm}
          reload={reload}
        />
      ),
    },
    {
      label: "Compras y ventas",
      key: 2,
      children: (
        <ComprasVentasTab
          onReload={onReload}
          activeForm={activeForm}
          reload={reload}
        />
      ),
    },
    {
      label: "Cobros",
      key: 3,
      children: (
        <CobrosTab
          onReload={onReload}
          activeForm={activeForm}
          reload={reload}
        />
      ),
    },
    {
      label: "Pagos",
      key: 4,
      children: (
        <PagosTab onReload={onReload} activeForm={activeForm} reload={reload} />
      ),
    },
    {
      label: "Cajas y Bancos",
      key: 5,
      children: <CajasBancosTab onReload={onReload} activeForm={activeForm} />,
    },
    {
      label: "Nómina",
      key: 6,
      children: <NominaTab onReload={onReload} activeForm={activeForm} />,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Parámetros Contables",
                },
              ]}
            />
          </Row>
          <Row>
            <Button
              disabled={!checkPermisos("MPARAMETROS-CONTABLES").crear}
              type="primary"
              onClick={handleActiveForm}
              icon={<EditFilled />}
            >
              Editar
            </Button>
          </Row>
        </Row>
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        {/* Boby */}
        <Form disabled={!activeForm}>
          <Tabs items={tabs} />
        </Form>
      </Content>
    </Spin>
  );
};
