import React, { useEffect, useRef } from "react";
import { FormPagoEfectivo } from "./FormPagoEfectivo";
import { useForm } from "../../../../../hooks/useForm";
import { TIPOS_FORMAS_PAGO } from "../../../../../configs/constants/tipos-formas-pagos";

export const TabPagoEfectivo = ({
  pendiente,
  cajas = [],
  formasPagosSri = [],
  registrarPago = () => {},
}) => {
  const valorRef = useRef();

  const { form, setForm, onChangeForm, onResetForm } = useForm({
    id_tipo_forma_pago: TIPOS_FORMAS_PAGO.EFECTIVO,
    total: pendiente,
    id_caja: null,
    id_forma_pago_sri: null,
  });

  useEffect(() => {
    valorRef.current?.focus();
    setForm((prev) => ({
      ...prev,
      total: pendiente,
    }));
  }, [pendiente]);

  useEffect(() => {
    if (cajas.length > 0) {
      setForm((prev) => ({
        ...prev,
        id_caja: cajas[0]?.id_caja,
      }));
    }
  }, [cajas]);

  useEffect(() => {
    if (formasPagosSri.length > 0) {
      setForm((prev) => ({
        ...prev,
        id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
      }));
    }
  }, [formasPagosSri]);

  const registrar = () => {

    if(!form.id_caja){
      alert('Seleccione una Caja');
      return;
    }

    registrarPago(form);
    setForm((prev) => ({
      ...prev,
      id_caja: cajas[0]?.id_caja,
      id_forma_pago_sri: formasPagosSri[0].id_forma_pago_sri,
    }));
  };

  return (
    <FormPagoEfectivo
      form={form}
      onChangeForm={onChangeForm}
      cajas={cajas}
      formasPagosSri={formasPagosSri}
      registrar={registrar}
      valorRef={valorRef}
    />
  );
};
