import React from "react";
import { Route, Routes } from "react-router-dom";

import { AlmacenesPage } from "../../pages/app/compras/almacenes/AlmacenesPage";
import { CategoriasProductosPage } from "../../pages/app/compras/categorias-productos/CategoriasProductosPage";
import { LineasProductosPage } from "../../pages/app/compras/lineas-productos/LineasProductosPage";
import { MedidasPage } from "../../pages/app/compras/medidas/MedidasPage";
import { CrearProductoPage } from "../../pages/app/compras/productos/CrearProductoPage";
import { ProductoPage } from "../../pages/app/compras/productos/ProductoPage";
import { ProductosPage } from "../../pages/app/compras/productos/ProductosPage";
import { SubCategoriasProductosPage } from "../../pages/app/compras/sub-categorias-productos/SubCategoriasProductosPage";
import { SubGruposProductosPage } from "../../pages/app/compras/sub-grupos-productos/SubGruposProductosPage";
import { ProveedoresPage } from "../../pages/app/compras/proveedores/ProveedoresPage";
import { ProveedorPage } from "../../pages/app/compras/proveedores/ProveedorPage";
import { CrearProveedorPage } from "../../pages/app/compras/proveedores/CrearProveedorPage";
import { GruposProveedoresPage } from "../../pages/app/compras/grupos-proveedores/GruposProveedoresPage";
import { ComprasPage } from "../../pages/app/compras/compras/ComprasPage";
import { CrearCompraPage } from "../../pages/app/compras/compras/CrearComprasPage";
import { DetalleCompraPage } from "../../pages/app/compras/compras/DetalleCompraPage";
import { GastosPage } from "../../pages/app/compras/gastos/GastosPage";
import { ComprasElectronicasPage } from "../../pages/app/compras/compras/ComprasElectronicasPage";
import { DetalleCompraElectronicaPage } from "../../pages/app/compras/compras/DetalleCompraElectronicaPage";
import { ServiciosPage } from "../../pages/app/compras/servicios/ServiciosPage";
import { CrearServicioPage } from "../../pages/app/compras/servicios/CrearServicioPage";
import { ServicioPage } from "../../pages/app/compras/servicios/ServicioPage";

export const ComprasRoutes = () => {
  return (
    <Routes>
      <Route path="/compras" element={<ComprasPage />} />
      <Route path="/compras/crear-compra" element={<CrearCompraPage />} />
      <Route path="/compras/:id" element={<DetalleCompraPage />} />

      <Route
        path="/compras-electronicas"
        element={<ComprasElectronicasPage />}
      />
      <Route
        path="/compras-electronicas/:id"
        element={<DetalleCompraElectronicaPage />}
      />

      <Route path="/proveedores" element={<ProveedoresPage />} />
      <Route
        path="/proveedores/crear-proveedor"
        element={<CrearProveedorPage />}
      />
      <Route path="/proveedores/:id" element={<ProveedorPage />} />

      <Route path="/grupos-proveedores" element={<GruposProveedoresPage />} />

      <Route path="/productos" element={<ProductosPage />} />
      <Route path="/productos/crear-producto" element={<CrearProductoPage />} />
      <Route path="/productos/:id" element={<ProductoPage />} />

      <Route path="/servicios" element={<ServiciosPage />} />
      <Route path="/servicios/crear-servicio" element={<CrearServicioPage />} />
      <Route path="/servicios/:id" element={<ServicioPage />} />

      <Route path="/gastos" element={<GastosPage />} />

      <Route path="/lineas-productos" element={<LineasProductosPage />} />

      <Route
        path="/categorias-productos"
        element={<CategoriasProductosPage />}
      />

      <Route
        path="/sub-categorias-productos"
        element={<SubCategoriasProductosPage />}
      />

      <Route
        path="/sub-grupos-productos"
        element={<SubGruposProductosPage />}
      />

      <Route path="/almacenes" element={<AlmacenesPage />} />

      <Route path="/medidas" element={<MedidasPage />} />
    </Routes>
  );
};
