import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";
import {
  cantonesRequest,
  estadosSucursalRequest,
  tiposSucursalRequest,
} from "../../../../../services/api/opciones";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import {
  actualizarSucursalRequest,
  crearSucursalRequest,
} from "../../../../../services/api/sucursales";

const { Option } = Select;

export const EditarSucursalDrawer = ({
  open,
  showDrawer,
  onClose,
  sucursal,
  reload = () => {},
}) => {
  const { empresa } = useContext(UserContext);
  const { reloadSucursales } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    sucursal: sucursal.sucursal,
    direccion: sucursal.direccion,
    identificacion: sucursal.identificacion,
    razon_social: sucursal.razon_social,
    telefono_1: sucursal.telefono_1,
    telefono_2: sucursal.telefono_2,
    meta_venta: sucursal.meta_venta,
    comision_produccion: sucursal.comision_produccion,
    comision_distribucion: sucursal.comision_distribucion,
    id_tipo_sucursal: sucursal.id_tipo_sucursal,
    id_estado_sucursal: sucursal.id_estado_sucursal,
    id_canton: sucursal.id_canton,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [cantones, setCantones] = useState([]);
  const [tiposSucursal, setTiposSucursal] = useState([]);
  const [estadosSucursal, setEstadosSucursal] = useState([]);

  //TIPOS SUCURSAL
  useEffect(() => {
    tiposSucursalRequest().then((res) =>
      setTiposSucursal(
        res.map((r) => ({
          value: r.id_tipo_sucursal,
          label: r.descripcion,
        }))
      )
    );
  }, []);

  //ESTADO SUCURSAL
  useEffect(() => {
    estadosSucursalRequest().then((res) =>
      setEstadosSucursal(
        res.map((r) => ({
          value: r.id_estado_sucursal,
          label: r.descripcion,
        }))
      )
    );
  }, []);

  //CANTONES
  useEffect(() => {
    cantonesRequest({}).then((res) => {
      setCantones(
        res.map((r) => ({
          value: r.id_canton,
          label: r.nombre_canton,
        }))
      );
    });
  }, []);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const {
      meta_venta,
      comision_produccion,
      comision_distribucion,
      id_tipo_sucursal,
      id_estado_sucursal,
    } = form;

    if (
      meta_venta != null &&
      meta_venta != "" &&
      !Number.isFinite(meta_venta)
    ) {
      setError({
        show: true,
        message: "La meta de venta unicamente acepta numeros",
      });
      return false;
    }
    if (
      comision_produccion != null &&
      comision_produccion != "" &&
      !Number.isFinite(comision_produccion)
    ) {
      setError({
        show: true,
        message: "La comision de producción unicamente acepta numeros",
      });
      return false;
    }
    if (
      comision_distribucion != null &&
      comision_distribucion != "" &&
      !Number.isFinite(comision_distribucion)
    ) {
      setError({
        show: true,
        message: "La comision de distribucion unicamente acepta numeros",
      });
      return false;
    }

    if (!id_tipo_sucursal) {
      setError({
        show: true,
        message: "Seleccione el tipo de sucursal",
      });
      return false;
    }
    if (!id_estado_sucursal) {
      setError({
        show: true,
        message: "Seleccione el estado ",
      });
      return false;
    }

    return true;
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();
    if (!validacion) return;
    const dataRequest = {
      sucursal: form.sucursal,
      direccion: form.direccion,
      identificacion: form.identificacion,
      razon_social: form.razon_social,
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      meta_venta: !form.meta_venta ? null : Number(form.meta_venta),
      comision_produccion: !form.comision_produccion
        ? null
        : Number(form.comision_produccion),
      comision_distribucion: !form.comision_distribucion
        ? null
        : Number(form.comision_distribucion),
      id_tipo_sucursal: form.id_tipo_sucursal,
      id_estado_sucursal: form.id_estado_sucursal,
      id_canton: form.id_canton,
    };

    setIsLoading(true);
    actualizarSucursalRequest(sucursal.id_sucursal, dataRequest)
      .then((res) => {
        reload();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar sucursal"
      width={700}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={editar} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Sucursal">
                <Input
                  placeholder="Sucursal"
                  value={form.sucursal}
                  onChange={(e) => onChangeForm("sucursal", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Dirección">
                <Input
                  placeholder="Dirección"
                  value={form.direccion}
                  onChange={(e) => onChangeForm("direccion", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Tipo" required>
                <Select
                  value={form.id_tipo_sucursal}
                  onChange={(e) => onChangeForm("id_tipo_sucursal", e)}
                  options={tiposSucursal}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Estado" required>
                <Select
                  value={form.id_estado_sucursal}
                  onChange={(e) => onChangeForm("id_estado_sucursal", e)}
                  options={estadosSucursal}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Identificación">
                <Input
                  placeholder="Identificación"
                  value={form.identificacion}
                  onChange={(e) =>
                    onChangeForm("identificacion", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Razon social">
                <Input
                  placeholder="Razon social"
                  value={form.razon_social}
                  onChange={(e) => onChangeForm("razon_social", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Teléfono 1">
                <Input
                  placeholder="Teléfono 1"
                  value={form.telefono_1}
                  onChange={(e) => onChangeForm("telefono_1", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Teléfono 2">
                <Input
                  placeholder="Teléfono 2"
                  value={form.telefono_2}
                  onChange={(e) => onChangeForm("telefono_2", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cantón">
                <Select
                  value={form.id_canton}
                  onChange={(e) => onChangeForm("id_canton", e)}
                  options={cantones}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Meta de venta">
                <Input
                  placeholder="Meta de venta"
                  value={form.meta_venta}
                  onChange={(e) => onChangeForm("meta_venta", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Comision producción">
                <Input
                  placeholder="Comision producción"
                  value={form.comision_produccion}
                  onChange={(e) =>
                    onChangeForm("comision_produccion", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Comision distribucion">
                <Input
                  placeholder="Comision distribucion"
                  value={form.comision_distribucion}
                  onChange={(e) =>
                    onChangeForm("comision_distribucion", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};
