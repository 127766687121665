import { Checkbox, Col, Input, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";

export const TabGeneral = memo(
  ({
    form = {},
    onChangeForm = (e, d) => {},
    tarifas = [],
  }) => {
    return (
      <Content>
        
        <Row gutter={5}>
          <Col span={12}>
            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_modificar_precios}
                onChange={(e) =>
                  onChangeForm("permitir_modificar_precios", e.target.checked)
                }
              >
                Permitir Modificar Precios
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_seleccionar_precios}
                onChange={(e) =>
                  onChangeForm("permitir_seleccionar_precios", e.target.checked)
                }
              >
                Permitir Seleccionar Precios
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_modificar_descuentos}
                onChange={(e) =>
                  onChangeForm(
                    "permitir_modificar_descuentos",
                    e.target.checked
                  )
                }
              >
                Permitir Modificar Descuentos
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.administrador}
                onChange={(e) =>
                  onChangeForm("administrador", e.target.checked)
                }
              >
                Administrador
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_modificar_secuencias_facturas}
                onChange={(e) =>
                  onChangeForm(
                    "permitir_modificar_secuencias_facturas",
                    e.target.checked
                  )
                }
              >
                Permitir Modificar Secuencias de Facturas
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_seleccionar_secuencia}
                onChange={(e) =>
                  onChangeForm(
                    "permitir_seleccionar_secuencia",
                    e.target.checked
                  )
                }
              >
                Permitir seleccionar manualmente Secuencia
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_seleccionar_almacenes}
                onChange={(e) =>
                  onChangeForm(
                    "permitir_seleccionar_almacenes",
                    e.target.checked
                  )
                }
              >
                Permitir Seleccionar Almacenes
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_modificar_iva}
                onChange={(e) =>
                  onChangeForm("permitir_modificar_iva", e.target.checked)
                }
              >
                Permitir Modificar IVA
              </Checkbox>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_seleccionar_vendedores}
                onChange={(e) =>
                  onChangeForm(
                    "permitir_seleccionar_vendedores",
                    e.target.checked
                  )
                }
              >
                Permitir Seleccionar Vendedores
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_realizar_pedido}
                onChange={(e) =>
                  onChangeForm("permitir_realizar_pedido", e.target.checked)
                }
              >
                Permitir realizar Pedido
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.permitir_realizar_proforma}
                onChange={(e) =>
                  onChangeForm("permitir_realizar_proforma", e.target.checked)
                }
              >
                Permitir realizar Proforma
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={7}>
                <Typography>Tarifa Tope: </Typography>
              </Col>
              <Col span={10}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_tarifa}
                  onChange={(e) => onChangeForm("id_tarifa", e)}
                >
                  {tarifas.map((a) => {
                    return (
                      <Select.Option key={a.id_tarifa} value={a.id_tarifa}>
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={7}>
                <Typography>Descuento Permitido: </Typography>
              </Col>
              <Col span={10}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  type={"number"}
                  value={form.descuento_permitido}
                  onChange={(e) =>
                    onChangeForm("descuento_permitido", e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={7}>
                <Typography>Email: </Typography>
              </Col>
              <Col span={10}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.email}
                  onChange={(e) => onChangeForm("email", e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={7}>
                <Typography>Teléfono:</Typography>
              </Col>
              <Col span={10}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.telefono}
                  onChange={(e) => onChangeForm("telefono", e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
