import { Form, Input, Table, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      let change = false;
      for (const propety in values) {
        if (record[propety] != values[propety]) {
          change = true;
          break;
        }
      }
      toggleEdit();
      if (!change) return;
      handleSave(
        {
          ...record,
          ...values,
        },
        dataIndex
      );
    } catch (errInfo) {}
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          type={"number"}
          size={"small"}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export const TablaDetalles = ({
  data,
  productoSeleccionado = (p) => {},
  seleccionarProducto = (i) => {},
  onChange = (data, dataIndex) => {},
  ivas = [],
  disabled = false
}) => {
  const handleSave = (data, dataIndex) => {
    onChange(data, dataIndex);
  };

  const components = {
    body: {
      row: (data) => EditableRow({ ...data, seleccionarProducto }),
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
      width: "10%",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "20%",
    },

    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      width: "12%",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "cantidad",
        title: "Cantidad",
        handleSave,
      }),
      render: (n) => numberFormat(n),
    },
    {
      title: "Costo",
      dataIndex: "precio_unitario",
      key: "precio_unitario",
      width: "12%",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "precio_unitario",
        title: "precio_unitario",
        handleSave,
      }),
      render: (n) => numberFormat(n, 6),
    },
    {
      title: "Desc.",
      dataIndex: "descuento",
      key: "descuento",
      width: "12%",
      ellipsis: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "descuento",
        title: "descuento",
        handleSave,
      }),
      render: (n) => numberFormat(n),
    },
    {
      title: "IVA",
      dataIndex: "codigo_porcentaje_iva",
      key: "codigo_porcentaje_iva",
      width: "10%",
      render: (c) => ivas?.find((iva) => iva.codigo === Number(c))?.descripcion,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "10%",
      className: "custom-column",
      render: (n) => numberFormat(n),
    },
  ];

  const getRowClassName = (record, index) => {
    if (
      !record.producto?.id_producto &&
      !record.gasto?.id_gasto &&
      !record.id_producto &&
      !record.id_gasto
    ) {
      return "custom-row-error";
    }
    return "custom-row";
  };

  return (
    <Form disabled={disabled}>
      <Table
        components={components}
        size="small"
        columns={columns}
        dataSource={data}
        rowClassName={getRowClassName}
        className="small-table"
        onRow={(props) => ({
          ...props,
          onClick: () => {
            seleccionarProducto(props);
          },
        })}
      />
    </Form>
  );
};
