import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { TablaDetalles } from "./TablaDetalles";
import { ModalInformacionDetalle } from "./ModalInformacionDetalle";
import { useState } from "react";
import { ModalObservacionAdicional } from "./ModalObservacionAdicional";
import { ProductoInput } from "../../../compras/productos/components/ProductoInput";
import { ClienteInput } from "../../clientes/components/ClienteInput";
import { TablaRetencionIva } from "../../../compras/compras/components/TablaRetencionIva";
import { TablaRetencionRenta } from "../../../compras/compras/components/TablaRetencionRenta";
import { CODIGOS_IMPUESTOS } from "../../../../../helpers/constants/codigos-impuestos";
import { CODIGOS_TIPO_RETENCION } from "../../../../../helpers/constants/codigos-tipos-retencion";
import { TablaRetencion } from "./TablaRetencion";
import { InformacionTransportista } from "./InformacionTransportista";

export const TabMantenimiento = memo(
  ({
    nota_credito = false,
    edit = false,
    form = {},
    onChangeForm = (e, d) => { },
    user = {},
    facturadores = [],
    vendedores = [],
    almacenes = [],
    tarifas = [],
    secuencias = [],
    medidasProducto = [],
    productos = [],
    productoSeleccionado = null,
    seleccionarProducto = (i) => { },
    eliminarProducto = (p) => { },
    agregarProducto = (p) => { },
    totales = {},
    setTotales = () => { },
    onChange = (data) => { },
    isLoadingDetalles = false,
    isLoadingTotales = false,
    onChangeTotales = (data) => { },
    errorCalculos = { show: false, message: "" },
    setErrorCalculos = (v) => { },
    onChangeDetalle = (d) => { },
    onChangeObservacionAdicional = (o) => { },
    retencion = null,
    codigosRetenciones = [],
    isPuntoEmision = false,
    razon_social_transporte = "",
    identificacion_transporte = "",
    punto_emision = "",
    codigo_establecimiento = "",
    codigo_punto_emision = "",
    secuencia_text = "",
    clave_acceso = "",
    autorizacion = "",
    ivas=[]
  }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalObservacionOpen, setIsModalObservacionOpen] = useState(false);

    return (
      <Content>
        <Row gutter={12}>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Emision: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_emision}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_emision", e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Vence: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_vence}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_vence", e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={10} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Serie: </Typography>
                  </Col>
                  <Col span={14}>
                    <Row gutter={10} justify={"space-between"}>
                      <Col span={12}>
                        <Input
                          size="small"
                          style={{ width: "100%" }}
                          disabled={true}
                          value={form.codigo_establecimiento}
                          onChange={(e) =>
                            onChangeForm(
                              "codigo_establecimiento",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          size="small"
                          style={{ width: "100%" }}
                          disabled={true}
                          value={form.codigo_punto_emision}
                          onChange={(e) =>
                            onChangeForm("codigo_punto_emision", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Secuencia: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.secuencia}
                      onChange={(e) =>
                        onChangeForm("secuencia", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 1 }}>
              <Col span={5}>
                <Typography>Concepto: </Typography>
              </Col>
              <Col span={19}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.concepto}
                  onChange={(e) => onChangeForm("concepto", e.target.value)}
                />
              </Col>
            </Row>

            <ClienteInput
              label="C.I./RUC:"
              value={form.cliente}
              onChange={(c) => onChangeForm("cliente", c)}
              disabled={edit || nota_credito}
            />

            <ProductoInput
              label="Codigo:"
              value={productoSeleccionado}
              onChange={agregarProducto}
              eliminarProducto={eliminarProducto}
            />
            <Row gutter={10} style={{ marginTop: 5 }} justify={"space-around"}>
              <Col span={12}>
                <Row gutter={12}>
                  <Col>

                    <Button
                      icon={<InfoCircleFilled />}
                      type={"primary"}
                      size="small"
                      onClick={() => {
                        if (
                          productoSeleccionado !== undefined &&
                          productoSeleccionado !== null
                        ) {
                          setIsModalOpen(true);
                        }
                      }}
                    >
                      Información
                    </Button>

                  </Col>
                  <Col>
                    <Button type={"primary"} size="small">
                      Existencias
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col>
                    <Button type={"primary"} size="small">
                      Series
                    </Button>
                  </Col>
                  <Col>
                    <Button type={"primary"} size="small">
                      Lotes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Facturador: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_agente_venta_facturador}
                      disabled={true}
                      onChange={(e) =>
                        onChangeForm("id_agente_venta_facturador", e)
                      }
                    >
                      {facturadores.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_agente_venta}
                            value={a.id_agente_venta}
                          >
                            {a.nombres}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.contabilizado}
                  onChange={(e) =>
                    onChangeForm("contabilizado", e.target.checked)
                  }
                >
                  Contabilizado
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Vendedor:</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      disabled={!user?.permitir_seleccionar_vendedores || edit}
                      value={form.id_agente_venta_vendedor}
                      onChange={(e) =>
                        onChangeForm("id_agente_venta_vendedor", e)
                      }
                    >
                      {vendedores.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_agente_venta}
                            value={a.id_agente_venta}
                          >
                            {a.nombres}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.imprimir}
                  onChange={(e) => onChangeForm("imprimir", e.target.checked)}
                >
                  Imprimir
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Almacen: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      disabled={!user?.permitir_seleccionar_almacenes || edit}
                      value={form.id_almacen}
                      onChange={(e) => onChangeForm("id_almacen", e)}
                    >
                      {almacenes.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_almacen}
                            value={a.id_almacen}
                          >
                            {a.nombre_almacen}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tarifa: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tarifa}
                      onChange={(e) => onChangeForm("id_tarifa", e)}
                    >
                      {tarifas.map((a) => {
                        return (
                          <Select.Option key={a.id_tarifa} value={a.id_tarifa}>
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Secuencia: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_secuencia}
                      disabled={!user?.permitir_seleccionar_secuencia || edit}
                      onChange={(e) => onChangeForm("id_secuencia", e)}
                      dropdownMatchSelectWidth={false}
                    >
                      {secuencias.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_secuencia}
                            value={a.id_secuencia}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
        </Row>

        <Spin spinning={isLoadingDetalles}>
          <Row gutter={12} style={{ marginTop: 2 }}>
            <Col span={24}>
              <TablaDetalles
                data={productos}
                productoSeleccionado={productoSeleccionado}
                seleccionarProducto={seleccionarProducto}
                onChange={onChange}
                ivas={ivas}
              />
            </Col>
          </Row>
        </Spin>
        {errorCalculos.show && (
          <Alert
            description={errorCalculos.message}
            type="error"
            closable
            onClose={() => setErrorCalculos({ show: false, message: "" })}
          />
        )}
        <Row gutter={12}>
          <Col span={12}>
          
            <Button
              icon={<InfoCircleFilled />}
              type={"primary"}
              size="small"
              onClick={() => setIsModalObservacionOpen(true)}
            >
              Observación
            </Button>

            <Typography style={{ fontWeight: "bold", marginTop: 10 }}>
              Datos Documento
            </Typography>
            <Row>
              <Col span={6}>
                <Typography>Clave de acceso: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.clave_acceso}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 1 }}>
              <Col span={6}>
                <Typography>Autorizacion: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.autorizacion}
                  disabled={true}
                />
              </Col>
            </Row>

            <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
              Datos Documento Relacionado
            </Typography>
            <Row>
              <Col span={6}>
                <Typography>Emision: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.documento_relacionado?.fecha_emision}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 1 }}>
              <Col span={6}>
                <Typography>Secuencia: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.documento_relacionado?.numero_documento}
                  disabled={true}
                />
              </Col>
            </Row>

            {
              isPuntoEmision && 
              <InformacionTransportista
              razon_social_transporte={razon_social_transporte}
              identificacion_transporte={identificacion_transporte}
              punto_emision={punto_emision}
              codigo_establecimiento={codigo_establecimiento}
              codigo_punto_emision={codigo_punto_emision}
              secuencia_text={secuencia_text}
              clave_acceso={clave_acceso}
              autorizacion={autorizacion}
              />
            }

          </Col>

          <Col span={12}>
            <Spin spinning={isLoadingTotales}>
              <Row justify={"end"}>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Descuento %: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={totales.descuento_porcentaje}
                        type="number"
                        onChange={(e) =>
                          setTotales({
                            ...totales,
                            descuento_porcentaje: e.target.value,
                          })
                        }
                        onBlur={() => {
                          onChangeTotales(totales);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Descuento $: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.descuento_valor)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total Neto: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_neto)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total con IVA: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_iva)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total IVA 0%: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_iva_cero)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total No Obj: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_no_objeto)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total Exento: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_exento)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Total ICE: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.total_ice)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Total IVA: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.total_iva)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Propina: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={totales.propina}
                        onChange={(e) =>
                          setTotales({
                            ...totales,
                            propina: e.target.value,
                          })
                        }
                        onBlur={() => {
                          onChangeTotales(totales);
                        }}
                      />
                    </Col>
                  </Row>

                  {retencion && (
                    <>
                      <Row>
                        <Col span={12}>
                          <Typography> - Retencion IVA: </Typography>
                        </Col>
                        <Col span={12}>
                          <Input
                            size="small"
                            style={{ width: "100%" }}
                            value={numberFormat(totales.retencion_iva)}
                            disabled={true}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Typography> - Retencion IR: </Typography>
                        </Col>
                        <Col span={12}>
                          <Input
                            size="small"
                            style={{ width: "100%" }}
                            value={numberFormat(totales.retencion_renta)}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col span={12}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Total:
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%", fontWeight: "bold" }}
                        value={numberFormat(totales.total)}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Neto:{" "}
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%", fontWeight: "bold" }}
                        value={numberFormat(totales.neto)}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>

        {retencion && (
          <Row gutter={12}>
            <Col span={24}>
              <Row>
                <Typography style={{ fontWeight: "bold" }}>
                  Datos Retencion
                </Typography>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Row gutter={10} style={{ marginTop: 2 }}>
                    <Col span={12}>
                      <Row gutter={10}>
                        <Col span={10}>
                          <Typography>Numero Retencion: </Typography>
                        </Col>
                        <Col span={14}>
                          <Input
                            disabled={true}
                            size="small"
                            value={retencion.numero_documento}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={10}>
                        <Col span={10}>
                          <Typography>Fecha Emision: </Typography>
                        </Col>
                        <Col span={14}>
                          <Input
                            disabled={true}
                            size="small"
                            value={retencion.fecha_emision}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={10} style={{ marginTop: 2 }}>
                    <Col span={5}>
                      <Typography>Clave Acceso:</Typography>
                    </Col>
                    <Col span={19}>
                      <Input
                        disabled={true}
                        size="small"
                        value={retencion.clave_acceso}
                      />
                    </Col>
                  </Row>

                  <Row gutter={10} style={{ marginTop: 3 }}>
                    <Col span={5}>
                      <Typography>Autorizacion:</Typography>
                    </Col>
                    <Col span={19}>
                      <Input
                        disabled={true}
                        size="small"
                        value={retencion.autorizacion}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Row>
                        <Col span={10}>
                          <Typography style={{ fontWeight: "bold" }}>
                            Total Retenido:
                          </Typography>
                        </Col>
                        <Col span={14}>
                          <Input
                            value={numberFormat(retencion.total)}
                            size="small"
                            style={{ fontWeight: "bold" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Retencion Renta
                  </Typography>
                  <TablaRetencion
                    data={retencion.detalles_retenciones.filter(
                      (retencion) =>
                        retencion.codigo_impuesto_retencion ===
                        CODIGOS_TIPO_RETENCION.RETENCION_RENTA
                    )}
                    codigosRetenciones={codigosRetenciones}
                  />
                  <Row justify={"space-between"} style={{ marginTop: 10 }}>
                    <Col span={5}>
                      <Input
                        disabled={true}
                        size="small"
                        style={{ fontWeight: "bold" }}
                        value={numberFormat(
                          retencion?.detalles_retenciones
                            ?.filter(
                              (retencion) =>
                                retencion.codigo_impuesto_retencion ===
                                CODIGOS_TIPO_RETENCION.RETENCION_RENTA
                            )
                            .reduce(
                              (a, v) => a + Number(v.base_imponible),
                              0
                            ) ?? 0
                        )}
                      />
                    </Col>
                    <Col span={5}>
                      <Input
                        disabled={true}
                        size="small"
                        style={{ fontWeight: "bold" }}
                        value={numberFormat(
                          retencion?.detalles_retenciones
                            ?.filter(
                              (retencion) =>
                                retencion.codigo_impuesto_retencion ===
                                CODIGOS_TIPO_RETENCION.RETENCION_RENTA
                            )
                            .reduce(
                              (a, v) => a + Number(v.valor_retenido),
                              0
                            ) ?? 0
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Retencion IVA
                  </Typography>

                  <TablaRetencion
                    data={retencion.detalles_retenciones.filter(
                      (retencion) =>
                        retencion.codigo_impuesto_retencion ===
                        CODIGOS_TIPO_RETENCION.RETENCION_IVA
                    )}
                    codigosRetenciones={codigosRetenciones}
                  />
                  <Row justify={"space-between"} style={{ marginTop: 10 }}>
                    <Col span={5}>
                      <Input
                        disabled={true}
                        size="small"
                        style={{ fontWeight: "bold" }}
                        value={numberFormat(
                          retencion?.detalles_retenciones
                            ?.filter(
                              (retencion) =>
                                retencion.codigo_impuesto_retencion ===
                                CODIGOS_TIPO_RETENCION.RETENCION_IVA
                            )
                            .reduce(
                              (a, v) => a + Number(v.base_imponible),
                              0
                            ) ?? 0
                        )}
                      />
                    </Col>
                    <Col span={5}>
                      <Input
                        disabled={true}
                        size="small"
                        style={{ fontWeight: "bold" }}
                        value={numberFormat(
                          retencion?.detalles_retenciones
                            ?.filter(
                              (retencion) =>
                                retencion.codigo_impuesto_retencion ===
                                CODIGOS_TIPO_RETENCION.RETENCION_IVA
                            )
                            .reduce(
                              (a, v) => a + Number(v.valor_retenido),
                              0
                            ) ?? 0
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <ModalObservacionAdicional
          value={form}
          onChangeValue={onChangeObservacionAdicional}
          isModalOpen={isModalObservacionOpen}
          handleCancel={() => setIsModalObservacionOpen(false)}
        />

        {productoSeleccionado !== undefined &&
          productoSeleccionado !== null && (
            <ModalInformacionDetalle
              detalle={productoSeleccionado}
              onChangeDetalle={onChangeDetalle}
              isModalOpen={isModalOpen}
              handleCancel={() => setIsModalOpen(false)}
            />
          )}
      </Content>
    );
  }
);
