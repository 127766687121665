import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_COBROS = '/parametros-contables-cobros';

export const parametrosContablesCobrosEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_COBROS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesCobrosRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_COBROS, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesCobrosRequest = async (id_parametros_contables_cobros, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_COBROS + '/' + id_parametros_contables_cobros, data);
    const response = request.data;
    return response;
}