import React, { useContext, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { eliminarCuentaContableRequest } from "../../../../../services/api/cuentas-contables";
import { EditarCuentaContable } from "./EditarCuentaContable";

export const TablaPlanCuentas = ({ data }) => {
  const customFilter = (value, record) => {
    const codigoInicio = record.codigo_cuenta.split(".")[0];
    const matchPrincipal = record.codigo_cuenta.includes(value);
    const matchSubcuentas = record.children.some((subcuenta) =>
      subcuenta.codigo_cuenta.includes(value)
    );

    return matchPrincipal || matchSubcuentas;
  };

  const { getColumnSearchProps } = useColumnSearch({
    onCustomFilter: customFilter,
  });
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadPlanCuentas } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [idCuentaCotable, setIdCuentaCotable] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_cuenta_contable = record.id_cuenta_contable;
    if (!id_cuenta_contable) return;
    setIdCuentaCotable(id_cuenta_contable);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_cuenta_contable = record.id_cuenta_contable;
    if (!id_cuenta_contable) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta cuenta contable, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCuentaContableRequest(id_cuenta_contable).then((res) => {
      reloadPlanCuentas();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MPLAN-CUENTAS").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MPLAN-CUENTAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Codigo contable",
      dataIndex: "codigo_contable",
      key: "codigo_contable",
      width: "20%",
    },
    {
      title: "Nombre cuenta contable",
      dataIndex: "nombre_cuenta_contable",
      key: "nombre_cuenta_contable",
      width: "70%",
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_cuenta_contable"}
        columns={columns}
        dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
        size="small"
      />
      <EditarCuentaContable
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id_cuenta_contable={idCuentaCotable}
      />
    </>
  );
};
