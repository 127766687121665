import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { crearCajaRequest } from "../../../../../services/api/cajas";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { crearBancoRequest } from "../../../../../services/api/bancos";
import { tiposCuentasRequest } from "../../../../../services/api/opciones";
import { parametrosContablesCajasBancosEmpresaRequest } from "../../../../../services/api/parametros-contables-cajas-bancos";

export const CrearBanco = ({ open, showDrawer, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadBancos } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    banco: "",
    numero_cuenta: "",
    secuencial_cheque: 1,
    telefono: "",
    fecha_apertura: null,
    titular: "",
    activo: true,
    cuenta_contable_banco: null,
    id_tipo_cuenta: 1,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [tiposCuentas, setTiposCuentas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    tiposCuentasRequest()
      .then((res) => {
        setTiposCuentas(res);
      })
  }, []);

  useEffect(() => {
    if (form.id_tipo_cuenta !== 1) {
      setForm((prev) => ({
        ...prev,
        secuencial_cheque: null,
      }));
    }
  }, [form.id_tipo_cuenta]);

  useEffect(() => {
    parametrosContablesCajasBancosEmpresaRequest(empresa.id_empresa).then(
      (res) => {
        if (res && res.cuenta_contable_transitoria_bancos) {
          setForm((prev) => ({
            ...prev,
            cuenta_contable_banco: res.cuenta_contable_transitoria_bancos,
          }));
        }
      }
    );
  }, []);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const {
      banco,
      numero_cuenta,
      cuenta_contable_banco,
      secuencial_cheque,
      id_tipo_cuenta,
    } = form;

    if (banco.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el Banco",
      });
      return false;
    }

    if (numero_cuenta.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el numero de cuenta",
      });
      return false;
    }

    if (!cuenta_contable_banco && !cuenta_contable_banco?.id_cuenta_contable) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable",
      });
      return false;
    }

    if (
      id_tipo_cuenta === 1 &&
      (isNaN(Number(secuencial_cheque)) || Number(secuencial_cheque) <= 0)
    ) {
      setError({
        show: true,
        message: "Ingrese un numero de cheque valido",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      banco: form.banco,
      numero_cuenta: form.numero_cuenta,
      secuencial_cheque: !form.secuencial_cheque
        ? null
        : Number(form.secuencial_cheque),
      telefono: form.telefono,
      fecha_apertura: form.fecha_apertura,
      titular: form.titular,
      activo: form.activo,
      id_cuenta_contable_banco: form.cuenta_contable_banco?.id_cuenta_contable,
      id_tipo_cuenta: form.id_tipo_cuenta,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearBancoRequest(dataRequest)
      .then((res) => {
        reloadBancos();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear Banco"
      width={600}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Banco:" required>
            <Row gutter={10}>
              <Col span={16}>
                <Input
                  placeholder="Banco"
                  value={form.banco}
                  onChange={(e) => onChangeForm("banco", e.target.value)}
                />
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={form.activo}
                  onChange={(e) => onChangeForm("activo", e.target.checked)}
                >
                  Activo
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="N. Cuenta:" required>
                <Input
                  placeholder="N. Cuenta"
                  value={form.numero_cuenta}
                  onChange={(e) =>
                    onChangeForm("numero_cuenta", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tipo de Cuenta:">
                <Select
                  value={form.id_tipo_cuenta}
                  onChange={(e) => onChangeForm("id_tipo_cuenta", e)}
                >
                  {tiposCuentas.map((t) => {
                    return (
                      <Select.Option
                        key={t.id_tipo_cuenta}
                        value={t.id_tipo_cuenta}
                      >
                        {t.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Titular:">
                <Input
                  placeholder="Titular"
                  value={form.titular}
                  onChange={(e) => onChangeForm("titular", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Fecha de Apertura">
                <DatePicker
                  placeholder={"Fecha"}
                  value={form.fecha_apertura}
                  onChange={(e) => onChangeForm("fecha_apertura", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Secuencial Cheque:">
                <Input
                  disabled={form.id_tipo_cuenta !== 1}
                  value={form.secuencial_cheque}
                  onChange={(e) =>
                    onChangeForm("secuencial_cheque", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Teléfono:">
                <Input
                  placeholder="Teléfono"
                  value={form.telefono}
                  onChange={(e) => onChangeForm("telefono", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <CuentaContableInput
            label="C.C Banco:"
            value={form.cuenta_contable_banco}
            onChange={(c) => onChangeForm("cuenta_contable_banco", c)}
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
