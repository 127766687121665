import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PlanCuentasPage } from "../../pages/app/contabilidad/plan-cuentas/PlanCuentasPage";
import { CodigosRetencionesPage } from "../../pages/app/contabilidad/codigos-retenciones/CodigosRetencionesPage";
import { GenerarATSPage } from "../../pages/app/contabilidad/generar-ats/GenerarATSPage";

export const ContabilidadRoutes = () => {
  return (
    <Routes>
      <Route path="/plan-cuentas" element={<PlanCuentasPage />} />
      <Route path="/codigos-retenciones" element={<CodigosRetencionesPage />} />
      <Route path="/generar-ats" element={<GenerarATSPage />} />
    </Routes>
  );
};
