import API from "../../configs/api/api.config";

const URL_SUCURSALES = '/sucursales';

export const sucursalesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_SUCURSALES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const sucursalIdRequest = async (id_sucursal) => {
    const request = await API.get(URL_SUCURSALES + '/' + id_sucursal);
    const response = request.data;
    return response;
}

export const crearSucursalRequest = async (data) => {
    const request = await API.post(URL_SUCURSALES, data);
    const response = request.data;
    return response;
}

export const actualizarSucursalRequest = async (id_sucursal, data) => {
    const request = await API.patch(URL_SUCURSALES + '/' + id_sucursal, data);
    const response = request.data;
    return response;
}

export const eliminarSucursalRequest = async (id_sucursal) => {
    const request = await API.delete(URL_SUCURSALES + '/' + id_sucursal);
    const response = request.data;
    return response;
}