import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { IoImage, IoTrash } from "react-icons/io5";
import { crearSubGrupoProductoRequest } from "../../../../../services/api/sub-grupos-productos";
import { crearAlmacenRequest } from "../../../../../services/api/almacenes";

const { Option } = Select;

export const CrearAlmacen = ({ open, showDrawer, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadAlmacenes } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    nombre_almacen: "",
    almacen_activo: true,
    permite_reserva: false,
    disponible_ecommerce: false,
    meta_venta: 0,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { nombre_almacen } = form;

    if (nombre_almacen.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion",
      });
      return false;
    }

    return true;
  };

  const crear = () => {

    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      nombre_almacen: form.nombre_almacen,
      almacen_activo: form.almacen_activo,
      permite_reserva: form.permite_reserva,
      disponible_ecommerce: form.disponible_ecommerce,
      meta_venta: !form.meta_venta ? 0 : Number(form.meta_venta),
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearAlmacenRequest(dataRequest)
      .then((res) => {
        reloadAlmacenes();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear Almacen"
      width={500}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Descripción" required>
            <Input
              placeholder="Descripción"
              value={form.nombre_almacen}
              onChange={(e) => onChangeForm("nombre_almacen", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              style={{ marginTop: 0 }}
              checked={form.almacen_activo}
              onChange={(e) => onChangeForm("almacen_activo", e.target.checked)}
            >
              Activo
            </Checkbox>
            <Checkbox
              checked={form.permite_reserva}
              onChange={(e) =>
                onChangeForm("permite_reserva", e.target.checked)
              }
            >
              Permite Reserva
            </Checkbox>
            <Checkbox
              checked={form.disponible_ecommerce}
              onChange={(e) =>
                onChangeForm("disponible_ecommerce", e.target.checked)
              }
            >
              Disponible Ecommerce
            </Checkbox>
          </Form.Item>
          <Form.Item label="Meta de venta">
            <Input
              placeholder="Meta de venta"
              value={form.meta_venta}
              type={"number"}
              onChange={(e) => onChangeForm("meta_venta", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
