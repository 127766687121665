import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { EmpresasPage } from "../../pages/app/administracion/empresas/EmpresasPage";
import { EmpresaPage } from "../../pages/app/administracion/empresas/EmpresaPage";
import { UsuariosPage } from "../../pages/app/administracion/usuarios/UsuariosPage";
import { UsuarioPage } from "../../pages/app/administracion/usuarios/UsuarioPage";
import { ParametrosProductosPage } from "../../pages/app/administracion/parametros-productos/ParametrosProductosPage";
import { ParametrosFacturacionPage } from "../../pages/app/administracion/parametros-facturacion/ParametrosFacturacionPage";
import { ParametrosFacturacionElectronicaPage } from "../../pages/app/administracion/parametros-facturacion-electronica/ParametrosFacturacionElectronicaPage";
import { SucursalesPage } from "../../pages/app/administracion/sucursales/SucursalesPage";
import { SucursalPage } from "../../pages/app/administracion/sucursales/SucursalPage";
import { ParametrosContablesPage } from "../../pages/app/administracion/parametros-contables/ParametrosContablesPage";

export const AdministracionRoutes = () => {
  return (
    <Routes>
      <Route path="/empresas" element={<EmpresasPage />} />
      <Route path="/empresas/:id" element={<EmpresaPage />} />
      <Route path="/usuarios/" element={<UsuariosPage />} />
      <Route path="/usuarios/:id" element={<UsuarioPage />} />
      <Route
        path="/parametros-productos"
        element={<ParametrosProductosPage />}
      />
      <Route
        path="/parametros-contables"
        element={<ParametrosContablesPage />}
      />
      <Route
        path="/parametros-facturacion"
        element={<ParametrosFacturacionPage />}
      />
      <Route
        path="/parametros-facturacion-electronica"
        element={<ParametrosFacturacionElectronicaPage />}
      />
      <Route path="/sucursales" element={<SucursalesPage />} />
      <Route path="/sucursales/:id" element={<SucursalPage />} />
    </Routes>
  );
};
