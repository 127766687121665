import React from "react";
import { ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";
import { Tag } from "antd";

export const TagDocumentoElectronico = ({ estado }) => {

  const colors = {
    0: "red",
    1: "cyan",
    2: "orange",
    3: "green",
    4: "blue",
    5: "magenta",
    6: "volcano",
  }

  return <Tag color={colors[estado]} >{ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS[estado]}</Tag>;

};
