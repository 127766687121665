import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_PAGOS = '/parametros-contables-pagos';

export const parametrosContablesPagosEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_PAGOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesPagosRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_PAGOS, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesPagosRequest = async (id_parametros_contables_pagos, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_PAGOS + '/' + id_parametros_contables_pagos, data);
    const response = request.data;
    return response;
}