import { Modal } from "antd";
import React from "react";
import { useForm } from "../../../../../hooks/useForm";
import { FormRetencionRenta } from "./FormRetencionRenta,";
import { useEffect } from "react";
import { FormRetencionIva } from "./FormRetencionIva";

export const ModalCrearRetencionIva = ({
  isOpen = false,
  close = () => {},
  add = () => {},
  codigosRetenciones = [],
  disponible = 0,
}) => {
  const { form, setForm, onChangeForm, onResetForm } = useForm({
    codigo_impuesto_retencion: "",
    codigo: "",
    base_imponible: 0,
    porcentaje_retener: 0,
    valor_retenido: 0,
    id_codigo_retencion: null,
  });

  useEffect(() => {
    if (isOpen) return;
    onResetForm();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    setForm((prev) => ({
      ...prev,
      id_codigo_retencion: codigosRetenciones[0]?.id_codigo_retencion,
      base_imponible: disponible,
    }));
  }, [isOpen]);

  useEffect(() => {
    const codigo_retencion = codigosRetenciones.find(
      (codigoRetencion) =>
        codigoRetencion.id_codigo_retencion === form.id_codigo_retencion
    );
    if (!codigo_retencion) return;
    setForm((prev) => ({
      ...prev,
      codigo_impuesto_retencion: codigo_retencion.tipo_retencion?.codigo,
      codigo: codigo_retencion.codigo,
      porcentaje_retener: codigo_retencion.porcentaje,
      valor_retenido:
        Number(prev.base_imponible) * Number(codigo_retencion.porcentaje / 100),
    }));
  }, [form.id_codigo_retencion]);

  useEffect(() => {
    const codigo_retencion = codigosRetenciones.find(
      (codigoRetencion) =>
        codigoRetencion.id_codigo_retencion === form.id_codigo_retencion
    );
    if (!codigo_retencion) return;
    setForm((prev) => ({
      ...prev,
      valor_retenido:
        Number(prev.base_imponible) * Number(prev.porcentaje_retener / 100),
    }));
  }, [form.base_imponible]);

  const validacionGuardar = () => {
    if (disponible - Number(form.base_imponible) < 0) {
      alert(`Unicamente puede aplicar una retención con Base: ${disponible}`);
      return false;
    }
    return true;
  };

  return (
    <Modal
      title={"Retención Iva"}
      open={isOpen}
      closable={false}
      onCancel={close}
      onOk={() => {
        if (!validacionGuardar()) return;
        add(form);
        close();
      }}
      okText={"Guardar Cambios"}
    >
      <FormRetencionIva
        form={form}
        setForm={setForm}
        onChangeForm={onChangeForm}
        codigosRetenciones={codigosRetenciones}
      />
    </Modal>
  );
};
