import API from "../../configs/api/api.config";

const URL_SUB_GRUPOS_PRODUCTOS = '/sub-grupos-productos';

export const subGruposProductosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_SUB_GRUPOS_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const subGrupoProductoIdRequest = async (id_sub_grupo_producto) => {
    const request = await API.get(URL_SUB_GRUPOS_PRODUCTOS + '/' + id_sub_grupo_producto);
    const response = request.data;
    return response;
}

export const crearSubGrupoProductoRequest = async (data) => {
    const request = await API.post(URL_SUB_GRUPOS_PRODUCTOS, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const actualizarSubGrupoProductoRequest = async (id_sub_grupo_producto, data) => {
    const request = await API.patch(URL_SUB_GRUPOS_PRODUCTOS + '/' + id_sub_grupo_producto, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const eliminarSubGrupoProductoRequest = async (id_sub_grupo_producto) => {
    const request = await API.delete(URL_SUB_GRUPOS_PRODUCTOS + '/' + id_sub_grupo_producto);
    const response = request.data;
    return response;
}