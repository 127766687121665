import React from "react";
import { Route, Routes } from "react-router-dom";

import { CajasPage } from "../../pages/app/tesoreria/cajas/CajasPage";
import { BancosPage } from "../../pages/app/tesoreria/bancos/BancosPage";

export const TesoreriaRoutes = () => {
  return (
    <Routes>
      <Route path="/cajas" element={<CajasPage />} />
      <Route path="/bancos" element={<BancosPage />} />
    </Routes>
  );
};
