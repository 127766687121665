import React from "react";
import { Button, Table, Typography } from "antd";
import { SelectOutlined } from "@ant-design/icons";

export const TablaSProductos = ({ data, onSelect = () => {} }) => {
  const columns = [
    {
      title: "Selección",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          shape="default"
          size="small"
          icon={<SelectOutlined />}
          onClick={() => onSelect(record)}
        />
      ),
    },
    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
      width: "10%",
    },
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "20%",
    },
    {
      title: "Existencia",
      dataIndex: "existencia",
      key: "existencia",
      width: "10%",
    },
    {
      title: "Precio 1",
      dataIndex: "tarifas_productos",
      key: "tarifas_productos",
      width: "10%",
      render: (record) => {
        const precio =
          record?.find((t) => t.tarifa === "Precio 1" && t.medida === "interna")
            ?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 2",
      dataIndex: "tarifas_productos",
      key: "tarifas_productos",
      width: "10%",
      render: (record) => {
        const precio =
          record?.find((t) => t.tarifa === "Precio 2" && t.medida === "interna")
            ?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 3",
      dataIndex: "tarifas_productos",
      key: "tarifas_productos",
      width: "10%",
      render: (record) => {
        const precio =
          record?.find((t) => t.tarifa === "Precio 3" && t.medida === "interna")
            ?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 4",
      dataIndex: "tarifas_productos",
      key: "tarifas_productos",
      width: "10%",
      render: (record) => {
        const precio =
          record?.find((t) => t.tarifa === "Precio 4" && t.medida === "interna")
            ?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
    {
      title: "Precio 5",
      dataIndex: "tarifas_productos",
      key: "tarifas_productos",
      width: "10%",
      render: (record) => {
        const precio =
          record?.find((t) => t.tarifa === "Precio 5" && t.medida === "interna")
            ?.precio ?? 0;
        return <Typography style={{ fontSize: 12 }}> {precio}</Typography>;
      },
    },
  ];

  return (
    <Table
      scroll={{
        y: 280,
      }}
      rowKey={"id_producto"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
