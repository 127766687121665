import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { DefaultMenu } from "../../configs/navigation/DefaultMenu";
import { SidebarContext } from "../../context/SidebarContext";
import { PRIMARY_COLOR } from "../../configs/constants/colors";

export const SiderApp = () => {
  const { menu } = useContext(UserContext);
  const { collapsed, handleOpen, handleClose } = useContext(SidebarContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [activePathsState, setActivePathsState] = useState([]);

  useEffect(() => {
    const active = getActivePaths(location.pathname);
    if (!active || active.length === 0) return;
    setActivePathsState(active);
  }, [location]);

  const menuOptions = useMemo(
    () => [...DefaultMenu, ...menu] ?? [...DefaultMenu],
    [menu]
  );

  const itemsMenu = useMemo(() => {
    return menuOptions.map((menuItem) => ({
      key: menuItem.key,
      icon: menuItem.icon,
      children: menuItem.children,
      label: menuItem.label,
      type: menuItem.type,
    }));
  }, [menuOptions]);

  const onClick = useMemo(() => {
    const handleClick = (e) => {
      const key = e.key;
      let findNavigate = null;
      for (let menuItem of menuOptions) {
        if (menuItem.key === key) {
          findNavigate = menuItem.navigate;
          break;
        }
        if (menuItem.children) {
          for (let childItem of menuItem.children) {
            if (childItem.key === key) {
              findNavigate = childItem.navigate;
              break;
            }
          }
        }
      }

      if (!findNavigate) return;
      navigate(findNavigate);
    };

    return handleClick;
  }, [menuOptions, navigate]);

  const getActivePaths = (route) => {
    const activePaths = [];
    for (let menuItem of menuOptions) {
      if (menuItem.navigate?.includes(route)) {
        activePaths.push(menuItem.key);
        break;
      }
      if (menuItem.children) {
        for (let childItem of menuItem.children) {
          if (childItem.navigate?.includes(route)) {
            activePaths.push(menuItem.key, childItem.key);
            break;
          }
        }
      }
    }
    return activePaths;
  };

  return (
    <Sider
      className="custom-scroll"      
      style={{
        overflow: "auto",
        minHeight: "100%",
        minWidth: "350px",
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={() => (collapsed ? handleClose() : handleOpen())}
      width={270}
      theme={"dark"}
    >
      <Menu
        onClick={onClick}
        selectedKeys={activePathsState}
        items={[...itemsMenu]}
        mode="inline"
      theme={"dark"}
        style={{
          paddingBottom: 80,
        }}
      />
    </Sider>
  );
};
