import React, { Children, useContext } from "react";
import {
  DeleteOutlined,
  FileTextOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../../../../helpers/crypto/crypto";
import { UserContext } from "../../../../../context/UserContext";
import { AuthContext } from "../../../../../context/AuthContext";
import { eliminarEmpresaRequest } from "../../../../../services/api/empresas";
import { ActionContext } from "../../../../../context/ActionContext";

export const TablaEmpresa = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadEmpresas } = useContext(ActionContext);

  const navigate = useNavigate();

  const navegarEmpresaDetalle = (record) => {
    if (!record.id_empresa) return;
    const id = record.id_empresa;
    navigate(`/administracion/empresas/${id}`);
  };

  const eliminar = (record) => {
    const id_empresa = record.id_empresa;
    if (!id_empresa) return;
    if (id_empresa != empresa.id_empresa) {
      alert(
        "No se encuentra en la empresa, cambie en la parte superior la empresa que desea eliminar."
      );
      return;
    }
    const confirm = window.confirm(
      "¿Esta seguro de eliminar la empresa, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarEmpresaRequest(id_empresa).then((res) => {
      reloadEmpresas();
      checkData();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MEMPRESAS").leer}
              shape="default"
              icon={<FileTextOutlined />}
              onClick={() => navegarEmpresaDetalle(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              disabled={!checkPermisos("MEMPRESAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Identificación Empresa",
      dataIndex: "identificacion_empresa",
      key: "identificacion_empresa",
      width: "20%",
      ...getColumnSearchProps("identificacion_empresa"),
    },
    {
      title: "Razon social empresa",
      dataIndex: "razon_social_empresa",
      key: "razon_social_empresa",
      width: "20%",
      ...getColumnSearchProps("razon_social_empresa"),
    },
    {
      title: "Direccion",
      dataIndex: "direccion_empresa",
      key: "direccion_empresa",
      width: "20%",
    },
    {
      title: "Representante legal",
      dataIndex: "representante_legal",
      key: "representante_legal",
      width: "20%",
    },
  ];

  return <Table columns={columns} dataSource={data} size="small" />;
};
