import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_PRODUCTOS = '/parametros-contables-productos';

export const parametrosContablesProductosEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesProductosRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_PRODUCTOS, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesProductosRequest = async (id_parametros_contables_productos, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_PRODUCTOS + '/' + id_parametros_contables_productos, data);
    const response = request.data;
    return response;
}