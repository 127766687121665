import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { ModalEditarRetencionRenta } from "./ModalEditarRetencionRenta";

export const TablaRetencionRenta = ({
  data,
  eliminar = (r) => {},
  editar = (r) => {},
  codigosRetenciones = [],
}) => {
  const [retencionRenta, setRetencionRenta] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const editarRetencion = (r) => {
    setRetencionRenta(r);
    setIsOpen(true);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "15%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editarRetencion(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              shape="default"
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={() => eliminar(record)}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Base",
      dataIndex: "base_imponible",
      key: "base_imponible",
      width: "15%",
      render: (record) => numberFormat(record),
    },
    {
      title: "Retencion Renta",
      dataIndex: "codigo",
      key: "codigo",
      width: "40%",
      render: (record) =>
        codigosRetenciones.find(
          (codigoRetencion) => codigoRetencion.codigo === record
        )?.descripcion,
    },
    {
      title: "%",
      dataIndex: "porcentaje_retener",
      key: "porcentaje_retener",
      width: "10%",
      render: (record) => numberFormat(record),
    },
    {
      title: "Valor",
      dataIndex: "valor_retenido",
      key: "valor_retenido",
      width: "15%",
      render: (record) => numberFormat(record),
    },
  ];
  return (
    <>
      <Table
        scroll={{
          y: 150,
        }}
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          hideOnSinglePage: true,
        }}
        style={{ marginTop: 5 }}
      />

      {!!retencionRenta && (
        <ModalEditarRetencionRenta
          retencion={retencionRenta}
          editar={editar}
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          codigosRetenciones={codigosRetenciones}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      )}
    </>
  );
};
