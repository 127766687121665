import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";

const { RangePicker } = DatePicker;

export const TabEcommerce = memo(
  ({ form, onChangeForm, setForm = () => {}, tiposEcommerceProducto }) => {
    return (
      <Content>
        <Row gutter={5}>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={15}>
                <Row justify={"space-between"}>
                  <Col span={8}>
                    <Typography>Ecommerce: </Typography>
                  </Col>
                  <Col span={16}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_ecommerce_producto}
                      onChange={(e) =>
                        onChangeForm(
                          "id_tipo_ecommerce_producto",
                          e
                        )
                      }
                    >
                      {tiposEcommerceProducto.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_ecommerce_producto}
                            value={a.id_tipo_ecommerce_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.destacado}
                onChange={(e) => onChangeForm("destacado", e.target.checked)}
              >
                Destacado
              </Checkbox>
            </Row>
            <Row gutter={5} style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.oferta}
                onChange={(e) => onChangeForm("oferta", e.target.checked)}
              >
                Oferta
              </Checkbox>
            </Row>
            <Row gutter={5} style={{ marginTop: 10 }}>
              <RangePicker
                disabled={!form.oferta}
                placeholder={["Oferta desde", "Oferta hasta"]}
                value={[form.oferta_desde, form.oferta_hasta]}
                onChange={(e) => {
                  setForm({
                    ...form,
                    oferta_desde: e?.[0] ?? null,
                    oferta_hasta: e?.[1] ?? null,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
