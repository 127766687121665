import React from "react";
import { Button, Table } from "antd";
import { SelectOutlined } from "@ant-design/icons";

export const TablaCuentasContables = ({ data, onSelect = () => {} }) => {
  const columns = [
    {
      title: "Selección",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          shape="default"
          size="small"
          icon={<SelectOutlined />}
          onClick={() => onSelect(record)}
        />
      ),
    },
    {
      title: "Codigo contable",
      dataIndex: "codigo_contable",
      key: "codigo_contable",
      width: "20%",
    },
    {
      title: "Nombre cuenta contable",
      dataIndex: "nombre_cuenta_contable",
      key: "nombre_cuenta_contable",
      width: "60%",
    },
  ];

  return (
    <Table
      scroll={{
        y: 280,
      }}
      rowKey={"id_cuenta_contable"}
      columns={columns}
      dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
      size="small"
    />
  );
};
