import API from "../../configs/api/api.config";

const URL_SUB_CATEGORIAS_PRODUCTOS = '/sub-categorias-productos';

export const subCategoriasProductosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_SUB_CATEGORIAS_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const subCategoriaProductoIdRequest = async (id_sub_categoria_producto) => {
    const request = await API.get(URL_SUB_CATEGORIAS_PRODUCTOS + '/' + id_sub_categoria_producto);
    const response = request.data;
    return response;
}

export const crearSubCategoriaProductoRequest = async (data) => {
    const request = await API.post(URL_SUB_CATEGORIAS_PRODUCTOS, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const actualizarSubCategoriaProductoRequest = async (id_sub_categoria_producto, data) => {
    const request = await API.patch(URL_SUB_CATEGORIAS_PRODUCTOS + '/' + id_sub_categoria_producto, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const eliminarSubCategoriaProductoRequest = async (id_sub_categoria_producto) => {
    const request = await API.delete(URL_SUB_CATEGORIAS_PRODUCTOS + '/' + id_sub_categoria_producto);
    const response = request.data;
    return response;
}