import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_CAJAS_BANCOS = '/parametros-contables-cajas-bancos';

export const parametrosContablesCajasBancosEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_CAJAS_BANCOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesCajasBancosRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_CAJAS_BANCOS, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesCajasBancosRequest = async (id_parametros_contables_cajas_bancos, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_CAJAS_BANCOS + '/' + id_parametros_contables_cajas_bancos, data);
    const response = request.data;
    return response;
}