// Ambientes: 1. local, 2. produccion

const ambiente = 'produccion';

const url_ambiente = {
    local:
    {
        host: 'http://localhost:3005/api',
    },
    produccion:
    {
        host: 'https://accounting-system-bk-ssrfijlk6a-uc.a.run.app/api',
    },
};

export const CONFIGS = {
    API_URL: url_ambiente[ambiente].host,
    AMBIENTE: ambiente,
}

