import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { IoAdd, IoArrowBack, IoSettingsSharp } from "react-icons/io5";

import { TablaEmpresa } from "./components/TablaEmpresa";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { empresasUsuarioRequest } from "../../../../services/api/empresas";
import CrearEmpresaDrawer from "./components/CrearEmpresaDrawer";
import { ActionContext } from "../../../../context/ActionContext";

export const EmpresasPage = () => {
  const { isEmpresasChange } = useContext(ActionContext);
  const { checkPermisos } = useContext(UserContext);

  const navigate = useNavigate();

  const [empresas, setEmpresas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    empresasUsuarioRequest({})
      .then((res) => {
        setEmpresas(res.map((e) => ({ ...e, key: e.id_empresa })));
      })
      .finally(() => setIsLoading(false));
  }, [isEmpresasChange]);

  return (
    <Content>
      {/* Header */}
      <Row
        align={"middle"}
        style={{ marginBottom: 5 }}
        justify={"space-between"}
      >
        <Row align={"middle"}>
          <Tooltip title={"Regresar"}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              shape="circle"
              icon={<IoArrowBack size={20} />}
              style={{ marginRight: 10 }}
            />
          </Tooltip>
          <Breadcrumb
            items={[
              {
                title: (
                  <Row align={"middle"}>
                    <IoSettingsSharp style={{ marginRight: 5 }} />
                    <span>Administración</span>
                  </Row>
                ),
              },
              {
                title: "Empresas",
              },
            ]}
          />
        </Row>
        <Button
          disabled={!checkPermisos("MEMPRESAS").crear}
          type="primary"
          onClick={showDrawer}
        >
          Crear
        </Button>
      </Row>
      {/* Tabla */}
      <Spin spinning={isLoading}>
        <TablaEmpresa data={empresas} />
      </Spin>
      {/* Crear Drawer */}
      <CrearEmpresaDrawer
        open={open}
        showDrawer={showDrawer}
        onClose={onClose}
      />
    </Content>
  );
};
