import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { usuarioRequest } from "../services/api/usuarios";
import {
  empresaStorageGet,
  empresaStorageRemove,
  empresaStorageSave,
} from "../helpers/storage/empresa-storage";
import { usuarioEmpresaRequest } from "../services/api/empresas";
import { ROL_USUARIO } from "../configs/constants/roles";
import { usuarioMenu } from "../configs/navigation/UsuarioMenu";
import { CONFIGS } from "../configs/configs";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const {
    usuario: authUsuario,
    isAuthenticated,
    isChekingAuth,
  } = useContext(AuthContext);

  const [usuario, setUsuario] = useState({});

  const [empresas, setEmpresas] = useState([]);

  const [empresa, setEmpresa] = useState({});

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (authUsuario.id_usuario) {
      checkData();
    }
  }, [authUsuario]);

  //MENU USER
  useEffect(() => {
    checkMenu();
  }, [empresa, usuario]);

  //CLEAN STATES
  useEffect(() => {
    if (!isChekingAuth && !isAuthenticated) {
      empresaStorageRemove();
      setUsuario({});
      setEmpresas([]);
      setEmpresa({});
      setMenu([]);
    }
  }, [isChekingAuth, isAuthenticated]);

  const checkData = async () => {
    const id_usuario = authUsuario.id_usuario;
    const u = await usuarioRequest(id_usuario);
    setUsuario(u);
    if (u.rol?.codigo_rol == ROL_USUARIO.ADMIN) return;
    const usuarioEmpresas = u.empresas_usuario
      ?.filter((e) => e.empresa != null)
      .map((e) => e.empresa);
    setEmpresas(usuarioEmpresas);
    const empresaStorage = empresaStorageGet();
    let id_empresa = usuarioEmpresas[0]?.id_empresa;
    if (empresaStorage?.id_empresa) {
      const empresaValid = usuarioEmpresas?.find(
        (ue) => ue.id_empresa == empresaStorage?.id_empresa
      );
      if (empresaValid?.id_empresa) {
        id_empresa = empresaValid.id_empresa;
      }
    }
    const e = await usuarioEmpresaRequest({
      id_usuario: u.id_usuario,
      id_empresa: id_empresa,
    });

    setEmpresa(e);
    empresaStorageSave(e);
  };

  const checkMenu = () => {
    if (!usuario.id_usuario) return;

    if (CONFIGS.AMBIENTE === "produccion") {
      if (usuario?.rol?.codigo_rol == ROL_USUARIO.USUARIO_ADMIN) {
        setMenu(usuarioMenu);
      }
    }

    if (
      usuario?.rol?.codigo_rol == ROL_USUARIO.USUARIO ||
      (usuario?.rol?.codigo_rol == ROL_USUARIO.USUARIO_ADMIN &&
        CONFIGS.AMBIENTE === "local")
    ) {
      if (!empresa.id_empresa) return;
      const menuGenerate = generarMenu(usuarioMenu, empresa.empresa_sistemas);
      setMenu(menuGenerate);
    }
  };

  const generarMenu = (m, jsonMenu) => {
    let menuG = [];

    for (let menuItem of m) {
      const sistema = jsonMenu?.find(
        (jsMenuItem) => jsMenuItem.sistema.codigo_sistema === menuItem.key
      );
      if (!sistema || !sistema.sistema) {
        continue;
      }

      const children = menuItem.children;
      let childrenTemp = [];
      if (children) {
        for (let childrenItem of children) {
          const modulo = sistema.sistema.modulos.find(
            (sm) => sm.codigo_modulo === childrenItem.key
          );

          if (
            !modulo ||
            modulo.modulo_permisos.length === 0 ||
            !checkPermisos(modulo.codigo_modulo).leer
          ) {
            continue;
          }

          childrenTemp.push(childrenItem);
        }
      }

      if (childrenTemp.length === 0) continue;
      menuG.push({
        ...menuItem,
        children: childrenTemp,
      });
    }
    return menuG;
  };

  const onChangeEmpresa = async (id_empresa) => {
    if (id_empresa == empresa.id_empresa) return;
    const e = await usuarioEmpresaRequest({
      id_usuario: usuario.id_usuario,
      id_empresa: id_empresa,
    });

    setEmpresa(e);
    empresaStorageSave(e);
  };

  const checkPermisos = (modulo) => {
    const permisos = {
      leer: false,
      crear: false,
      editar: false,
      eliminar: false,
    };

    const codigo_rol = usuario.rol?.codigo_rol;

    if (CONFIGS.AMBIENTE === "produccion") {
      if (codigo_rol === ROL_USUARIO.USUARIO_ADMIN) {
        return {
          leer: true,
          crear: true,
          editar: true,
          eliminar: true,
        };
      }
    }

    const empresa_sistemas = empresa?.empresa_sistemas;

    if (empresa_sistemas?.length > 0) {
      const modulo_search = empresa_sistemas
        .flatMap((empresa_sistema) => empresa_sistema.sistema.modulos)
        .find((m) => m.codigo_modulo === modulo);

      if (modulo_search) {
        return {
          leer: modulo_search.modulo_permisos?.some((p) => p.leer) || false,
          crear: modulo_search.modulo_permisos?.some((p) => p.crear) || false,
          editar: modulo_search.modulo_permisos?.some((p) => p.editar) || false,
          eliminar:
            modulo_search.modulo_permisos?.some((p) => p.eliminar) || false,
        };
      }
    }

    return permisos;
  };

  return (
    <UserContext.Provider
      value={{
        menu,
        usuario,
        empresas,
        empresa,
        onChangeEmpresa,
        checkPermisos,
        checkData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
