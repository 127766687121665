import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";

export const TabContabilidad = memo(({ form, onChangeForm }) => {
  return (
    <Content>
      <Row gutter={5}>
        <Col span={12}>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={24}>
              <CuentaContableInput
                label="CC Inventario:"
                value={form.cuenta_contable_inventario}
                onChange={(e) => onChangeForm("cuenta_contable_inventario", e)}
              />
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={24}>
              <CuentaContableInput
                label="CC Ventas:"
                value={form.cuenta_contable_ventas}
                onChange={(e) => onChangeForm("cuenta_contable_ventas", e)}
              />
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={24}>
              <CuentaContableInput
                label="CC Costos:"
                value={form.cuenta_contable_costos}
                onChange={(e) => onChangeForm("cuenta_contable_costos", e)}
              />
            </Col>
          </Row>
          {/* TODO: Ret. Imp Renta */}
        </Col>
      </Row>
    </Content>
  );
});
