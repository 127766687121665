import { Breadcrumb, Button, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";
import { zonasClientesIdEmpresaRequest } from "../../../../services/api/zonas-clientes";
import { CrearZonaCliente } from "./components/CrearZonaCliente";
import { TablaZonasClientes } from "./components/TablaZonasClientes";

export const ZonasClientesPage = () => {

  const { isZonasClientesChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [zonasClientes, setZonasClientes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    zonasClientesIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setZonasClientes(res);
      })
      .finally(() => setIsLoading(false));
  }, [isZonasClientesChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Zonas Clientes",
                },
              ]}
            />
          </Row>
          <Row align={"middle"}>
            <Button
              disabled={!checkPermisos("MZONAS-CLIENTES").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaZonasClientes data={zonasClientes} />
        {/* Crear Drawer */}
        <CrearZonaCliente open={open} showDrawer={showDrawer} onClose={onClose} />
      </Content>
    </Spin>
  );
};
