import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Radio,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { VscGraph } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import dayjs from "dayjs";
import {
  generarATSPDFRequest,
  generarATSXMLRequest,
} from "../../../../services/api/generar-ats";

export const GenerarATSPage = () => {
  
  const { isGenerarATSChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm } = useForm({
    tipo_generacion_ats: 1,
    periodo: dayjs(new Date()),
    semestre: 1,
    anio: dayjs(new Date()),
    electronicos: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const validar = () => {
    const { tipo_generacion_ats, periodo, anio } = form;

    if (tipo_generacion_ats === 1 && !dayjs(periodo).isValid()) {
      setError({
        show: true,
        message: "Seleccione el periodo",
      });
      return false;
    }

    if (tipo_generacion_ats === 2 && !dayjs(anio).isValid()) {
      setError({
        show: true,
        message: "Seleccione el año",
      });
      return false;
    }

    return true;
  };

  const generarATSXML = () => {
    setError({
      show: false,
      message: "",
    });

    if (!validar()) return;

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      tipo_generacion_ats: form.tipo_generacion_ats,
      periodo: dayjs(form.periodo).format("YYYY-MM"),
      semestre: form.semestre,
      anio: dayjs(form.anio).format("YYYY"),
      electronicos: form.electronicos,
    };

    setIsLoading(true);
    generarATSXMLRequest(dataRequest)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;

        let fecha = "";

        if (form.tipo_generacion_ats === 1) {
          fecha = dayjs(form.periodo).format("MMMM-YYYY").toUpperCase();
        } else if (form.tipo_generacion_ats === 2) {
          const meses = ["JUNIO", "DICIEMBRE"];

          const mes = meses[form.semestre - 1];

          fecha = `${mes}-${dayjs(form.anio).format("YYYY")}`;
        }

        link.setAttribute("download", `ATS-${fecha}.xml`);

        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error vuelva a intentarlo",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generarATSPDF = () => {
    setError({
      show: false,
      message: "",
    });

    if (!validar()) return;

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      tipo_generacion_ats: form.tipo_generacion_ats,
      periodo: dayjs(form.periodo).format("YYYY-MM"),
      semestre: form.semestre,
      anio: dayjs(form.anio).format("YYYY"),
      electronicos: form.electronicos,
    };

    setIsLoading(true);
    generarATSPDFRequest(dataRequest)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res], { type: "application/pdf" })
        );

        let fecha = "";

        if (form.tipo_generacion_ats === 1) {
          fecha = dayjs(form.periodo).format("MMMM-YYYY").toUpperCase();
        } else if (form.tipo_generacion_ats === 2) {
          const meses = ["JUNIO", "DICIEMBRE"];
          const mes = meses[form.semestre - 1];
          fecha = `${mes}-${dayjs(form.anio).format("YYYY")}`;
        }

        const newTab = window.open(url, "_blank");

        if (newTab) {
          newTab.focus();
        } else {
          alert(
            "El bloqueo de ventanas emergentes del navegador puede estar impidiendo que se abra una nueva pestaña. Asegúrate de habilitar las ventanas emergentes."
          );
        }
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error vuelva a intentarlo",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <VscGraph style={{ marginRight: 5 }} />
                      <span>Contabilidad</span>
                    </Row>
                  ),
                },
                {
                  title: "Generar ATS",
                },
              ]}
            />
          </Row>
          {/* <Row align={"middle"}>
            <Button
              disabled={!checkPermisos("MGENERAR-ATS").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row> */}
        </Row>
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={5}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("tipo_generacion_ats", e.target.value);
                  }}
                  value={form.tipo_generacion_ats}
                >
                  <Radio key={1} value={1}>
                    Mensual
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={19}>
                <Row>
                  <Col span={5}>
                    <Typography>Periodo: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      picker={"month"}
                      value={form.periodo}
                      onChange={(e) => onChangeForm("periodo", e)}
                      disabled={form.tipo_generacion_ats === 2}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} align={"middle"} style={{ marginTop: 10 }}>
              <Col span={5}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("tipo_generacion_ats", e.target.value);
                  }}
                  value={form.tipo_generacion_ats}
                >
                  <Radio key={2} value={2}>
                    Semestral
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={19}>
                <Row align={"middle"}>
                  <Col span={12}>
                    <Radio.Group
                      onChange={(e) => {
                        onChangeForm("semestre", e.target.value);
                      }}
                      value={form.semestre}
                      disabled={form.tipo_generacion_ats === 1}
                    >
                      <Radio key={1} value={1}>
                        Primer Semestre
                      </Radio>

                      <Radio key={2} value={2}>
                        Segundo Semestre
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={10}>
                        <Typography>Año: </Typography>
                      </Col>
                      <Col span={14}>
                        <DatePicker
                          size="small"
                          picker={"year"}
                          value={form.anio}
                          onChange={(e) => onChangeForm("anio", e)}
                          disabled={form.tipo_generacion_ats === 1}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} align={"middle"} style={{ marginTop: 10 }}>
              <Checkbox
                checked={form.electronicos}
                onChange={(e) => onChangeForm("electronicos", e.target.checked)}
              >
                Incluir comprobantes electronicos
              </Checkbox>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={12}>
                <Typography.Link
                  href="http://descargas.sri.gob.ec/download/anexos/dimm/windows/Dimm-1.17-Win.exe"
                  target="_blank"
                >
                  Version DIMM 1.17.0
                </Typography.Link>
              </Col>
              <Col span={12}>
                <Row>
                  <Typography.Link
                    href="http://descargas.sri.gob.ec/download/anexos/ats/ats.plugin.1.5.0.zip"
                    target="_blank"
                  >
                    Version ATS 1.5.0
                  </Typography.Link>
                </Row>
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: 10 }}>
              <Col>
                <Button type="primary" onClick={generarATSXML}>
                  Generar XML
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={generarATSPDF}>
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Spin>
  );
};
