import { ConfigProvider } from 'antd';
import { Toaster } from 'sonner';
import './App.css';
import { AppTheme } from './configs/theme/AppTheme';
import { ActionProvider } from './context/ActionContext';
import { AuthProvider } from './context/AuthContext';
import { SidebarProvider } from './context/SidebarContext';
import { UserProvider } from './context/UserContext';
import { MainRoute } from './routes/MainRoute';
import esES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale(esES);

function App() {

  return (
    <ConfigProvider locale={esES}>
      <AppTheme>
        <AuthProvider>
          <UserProvider>
            <SidebarProvider>
              <ActionProvider>
                <MainRoute />
              </ActionProvider>
            </SidebarProvider>
          </UserProvider>
        </AuthProvider>
        <Toaster richColors />
      </AppTheme>
    </ConfigProvider>
  );
}

export default App;
