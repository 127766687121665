import { Breadcrumb, Button, Col, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { MdFireTruck } from "react-icons/md";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { comprasElectronicasIdEmpresaRequest } from "../../../../services/api/compras";
import { TablaCompras } from "./components/TablaCompras";
import { ModalSubirComprasElectronicas } from "./components/ModalSubirComprasElectronicas";
import { TablaComprasElectronicas } from "./components/TablaComprasElectronicas";

export const ComprasElectronicasPage = () => {
  
  const { isComprasChange } = useContext(ActionContext);

  const { handleOpen, handleClose } = useContext(SidebarContext);

  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [compras, setCompras] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    comprasElectronicasIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setCompras(res);
      })
      .finally(() => setIsLoading(false));
  }, [isComprasChange]);

  const cargarCompras = () => {
    setIsOpen(true);
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Compras Electronicas",
                },
              ]}
            />
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col>
              <Button
                disabled={!checkPermisos("MCOMPRAS").crear}
                type="primary"
                onClick={cargarCompras}
              >
                Seleccionar
              </Button>
            </Col>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaComprasElectronicas data={compras} />
        <ModalSubirComprasElectronicas
          isOpen={isOpen}
          close={() => setIsOpen(false)}
        />
      </Content>
    </Spin>
  );
};
