import { Card, Col, Descriptions, Row } from 'antd'
import React from 'react'
import { dateDDMMYYYYHHmm } from '../../../../../helpers/format/dates-formater'

export const TabUsuario = ({usuario}) => {
  return (
    <Card
          title="Información del usuario"
          size="small"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Identificación Usuario">
                  {usuario.usuario?.identificacion_usuario}
                </Descriptions.Item>
                <Descriptions.Item label="Nombre usuario">
                  {usuario.usuario?.nombre_usuario}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {usuario.usuario?.email_usuario}
                </Descriptions.Item>
                <Descriptions.Item label="Roles dentro de la empresa">
                  {usuario.empresa_rol_usuarios
                    ?.map((eru) => eru.empresa_rol?.nombre_empresa_rol)
                    .join(" - ")}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha registro">
                  {dateDDMMYYYYHHmm(usuario.usuario?.fecha_registro)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
  )
}
