import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarGastoRequest } from "../../../../../services/api/gastos";
import { EditarGasto } from "./EditarGasto";

export const TablaGastos = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadGastos } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_gasto = record.id_gasto;
    if (!id_gasto) return;
    setId(id_gasto);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_gasto = record.id_gasto;
    if (!id_gasto) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este Gasto esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarGastoRequest(id_gasto).then((res) => {
      reloadGastos();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MGASTOS").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MGASTOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Codigo Cuenta",
      dataIndex: "cuenta_contable_gasto",
      key: "cuenta_contable_gasto",
      width: "15%",
      render: (record) => record?.codigo_contable,
    },
    {
      title: "Cuenta",
      dataIndex: "cuenta_contable_gasto",
      key: "cuenta_contable_gasto",
      width: "25%",
      render: (record) => record?.nombre_cuenta_contable,
    },
    {
      title: "Codigo Retención",
      dataIndex: "codigo_retencion",
      key: "codigo_retencion",
      width: "15%",
      render: (record) => record?.codigo,
    },
    {
      title: "D. Retencion",
      dataIndex: "codigo_retencion",
      key: "codigo_retencion",
      width: "25%",
      render: (record) => record?.descripcion,
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 450,
        }}
        rowKey={"id_gasto"}
        columns={columns}
        dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
        size="small"
      />
      <EditarGasto
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
