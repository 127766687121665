import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { crearCuentaContableRequest } from "../../../../../services/api/cuentas-contables";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { IoImage, IoTrash } from "react-icons/io5";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { crearLineaProductoRequest } from "../../../../../services/api/lineas-productos";

const { Option } = Select;

export const CrearLineaProducto = ({ open, showDrawer, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadLineasProductos } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    descripcion: "",
    url_imagen: "",
    ecommerce_destacado: false,
    cuenta_contable_inventario: null,
    cuenta_contable_ventas: null,
    cuenta_contable_costos: null,
    file: null,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { descripcion } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      descripcion: form.descripcion,
      url_imagen: form.url_imagen,
      ecommerce_destacado: form.ecommerce_destacado,
      id_cuenta_contable_inventario:
        form.cuenta_contable_inventario?.id_cuenta_contable,
      id_cuenta_contable_ventas:
        form.cuenta_contable_ventas?.id_cuenta_contable,
      id_cuenta_contable_costos:
        form.cuenta_contable_costos?.id_cuenta_contable,
      id_empresa: empresa.id_empresa,
    };

    let formData = new FormData();

    Object.keys(dataRequest).forEach((key) => {
      formData.append(key, dataRequest[key]);
    });

    if (form.file && form.file instanceof File) {
      formData.append("file", form.file);
    }

    setIsLoading(true);
    crearLineaProductoRequest(formData)
      .then((res) => {
        reloadLineasProductos();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear Linea de Productos"
      width={650}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Descripción" required>
            <Input
              placeholder="Descripción"
              value={form.descripcion}
              onChange={(e) => onChangeForm("descripcion", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={form.ecommerce_destacado}
              onChange={(e) =>
                onChangeForm("ecommerce_destacado", e.target.checked)
              }
            >
              Ecommerce Destacado
            </Checkbox>
          </Form.Item>

          <Row justify={"center"}>
            {!!form.url_imagen ? (
              <Row style={{ position: "relative" }}>
                <Image src={form.url_imagen} style={{ height: 100 }} />
                <Button
                  style={{ position: "absolute", top: 0, right: 0 }}
                  type="text"
                  shape="circle"
                  onClick={() =>
                    setForm({
                      ...form,
                      file: null,
                      url_imagen: "",
                    })
                  }
                >
                  <IoTrash size={20} color="red" />
                </Button>
              </Row>
            ) : (
              <Row
                style={{
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: PRIMARY_COLOR,
                  borderStyle: "dashed",
                  height: 100,
                  width: 300,
                  position: "relative",
                }}
                justify={"center"}
                align={"middle"}
              >
                <Col>
                  <Row justify={"center"} align={"middle"}>
                    <IoImage size={30} color={PRIMARY_COLOR} />
                  </Row>
                  <Row>Subir archivo o arrastrar imagen</Row>
                </Col>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target?.files?.[0];
                    if (file) {
                      if (file.type.includes("image")) {
                        setForm({
                          ...form,
                          file: file,
                          url_imagen: URL.createObjectURL(file),
                        });
                      } else {
                        // Archivo no válido, muestra un mensaje de error o realiza alguna acción apropiada
                        alert("Solo se permiten archivos P12.");
                      }
                    }
                  }}
                  accept="image/*"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
              </Row>
            )}
          </Row>
          <Row style={{ marginTop: 30 }} />

          <CuentaContableInput
            label="CC Inventario:"
            value={form.cuenta_contable_inventario}
            onChange={(v) => onChangeForm("cuenta_contable_inventario", v)}
          />
          <CuentaContableInput
            label="CC Ventas:"
            value={form.cuenta_contable_ventas}
            onChange={(v) => onChangeForm("cuenta_contable_ventas", v)}
          />
          <CuentaContableInput
            label="CC Costos:"
            value={form.cuenta_contable_costos}
            onChange={(v) => onChangeForm("cuenta_contable_costos", v)}
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
