import { Breadcrumb, Button, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { GiTwoCoins } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";
import {
  bancosIdEmpresaRequest,
  cajasIdEmpresaRequest,
} from "../../../../services/api/bancos";
import { TablaBancos, TablaCajas } from "./components/TablaBanco";
import { CrearBanco, CrearCaja } from "./components/CrearBanco";

export const BancosPage = () => {

  const { isBancosChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [bancos, setBancos] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    bancosIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setBancos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isBancosChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <GiTwoCoins style={{ marginRight: 5 }} />
                      <span>Tesorería</span>
                    </Row>
                  ),
                },
                {
                  title: "Bancos",
                },
              ]}
            />
          </Row>
          <Row align={"middle"}>
            <Button
              disabled={!checkPermisos("MBANCOS").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaBancos data={bancos} />
        {/* Crear Drawer */}
        <CrearBanco open={open} showDrawer={showDrawer} onClose={onClose} />
      </Content>
    </Spin>
  );
};
