import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthRoute } from "./auth/AuthRoute";
import { AppRoutes } from "./app/AppRoutes";
import { AuthContext } from "../context/AuthContext";

export const MainRoute = () => {

  const { isAuthenticated } = useContext(AuthContext);
  
  return (
    <BrowserRouter>
      <Routes>
        {isAuthenticated ? (
          <Route path="/*" element={<AppRoutes />} />
        ) : (
          <Route path="/auth/*" element={<AuthRoute />} />
        )}
        <Route path="/*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </BrowserRouter>
  );
};
