import { Button, Image, Input, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useState } from "react";
import { subirImagenProductoRequest } from "../../../../../services/api/productos";
import { IoTrash } from "react-icons/io5";

export const TabImagenes = memo(({ form, onChangeForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const subirImagen = (e) => {
    const formData = new FormData();
    formData.append("file", e);
    setIsLoading(true);
    subirImagenProductoRequest(formData)
      .then((res) => {
        onChangeForm("imagenes", [...(form.imagenes ?? []), res]);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteImage = (imagen) => {
    const filter = form?.imagenes?.filter((img) => img != imagen);
    onChangeForm("imagenes", [...filter]);
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        <Row gutter={5}>
          <Input
            placeholder="Seleccionar imagen"
            accept="image/*"
            multiple={false}
            value={file}
            type={"file"}
            onChange={(e) => {
              subirImagen(e.target.files[0]);
            }}
          />
          <Row style={{ marginTop: 10 }}>
            {form?.imagenes?.map((imagen, index) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <Image key={index} src={imagen} height={200} />
                  <Button
                    danger
                    icon={<IoTrash />}
                    onClick={() => deleteImage(imagen)}
                    type={"primary"}
                    style={{ position: "absolute", top: 0, right: 0 }}
                  />
                </div>
              );
            })}
          </Row>
        </Row>
      </Content>
    </Spin>
  );
});
