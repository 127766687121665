import axios from 'axios';
import { CONFIGS } from '../configs';
import { authStorageGet } from '../../helpers/storage/auth-storage';

const API = axios.create({ baseURL: CONFIGS.API_URL });

API.interceptors.request.use(
    (config) => {
        const auth = authStorageGet();
        const token = auth?.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default API;