import { useState } from 'react'

export const useForm = (initValues = {}) => {

    const [form, setForm] = useState({ ...initValues })

    const onChangeForm = (name, value) => setForm({
        ...form,
        [name]: value,
    })

    const onResetForm = () => setForm({
        ...initValues
    })

    return {
        form: form,
        setForm: setForm,
        onChangeForm: onChangeForm,
        onResetForm: onResetForm,
        initValues: JSON.parse(JSON.stringify({ ...initValues }))
    }
}
