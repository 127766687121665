import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Spin
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import {
  actualizarGrupoClienteRequest,
  grupoClienteIdRequest,
} from "../../../../../services/api/grupos-clientes";

const { Option } = Select;

export const EditarGrupoCliente = ({ open, showDrawer, id, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadGruposClientes } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    descripcion: "",
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && id != 0) {
      setIsLoading(true);
      grupoClienteIdRequest(id)
        .then((res) => {
          setForm({
            descripcion: res.descripcion,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [open, id]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { descripcion, cuenta_contable_caja } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion",
      });
      return false;
    }

    return true;
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      descripcion: form.descripcion,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);

    actualizarGrupoClienteRequest(id, dataRequest)
      .then((res) => {
        reloadGruposClientes();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar Grupo Clientes"
      width={650}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={editar} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form layout="vertical" disabled={isLoading}>
            <Form.Item label="Descripción" required>
              <Input
                placeholder="Descripción"
                value={form.descripcion}
                onChange={(e) => onChangeForm("descripcion", e.target.value)}
              />
            </Form.Item>
          </Form>
        </Form>
      </Spin>
    </Drawer>
  );
};
