import API from "../../configs/api/api.config";

const URL_EMPRESAS = '/empresas';

export const empresaIdRequest = async (id) => {
    const request = await API.get(URL_EMPRESAS + '/' + id);
    const response = request.data;
    return response;
}

export const crearEmpresaRequest = async (data) => {
    const request = await API.post(URL_EMPRESAS, data);
    const response = request.data;
    return response;
}

export const editarEmpresaRequest = async (id_empresa, data) => {
    const request = await API.patch(URL_EMPRESAS + '/' + id_empresa, data);
    const response = request.data;
    return response;
}

export const eliminarEmpresaRequest = async (id_empresa) => {
    const request = await API.delete(URL_EMPRESAS + '/' + id_empresa);
    const response = request.data;
    return response;
}

export const usuarioEmpresaRequest = async (data) => {
    const request = await API.post(URL_EMPRESAS + `/usuario_empresa`, data);
    const response = request.data;
    return response;
}

export const usuarioEmpresasRequest = async (data) => {
    const request = await API.post(URL_EMPRESAS + `/identificacion_usuario`, data);
    const response = request.data;
    return response;
}

export const empresasUsuarioRequest = async (data) => {
    const request = await API.post(URL_EMPRESAS + `/usuario`, data);
    const response = request.data;
    return response;
}

export const empresaRolesRequest = async (id_empresa) => {
    const request = await API.get(`${URL_EMPRESAS}/empresa_roles/${id_empresa}`);
    const response = request.data;
    return response;
}

export const crearUsuarioEmpresaRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/crear_usuario_empresa`, data);
    const response = request.data;
    return response;
}

export const actualizarUsuarioEmpresaRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/actualizar_usuario_empresa`, data);
    const response = request.data;
    return response;
}

export const eliminarUsuarioEmpresaRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/eliminar_usuario_empresa`, data);
    const response = request.data;
    return response;
}

export const editarModulosPermisosRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/eliminar_usuario_empresa`, data);
    const response = request.data;
    return response;
}

export const crearEmpresaRolUsuarioRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/crear_empresa_rol_usuario`, data);
    const response = request.data;
    return response;
}

export const elimiarEmpresaRolUsuarioRequest = async (data) => {
    const request = await API.post(`${URL_EMPRESAS}/eliminar_empresa_rol_usuario`, data);
    const response = request.data;
    return response;
}

export const subirImagenEmpresaRequest = async (data) => {
    const request = await API.post(URL_EMPRESAS + '/subir-imagen', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}