import API from "../../configs/api/api.config";

const URL_PARAMETROS_FACTURACION = '/parametros-facturacion';

export const parametrosFacturacionEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_FACTURACION + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosFacturacionRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_FACTURACION, data);
    const response = request.data;
    return response;
}

export const actualizarParametrosFacturacionRequest = async (id_parametros_facturacion, data) => {
    const request = await API.patch(URL_PARAMETROS_FACTURACION + '/' + id_parametros_facturacion, data);
    const response = request.data;
    return response;
}