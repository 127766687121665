import {
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { EmailsInput } from "../../../../../global/components/EmailsInput";

export const FormProveedor = memo(
  ({
    form = {},
    onChangeForm = (e, d) => {},
    tiposIdentificacion = [],
    gruposProveedores = [],
    tiposCompras = [],
    tiposEntidad = [],
    tiposRegimen = [],
    sustentoTributario = [],
    tiposFormaPago = [],
    tiposPagoA = [],
    porcentajeRetencion = [],
    paises = [],
  }) => {
    return (
      <Content>
        <Row gutter={5}>
          <Col span={16}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_identificacion}
                      onChange={(e) =>
                        onChangeForm("id_tipo_identificacion", e)
                      }
                    >
                      {tiposIdentificacion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_identificacion}
                            value={a.id_tipo_identificacion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Checkbox
                      checked={form.activo}
                      onChange={(e) => onChangeForm("activo", e.target.checked)}
                    >
                      Activo
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Identificación: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.identificacion}
                      onChange={(e) =>
                        onChangeForm("identificacion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Checkbox
                      checked={form.relacionado}
                      onChange={(e) =>
                        onChangeForm("relacionado", e.target.checked)
                      }
                    >
                      Relacionado
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <CuentaContableInput
                  label="Cuenta:"
                  value={form.cuenta_contable_proveedor}
                  onChange={(c) => onChangeForm("cuenta_contable_proveedor", c)}
                  customGutter={5}
                  spanLabel={5}
                  spanInput={7}
                  spanSearch={2}
                  spanText={7}
                  spanDelete={2}
                />
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Razón social: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.razon_social}
                      onChange={(e) =>
                        onChangeForm("razon_social", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Nombre comercial: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.nombre_comercial}
                      onChange={(e) =>
                        onChangeForm("nombre_comercial", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Dirección: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.direccion}
                      onChange={(e) =>
                        onChangeForm("direccion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Email: </Typography>
                  </Col>
                  <Col span={19}>
                    {/* <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.email}
                      onChange={(e) => onChangeForm("email", e.target.value)}
                    /> */}
                    <EmailsInput 
                      value={form.email}
                      onChange={(value) => onChangeForm("email", value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Grupo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_grupo_proveedor}
                      onChange={(e) => onChangeForm("id_grupo_proveedor", e)}
                    >
                      {gruposProveedores.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_grupo_proveedor}
                            value={a.id_grupo_proveedor}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Teléfono 1: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.telefono_1}
                      onChange={(e) =>
                        onChangeForm("telefono_1", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Teléfono 2: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.telefono_2}
                      onChange={(e) =>
                        onChangeForm("telefono_2", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Radio.Group
                    onChange={(e) => {
                      onChangeForm("id_tipo_compra", e.target.value);
                    }}
                    value={form.id_tipo_compra}
                  >
                    <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                      Compras:
                    </Typography>
                    <Space direction="horizontal">
                      {tiposCompras.map((f) => {
                        return (
                          <Radio
                            key={f.id_tipo_compra}
                            value={f.id_tipo_compra}
                          >
                            {f.descripcion}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Checkbox
                checked={form.contribuyente_especial}
                onChange={(e) =>
                  onChangeForm("contribuyente_especial", e.target.checked)
                }
              >
                Contribuyente Especial
              </Checkbox>

              <Checkbox
                checked={form.lleva_contabilidad}
                onChange={(e) => {
                  if (form.contribuyente_especial || form.id_tipo_entidad === 2) return;
                    onChangeForm("lleva_contabilidad", e.target.checked);
                }}
              >
                Lleva contabilidad
              </Checkbox>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo de proveedor: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_entidad}
                      onChange={(e) => onChangeForm("id_tipo_entidad", e)}
                    >
                      {tiposEntidad.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_entidad}
                            value={a.id_tipo_entidad}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Regimen: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_regimen}
                      onChange={(e) => onChangeForm("id_tipo_regimen", e)}
                    >
                      {tiposRegimen.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_regimen}
                            value={a.id_tipo_regimen}
                          >
                            {a.descripcion_regimen}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Sustento Tributario: </Typography>
                  </Col>
                  <Col span={19}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_sustento_tributario}
                      onChange={(e) =>
                        onChangeForm("id_sustento_tributario", e)
                      }
                    >
                      {sustentoTributario.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_sustento_tributario}
                            value={a.id_sustento_tributario}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Forma de Pago: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_forma_pago}
                      onChange={(e) => onChangeForm("id_tipo_forma_pago", e)}
                    >
                      {tiposFormaPago.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_forma_pago}
                            value={a.id_tipo_forma_pago}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Pago a: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_pago_a}
                      onChange={(e) => onChangeForm("id_tipo_pago_a", e)}
                    >
                      {tiposPagoA.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_pago_a}
                            value={a.id_tipo_pago_a}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>% Ret. IVA Bienes: </Typography>
                  </Col>
                  <Col span={19}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_porcentaje_retencion_bienes}
                      onChange={(e) =>
                        onChangeForm("id_porcentaje_retencion_bienes", e)
                      }
                    >
                      {porcentajeRetencion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_porcentaje_retencion}
                            value={a.id_porcentaje_retencion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>% Ret. IVA Servicios: </Typography>
                  </Col>
                  <Col span={19}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_porcentaje_retencion_servicios}
                      onChange={(e) =>
                        onChangeForm("id_porcentaje_retencion_servicios", e)
                      }
                    >
                      {porcentajeRetencion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_porcentaje_retencion}
                            value={a.id_porcentaje_retencion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>País pago: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_pais_pago}
                      disabled={form.id_tipo_pago_a != 2}
                      onChange={(e) => onChangeForm("id_pais_pago", e)}
                      showSearch
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                      filterSort={(optionA, optionB) => {
                        return (optionA?.children ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.children ?? "").toLowerCase()
                          );
                      }}
                    >
                      <Select.Option key={null} value={null}>
                        GENERAL
                      </Select.Option>
                      {paises.map((a) => {
                        return (
                          <Select.Option key={a.id_pais} value={a.id_pais}>
                            {a.nombre}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
