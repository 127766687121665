import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useState } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { ProveedorInput } from "../../proveedores/components/ProveedorInput";
import { ProductoGastoInput } from "./ProductoGastoInput";
import { TablaDetalles } from "./TablaDetalles";
import { TIPOS_DOCUMENTOS } from "../../../../../configs/constants/tipos-documentos";

export const TabMantenimiento = memo(
  ({
    nota_credito = false,
    edit = false,
    form = {},
    onChangeForm = (e, d) => {},
    user = {},
    tiposDocumentos = [],
    secuencias = [],
    sustentosTributarios = [],
    almacenes = [],
    productos = [],
    productoSeleccionado = null,
    seleccionarProducto = (i) => {},
    eliminarProducto = (p) => {},
    agregarProducto = (p) => {},
    totales = {},
    setTotales = () => {},
    onChange = (data) => {},
    isLoadingDetalles = false,
    isLoadingTotales = false,
    onChangeTotales = (data) => {},
    errorCalculos = { show: false, message: "" },
    setErrorCalculos = (v) => {},
    onCreateProveedor = () => {},
    onCreateProducto = () => {},
    onLink = () => {},
    ivas = [],
    electronico = false,
    createProveedor = false,
    createProducto = false,
    ats = true,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalObservacionOpen, setIsModalObservacionOpen] = useState(false);

    return (
      <Content>
        <Row gutter={12}>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={8}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Emision: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_emision}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_emision", e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Vence: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_vence}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_vence", e)}
                      locale={"es_EC"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>F. Registro: </Typography>
                  </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_registro}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_registro", e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <ProveedorInput
              label="Proveedor:"
              value={form.proveedor}
              onChange={(p) => onChangeForm("proveedor", p)}
              disabled={electronico || edit}
              onCreate={onCreateProveedor}
              create={createProveedor}
            />

            <Row gutter={10} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Secuencia: </Typography>
                  </Col>
                  <Col span={14}>
                    <Row gutter={10} justify={"space-between"}>
                      <Col span={12}>
                        <Input
                          size="small"
                          style={{ width: "100%" }}
                          value={form.codigo_establecimiento}
                          type={"number"}
                          onChange={(e) =>
                            onChangeForm(
                              "codigo_establecimiento",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          size="small"
                          style={{ width: "100%" }}
                          value={form.codigo_punto_emision}
                          type={"number"}
                          onChange={(e) =>
                            onChangeForm("codigo_punto_emision", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.secuencia}
                      onChange={(e) =>
                        onChangeForm("secuencia", e.target.value)
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <Checkbox
                      checked={form.ats}
                      onChange={(e) => onChangeForm("ats", e.target.checked)}
                    >
                      ATS
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 1 }}>
              <Col span={5}>
                <Typography>Concepto: </Typography>
              </Col>
              <Col span={19}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.concepto}
                  onChange={(e) => onChangeForm("concepto", e.target.value)}
                />
              </Col>
            </Row>

            <ProductoGastoInput
              label="Codigo:"
              value={productoSeleccionado}
              onChange={agregarProducto}
              onCreate={onCreateProducto}
              eliminarProducto={eliminarProducto}
              create={createProducto}
              proveedor={form.proveedor}
              disabled={electronico || edit}
              link={electronico}
              onLink={onLink}
            />

            <Row gutter={10} style={{ marginTop: 1 }} justify={"space-around"}>
              <Col span={12}>
                <Row gutter={12}>
                  <Col>
                    {/* <Button
                      icon={<InfoCircleFilled />}
                      type={"primary"}
                      size="small"
                      onClick={() => {
                        if (
                          productoSeleccionado !== undefined &&
                          productoSeleccionado !== null
                        ) {
                          setIsModalOpen(true);
                        }
                      }}
                    >
                      Información
                    </Button> */}
                  </Col>
                  <Col>
                    <Button type={"primary"} size="small">
                      Existencias
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col>
                    <Button type={"primary"} size="small">
                      Series
                    </Button>
                  </Col>
                  <Col>
                    <Button type={"primary"} size="small">
                      Lotes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo documento: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_documento}
                      onChange={(e) => onChangeForm("id_tipo_documento", e)}
                    >
                      {tiposDocumentos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_documento}
                            value={a.id_tipo_documento}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                {tiposDocumentos.find(
                  (t) => t.codigo === TIPOS_DOCUMENTOS.LIQUIDACIONES
                )?.id_tipo_documento === form.id_tipo_documento && (
                  <Row justify={"space-between"}>
                    <Col span={10}>
                      <Typography>Liquidacion: </Typography>
                    </Col>
                    <Col span={14}>
                      <Select
                        size="small"
                        style={{ width: "100%" }}
                        value={form.id_secuencia}
                        onChange={(e) => onChangeForm("id_secuencia", e)}
                      >
                        {secuencias.map((a) => {
                          return (
                            <Select.Option
                              key={a.id_secuencia}
                              value={a.id_secuencia}
                            >
                              {a.descripcion}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: 1 }}>
              <Col span={5}>
                <Typography>Autorizacion: </Typography>
              </Col>
              <Col span={19}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.autorizacion}
                  onChange={(e) => onChangeForm("autorizacion", e.target.value)}
                  disabled={!ats}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 1 }}>
              <Col span={5}>
                <Typography>Sustento Tributario: </Typography>
              </Col>
              <Col span={19}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_sustento_tributario}
                  onChange={(e) => onChangeForm("id_sustento_tributario", e)}
                  disabled={!ats}
                >
                  {sustentosTributarios.map((a) => {
                    return (
                      <Select.Option
                        key={a.id_sustento_tributario}
                        value={a.id_sustento_tributario}
                      >
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Almacen: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_almacen}
                      onChange={(e) => onChangeForm("id_almacen", e)}
                    >
                      {almacenes.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_almacen}
                            value={a.id_almacen}
                          >
                            {a.nombre_almacen}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.contabilizado}
                  onChange={(e) =>
                    onChangeForm("contabilizado", e.target.checked)
                  }
                >
                  Contabilizado
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>

        <Spin spinning={isLoadingDetalles}>
          <Row gutter={12} style={{ marginTop: 2 }}>
            <Col span={24}>
              <TablaDetalles
                data={productos}
                productoSeleccionado={productoSeleccionado}
                seleccionarProducto={seleccionarProducto}
                onChange={onChange}
                ivas={ivas}
                disabled={electronico}
              />
            </Col>
          </Row>
        </Spin>

        {errorCalculos.show && (
          <Alert
            description={errorCalculos.message}
            type="error"
            closable
            onClose={() => setErrorCalculos({ show: false, message: "" })}
          />
        )}

        <Row gutter={12}>
          <Col span={12}>
            <Typography style={{ fontWeight: "bold", marginTop: 10 }}>
              Datos Documento
            </Typography>
            <Row>
              <Col span={6}>
                <Typography>Clave de acceso: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.clave_acceso}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 1 }}>
              <Col span={6}>
                <Typography>Autorizacion: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.autorizacion}
                  disabled={true}
                />
              </Col>
            </Row>

            <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
              Datos Documento Relacionado
            </Typography>
            <Row>
              <Col span={6}>
                <Typography>Emision: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.documento_relacionado?.fecha_emision}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 1 }}>
              <Col span={6}>
                <Typography>Secuencia: </Typography>
              </Col>
              <Col span={18}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.documento_relacionado?.numero_documento}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Spin spinning={isLoadingTotales}>
              <Row justify={"end"}>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Descuento $: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.descuento_valor)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total Neto: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_neto)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total con IVA: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_iva)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total IVA 0%: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_iva_cero)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total No Obj: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_no_objeto)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Sub Total Exento: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.sub_total_exento)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Total ICE: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.total_ice)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Total IVA: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.total_iva)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Propina: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={totales.propina}
                        onChange={(e) =>
                          setTotales({
                            ...totales,
                            propina: e.target.value,
                          })
                        }
                        onBlur={() => {
                          onChangeTotales(totales);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Typography>IVA Presuntivo: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={totales.iva_presuntivo}
                        onChange={(e) =>
                          setTotales({
                            ...totales,
                            iva_presuntivo: e.target.value,
                          })
                        }
                        onBlur={() => {
                          onChangeTotales(totales);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Typography>IR Presuntiva: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={totales.ir_presuntiva}
                        onChange={(e) =>
                          setTotales({
                            ...totales,
                            ir_presuntiva: e.target.value,
                          })
                        }
                        onBlur={() => {
                          onChangeTotales(totales);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography> - Retencion IVA: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.retencion_iva)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography> - Retencion IR: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.retencion_renta)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Total:
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%", fontWeight: "bold" }}
                        value={numberFormat(totales.total)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Cantidad Items: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.cantidad_items)}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography>Cantidad Total: </Typography>
                    </Col>
                    <Col span={12}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        value={numberFormat(totales.total_cantidad)}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
    );
  }
);
