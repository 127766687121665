import API from "../../configs/api/api.config";

const URL_RETENCIONES = '/retenciones';

export const eliminarRetencionRequest = async (id_retencion) => {
    const request = await API.delete(URL_RETENCIONES + '/' + id_retencion);
    const response = request.data;
    return response;
}

export const autorizarRetencionRequest = async (id_retencion) => {
    const request = await API.post(URL_RETENCIONES + '/autorizar' + '/' + id_retencion);
    const response = request.data;
    return response;
}

export const anularRetencionRequest = async (id_retencion) => {
    const request = await API.post(URL_RETENCIONES + '/anular' + '/' + id_retencion);
    const response = request.data;
    return response;
}

export const pdfRetencionRequest = async (id_retencion) => {
    const request = await API.post(URL_RETENCIONES + '/pdf' + '/' + id_retencion, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const xmlRetencionRequest = async (id_retencion) => {
    const request = await API.post(URL_RETENCIONES + '/xml' + '/' + id_retencion, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const sendEmailRetencionRequest = async (id_retencion) => {
    const request = await API.post(URL_RETENCIONES + '/email' + '/' + id_retencion);
    const response = request.data;
    return response;
}


export const subirRetencionesVentasXMLRequest = async (data) => {
    const request = await API.post(URL_RETENCIONES + "/subir-retenciones-electronicas-ventas-xml", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}
