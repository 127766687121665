import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import React, { useState } from "react";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { ModalEditarPago } from "./ModalEditarPago";

export const TablaPagos = ({
  data,
  eliminar = () => {},
  editar = () => {},
  cajas = [],
  bancos = [],
  bancosClientes,
  formasPagosSri = [],
}) => {
  const { getColumnSearchProps } = useColumnSearch();

  const [pago, setPago] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const editarPago = (p) => {
    setPago(p);
    setIsOpen(true);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "20%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editarPago(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              shape="default"
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={() => eliminar(record)}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Forma de Pago",
      dataIndex: "tipo_forma_pago",
      key: "tipo_forma_pago",
      width: "60%",
      render: (record) => record?.descripcion,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "20%",
      align: "center",
      render: (record) => numberFormat(record),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 300,
        }}
        size="small"
        columns={columns}
        dataSource={data}
        style={{ marginTop: 7 }}
      />
      {!!pago && (
        <ModalEditarPago
          pago={pago}
          editar={editar}
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          cajas={cajas}
          bancos={bancos}
          bancosClientes={bancosClientes}
          formasPagosSri={formasPagosSri}
        />
      )}
    </>
  );
};
