import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Table,
  Tooltip,
  Typography
} from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarCompraRequest } from "../../../../../services/api/compras";
import { TagDocumentoElectronico } from "./TagDocumentoElectronico";

export const TablaCompras = ({ data = [],  onChangeTablePagination = () => {}}) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadCompras } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_compra = record.id_compra;
    if (!id_compra) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta compra, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCompraRequest(id_compra).then((res) => {
      reloadCompras();
    });
  };

  const detalleCompra = (data) => {
    const id_compra = data?.id_compra;
    if (!id_compra) return;
    navigate(`../compras/${id_compra}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "6%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MCOMPRAS").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleCompra(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MCOMPRAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Emision",
      dataIndex: "fecha_emision",
      key: "fecha_emision",
      width: "8%",
    },
    {
      title: "Tipo Documento",
      dataIndex: "tipo_documento",
      key: "tipo_documento",
      width: "24%",
      ellipsis: true,
      render: (record, data) => (
        <Row gutter={5}>
          <Col>
            <Typography>{record.descripcion}</Typography>
          </Col>
          <Col>
            {(data.electronico ||
              data.estado_documento_electronico ===
                CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO) && (
              <TagDocumentoElectronico
                estado={data.estado_documento_electronico}
              />
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "N. Documento",
      dataIndex: "numero_documento",
      key: "numero_documento",
      width: "13%",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
      width: "20%",
      ellipsis: true,
      render: (record) => record?.razon_social,
    },
    {
      title: "Almacén",
      dataIndex: "almacen",
      key: "almacen",
      width: "10%",
      ellipsis: true,
      render: (record) => record?.nombre_almacen,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "8%",
      render: (record) => numberFormat(record),
    },
    {
      title: "Cont.",
      dataIndex: "contabilizado",
      key: "contabilizado",
      width: "5%",
      align: "center",
      render: (record, row) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "N. Retención",
      dataIndex: "retencion",
      key: "retencion",
      width: "15%",
      ellipsis: true,
      render: (record) => (
        <Col>
          {record?.numero_documento}
          &nbsp;&nbsp;
          <TagDocumentoElectronico
            estado={record?.estado_documento_electronico}
          />
        </Col>
      ),
    },
  ];

  return (
    <Table
      scroll={{
        x: 1200,
        y: 390,
      }}
      rowKey={"id_compra"}
      columns={columns}
      dataSource={data}
      size="small"
      pagination={{
        onChange: (page, pageSize) => onChangeTablePagination(page, pageSize, data)
      }}
    />
  );
};
