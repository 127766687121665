import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  anularCompraRequest,
  autorizarCompraRequest,
  pdfCompraRequest,
  sendEmailCompraRequest,
  xmlCompraRequest,
} from "../../../../../services/api/compras";
import { CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";
import {
  anularRetencionRequest,
  autorizarRetencionRequest,
  eliminarRetencionRequest,
  pdfRetencionRequest,
  sendEmailRetencionRequest,
  xmlRetencionRequest,
} from "../../../../../services/api/retenciones";
import { toast } from "sonner";

export const OpcionesCompra = ({
  compra = {},
  handleReload = () => {},
  setIsLoading = () => {},
}) => {
  const navigate = useNavigate();

  //COMPRA

  const autorizarCompra = () => {
    setIsLoading(true);
    autorizarCompraRequest(compra.id_compra).finally(() => {
      handleReload();
      setIsLoading(false);
    });
  };

  const anularCompra = () => {
    const confirm = window.confirm(
      `Esta seguro de ANULAR la compra ${compra.numero_documento}`
    );

    if (!confirm) return;

    setIsLoading(true);
    anularCompraRequest(compra.id_compra)
      .catch((err) => {
        const message = err.response?.data?.message;
        !!message && alert(message);
      })
      .finally(() => {
        handleReload();
        setIsLoading(false);
      });
  };

  const pdfCompra = () => {
    setIsLoading(true);
    pdfCompraRequest(compra.id_compra)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${compra.tipo_documento?.descripcion ?? ""} ${
            compra.numero_documento
          }.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const xmlCompra = () => {
    setIsLoading(true);
    xmlCompraRequest(compra.id_compra)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${compra.tipo_documento?.descripcion ?? ""} ${
            compra.numero_documento
          }.xml`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const enviarEmailCompra = () => {
    setIsLoading(true);
    sendEmailCompraRequest(compra.id_compra)
      .then((res) => {
        toast.success("Correo enviado correctamente");
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //RETENCION

  const autorizarRetencion = () => {
    const retencion = compra.retencion;
    const id_retencion = retencion?.id_retencion;
    if (!id_retencion) return;
    setIsLoading(true);
    autorizarRetencionRequest(id_retencion).finally(() => {
      handleReload();
      setIsLoading(false);
    });
  };

  const anularRetencion = () => {
    const retencion = compra.retencion;
    const id_retencion = retencion?.id_retencion;
    if (!id_retencion) return;

    const confirm = window.confirm(
      `Esta seguro de ANULAR la retencion ${retencion.numero_documento}`
    );

    if (!confirm) return;

    setIsLoading(true);
    anularRetencionRequest(id_retencion)
      .catch((err) => {
        const message = err.response?.data?.message;
        !!message && alert(message);
      })
      .finally(() => {
        handleReload();
        setIsLoading(false);
      });
  };

  const pdfRetencion = () => {
    const retencion = compra.retencion;
    const id_retencion = retencion?.id_retencion;
    if (!id_retencion) return;
    setIsLoading(true);
    pdfRetencionRequest(id_retencion)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${compra.retencion?.tipo_documento?.descripcion ?? ""} ${
            compra.retencion?.numero_documento
          }.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const xmlRetencion = () => {
    const retencion = compra.retencion;
    const id_retencion = retencion?.id_retencion;
    if (!id_retencion) return;
    setIsLoading(true);
    xmlRetencionRequest(id_retencion)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${compra?.retencion?.tipo_documento?.descripcion ?? ""} ${
            compra?.retencion?.numero_documento
          }.xml`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const xmlEliminar = () => {
    const confirm = window.confirm(`¿Esta suguro de eliminar la retencion?`);
    if(!confirm) return;
    const retencion = compra.retencion;
    const id_retencion = retencion?.id_retencion;
    if (!id_retencion) return;
    setIsLoading(true);
    eliminarRetencionRequest(id_retencion).finally(() => {
      handleReload();
      setIsLoading(false);
    });
  };

  const enviarEmailRetencion = () => {
    setIsLoading(true);
    sendEmailRetencionRequest(compra.retencion.id_retencion)
      .then((res) => {
        toast.success("Correo enviado correctamente");
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const items = [
    {
      label: "Compra",
      key: "0",
      type: "group",
      children: [
        {
          label: "Autorizar",
          onClick: autorizarCompra,
          key: "0-0",
          disabled:
            compra.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.AUTORIZADO ||
            compra.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
        },
        {
          label: "Anular",
          onClick: anularCompra,
          key: "0-0-1",
          disabled:
            compra.estado_documento_electronico ===
            CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
        },
        {
          type: "divider",
        },
        {
          label: "Descargar PDF",
          onClick: pdfCompra,
          key: "0-1",
        },
        {
          label: "Descargar XML",
          onClick: xmlCompra,
          key: "0-2",
        },
        {
          label: "Reenviar correo",
          onClick: enviarEmailCompra,
          key: "0-3",
        },
      ],
    },
    {
      label: "Retención",
      key: "1",
      type: "group",
      children: [
        {
          label: "Autorizar",
          onClick: autorizarRetencion,
          key: "1-0",
          disabled:
            !compra.retencion ||
            compra.retencion?.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.AUTORIZADO ||
            compra.retencion?.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
        },
        {
          label: "Anular",
          onClick: anularRetencion,
          key: "1-0-1",
          disabled:
            !compra.retencion ||
            compra.retencion?.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
        },
        {
          label: "Eliminar",
          onClick: xmlEliminar,
          key: "1-1",
          disabled:
            !compra.retencion ||
            compra.retencion?.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.AUTORIZADO ||
            compra.retencion?.estado_documento_electronico ===
              CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
        },
        {
          type: "divider",
        },
        {
          label: "Descargar PDF",
          onClick: pdfRetencion,
          key: "1-2",
          disabled: !compra.retencion,
        },
        {
          label: "Descargar XML",
          onClick: xmlRetencion,
          key: "1-3",
          disabled: !compra.retencion,
        },
        {
          label: "Reenviar correo",
          onClick: enviarEmailRetencion,
          key: "1-4",
          disabled: !compra.retencion,
        },
      ],
    },
  ];

  return (
    <Dropdown disabled={!compra.id_compra} menu={{ items }} trigger={["click"]}>
      <Button type="primary">
        Opciones <DownOutlined />
      </Button>
    </Dropdown>
  );
};
