import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";
import { TIPOS_DOCUMENTOS } from "../../../../../configs/constants/tipos-documentos";
import {
  anularDocumentoRequest,
  autorizarDocumentoRequest,
  pdfDocumentoRequest,
  sendEmailDocumentoRequest,
  xmlDocumentoRequest,
} from "../../../../../services/api/facturacion";
import { toast } from "sonner";

export const OpcionesDocumento = ({
  documento = {},
  handleReload = () => {},
  setIsLoading = () => {},
  isLoading = false,
}) => {
  const navigate = useNavigate();

  const autorizarDocumento = () => {
    setIsLoading(true);
    autorizarDocumentoRequest(documento.id_documento).finally(() => {
      handleReload();
      setIsLoading(false);
    });
  };

  const anularDocumento = () => {
    const confirm = window.confirm(
      `Esta seguro de ANULAR el documento ${documento.numero_documento}`
    );

    if (!confirm) return;

    setIsLoading(true);
    anularDocumentoRequest(documento.id_documento)
      .catch((err) => {
        const message = err.response?.data?.message;
        !!message && alert(message);
      })
      .finally(() => {
        handleReload();
        setIsLoading(false);
      });
  };

  const pdfDocumento = () => {
    setIsLoading(true);
    pdfDocumentoRequest(documento.id_documento)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${documento.tipo_documento?.descripcion ?? ""} ${
            documento.numero_documento
          }.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const xmlDocumento = () => {
    setIsLoading(true);
    xmlDocumentoRequest(documento.id_documento)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${documento.tipo_documento?.descripcion ?? ""} ${
            documento.numero_documento
          }.xml`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const enviarEmail = () => {
    setIsLoading(true);
    sendEmailDocumentoRequest(documento.id_documento)
      .then((res) => {
        toast.success("Correo enviado correctamente");
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const notaCredito = () => {
    navigate(`../facturacion/crear-nota-credito/${documento.id_documento}`);
  };

  const items = [
    {
      label: "Autorizar",
      onClick: autorizarDocumento,
      key: "0",
      disabled:
        documento.estado_documento_electronico ===
          CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.AUTORIZADO ||
        documento.estado_documento_electronico ===
          CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
    },
    {
      label: "Anular",
      onClick: anularDocumento,
      key: "0-1",
      disabled: CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO,
    },
    {
      type: "divider",
    },
    {
      label: "Descargar PDF",
      onClick: pdfDocumento,
      key: "1",
    },
    {
      label: "Descargar XML",
      onClick: xmlDocumento,
      key: "2",
    },
    {
      label: "Reenviar correo",
      onClick: enviarEmail,
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: "Aplicar Nota de Credito",
      onClick: notaCredito,
      key: "4",
      disabled:
        documento.codigo_documento === TIPOS_DOCUMENTOS.NOTAS_CREDITOS
        || documento.documento_operadora,
    },
  ];

  return (
    <Dropdown
      disabled={!documento.id_documento}
      menu={{ items }}
      trigger={["click"]}
    >
      <Button type="primary">
        Opciones <DownOutlined />
      </Button>
    </Dropdown>
  );
};
