import API from "../../configs/api/api.config";

const URL_GRUPOS_CLIENTES = '/grupos-clientes';

export const gruposClientesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_GRUPOS_CLIENTES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const grupoClienteIdRequest = async (id_grupo_cliente) => {
    const request = await API.get(URL_GRUPOS_CLIENTES + '/' + id_grupo_cliente);
    const response = request.data;
    return response;
}

export const crearGrupoClienteRequest = async (data) => {
    const request = await API.post(URL_GRUPOS_CLIENTES, data);
    const response = request.data;
    return response;
}

export const actualizarGrupoClienteRequest = async (id_grupo_cliente, data) => {
    const request = await API.patch(URL_GRUPOS_CLIENTES + '/' + id_grupo_cliente, data);
    const response = request.data;
    return response;
}

export const eliminarGrupoClienteRequest = async (id_grupo_cliente) => {
    const request = await API.delete(URL_GRUPOS_CLIENTES + '/' + id_grupo_cliente);
    const response = request.data;
    return response;
}