import API from "../../configs/api/api.config";

const URL_PROVEEDORES = '/proveedores';

export const proveedoresIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PROVEEDORES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const proveedorIdRequest = async (id_proveedor) => {
    const request = await API.get(URL_PROVEEDORES + '/' + id_proveedor);
    const response = request.data;
    return response;
}

export const buscarProveedoresRequest = async (data) => {
    const request = await API.post(URL_PROVEEDORES + '/buscar', data);
    const response = request.data;
    return response;
}

export const crearProveedorRequest = async (data) => {
    const request = await API.post(URL_PROVEEDORES, data);
    const response = request.data;
    return response;
}

export const actualizarProveedorRequest = async (id_proveedor, data) => {
    const request = await API.patch(URL_PROVEEDORES + '/' + id_proveedor, data);
    const response = request.data;
    return response;
}

export const eliminarProveedorRequest = async (id_proveedor) => {
    const request = await API.delete(URL_PROVEEDORES + '/' + id_proveedor);
    const response = request.data;
    return response;
}
