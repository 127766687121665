import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Checkbox, Row, Table, Tooltip } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarAgenteVentaRequest } from "../../../../../services/api/agentes-ventas";

export const TablaAgentesVentas = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadAgentesVentas } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_agente_venta = record.id_agente_venta;
    if (!id_agente_venta) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este agente de venta, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarAgenteVentaRequest(id_agente_venta).then((res) => {
      reloadAgentesVentas();
    });
  };

  const detalleAgenteVenta = (data) => {
    const id_agente_venta = data?.id_agente_venta;
    if (!id_agente_venta) return;
    navigate(`./${id_agente_venta}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "8%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MAGENTES-VENTAS").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleAgenteVenta(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MAGENTES-VENTAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      width: "42%",
      ellipsis: true,
      ...getColumnSearchProps("nombres"),
    },
    {
      title: "Facturacion",
      dataIndex: "facturador",
      key: "facturador",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Vendedor",
      dataIndex: "vendedor",
      key: "vendedor",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Cobrador",
      dataIndex: "cobrador",
      key: "cobrador",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Despachador",
      dataIndex: "despachador",
      key: "despachador",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Estado",
      dataIndex: "activo",
      key: "activo",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_agente_venta"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
