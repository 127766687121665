import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_NOMINA = '/parametros-contables-nomina';

export const parametrosContablesNominaEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_NOMINA + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesNominaRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_NOMINA, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesNominaRequest = async (id_parametros_contables_nomina, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_NOMINA + '/' + id_parametros_contables_nomina, data);
    const response = request.data;
    return response;
}