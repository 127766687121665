import API from "../../configs/api/api.config";

const URL_ZONAS_CLIENTES = '/zonas-clientes';

export const zonasClientesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_ZONAS_CLIENTES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const zonaClienteIdRequest = async (id_zona_cliente) => {
    const request = await API.get(URL_ZONAS_CLIENTES + '/' + id_zona_cliente);
    const response = request.data;
    return response;
}

export const crearZonaClienteRequest = async (data) => {
    const request = await API.post(URL_ZONAS_CLIENTES, data);
    const response = request.data;
    return response;
}

export const actualizarZonaClienteRequest = async (id_zona_cliente, data) => {
    const request = await API.patch(URL_ZONAS_CLIENTES + '/' + id_zona_cliente, data);
    const response = request.data;
    return response;
}

export const eliminarZonaClienteRequest = async (id_zona_cliente) => {
    const request = await API.delete(URL_ZONAS_CLIENTES + '/' + id_zona_cliente);
    const response = request.data;
    return response;
}