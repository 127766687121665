import API from "../../configs/api/api.config";

const URL_CUENTAS_CONTABLES = '/cuentas-contables';

export const cuentasContablesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_CUENTAS_CONTABLES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const cuentaContableIdRequest = async (id_cuenta_contable) => {
    const request = await API.get(URL_CUENTAS_CONTABLES + '/' + id_cuenta_contable);
    const response = request.data;
    return response;
}

export const crearCuentaContableRequest = async (data) => {
    const request = await API.post(URL_CUENTAS_CONTABLES, data);
    const response = request.data;
    return response;
}

export const cargarPlanCuentasEmpresaBaseRequest = async (data) => {
    const request = await API.post(URL_CUENTAS_CONTABLES + '/cargar-plan-cuentas-default', data);
    const response = request.data;
    return response;
}

export const busquedaCuentasContablesRequest = async (data) => {
    const request = await API.post(URL_CUENTAS_CONTABLES + '/busqueda-cuentas-contables', data);
    const response = request.data;
    return response;
}

export const actualizarCuentaContableRequest = async (id_cuenta_contable, data) => {
    const request = await API.patch(URL_CUENTAS_CONTABLES + '/' + id_cuenta_contable, data);
    const response = request.data;
    return response;
}

export const eliminarCuentaContableRequest = async (id_cuenta_contable) => {
    const request = await API.delete(URL_CUENTAS_CONTABLES + '/' + id_cuenta_contable);
    const response = request.data;
    return response;
}