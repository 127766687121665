import API from "../../configs/api/api.config";

const URL_CAJAS = '/cajas';

export const cajasIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_CAJAS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const cajaIdRequest = async (id_caja) => {
    const request = await API.get(URL_CAJAS + '/' + id_caja);
    const response = request.data;
    return response;
}

export const crearCajaRequest = async (data) => {
    const request = await API.post(URL_CAJAS, data);
    const response = request.data;
    return response;
}

export const actualizarCajaRequest = async (id_caja, data) => {
    const request = await API.patch(URL_CAJAS + '/' + id_caja, data);
    const response = request.data;
    return response;
}

export const eliminarCajaRequest = async (id_caja) => {
    const request = await API.delete(URL_CAJAS + '/' + id_caja);
    const response = request.data;
    return response;
}