import {
  Alert,
  Button,
  Checkbox,
  Col,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import { useForm } from "../../../../../hooks/useForm";
import { useEffect } from "react";
import { tiposModificacionAsientoRequest } from "../../../../../services/api/opciones";
import { UserContext } from "../../../../../context/UserContext";
import {
  crearParametrosContablesGeneralesRequest,
  editarParametrosContablesGeneralesRequest,
  parametrosContablesGeneralesEmpresaRequest,
} from "../../../../../services/api/parametros-contables-generales";
import { toast } from "sonner";

export const GeneralTab = ({ onReload = () => {}, activeForm, reload }) => {
  
  const { empresa, checkPermisos } = useContext(UserContext);

  const { form, onChangeForm, setForm } = useForm({
    id_parametros_contables_generales: null,
    genera_asientos_contables: false,
    centros_costos: false,
    id_tipo_modificacion_asiento: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposModificacionAsiento, setTiposModificacionAsiento] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    tiposModificacionAsientoRequest().then((res) =>
      setTiposModificacionAsiento(res)
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    parametrosContablesGeneralesEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res) {
          setForm({
            id_parametros_contables_generales:
              res.id_parametros_contables_generales,
            genera_asientos_contables: res.genera_asientos_contables,
            centros_costos: res.centros_costos,
            id_tipo_modificacion_asiento:
              res.tipo_modificacion_asiento?.id_tipo_modificacion_asiento,
          });
        } else {
          setError({
            show: true,
            message:
              "Crear los parametros contables generales antes de utilizar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const validarCampos = () => {
    const { id_tipo_modificacion_asiento } = form;

    if (!id_tipo_modificacion_asiento) {
      setError({
        show: true,
        message: "Seleccione el tipo de modificacion del asiento",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros contables generales de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      genera_asientos_contables: form.genera_asientos_contables,
      centros_costos: form.centros_costos,
      id_tipo_modificacion_asiento: form.id_tipo_modificacion_asiento,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearParametrosContablesGeneralesRequest(dataRequest)
      .then((res) => {
        onReload();
        toast.success("Parametros contables generales creados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios en parametros contables generales de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      genera_asientos_contables: form.genera_asientos_contables,
      centros_costos: form.centros_costos,
      id_tipo_modificacion_asiento: form.id_tipo_modificacion_asiento,
    };

    setIsLoading(true);
    editarParametrosContablesGeneralesRequest(
      form.id_parametros_contables_generales,
      dataRequest
    )
      .then((res) => {
        onReload();
        toast.success(
          "Parametros contables generales guardados correctamente !"
        );
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col>
        {/* ERROR */}
          <Row>
            {error.show && (
              <Alert
                description={error.message}
                type="error"
                closable
                onClose={() => setError({ show: false, message: "" })}
              />
            )}
          </Row>
          <Row>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("genera_asientos_contables", e.target.value);
              }}
              value={form.genera_asientos_contables}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Genera asientos contables
              </Typography>
              <Space direction="vertical">
                <Radio key={true} value={true}>
                  SI
                </Radio>
                <Radio key={false} value={false}>
                  NO
                </Radio>
              </Space>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_modificacion_asiento", e.target.value);
              }}
              value={form.id_tipo_modificacion_asiento}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo modificación de asientos
              </Typography>
              <Space direction="vertical">
                {tiposModificacionAsiento.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_modificacion_asiento}
                      value={f.id_tipo_modificacion_asiento}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Checkbox
              checked={form.centros_costos}
              onChange={(e) => onChangeForm("centros_costos", e.target.checked)}
            >
              Centros de Costos
            </Checkbox>
          </Row>
          {/* BOTONES */}
          <Row style={{ marginTop: 30 }}>
            {form.id_parametros_contables_generales ? (
              <Button
                type="primary"
                onClick={editar}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").editar || !activeForm
                }
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={crear}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").crear || !activeForm
                }
              >
                Crear
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
