import { createContext, useState } from "react";

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
    
  const [collapsed, setCollapsed] = useState(false);

  const handleOpen = () => {
    setCollapsed(true);
  };

  const handleClose = () => {
    setCollapsed(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        handleOpen,
        handleClose,
        collapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
