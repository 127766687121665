import { useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect } from "react";
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../../../hooks/useForm";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  tiposDescuentoAsignadoRequest,
  visualizacionDescuentoRequest,
} from "../../../../services/api/opciones";
import { UserContext } from "../../../../context/UserContext";

import {
  actualizarParametrosFacturacionRequest,
  crearParametrosFacturacionRequest,
  parametrosFacturacionEmpresaRequest,
} from "../../../../services/api/parametros-facturacion";


export const ParametrosFacturacionPage = () => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    id_parametros_facturacion: null,
    obligar_cupo_credito: false,
    controlar_saldos_vencidos: false,
    porcentaje_interes: null,
    permitir_entregas_parciales_facturacion: false,
    permitir_servicios_guias_remision: false,
    enviar_notificacion_cobro_mail_cliente: false,
    enviar_notificacion_pago_mail_proveedor: false,
    lotes_descontar_automatico: false,
    id_visualizacion_descuento: null,
    id_tipo_descuento_asignado: null,
  });

  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const [activeForm, setActiveForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [tiposDescuentoAsignado, setTiposDescuentoAsignado] = useState([]);
  useEffect(() => {
    tiposDescuentoAsignadoRequest()
      .then((res) => setTiposDescuentoAsignado(res))
      .finally();
  }, []);

  const [visualizacionesDescuento, setVisualizacionesDescuento] = useState([]);
  useEffect(() => {
    visualizacionDescuentoRequest()
      .then((res) => setVisualizacionesDescuento(res))
      .finally();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setForm({ ...initValues });
    parametrosFacturacionEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res.id_parametros_facturacion) {
          setForm({
            ...form,
            id_parametros_facturacion: res.id_parametros_facturacion,
            obligar_cupo_credito: res.obligar_cupo_credito,
            controlar_saldos_vencidos: res.controlar_saldos_vencidos,
            porcentaje_interes: res.porcentaje_interes,
            permitir_entregas_parciales_facturacion:
              res.permitir_entregas_parciales_facturacion,
            permitir_servicios_guias_remision:
              res.permitir_servicios_guias_remision,
            enviar_notificacion_cobro_mail_cliente:
              res.enviar_notificacion_cobro_mail_cliente,
            enviar_notificacion_pago_mail_proveedor:
              res.enviar_notificacion_pago_mail_proveedor,
            lotes_descontar_automatico: res.lotes_descontar_automatico,
            id_visualizacion_descuento:
              res.visualizacion_descuento?.id_visualizacion_descuento,
            id_tipo_descuento_asignado:
              res.tipo_descuento_asignado.id_tipo_descuento_asignado,
          });
        } else {
          setError({
            show: true,
            message: "Completar estos datos antes de empezar usar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const handleCreate = () => {
    setActiveForm(true);
  };

  const handleEdit = () => {
    setActiveForm(true);
  };

  const handleGuardarCambiosCrear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros de facturacion para la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      obligar_cupo_credito: form.obligar_cupo_credito,
      controlar_saldos_vencidos: form.controlar_saldos_vencidos,
      porcentaje_interes:
        form.porcentaje_interes == "" || form.porcentaje_interes == null
          ? null
          : Number(form.porcentaje_interes),
      permitir_entregas_parciales_facturacion:
        form.permitir_entregas_parciales_facturacion,
      permitir_servicios_guias_remision: form.permitir_servicios_guias_remision,
      enviar_notificacion_cobro_mail_cliente:
        form.enviar_notificacion_cobro_mail_cliente,
      enviar_notificacion_pago_mail_proveedor:
        form.enviar_notificacion_pago_mail_proveedor,
      lotes_descontar_automatico: form.lotes_descontar_automatico,
      id_visualizacion_descuento: form.id_visualizacion_descuento,
      id_tipo_descuento_asignado: form.id_tipo_descuento_asignado,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearParametrosFacturacionRequest(dataRequest)
      .then((res) => {
        onReload();
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validarCampos = () => {
    const {
      porcentaje_interes,
      id_visualizacion_descuento,
      id_tipo_descuento_asignado,
    } = form;

    if (
      porcentaje_interes != null &&
      porcentaje_interes != "" &&
      Number.isFinite(Number(porcentaje_interes))
    ) {
      setError({
        show: true,
        message: "Revise el porcentaje interes",
      });
      return false;
    }

    if (!id_visualizacion_descuento) {
      setError({
        show: true,
        message: "Seleccione el modo de visualizacion del descuento",
      });
      return false;
    }

    if (!id_tipo_descuento_asignado) {
      setError({
        show: true,
        message: "Seleccione el Tipo de descuento asignado",
      });
      return false;
    }

    return true;
  };

  const onReload = () => {
    setActiveForm(false);
    setReload(!reload);
  };

  const handleGuardarCambiosActualizar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios de parámetros facturación de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      obligar_cupo_credito: form.obligar_cupo_credito,
      controlar_saldos_vencidos: form.controlar_saldos_vencidos,
      porcentaje_interes:
        form.porcentaje_interes == "" || form.porcentaje_interes == null
          ? null
          : Number(form.porcentaje_interes),
      permitir_entregas_parciales_facturacion:
        form.permitir_entregas_parciales_facturacion,
      permitir_servicios_guias_remision: form.permitir_servicios_guias_remision,
      enviar_notificacion_cobro_mail_cliente:
        form.enviar_notificacion_cobro_mail_cliente,
      enviar_notificacion_pago_mail_proveedor:
        form.enviar_notificacion_pago_mail_proveedor,
      lotes_descontar_automatico: form.lotes_descontar_automatico,
      id_visualizacion_descuento: form.id_visualizacion_descuento,
      id_tipo_descuento_asignado: form.id_tipo_descuento_asignado,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    actualizarParametrosFacturacionRequest(
      form.id_parametros_facturacion,
      dataRequest
    )
      .then((res) => {
        onReload();
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20}/>}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Parámetros Facturación",
                },
              ]}
            />
          </Row>
          <Row>
            {form.id_parametros_facturacion == null ? (
              activeForm ? (
                <Button
                  disabled={!checkPermisos("MPARAMETROS-FACTURACION").crear}
                  type="primary"
                  onClick={handleGuardarCambiosCrear}
                >
                  Guardar cambios
                </Button>
              ) : (
                <Button
                  disabled={!checkPermisos("MPARAMETROS-FACTURACION").crear}
                  type="primary"
                  onClick={handleCreate}
                >
                  Crear
                </Button>
              )
            ) : activeForm ? (
              <Button
                disabled={!checkPermisos("MPARAMETROS-FACTURACION").editar}
                type="primary"
                onClick={handleGuardarCambiosActualizar}
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                disabled={!checkPermisos("MPARAMETROS-FACTURACION").editar}
                type="primary"
                onClick={handleEdit}
                icon={<EditFilled />}
              >
                Editar
              </Button>
            )}
          </Row>
        </Row>
        {/* Error */}
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        {/* Boby */}
        <Form disabled={!activeForm}>
          <Row style={{ marginTop: 10 }} gutter={10} justify={"space-around"}>
            <Col span={8}>
              <Row>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Facturación
                </Typography>
              </Row>
              <Row>
                <Checkbox
                  checked={form.obligar_cupo_credito}
                  onChange={(e) =>
                    onChangeForm("obligar_cupo_credito", e.target.checked)
                  }
                >
                  Obligar cupo de crédito
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  checked={form.controlar_saldos_vencidos}
                  onChange={(e) =>
                    onChangeForm("controlar_saldos_vencidos", e.target.checked)
                  }
                >
                  Controlar saldos vencidos
                </Checkbox>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Entregas parciales
                </Typography>
              </Row>
              <Row>
                <Checkbox
                  checked={form.permitir_entregas_parciales_facturacion}
                  onChange={(e) =>
                    onChangeForm(
                      "permitir_entregas_parciales_facturacion",
                      e.target.checked
                    )
                  }
                >
                  Permitir entregas parciales en facturación
                </Checkbox>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Guiás de Remisión
                </Typography>
              </Row>
              <Row>
                <Checkbox
                  checked={form.permitir_servicios_guias_remision}
                  onChange={(e) =>
                    onChangeForm(
                      "permitir_servicios_guias_remision",
                      e.target.checked
                    )
                  }
                >
                  Permitir servicios en las guiás de remisión
                </Checkbox>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Opciones Cobros/Pagos
                </Typography>
              </Row>
              <Row>
                <Checkbox
                  checked={form.enviar_notificacion_cobro_mail_cliente}
                  onChange={(e) =>
                    onChangeForm(
                      "enviar_notificacion_cobro_mail_cliente",
                      e.target.checked
                    )
                  }
                >
                  Enviar notificación por mail de cobro al cliente
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  checked={form.enviar_notificacion_pago_mail_proveedor}
                  onChange={(e) =>
                    onChangeForm(
                      "enviar_notificacion_pago_mail_proveedor",
                      e.target.checked
                    )
                  }
                >
                  Enviar notificación por mail de pago al proveedor
                </Checkbox>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Control de Lotes Automático
                </Typography>
              </Row>
              <Row>
                <Checkbox
                  checked={form.lotes_descontar_automatico}
                  onChange={(e) =>
                    onChangeForm("lotes_descontar_automatico", e.target.checked)
                  }
                >
                  Lotes descontar automático
                </Checkbox>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_tipo_descuento_asignado", e.target.value);
                  }}
                  value={form.id_tipo_descuento_asignado}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Tipo de descuento asignado
                  </Typography>
                  <Space direction="vertical">
                    {tiposDescuentoAsignado.map((f) => {
                      return (
                        <Radio
                          key={f.id_tipo_descuento_asignado}
                          value={f.id_tipo_descuento_asignado}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_visualizacion_descuento", e.target.value);
                  }}
                  value={form.id_visualizacion_descuento}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Modo de visualización del descuento
                  </Typography>
                  <Space direction="vertical">
                    {visualizacionesDescuento.map((f) => {
                      return (
                        <Radio
                          key={f.id_visualizacion_descuento}
                          value={f.id_visualizacion_descuento}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 15 }}>
                <Typography>Porcentaje Interés: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.porcentaje_interes}
                  onChange={(e) =>
                    onChangeForm("porcentaje_interes", e.target.value)
                  }
                />
              </Row>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
      </Content>
    </Spin>
  );
};
