import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { IoLockClosedOutline } from "react-icons/io5";
import { AiOutlineIdcard } from "react-icons/ai";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useForm } from "../../../hooks/useForm";
import { loginRequest } from "../../../services/api/auth";
import { ROL_USUARIO } from "../../../configs/constants/roles";
import { usuarioEmpresasRequest } from "../../../services/api/empresas";
import { empresaStorageSave } from "../../../helpers/storage/empresa-storage";
import { LoadingOutlined } from "@ant-design/icons";

export const LoginPage = () => {
  const { handleLogin } = useContext(AuthContext);

  const { form, onChangeForm } = useForm({
    identificacion_usuario: "",
    password_usuario: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isError, setIsError] = useState({
    show: false,
    message: "",
  });

  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    setEmpresas([]);
    const identificacion = form.identificacion_usuario.trim();

    const timeout = setTimeout(() => {
      if (identificacion.length >= 5) {
        checkUsuarioEmpresas(identificacion);
      } else {
        setEmpresas([]);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [form.identificacion_usuario]);

  const onLogin = async () => {
    setIsError({
      show: false,
      message: true,
    });

    if (
      form.identificacion_usuario.trim().length == 0 ||
      form.password_usuario.trim().length == 0
    ) {
      setIsError({
        show: true,
        message: "Ingrese la identificacion y la contraseña",
      });

      return;
    }

    const dataRequest = {
      identificacion_usuario: form.identificacion_usuario,
      password_usuario: form.password_usuario,
    };
    setIsLoading(true);
    const data = await loginRequest(dataRequest)
      .then((res) => {
        if (res.token) {
          if (
            res.empresas_usuario?.length === 0 &&
            res.rol?.codigo_rol === ROL_USUARIO.USUARIO
          ) {
            setIsError({
              show: true,
              message:
                "Usted se encuentra registrado pero no tiene acceso a ninguna empresa, contactese con su administrador.",
            });
            return;
          }
          return res;
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode == 401) {
          setIsError({
            show: true,
            message: "Credenciales incorrectas",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    if (!data) return;
    handleLogin(data);
  };

  const checkUsuarioEmpresas = async (id) => {
  
    const dataRequest = {
      identificacion_usuario: id,
    };

    setIsLoadingSearch(true);
    const data = await usuarioEmpresasRequest(dataRequest)
      .finally(() =>
      setIsLoadingSearch(false)
    );

    if (!data) return;

    setEmpresas(
      data.map((e) => ({
        value: e.id_empresa,
        label: `${
          !e.nombre_comercial_empresa
            ? e.razon_social_empresa
            : e.nombre_comercial_empresa
        } - ${e.identificacion_empresa}`,
      }))
    );
  };

  const onChange = (value) => {
    empresaStorageSave({ id_empresa: +value });
  };

  return (
    <Card
      style={{
        width: 350,
        height: 400,
        padding: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography.Title
        level={4}
        style={{
          margin: 0,
          textAlign: "center",
          marginBottom: 40,
        }}
      >
        Bienvenido
      </Typography.Title>

      <Form
        layout="vertical"
        style={{
          padding: 10,
        }}
        onFinish={onLogin}
        disabled={isLoading}
      >
        <Form.Item>
          <Row  gutter={10} align={"middle"}>
            <Col
              span={isLoadingSearch ? 22 : 24}
            >
              <Input
                value={form.identificacion_usuario}
                onChange={(e) =>
                  onChangeForm("identificacion_usuario", e.target.value)
                }
                prefix={<AiOutlineIdcard color="grey" />}
                placeholder="Identificación"
              />
            </Col>
            <Col
              span={isLoadingSearch ? 2 : 0}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 20,
                    }}
                    spin={isLoadingSearch}
                  />
                }
              />
            </Col>
          </Row>
        </Form.Item>
        {empresas.length > 0 && (
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione una empresa"
              optionFilterProp="children"
              onChange={onChange}
              defaultValue={empresas[0].value}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={empresas}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Input.Password
            value={form.password_usuario}
            onChange={(e) => onChangeForm("password_usuario", e.target.value)}
            type="password"
            prefix={<IoLockClosedOutline color="grey" />}
            placeholder="Contraseña"
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" onSubmit={handleLogin}>
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
      {isLoading && (
        <Row justify={"center"}>
          <Spin />
        </Row>
      )}
      {isError.show && <Alert message={isError.message} type="error" />}
    </Card>
  );
};
