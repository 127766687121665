import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { crearCajaRequest } from "../../../../../services/api/cajas";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { parametrosContablesCajasBancosEmpresaRequest } from "../../../../../services/api/parametros-contables-cajas-bancos";

const { Option } = Select;

export const CrearCaja = ({ open, showDrawer, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadCajas } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    descripcion: "",
    activo: true,
    cuenta_contable_caja: null,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    parametrosContablesCajasBancosEmpresaRequest(empresa.id_empresa).then(
      (res) => {
        if (res && res.cuenta_contable_transitoria_cajas) {
          setForm((prev) => ({
            ...prev,
            cuenta_contable_caja: res.cuenta_contable_transitoria_cajas,
          }));
        }
      }
    );
  }, []);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { descripcion, cuenta_contable_caja } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion",
      });
      return false;
    }

    if (!cuenta_contable_caja && !cuenta_contable_caja?.id_cuenta_contable) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      descripcion: form.descripcion,
      activo: form.activo,
      id_empresa: empresa.id_empresa,
      id_cuenta_contable_caja: form.cuenta_contable_caja?.id_cuenta_contable,
    };

    setIsLoading(true);
    crearCajaRequest(dataRequest)
      .then((res) => {
        reloadCajas();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear Caja"
      width={500}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Descripción" required>
            <Input
              placeholder="Descripción"
              value={form.descripcion}
              onChange={(e) => onChangeForm("descripcion", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              style={{ marginTop: 0 }}
              checked={form.activo}
              onChange={(e) => onChangeForm("activo", e.target.checked)}
            >
              Activo
            </Checkbox>
            <CuentaContableInput
              label="C.C Caja"
              value={form.cuenta_contable_caja}
              onChange={(c) => onChangeForm("cuenta_contable_caja", c)}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
