import React, { useContext } from "react";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { eliminarEmpresaRequest, eliminarUsuarioEmpresaRequest } from "../../../../../services/api/empresas";
import { ActionContext } from "../../../../../context/ActionContext";
import { dateDDMMYYYYHHmm } from "../../../../../helpers/format/dates-formater";

export const TablaUsuarios = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData, usuario: usuarioContext } = useContext(UserContext);
  const { reloadUsuarios } = useContext(ActionContext);

  const navigate = useNavigate();

  const navegarUsuarioDetalle = (record) => {
    if (!record.id_usuario) return;
    const id = record.id_usuario;
    navigate(`/administracion/usuarios/${id}`);
  };

  const eliminar = (record) => {
    const id_usuario = record.id_usuario;
    if (!id_usuario) return;

      const message = `¿Esta seguro de eliminar este usuario?`;
      const messageValidate = `¿Esta seguro de eliminar su usuario de la empresa ${empresa.nombre_comercial_empresa}?`;
  
      const confirm = window.confirm(
        id_usuario == usuarioContext.id_usuario
          ? messageValidate
          : message
      );
      if (!confirm) return;
  
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        id_usuario: id_usuario,
      };
  
      eliminarUsuarioEmpresaRequest(dataRequest).then((res) => {
        reloadUsuarios()
        checkData();
      });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MUSUARIOS").leer}
              shape="default"
              icon={<FileTextOutlined />}
              onClick={() => navegarUsuarioDetalle(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              disabled={!checkPermisos("MUSUARIOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Identificación usuario",
      dataIndex: "identificacion_usuario",
      key: "identificacion_usuario",
      width: "20%",
      ...getColumnSearchProps("identificacion_usuario"),
    },
    {
      title: "Nombre usuario",
      dataIndex: "nombre_usuario",
      key: "nombre_usuario",
      width: "20%",
      ...getColumnSearchProps("nombre_usuario"),
    },
    {
      title: "Email",
      dataIndex: "email_usuario",
      key: "email_usuario",
      width: "20%",
    },
    {
      title: "Fecha registro",
      dataIndex: "fecha_registro",
      key: "fecha_registro",
      width: "20%",
      render: (date) => dateDDMMYYYYHHmm(date),
    },
  ];

  return <Table columns={columns} dataSource={data} rowKey={'id_usuario'} size="small" />;
};
