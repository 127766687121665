import API from "../../configs/api/api.config";

const URL_PRODUCTOS = '/productos';

export const productosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const busquedaProductosRequest = async (data) => {
    const request = await API.post(URL_PRODUCTOS + '/buscar', data);
    const response = request.data;
    return response;
}


export const generarCodigoProductoRequest = async (id_empresa) => {
    const request = await API.post(URL_PRODUCTOS + '/generar-codigo', { id_empresa });
    const response = request.data;
    return response;
}

export const generarCodigoBarrasProductoRequest = async () => {
    const request = await API.post(URL_PRODUCTOS + '/generar-codigo-barras');
    const response = request.data;
    return response;
}

export const productoIdRequest = async (id_producto) => {
    const request = await API.get(URL_PRODUCTOS + '/' + id_producto);
    const response = request.data;
    return response;
}

export const crearProductoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTOS, data);
    const response = request.data;
    return response;
}

export const actualizarProductoRequest = async (id_producto, data) => {
    const request = await API.patch(URL_PRODUCTOS + '/' + id_producto, data);
    const response = request.data;
    return response;
}

export const eliminarProductoRequest = async (id_producto) => {
    const request = await API.delete(URL_PRODUCTOS + '/' + id_producto);
    const response = request.data;
    return response;
}

export const subirImagenProductoRequest = async (data) => {
    const request = await API.post(URL_PRODUCTOS + '/subir-imagen', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}