import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { MdFireTruck } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS } from "../../../../configs/constants/estados-documentos-electronicos";
import { TIPOS_DOCUMENTOS } from "../../../../configs/constants/tipos-documentos";
import { TIPOS_IDENTIFICACIONES } from "../../../../configs/constants/tipos-identificaciones";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { CODIGOS_IMPUESTOS } from "../../../../helpers/constants/codigos-impuestos";
import { dateYYYYMMDD } from "../../../../helpers/format/dates-formater";
import { useForm } from "../../../../hooks/useForm";
import { almacenesIdEmpresaRequest } from "../../../../services/api/almacenes";
import {
  actualizarCompraRequest,
  calcularTotalesRequest,
  compraIdRequest,
  eliminarCompraRequest,
  registrarCompraElectronicaRequest,
  registrarGastoComprasRequest,
  registrarProductoComprasRequest,
  registrarProveedorComprasRequest,
} from "../../../../services/api/compras";
import {
  formasPagoSRIRequest,
  sustentoTributarioRequest,
  tarifasIvaRequest,
  tiposDocumentoRequest,
  tiposFormaPagoRequest,
} from "../../../../services/api/opciones";
import {
  secuenciaIdRequest,
  secuenciasIdEmpresaRequest,
} from "../../../../services/api/secuencias";
import { OpcionesCompra, OpcionesDocumento } from "./components/OpcionesCompra";
import { TabMantenimiento } from "./components/TabMantenimiento";
import { TabFormaPago } from "./components/TabFormaPago";
import { cajasIdEmpresaRequest } from "../../../../services/api/cajas";
import { bancosIdEmpresaRequest } from "../../../../services/api/bancos";
import { UNIDAD_TIEMPO } from "../../../../helpers/constants/unidad-tiempo";
import { TagDocumentoElectronico } from "../../ventas/facturacion/components/TagDocumentoElectronico";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const DetalleCompraElectronicaPage = () => {
  const { id } = useParams();

  const {
    isProductosChange,
    reloadProveedores,
    reloadProductos,
    reloadGastos,
  } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();
  const inputPasswordRef = useRef();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    fecha_emision: dayjs(new Date()),
    fecha_vence: dayjs(new Date()),
    fecha_registro: dayjs(new Date()),
    codigo_establecimiento: "",
    codigo_punto_emision: "",
    secuencia: "",
    ats: true,
    concepto: "",
    contabilizado: false,
    id_tipo_documento: null,
    id_secuencia: null,
    id_sustento_tributario: null,
    id_almacen: null,
  });

  const [compra, setCompra] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setEdit(false);
    setReload(!reload);
  };

  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [secuencias, setSecuencias] = useState([]);
  const [sustentosTributarios, setSustentosTributarios] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [ivas, setIvas] = useState([]);

  const [cajas, setCajas] = useState([]);
  const [formasPagosSri, setFormasPagosSri] = useState([]);
  const [tiposFormasPagos, setTiposFormasPagos] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [pagos, setPagos] = useState([]);

  const [totales, setTotales] = useState({
    sub_total: 0,
    descuento_valor: 0,
    sub_total_neto: 0,
    sub_total_iva: 0,
    sub_total_iva_cero: 0,
    sub_total_no_objeto: 0,
    sub_total_exento: 0,
    total_ice: 0,
    total_iva: 0,
    propina: 0,
    iva_presuntivo: 0,
    ir_presuntiva: 0,
    total: 0,
    retencion_iva: 0,
    retencion_renta: 0,
    cantidad_items: 0,
    total_cantidad: 0,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [errorCalculos, setErrorCalculos] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetalles, setIsLoadingDetalles] = useState(false);
  const [isLoadingTotales, setIsLoadingTotales] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    loadInitValues();
  }, [empresa, reload]);

  useEffect(() => {
    if (edit) {
      setForm((prev) => ({
        ...prev,
        concepto: "",
      }));
    }
  }, [edit]);

  const loadInitValues = () => {
    setIsLoading(true);
    Promise.all([
      compraIdRequest(id),
      tiposDocumentoRequest(),
      secuenciasIdEmpresaRequest(empresa.id_empresa),
      sustentoTributarioRequest(),
      almacenesIdEmpresaRequest(empresa.id_empresa),
      formasPagoSRIRequest(),
      cajasIdEmpresaRequest(empresa.id_empresa),
      tiposFormaPagoRequest(),
      bancosIdEmpresaRequest(empresa.id_empresa),
      tarifasIvaRequest(),
    ])
      .then(
        ([
          compraRes,
          tiposDocumentosRes,
          secuenciasRes,
          sustentosTributariosRes,
          almacenesRes,
          formasPagoSRIRes,
          cajasRes,
          tiposFormasPagosRes,
          bancosRes,
          tarifasIvaRes,
        ]) => {
          setCompra(compraRes);

          const proveedor = !compraRes.proveedor
            ? {
                identificacion: compraRes.ruc_emisor,
                razon_social: compraRes.razon_social_emisor,
                nombre_comercial: compraRes.nombre_comercial_emisor,
                lleva_contabilidad: compraRes.obligado_contabilidad === "SI",
                constribuyente_especial: !!compraRes.contribuyente_especial,
              }
            : compraRes.proveedor;

          setForm((prev) => ({
            ...prev,
            ...compraRes,
            fecha_emision: dayjs(compraRes.fecha_emision),
            fecha_vence: dayjs(compraRes.fecha_vence),
            fecha_registro: dayjs(compraRes.fecha_registro),
            codigo_establecimiento: compraRes.codigo_establecimiento,
            codigo_punto_emision: compraRes.codigo_punto_emision,
            secuencia: Number(compraRes.secuencia_text),
            ats: compraRes.ats,
            concepto: compraRes.concepto,
            contabilizado: compraRes.contabilizado,
            id_tipo_documento: compraRes.tipo_documento?.id_tipo_documento,
            id_secuencia: compraRes.secuencia?.id_secuencia,
            id_sustento_tributario:
              compraRes.sustento_tributario?.id_sustento_tributario,
            id_almacen: compraRes.almacen?.id_almacen,
            proveedor: proveedor,
          }));

          setTotales({
            sub_total: compraRes.sub_total,
            descuento_valor: compraRes.descuento_valor,
            sub_total_neto: compraRes.sub_total_neto,
            sub_total_iva: compraRes.sub_total_iva,
            sub_total_iva_cero: compraRes.sub_total_iva_cero,
            sub_total_no_objeto: compraRes.sub_total_no_objeto,
            sub_total_exento: compraRes.sub_total_exento,
            total_ice: compraRes.total_ice,
            total_iva: compraRes.total_iva,
            propina: compraRes.propina,
            iva_presuntivo: compraRes.iva_presuntivo,
            ir_presuntiva: compraRes.ir_presuntiva,
            total: compraRes.total,
            retencion_iva: compraRes.retencion_iva,
            retencion_renta: compraRes.retencion_renta,
            cantidad_items: compraRes.cantidad_items,
            total_cantidad: compraRes.total_cantidad,
          });

          const detalles = compraRes.detalles_compras.map((detalle, index) => {
            let iva = 0;
            let iva_producto = {};
            if (detalle.id_producto) {
              iva = detalle.producto?.iva_producto?.descripcion;
              iva_producto = detalle.producto?.iva_producto;
            }

            if (detalle.id_gasto) {
              iva = detalle.gasto?.iva?.descripcion;
              iva_producto = detalle.gasto?.iva;
            }
            return {
              ...detalle,
              subsidio: detalle.valor_subsidio,
              precio_unitario: detalle.costo,
              descuento: detalle.descuento_valor,
              iva: iva,
              iva_producto: iva_producto,
              index: index,
            };
          });

          setProductos(detalles);

          const pagosCompra = compraRes.pagos_compras.map((pago, index) => {
            return {
              ...pago,
              fecha: pago.fecha ? dayjs(pago.fecha) : null,
              fecha_vence: pago.fecha_vence ? dayjs(pago.fecha_vence) : null,
              index: index,
              id_tipo_forma_pago: pago.tipo_forma_pago?.id_tipo_forma_pago,
              id_forma_pago_sri: pago.forma_pago_sri?.id_forma_pago_sri,
              id_banco: pago.banco?.id_banco,
              id_caja: pago.caja?.id_caja,
            };
          });

          setPagos(pagosCompra);

          setTiposDocumentos(tiposDocumentosRes);
          setSecuencias(secuenciasRes);
          setSustentosTributarios(sustentosTributariosRes);
          setAlmacenes(almacenesRes);
          setFormasPagosSri(formasPagoSRIRes);
          setCajas(cajasRes);
          setTiposFormasPagos(tiposFormasPagosRes);
          setBancos(bancosRes);
          setIvas(tarifasIvaRes);

          if (
            !compraRes.sustento_tributario &&
            sustentosTributariosRes?.length > 0
          ) {
            const findSustentoTributario = sustentosTributariosRes.find(
              (sustentoTributario) =>
                sustentoTributario.id_sustento_tributario === 2
            );
            if (!findSustentoTributario) return;
            setForm((prev) => ({
              ...prev,
              id_sustento_tributario:
                findSustentoTributario.id_sustento_tributario,
            }));
          }

          if (!compraRes.almacen && almacenesRes?.length > 0) {
            const findAlmacen = almacenesRes.find(
              (almacen) => almacen.predeterminado
            );
            if (!findAlmacen) return;
            setForm((prev) => ({
              ...prev,
              id_almacen: findAlmacen.id_almacen,
            }));
          }
        }
      )
      .finally(() => setIsLoading(false));
  };

  const seleccionarProducto = (p) => {
    const index = p.index;
    setProductoSeleccionado(index);
  };

  const eliminarProducto = (p) => {
    const temporal = productos.filter((producto, index) => index !== p.index);
    calcularTotales(
      temporal,
      totales.propina,
      totales.iva_presuntivo,
      totales.ir_presuntiva,
      totales.retencion_iva,
      totales.retencion_renta
    );
    setProductoSeleccionado(null);
  };

  const agregarProducto = (p) => {
    if (p.id_producto) {
      const find = productos.find(
        (producto) => producto.id_producto === p.id_producto
      );
      if (find) return;

      const index = productos.length;

      const detalles = [
        ...productos,
        {
          ...p,
          codigo: p.codigo,
          descripcion: p.descripcion,
          cantidad: 1,
          descuento: 0,
          precio_unitario:
            p.tarifas_productos?.find(
              (tp) => tp.medida === "interna" && tp.tarifa === "Precio 1"
            )?.precio ?? 0,
          iva: p.iva_producto?.descripcion,
          index: index,
        },
      ];

      calcularTotales(
        detalles,
        totales.propina,
        totales.iva_presuntivo,
        totales.ir_presuntiva,
        totales.retencion_iva,
        totales.retencion_renta
      );
    }

    if (p.id_gasto) {
      const index = productos.length;

      const detalles = [
        ...productos,
        {
          ...p,
          codigo: p.cuenta_contable_gasto?.codigo_contable,
          descripcion: p.cuenta_contable_gasto?.nombre_cuenta_contable,
          cantidad: 1,
          descuento: 0,
          precio_unitario: 0,
          iva: p.iva?.descripcion,
          iva_producto: p.iva,
          subsidio: 0,
          index: index,
        },
      ];

      calcularTotales(
        detalles,
        totales.propina,
        totales.iva_presuntivo,
        totales.ir_presuntiva,
        totales.retencion_iva,
        totales.retencion_renta
      );
    }
  };

  const validarCampos = () => {
    const {
      fecha_emision,
      fecha_vence,
      fecha_registro,
      proveedor,
      secuencia,
      codigo_establecimiento,
      codigo_punto_emision,
    } = form;

    if (!dayjs(fecha_emision).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha de Emision",
      });
      return false;
    }

    if (!dayjs(fecha_vence).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!dayjs(fecha_registro).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!proveedor?.id_proveedor) {
      setError({
        show: true,
        message: "Seleccione un Proveedor",
      });
      return false;
    }

    if (
      !Number.isInteger(Number(codigo_establecimiento)) ||
      Number(codigo_establecimiento) <= 0
    ) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (
      !Number.isInteger(Number(codigo_punto_emision)) ||
      Number(codigo_punto_emision) <= 0
    ) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (!Number.isInteger(Number(secuencia)) || Number(secuencia) <= 0) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (productos.length === 0) {
      setError({
        show: true,
        message: "Agrege Detalles a la Compra",
      });
      return false;
    }

    if (productos.some((producto) => !producto.gasto && !producto.producto)) {
      setError({
        show: true,
        message: "Agrege un Producto o Gasto a los Detalles",
      });
      return false;
    }

    return true;
  };

  const guardar = async () => {
    setError({ show: false, message: "" });

    const validacion = validarCampos();
    if (!validacion) return;

    const pagos_compras = pagos.map((pago) => {
      const fecha = pago.fecha ? pago.fecha : dayjs(new Date());
      const fecha_vence = pago.fecha_vence
        ? pago.fecha_vence
        : dayjs(new Date());
      const plazo = dayjs(form.fecha_vence).diff(form.fecha_emision, "day");

      return {
        plazo: plazo,
        unidad_tiempo: UNIDAD_TIEMPO.DIAS,
        total: Number(pago.total),
        fecha: dateYYYYMMDD(fecha),
        fecha_vence: dateYYYYMMDD(fecha_vence),
        numero_documento: pago.numero_documento,
        id_forma_pago_sri: pago.id_forma_pago_sri,
        id_tipo_forma_pago: pago.id_tipo_forma_pago,
        id_caja: pago.id_caja,
        id_banco: pago.id_banco,
      };
    });

    const dataRequest = {
      fecha_vence: dateYYYYMMDD(form.fecha_vence),
      fecha_registro: dateYYYYMMDD(form.fecha_registro),
      id_compra: compra.id_compra,
      ats: form.ats,
      concepto: form.concepto,
      contabilizado: form.contabilizado,
      total: Number(form.total),
      retencion_iva: Number(form.retencion_iva),
      retencion_renta: Number(form.retencion_renta),
      id_almacen: form.id_almacen,
      id_sustento_tributario: form.id_sustento_tributario,
      detalles_compras: [],
      pagos_compras: pagos_compras,
    };

    setIsLoading(true);
    await registrarCompraElectronicaRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../compras/${res.id_compra}`, { replace: true });
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeDetalles = (data) => {
    const detalles = productos.map((producto, index) => {
      if (data.index === index) {
        return data;
      }
      return producto;
    });

    calcularTotales(
      detalles,
      totales.propina,
      totales.iva_presuntivo,
      totales.ir_presuntiva,
      totales.retencion_iva,
      totales.retencion_renta
    );
  };

  const onChangeTotales = (data) => {
    calcularTotales(
      productos,
      data.propina,
      data.iva_presuntivo,
      data.ir_presuntiva,
      data.retencion_iva,
      data.retencion_renta
    );
  };

  const calcularTotales = (
    p,
    propina,
    iva_presuntivo,
    ir_presuntiva,
    retencion_iva,
    retencion_renta
  ) => {
    setErrorCalculos({
      show: false,
      message: "",
    });

    const dataRequest = {
      detalles_documentos: p.map((producto) => {
        return {
          cantidad: Number(producto.cantidad),
          precio_unitario: Number(producto.precio_unitario),
          descuento: Number(producto.descuento),
          subsidio: Number(producto.subsidio),
          codigo_porcentaje_iva: Number(producto.iva_producto.codigo),
          tarifa_iva: Number(producto.iva_producto.valor),
          tarifa_ice: !producto.porcentaje_ice
            ? null
            : Number(producto.porcentaje_ice),
          tarifa_irbpnr: !producto.porcentaje_irbpnr
            ? null
            : Number(producto.porcentaje_irbpnr),
        };
      }),
      iva_presuntivo: Number(iva_presuntivo),
      ir_presuntiva: Number(ir_presuntiva),
      propina: Number(propina),
      retencion_iva: Number(retencion_iva),
      retencion_renta: Number(retencion_renta),
    };

    setIsLoadingDetalles(true);
    setIsLoadingTotales(true);
    calcularTotalesRequest(dataRequest)
      .then((res) => {
        const detallesRes = res.detalles_documentos.map((d, index) => ({
          ...p[index],
          ...d,
          index: index,
        }));

        setProductos(detallesRes);
        const totalesRes = {
          sub_total: res.sub_total,
          descuento_valor: res.descuento_valor,
          sub_total_neto: res.sub_total_neto,
          sub_total_iva: res.sub_total_iva,
          sub_total_iva_cero: res.sub_total_iva_cero,
          sub_total_no_objeto: res.sub_total_no_objeto,
          sub_total_exento: res.sub_total_exento,
          total_ice: res.total_ice,
          total_iva: res.total_iva,
          propina: res.propina,
          iva_presuntivo: res.iva_presuntivo,
          ir_presuntiva: res.ir_presuntiva,
          total: res.total,
          retencion_iva: res.retencion_iva,
          retencion_renta: res.retencion_renta,
          cantidad_items: res.cantidad_items,
          total_cantidad: res.total_cantidad,
        };
        setTotales(totalesRes);
      })
      .catch((err) => {
        const data = err?.response?.data;
        if (data && data.statusCode === 400) {
          if (data.message.includes("propina")) {
            setTotales({
              ...totales,
              propina: 0,
            });
          }
          setErrorCalculos({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingDetalles(false);
        setIsLoadingTotales(false);
      });
  };

  const onChangeDetalle = (d) => {
    const index = productos.findIndex((e) => e.id_producto === d.id_producto);
    const arrayTemp = productos.map((e, i) => {
      if (index === i) return { ...e, ...d };
      return e;
    });

    setProductos(arrayTemp);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const id_compra = compra.id_compra;
    if (!id_compra) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta Compra, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCompraRequest(id_compra).then((res) => {
      navigate(-1);
    });
  };

  const registrarPago = (pago) => {
    if (totales.total === 0) {
      alert("El total es 0 no se puede agregar un pago");
      return;
    }

    const tipo_forma_pago = tiposFormasPagos.find(
      (tipoFormaPago) =>
        tipoFormaPago.id_tipo_forma_pago === pago.id_tipo_forma_pago
    );

    const banco = bancos.find((banco) => banco.id_banco === pago.id_banco);

    const caja = cajas.find((caja) => caja.id_caja === pago.id_caja);

    const forma_pago_sri = formasPagosSri.find(
      (formaPagoSri) =>
        formaPagoSri.id_forma_pago_sri === pago.id_forma_pago_sri
    );

    const index = pagos.length;

    const nuevoPago = {
      ...pago,
      index: index,
      tipo_forma_pago: tipo_forma_pago,
      caja: caja,
      banco: banco,
      forma_pago_sri: forma_pago_sri,
    };

    setPagos((prev) => [...prev, nuevoPago]);
  };

  const eliminarPago = (pagoData) => {
    const pagosLista = pagos
      .filter((_, index) => index !== pagoData.index)
      .map((pago, index) => ({ ...pago, index: index }));
    setPagos(pagosLista);
  };

  const editarPago = (pagoData) => {
    const pagosLista = pagos.map((pago, index) => {
      if (pagoData.index === index) {
        return {
          ...pago,
          ...pagoData,
        };
      }
      return pago;
    });
    setPagos(pagosLista);
  };

  const registrarProveedorCompras = (id_proveedor) => {
    const dataRequest = {
      id_empresa: empresa.id_empresa,
      id_proveedor: id_proveedor,
    };
    setIsLoading(true);
    registrarProveedorComprasRequest(dataRequest)
      .then((res) => {
        reloadProveedores();
        handleReload();
        setEdit(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCreateProveedor = (proveedor) => {
    const id_proveedor = proveedor.id_proveedor;
    if (!id_proveedor) return;
    registrarProveedorCompras(id_proveedor);
  };

  const registrarProductoCompras = (
    id_producto,
    id_detalle_compra,
    producto
  ) => {
    const detalle_compra = productos.find(
      (producto) => producto.id_detalle_compra === id_detalle_compra
    );

    const detalleCompraCodigo = detalle_compra.codigo;
    const proveedorInfo = `${compra.ruc_emisor} - ${compra.razon_social_emisor}`;
    const productoInfo = `${producto.codigo} - ${producto.descripcion}`;

    const vincularMensaje = `¿Desea vincular automáticamente los detalles de compra con el código ${detalleCompraCodigo} de las compras realizadas al proveedor ${proveedorInfo} con el Producto ${productoInfo}?
\nSi elige la opción CANCELAR, únicamente se vinculará a este detalle el producto.`;

    const confirmacionVincular = window.confirm(vincularMensaje);

    const dataRequest = {
      id_producto: id_producto,
      id_detalle_compra: id_detalle_compra,
      vincular: confirmacionVincular,
    };

    setIsLoading(true);
    registrarProductoComprasRequest(dataRequest)
      .then((res) => {
        reloadProductos();
        handleReload();
        setEdit(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const registrarGastoCompras = (id_gasto, id_detalle_compra, gasto) => {
    const detalle_compra = productos.find(
      (producto) => producto.id_detalle_compra === id_detalle_compra
    );

    const detalleCompraCodigo = detalle_compra.codigo;
    const proveedorInfo = `${compra.ruc_emisor} - ${compra.razon_social_emisor}`;
    const gastoInfo = `${gasto.cuenta_contable_gasto?.codigo_contable} - ${gasto.cuenta_contable_gasto?.nombre_cuenta_contable}`;

    const vincularMensaje = `¿Desea vincular automáticamente los detalles de compra con el código ${detalleCompraCodigo} de las compras realizadas al proveedor ${proveedorInfo} con el Gasto ${gastoInfo}?
\nSi elige la opción CANCELAR, únicamente se vinculará a este detalle el gasto.`;

    const confirmacionVincular = window.confirm(vincularMensaje);

    const dataRequest = {
      id_gasto: id_gasto,
      id_detalle_compra: id_detalle_compra,
      vincular: confirmacionVincular,
    };

    setIsLoading(true);
    registrarGastoComprasRequest(dataRequest)
      .then((res) => {
        reloadGastos();
        handleReload();
        setEdit(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCreateProducto = (producto) => {
    const id_producto = producto?.id_producto;
    if (!id_producto) return;
    const temp = productos.map((p, index) => {
      if (index === productoSeleccionado) {
        return {
          ...p,
          producto: producto,
          id_producto: id_producto,
        };
      }
      return p;
    });
    setProductos(temp);
    registrarProductoCompras(
      id_producto,
      productos[productoSeleccionado]?.id_detalle_compra,
      producto
    );
  };

  const onLink = (producto) => {
    const id_detalle_compra =
      productos[productoSeleccionado]?.id_detalle_compra;
    if (!id_detalle_compra) return;
    if (producto.id_producto) {
      registrarProductoCompras(
        producto.id_producto,
        id_detalle_compra,
        producto
      );
    }
    if (producto.id_gasto) {
      registrarGastoCompras(producto.id_gasto, id_detalle_compra, producto);
    }
  };

  const items = [
    {
      key: 1,
      label: `Mantenimiento`,
      children: (
        <TabMantenimiento
          edit={!edit}
          form={form}
          onChangeForm={onChangeForm}
          tiposDocumentos={tiposDocumentos}
          secuencias={secuencias}
          sustentosTributarios={sustentosTributarios}
          almacenes={almacenes}
          productos={productos}
          ivas={ivas}
          productoSeleccionado={productos[productoSeleccionado]}
          seleccionarProducto={seleccionarProducto}
          eliminarProducto={eliminarProducto}
          agregarProducto={agregarProducto}
          totales={totales}
          setTotales={setTotales}
          onChange={onChangeDetalles}
          isLoadingDetalles={isLoadingDetalles}
          isLoadingTotales={isLoadingTotales}
          onChangeTotales={onChangeTotales}
          errorCalculos={errorCalculos}
          setErrorCalculos={setErrorCalculos}
          onChangeDetalle={onChangeDetalle}
          onCreateProveedor={onCreateProveedor}
          onCreateProducto={onCreateProducto}
          createProveedor={true}
          createProducto={true}
          electronico={true}
          onLink={onLink}
        />
      ),
    },
    {
      key: 2,
      label: `Forma de cobro`,
      children: (
        <TabFormaPago
          pagos={pagos}
          totales={totales}
          formasPagosSri={formasPagosSri}
          cajas={cajas}
          bancos={bancos}
          registrarPago={registrarPago}
          eliminarPago={eliminarPago}
          editarPago={editarPago}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Compras Electrónicas",
                },
                {
                  title: (
                    <Row gutter={10}>
                      <Col>
                        <Typography>{compra?.numero_documento}</Typography>
                      </Col>
                      <Col>
                        {compra.electronico && (
                          <TagDocumentoElectronico
                            estado={compra.estado_documento_electronico}
                          />
                        )}
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCOMPRAS").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCOMPRAS").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        {compra.codigo_error_sri && compra.error_sri && (
          <Alert
            message={
              <Typography style={{ fontSize: 12 }}>
                {
                  ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS[
                    compra.estado_documento_electronico
                  ]
                }
              </Typography>
            }
            description={`(${compra.codigo_error_sri}) ${compra.error_sri}`}
            type="error"
            // showIcon

            style={{ padding: 5, fontSize: 12 }}
          />
        )}
        <Form disabled={!edit}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
