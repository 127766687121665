import { createContext, useEffect, useState } from "react";
import {
  authStorageGet,
  authStorageRemove,
  authStorageSave,
} from "../helpers/storage/auth-storage";
import { validarRequest } from "../services/api/auth";
import { Alert, Spin, message } from "antd";
import { ROL_USUARIO } from "../configs/constants/roles";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChekingAuth, setIscheckingAuth] = useState(true);
  const [usuario, setUsuario] = useState({});

  useEffect(() => {
    checkAuth();
  }, []);

  const handleLogin = (data) => {
    const { token, ...other } = data;
    setUsuario({
      ...other,
    });
    authStorageSave({
      ...data,
    });
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    authStorageRemove();
    setIsAuthenticated(false);
    setUsuario({});
  };

  const checkAuth = async () => {
    setIscheckingAuth(true);
    const authStorage = authStorageGet();
    if (authStorage === null) {
      setIsAuthenticated(false);
      setIscheckingAuth(false);
      setUsuario({});
      authStorageRemove();
      return;
    }

    if (!authStorage.token) {
      setIsAuthenticated(false);
      setIscheckingAuth(false);
      setUsuario({});
      authStorageRemove();
      return;
    }
    validarRequest({ token: authStorage.token })
      .then((res) => {
        if (res.token) {
          const { token, ...other } = res;
          if (
            other.empresas_usuario?.length === 0 &&
            other.rol?.codigo_rol === ROL_USUARIO.USUARIO
          ) {
            setIsAuthenticated(false);
            setUsuario({});
            authStorageRemove();
            return;
          }
          setUsuario({
            ...other,
          });
          authStorageSave({
            ...res,
          });
          setIsAuthenticated(true);
        }
      })
      .catch((err) => {
        setIsAuthenticated(false);
        setUsuario({});
        authStorageRemove();
      })
      .finally(() => {
        setIscheckingAuth(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        usuario,

        isChekingAuth,
        isAuthenticated,

        handleLogin,
        handleLogout,
        checkAuth,
      }}
    >
      {children}
      {isChekingAuth && (
        <div
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            minHeight: "100%",
            minWidth: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </AuthContext.Provider>
  );
};
