import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarProductoRequest } from "../../../../../services/api/productos";
import { eliminarDocumentoRequest } from "../../../../../services/api/facturacion";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { NAVEGACION_FACTURACION } from "../../../../../configs/constants/navegacion";
import { TagDocumentoElectronico } from "./TagDocumentoElectronico";
import { CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";

export const TablaFacturacion = ({ data = [] }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadFacturacion } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_documento = record.id_documento;
    if (!id_documento) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este documento, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarDocumentoRequest(id_documento).then((res) => {
      reloadFacturacion();
    });
  };

  const detalleDocumento = (data) => {
    const id_documento = data?.id_documento;
    if (!id_documento) return;
    if (data.tipo_documento.codigo === "01") {
      navigate(`./${NAVEGACION_FACTURACION.FACTURAS}/${id_documento}`);
    }
    if (data.tipo_documento.codigo === "04") {
      navigate(`./${NAVEGACION_FACTURACION.NOTAS_CREDITOS}/${id_documento}`);
    }
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "6%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MFACTURACION-TRANSPORTISTA").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleDocumento(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MFACTURACION-TRANSPORTISTA").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Emision",
      dataIndex: "fecha_emision",
      key: "fecha_emision",
      width: "8%",
    },
    {
      title: "N. Documento",
      dataIndex: "numero_documento",
      key: "numero_documento",
      width: "13%",
    },
    {
      title: "Tipo Documento",
      dataIndex: "tipo_documento",
      key: "tipo_documento",
      width: "22%",
      render: (record, data) => (
        <Row gutter={5}>
          <Col>
            <Typography>{record?.descripcion}</Typography>
          </Col>
          <Col>
            {(data?.electronico ||
              data?.estado_documento_electronico ===
                CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO) && (
              <TagDocumentoElectronico
                estado={data?.estado_documento_electronico}
              />
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "razon_social_cliente",
      key: "razon_social_cliente",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "10%",
      render: (record) => numberFormat(record),
    },
    {
      title: "N. Retención",
      dataIndex: "retencion",
      key: "retencion",
      width: "13%",
      render: (record) => record?.numero_documento,
    },
  ];

  return (
    <Table
      scroll={{
        x: 1200,
        y: 390,
      }}
      rowKey={"id_documento"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
