import API from "../../configs/api/api.config";

const URL_CLIENTES = '/clientes';

export const clientesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_CLIENTES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const clientePredeterminadoRequest = async (id_empresa) => {
    const request = await API.post(URL_CLIENTES + '/default', { id_empresa });
    const response = request.data;
    return response;
}

export const generarCodigoClienteRequest = async (id_empresa) => {
    const request = await API.post(URL_CLIENTES + '/generar-codigo', { id_empresa });
    const response = request.data;
    return response;
}

export const clienteIdRequest = async (id_cliente) => {
    const request = await API.get(URL_CLIENTES + '/' + id_cliente);
    const response = request.data;
    return response;
}

export const crearClienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTES, data);
    const response = request.data;
    return response;
}

export const busquedaClientesRequest = async (data) => {
    const request = await API.post(URL_CLIENTES + '/buscar', data);
    const response = request.data;
    return response;
}


export const actualizarClienteRequest = async (id_cliente, data) => {
    const request = await API.patch(URL_CLIENTES + '/' + id_cliente, data);
    const response = request.data;
    return response;
}

export const eliminarClienteRequest = async (id_cliente) => {
    const request = await API.delete(URL_CLIENTES + '/' + id_cliente);
    const response = request.data;
    return response;
}

export const subirImagenClienteRequest = async (data) => {
    const request = await API.post(URL_CLIENTES + '/subir-imagen', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}