import API from "../../configs/api/api.config";

const URL_FACTURACION = '/facturacion';

export const facturacionIdEmpresaRequest = async (id_empresa, pagination) => {
    const request = await API.get(`${URL_FACTURACION}/empresa/${id_empresa}`, { params: pagination });
    const response = request.data;
    return response;
}

export const facturacionIdEmpresaDatesRequest = async (id_empresa, dates, pagination) => {
    const request = await API.get(`${URL_FACTURACION}/empresa/${id_empresa}/start/${dates.start}/end/${dates.end}`, { params: pagination });
    const response = request.data;
    return response;
}

export const facturacionIdEmpresaSearchRequest = async (id_empresa, search, pagination) => {
    const request = await API.get(`${URL_FACTURACION}/empresa/${id_empresa}/search/${search}`, { params: pagination });
    const response = request.data;
    return response;
}

export const documentoIdRequest = async (id_documento) => {
    const request = await API.get(URL_FACTURACION + '/' + id_documento);
    const response = request.data;
    return response;
}

export const crearFacturaRequest = async (data) => {
    const request = await API.post(URL_FACTURACION + '/factura', data);
    const response = request.data;
    return response;
}

export const editarFacturaRequest = async (id_documento, data) => {
    const request = await API.patch(URL_FACTURACION + '/factura' + '/' + id_documento, data);
    const response = request.data;
    return response;
}

export const crearNotaCreditoRequest = async (data) => {
    const request = await API.post(URL_FACTURACION + '/nota-credito', data);
    const response = request.data;
    return response;
}

export const editarNotaCreditoRequest = async (id_documento, data) => {
    const request = await API.patch(URL_FACTURACION + '/nota-credito' + '/' + id_documento, data);
    const response = request.data;
    return response;
}


export const calcularTotalesFacturaRequest = async (data) => {
    const request = await API.post(URL_FACTURACION + '/factura' + '/calcular-totales', data);
    const response = request.data;
    return response;
}

export const eliminarDocumentoRequest = async (id_documento) => {
    const request = await API.delete(URL_FACTURACION + '/' + id_documento);
    const response = request.data;
    return response;
}

export const autorizarDocumentoRequest = async (id_documento) => {
    const request = await API.post(URL_FACTURACION + '/autorizar' + '/' + id_documento);
    const response = request.data;
    return response;
}

export const anularDocumentoRequest = async (id_documento) => {
    const request = await API.post(URL_FACTURACION + '/anular' + '/' + id_documento);
    const response = request.data;
    return response;
}

export const pdfDocumentoRequest = async (id_documento) => {
    const request = await API.post(URL_FACTURACION + '/pdf' + '/' + id_documento, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const xmlDocumentoRequest = async (id_documento) => {
    const request = await API.post(URL_FACTURACION + '/xml' + '/' + id_documento, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const sendEmailDocumentoRequest = async (id_documento) => {
    const request = await API.post(URL_FACTURACION + '/email' + '/' + id_documento);
    const response = request.data;
    return response;
}

export const documentosIdEmpresaIdentificacionTransportista = async (id_empresa, identificacion_transportista) => {
    const request = await API.get(URL_FACTURACION + `/empresa/${id_empresa}/transportista/${identificacion_transportista}`);
    const response = request.data;
    return response;
}