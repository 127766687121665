export const CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS = {
    ANULADO: 0,
    CREADO: 1,
    DEVUELTO: 2,
    AUTORIZADO: 3,
    EN_PROCESO: 4,
    RECHAZADO: 5,
    NO_AUTORIZADO: 6,
}

export const ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS = {
    0: "ANULADO",
    1: "CREADO",
    2: "DEVUELTO",
    3: "AUTORIZADO",
    4: "EN PROCESO",
    5: "RECHAZADO",
    6: "NO AUTORIZADO",
}