import API from "../../configs/api/api.config";

const URL_MEDIDAS = '/medidas-productos';

export const medidasIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_MEDIDAS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const medidaIdRequest = async (id_medida_producto) => {
    const request = await API.get(URL_MEDIDAS + '/' + id_medida_producto);
    const response = request.data;
    return response;
}

export const crearMedidaRequest = async (data) => {
    const request = await API.post(URL_MEDIDAS, data);
    const response = request.data;
    return response;
}

export const actualizarMedidaRequest = async (id_medida_producto, data) => {
    const request = await API.patch(URL_MEDIDAS + '/' + id_medida_producto, data);
    const response = request.data;
    return response;
}

export const eliminarMedidaRequest = async (id_medida_producto) => {
    const request = await API.delete(URL_MEDIDAS + '/' + id_medida_producto);
    const response = request.data;
    return response;
}