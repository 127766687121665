import { Table } from "antd";
import React from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";

export const TablaRetencion = ({ data, codigosRetenciones = [] }) => {
  const columns = [
    {
      title: "Base",
      dataIndex: "base_imponible",
      key: "base_imponible",
      width: "20%",
      render: (record) => numberFormat(record),
    },
    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
      width: "10%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Retencion",
      dataIndex: "codigo",
      key: "codigo",
      width: "40%",
      render: (record) =>
        codigosRetenciones.find(
          (codigoRetencion) => codigoRetencion.codigo.split("-")[0] === record
        )?.descripcion,
    },
    {
      title: "%",
      dataIndex: "porcentaje_retener",
      key: "porcentaje_retener",
      width: "10%",
      render: (record) => numberFormat(record),
    },
    {
      title: "Valor",
      dataIndex: "valor_retenido",
      key: "valor_retenido",
      width: "20%",
      render: (record) => numberFormat(record),
    },
  ];
  return (
    <Table
      scroll={{
        y: 150,
      }}
      size="small"
      bordered
      columns={columns}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
      }}
      style={{ marginTop: 5 }}
    />
  );
};
