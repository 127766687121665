import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { IoAdd, IoTrash } from "react-icons/io5";
import { BsLink45Deg } from "react-icons/bs";
import { ModalProductoGasto } from "./ModalProductoGasto";
import { ModalCrearProducto } from "../../productos/components/ModalCrearProducto";

export const ProductoGastoInput = ({
  label = "",
  value,
  onChange = (p) => {},
  spanLabel = null,
  spanInput = null,
  spanText = null,
  customGutter = null,
  spanSearch = null,
  spanDelete = null,
  eliminarProducto = (p) => {},
  disabled = false,
  onCreate = () => {},
  create = false,
  onLink = () => {},
  link = false,
  proveedor = null,
}) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const registrarProducto = () => {
    setIsModalCreateOpen(true);
  };

  const vincularProducto = () => {
    setIsModalLinkOpen(true);
  };

  const quitarProductoGasto = () => {
    onChange(null);
  };

  return (
    <Row
      gutter={customGutter ?? 10}
      justify={"flex-start"}
      align={"middle"}
      style={{
        marginTop: 5,
        backgroundColor:
          value?.id_gasto ||
          value?.id_producto ||
          value?.producto?.id_producto ||
          value?.producto?.id_producto ||
          disabled ||
          !value ||
          !create
            ? "transparent"
            : "rgba(255,0,0,0.2)",
      }}
    >
      <Col span={spanLabel ?? 5}>
        <Typography>{label}</Typography>
      </Col>
      <Col span={spanInput ?? 5}>
        <Input size="small" value={value?.codigo} disabled />
      </Col>
      <Col span={spanSearch ?? 2}>
        <Button
          type="primary"
          size="small"
          icon={<SearchOutlined />}
          onClick={showModal}
          disabled={disabled}
        />
      </Col>
      <Col span={spanText ?? 6}>
        <Typography>{value?.descripcion}</Typography>
      </Col>
      {value != null && (
        <Col span={spanDelete ?? 6}>
          <Row justify={"end"} gutter={5}>
            {!value?.producto?.id_producto && !value?.gasto?.id_gasto && (
              <Col>
                <Tooltip title={"Registrar el producto"}>
                  <Button
                    onClick={registrarProducto}
                    type="primary"
                    size="small"
                  >
                    <IoAdd size={18} />
                  </Button>
                </Tooltip>
              </Col>
            )}
            {link && (
              <Col>
                <Tooltip title={"Vincular a Producto o Gasto"}>
                  <Button
                    onClick={vincularProducto}
                    type="primary"
                    size="small"
                  >
                    <BsLink45Deg size={18} />
                  </Button>
                </Tooltip>
              </Col>
            )}
            <Col>
              <Button
                onClick={() => eliminarProducto(value)}
                danger
                type="text"
                size="small"
                disabled={disabled}
              >
                <IoTrash size={18} />
              </Button>
            </Col>
          </Row>
        </Col>
      )}
      <ModalProductoGasto
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        onSelect={onChange}
      />
      {value?.id_detalle_compra &&
        value &&
        !value?.id_producto &&
        !value?.id_gasto && (
          <ModalCrearProducto
            isOpen={isModalCreateOpen}
            close={() => setIsModalCreateOpen(false)}
            id={value.id_detalle_compra}
            codigo={value.codigo}
            descripcion={value.descripcion}
            costo={value.costo}
            codigo_porcentaje_iva={value.codigo_porcentaje_iva}
            proveedor={proveedor}
            onCreate={onCreate}
          />
        )}
      {link && (
        <ModalProductoGasto
          isModalOpen={isModalLinkOpen}
          handleOk={() => setIsModalLinkOpen(false)}
          handleCancel={() => setIsModalLinkOpen(false)}
          value={value}
          selected={value?.producto ? value?.producto : value?.gasto}
          onSelect={onLink}
          link={link}
        />
      )}
    </Row>
  );
};
