import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { MdFireTruck } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import { categoriasProductosIdEmpresaRequest } from "../../../../services/api/categorias-productos";
import { lineasProductosIdEmpresaRequest } from "../../../../services/api/lineas-productos";
import { medidasIdEmpresaRequest } from "../../../../services/api/medidas";
import {
  cantonesRequest,
  estadosCivilRequest,
  origenesIngresosRequest,
  parroquiasRequest,
  provinciasRequest,
  sexosRequest,
  tarifasIvaRequest,
  tarifasRequest,
  tipoRegimenRequest,
  tiposCompraRequest,
  tiposEcommerceProductoRequest,
  tiposEntidadRequest,
  tiposIdentificacionRequest,
} from "../../../../services/api/opciones";
import {
  actualizarProductoRequest,
  eliminarProductoRequest,
  productoIdRequest,
} from "../../../../services/api/productos";
import { subCategoriasProductosIdEmpresaRequest } from "../../../../services/api/sub-categorias-productos";
import { subGruposProductosIdEmpresaRequest } from "../../../../services/api/sub-grupos-productos";
import { calcularTarifaRequest } from "../../../../services/api/tarifas-productos";
import { TabDatos } from "./components/TabDatos";
import { TabImagenes } from "./components/TabImagenes";
import { parametrosContablesComprasVentasEmpresaRequest } from "../../../../services/api/parametros-contables-compras-ventas";
import { agentesVentasIdEmpresaRequest } from "../../../../services/api/agentes-ventas";
import { gruposClientesIdEmpresaRequest } from "../../../../services/api/grupos-clientes";
import { rutasClientesIdEmpresaRequest } from "../../../../services/api/rutas-clientes";
import { zonasClientesIdEmpresaRequest } from "../../../../services/api/zonas-clientes";
import { empresaIdRequest } from "../../../../services/api/empresas";
import {
  actualizarClienteRequest,
  clienteIdRequest,
  clientesIdEmpresaRequest,
  eliminarClienteRequest,
} from "../../../../services/api/clientes";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const ClientePage = () => {
  const { id } = useParams();

  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    identificacion: "",
    activo: true,
    codigo: "",
    clave: "",
    razon_social: "",
    nombre_comercial: "",
    direccion: "",
    email: "",
    referencia: "",
    telefono_1: "",
    telefono_2: "",
    whatsapp: "",
    descuento: "",
    dias_credito: "",
    cupo_credito: "",
    entidad_bancaria: false,
    realizar_retenciones: false,
    contribuyente_especial: false,
    imagenes: [],
    id_tipo_identificacion: null,
    cuenta_contable_cliente: null,
    id_tipo_regimen: null,
    id_tipo_compra: null,
    id_origen_ingreso: null,
    id_grupo_cliente: null,
    id_ruta_cliente: null,
    id_zona_cliente: null,
    id_provincia: null,
    id_canton: null,
    id_parroquia: null,
    id_sexo: null,
    id_estado_civil: null,
    id_agente_venta_vendedor: null,
    id_agente_venta_cobrador: null,
    id_tipo_entidad: null,
    id_tarifa: null,
  });

  const [cliente, setCliente] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setReload(!reload);
  };

  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [gruposClientes, setGruposClientes] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [rutasClientes, setRutasClientes] = useState([]);
  const [zonasClientes, setZonasClientes] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);
  const [estadosCivil, setEstadosCivi] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const [parroquias, setParroquias] = useState([]);
  const [tarifas, setTarifas] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [tiposCompra, setTiposCompra] = useState([]);
  const [origenesIngresos, setOrigenesIngresos] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposIdentificacionRequest(),
      parametrosContablesComprasVentasEmpresaRequest(empresa.id_empresa),
      agentesVentasIdEmpresaRequest(empresa.id_empresa),
      gruposClientesIdEmpresaRequest(empresa.id_empresa),
      rutasClientesIdEmpresaRequest(empresa.id_empresa),
      zonasClientesIdEmpresaRequest(empresa.id_empresa),
      sexosRequest(),
      tiposEntidadRequest(),
      estadosCivilRequest(),
      empresaIdRequest(empresa.id_empresa),
      provinciasRequest(),
      cantonesRequest({ id_provincia: null }),
      parroquiasRequest({ id_canton: null }),
      tarifasRequest(),
      tipoRegimenRequest(),
      tiposCompraRequest(),
      origenesIngresosRequest(),
    ])
      .then(
        ([
          tiposIdentificacionRes,
          parametrosContablesComprasVentasEmpresaRes,
          agentesVentasRes,
          gruposClientesRes,
          rutasClientesRes,
          zonasClientesRes,
          sexosRes,
          tiposEntidadRes,
          estadosCivilRes,
          empresaIdRes,
          provinciasRes,
          cantonesRes,
          parroquiasRes,
          tarifasRes,
          tiposRegimenRes,
          tiposCompraRes,
          origenesIngresosRes,
        ]) => {
          setTiposIdentificacion(tiposIdentificacionRes);
          setGruposClientes(gruposClientesRes);
          setRutasClientes(rutasClientesRes);
          setZonasClientes(zonasClientesRes);
          setSexos(sexosRes);
          setTiposEntidad(tiposEntidadRes);
          setEstadosCivi(estadosCivilRes);
          setProvincias(provinciasRes);
          setCantones(cantonesRes);
          setParroquias(parroquiasRes);
          setTarifas(tarifasRes);
          setTiposRegimen(tiposRegimenRes);
          setTiposCompra(tiposCompraRes);
          setOrigenesIngresos(origenesIngresosRes);

          if (agentesVentasRes.length > 0) {
            const filter = agentesVentasRes.filter((e) => e.vendedor);
            setVendedores(filter);
            const find = filter.find((e, index) => index === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_vendedor: find.id_agente_venta,
              }));
            }
          }

          if (agentesVentasRes.length > 0) {
            const filter = agentesVentasRes.filter((e) => e.cobrador);
            setCobradores(filter);
            const find = filter.find((e, index) => index === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_cobrador: find.id_agente_venta,
              }));
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    setIsLoading(true);
    clienteIdRequest(id)
      .then((res) => {
        setCliente(res);
        setForm({
          ...form,
          identificacion: res.identificacion,
          activo: res.activo,
          codigo: res.codigo,
          clave: res.clave,
          razon_social: res.razon_social,
          nombre_comercial: res.nombre_comercial,
          direccion: res.direccion,
          referencia: res.referencia,
          email: res.email,
          telefono_1: res.telefono_1,
          telefono_2: res.telefono_2,
          whatsapp: res.whatsapp,
          descuento: res.descuento,
          dias_credito: res.dias_credito,
          cupo_credito: res.cupo_credito,
          entidad_bancaria: res.entidad_bancaria,
          realizar_retenciones: res.realizar_retenciones,
          contribuyente_especial: res.contribuyente_especial,
          imagenes: res.imagenes,
          id_tipo_identificacion:
            res.tipo_identificacion?.id_tipo_identificacion,
          cuenta_contable_cliente: res.cuenta_contable_cliente,
          id_tipo_regimen: res.tipo_regimen?.id_tipo_regimen,
          id_tipo_compra: res.tipo_compra?.id_tipo_compra,
          id_origen_ingreso: res.origen_ingreso?.id_origen_ingreso,
          id_grupo_cliente: res.grupo_cliente?.id_grupo_cliente,
          id_ruta_cliente: res.ruta_cliente?.id_ruta_cliente,
          id_zona_cliente: res.zona_cliente?.id_zona_cliente,
          id_provincia: res.provincia?.id_provincia,
          id_canton: res.canton?.id_canton,
          id_parroquia: res.parroquia?.id_parroquia,
          id_sexo: res.sexo?.id_sexo,
          id_estado_civil: res.estado_civil?.id_estado_civil,
          id_agente_venta_vendedor:
            res.agente_venta_vendedor?.id_agente_venta_vendedor,
          id_agente_venta_cobrador:
            res.agente_venta_cobrador?.id_agente_venta_cobrador,
          id_tipo_entidad: res.tipo_entidad?.id_tipo_entidad,
          id_tarifa: res.tarifa?.id_tarifa,
        });
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const items = [
    {
      key: 1,
      label: `Datos`,
      children: (
        <TabDatos
          form={form}
          onChangeForm={onChangeForm}
          tiposIdentificacion={tiposIdentificacion}
          vendedores={vendedores}
          gruposClientes={gruposClientes}
          cobradores={cobradores}
          rutasClientes={rutasClientes}
          zonasClientes={zonasClientes}
          sexos={sexos}
          tiposEntidad={tiposEntidad}
          estadosCivil={estadosCivil}
          provincias={provincias}
          cantones={cantones}
          parroquias={parroquias}
          tarifas={tarifas}
          tiposRegimen={tiposRegimen}
          tiposCompra={tiposCompra}
          origenesIngresos={origenesIngresos}
        />
      ),
    },
    {
      key: 2,
      label: `Imágenes`,
      children: <TabImagenes form={form} onChangeForm={onChangeForm} />,
    },
  ];

  const validarCampos = () => {
    const { identificacion, codigo, razon_social } = form;

    if (identificacion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (razon_social.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (codigo.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese un codigo para el cliente",
      });
      return false;
    }

    return true;
  };

  const guardar = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      identificacion: form.identificacion,
      activo: form.activo,
      codigo: form.codigo,
      clave: form.clave,
      razon_social: form.razon_social,
      nombre_comercial: form.nombre_comercial,
      direccion: form.direccion,
      referencia: form.referencia,
      email: form.email?.replaceAll(" ", ""),
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      whatsapp: form.whatsapp,
      descuento: !form.descuento ? null : Number(form.descuento),
      dias_credito: !form.dias_credito ? null : Number(form.dias_credito),
      cupo_credito: !form.cupo_credito ? null : Number(form.cupo_credito),
      entidad_bancaria: form.entidad_bancaria,
      realizar_retenciones: form.realizar_retenciones,
      contribuyente_especial: form.contribuyente_especial,
      imagenes: form.imagenes,
      id_tipo_identificacion: form.id_tipo_identificacion,
      id_cuenta_contable_cliente:
        form.cuenta_contable_cliente.id_cuenta_contable,
      id_tipo_regimen: form.id_tipo_regimen,
      id_tipo_compra: form.id_tipo_compra,
      id_origen_ingreso: form.id_origen_ingreso,
      id_grupo_cliente: form.id_grupo_cliente,
      id_ruta_cliente: form.id_ruta_cliente,
      id_zona_cliente: form.id_zona_cliente,
      id_provincia: form.id_provincia,
      id_canton: form.id_canton,
      id_parroquia: form.id_parroquia,
      id_sexo: form.id_sexo,
      id_estado_civil: form.id_estado_civil,
      id_agente_venta_vendedor: form.id_agente_venta_vendedor,
      id_agente_venta_cobrador: form.id_agente_venta_cobrador,
      id_tipo_entidad: form.id_tipo_entidad,
      id_tarifa: form.id_tarifa,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await actualizarClienteRequest(id, dataRequest)
      .then((res) => {
        onResetForm();
        handleReload();
      })
      .finally(() => setIsLoading(false));
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const id_cliente = cliente.id_cliente;
    if (!id_cliente) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este cliente, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarClienteRequest(id_cliente).then((res) => {
      navigate(-1);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Clientes",
                },
                {
                  title: cliente?.razon_social,
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCLIENTES").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCLIENTES").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form disabled={!edit}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
