export const TIPOS_FORMAS_PAGO = {
    EFECTIVO: 1,
    CREDITO: 2,
    CHEQUE: 3,
    TARJETA_CREDITO: 4,
    DEPOSITO: 5,
    NOTA_CREDITO: 6,
    NOTA_DEBITO: 7,
    MULTIPLE: 8,
    NC_INTERNA: 9,
    ND_INTERNA: 10,
}

