import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { IoAdd, IoArrowBack, IoSettingsSharp } from "react-icons/io5";

import { TablaEmpresa, TablaSucursal } from "./components/TablaSucursal";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { empresasUsuarioRequest } from "../../../../services/api/empresas";
import { CrearSucursalDrawer } from "./components/CrearSucursalDrawer";
import { ActionContext } from "../../../../context/ActionContext";
import { sucursalesIdEmpresaRequest } from "../../../../services/api/sucursales";

export const SucursalesPage = () => {
  const { isSucursalesChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [sucursal, setSucursal] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    sucursalesIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setSucursal(res);
      })
      .finally(() => setIsLoading(false));
  }, [isSucursalesChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Sucursales",
                },
              ]}
            />
          </Row>
          <Button
            disabled={!checkPermisos("MSUCURSALES").crear}
            type="primary"
            onClick={showDrawer}
          >
            Crear
          </Button>
        </Row>
        {/* Tabla */}
        <TablaSucursal data={sucursal} />
        {/* Crear Drawer */}
        <CrearSucursalDrawer
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
        />
      </Content>
    </Spin>
  );
};
