import { useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect } from "react";
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../../../hooks/useForm";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  formulasCalculoPrecioRequest,
  tarifasIvaRequest,
  tiposCalculoCostoRequest,
  tiposCostoProduccionRequest,
  tiposManejoPrecioRequest,
} from "../../../../services/api/opciones";
import Title from "antd/es/skeleton/Title";
import { almacenesIdEmpresaRequest } from "../../../../services/api/almacenes";
import { UserContext } from "../../../../context/UserContext";
import {
  actualizarParametrosProductosRequest,
  crearParametrosProductosRequest,
  parametrosProductosEmpresaRequest,
} from "../../../../services/api/parametros-productos";

const almacenGeneral = {
  id_almacen: 0,
  nombre_almacen: "Almacen general",
};

export const ParametrosProductosPage = () => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    id_parametros_productos: null,
    sumar_cantidad_facturacion: false,
    sumar_cantidad_tpv: false,
    sumar_cantidad_proforma: false,
    sumar_cantidad_entrega: false,
    transferencia_generar_ingreso_automaticamente: false,
    permitir_transferencia_con_stock: false,
    permitir_actualizar_precios_ultima_compra: false,
    permitir_multiples_tarifas_medidas_superior_inferior: false,
    formato_precio: 2,
    formato_incluido_iva: 2,
    formato_subtotales: 2,
    formato_valor_iva: 2,
    formato_total: 2,
    formato_costo: 2,
    formato_costo_subtotales: 2,
    formato_costo_total: 2,
    formato_costo_valor_iva: 2,
    formato_cantidad: 1,
    id_iva: null,
    id_tipo_calculo_costo: null,
    id_almacen: 0,
    id_formula_calculo_precio: null,
    id_tipo_manejo_precio: null,
    id_tipo_costo_produccion: null,
  });

  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const [activeForm, setActiveForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [formulasColculoPrecio, setFormulasColculoPrecio] = useState([]);
  useEffect(() => {
    formulasCalculoPrecioRequest()
      .then((res) => setFormulasColculoPrecio(res))
      .finally();
  }, []);

  const [tiposCostoProduccion, setTiposCostoProduccion] = useState([]);
  useEffect(() => {
    tiposCostoProduccionRequest()
      .then((res) => setTiposCostoProduccion(res))
      .finally();
  }, []);

  const [tiposManejoPrecio, setTiposManejoPrecio] = useState([]);
  useEffect(() => {
    tiposManejoPrecioRequest()
      .then((res) => setTiposManejoPrecio(res))
      .finally();
  }, []);

  const [tiposCalculoCosto, setTiposCalculoCosto] = useState([]);
  useEffect(() => {
    tiposCalculoCostoRequest()
      .then((res) => setTiposCalculoCosto(res))
      .finally();
  }, []);

  const [almacenes, setAlmacenes] = useState([]);
  useEffect(() => {
    almacenesIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setAlmacenes(res);
        setForm((prev) => ({
          ...prev,
          id_almacen: res[0]?.id_almacen,
        }));
      })
      .finally();
  }, [empresa]);


  const [tarifasIva, setTarifasIva] = useState([]);
  useEffect(() => {
    tarifasIvaRequest()
      .then((res) => {
        setTarifasIva(res);
      })
      .finally();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setForm({ ...initValues });
    parametrosProductosEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res.id_parametros_productos) {
          setForm({
            ...form,
            id_parametros_productos: res.id_parametros_productos,
            sumar_cantidad_facturacion: res.sumar_cantidad_facturacion,
            sumar_cantidad_tpv: res.sumar_cantidad_tpv,
            sumar_cantidad_proforma: res.sumar_cantidad_proforma,
            sumar_cantidad_entrega: res.sumar_cantidad_entrega,
            transferencia_generar_ingreso_automaticamente:
              res.transferencia_generar_ingreso_automaticamente,
            permitir_transferencia_con_stock:
              res.permitir_transferencia_con_stock,
            permitir_actualizar_precios_ultima_compra:
              res.permitir_actualizar_precios_ultima_compra,
            permitir_multiples_tarifas_medidas_superior_inferior:
              res.permitir_multiples_tarifas_medidas_superior_inferior,
            formato_precio: res.formato_precio,
            formato_incluido_iva: res.formato_incluido_iva,
            formato_subtotales: res.formato_subtotales,
            formato_valor_iva: res.formato_valor_iva,
            formato_total: res.formato_total,
            formato_costo: res.formato_costo,
            formato_costo_subtotales: res.formato_costo_subtotales,
            formato_costo_total: res.formato_costo_total,
            formato_costo_valor_iva: res.formato_costo_valor_iva,
            formato_cantidad: res.formato_cantidad,
            id_iva: res.iva?.id_iva,
            id_tipo_calculo_costo:
              res.tipo_calculo_costo?.id_tipo_calculo_costo,
            id_almacen: res.almacen?.id_almacen,
            id_formula_calculo_precio:
              res.formula_calculo_precio?.id_formula_calculo_precio,
            id_tipo_manejo_precio:
              res.tipo_manejo_precio?.id_tipo_manejo_precio,
            id_tipo_costo_produccion:
              res.tipo_costo_produccion?.id_tipo_costo_produccion,
          });
        } else {
          setError({
            show: true,
            message: "Completar estos datos antes de empezar usar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const handleCreate = () => {
    setActiveForm(true);
  };

  const handleEdit = () => {
    setActiveForm(true);
  };

  const handleGuardarCambiosCrear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros de los productos para la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      sumar_cantidad_facturacion: form.sumar_cantidad_facturacion,
      sumar_cantidad_tpv: form.sumar_cantidad_tpv,
      sumar_cantidad_proforma: form.sumar_cantidad_proforma,
      sumar_cantidad_entrega: form.sumar_cantidad_entrega,
      transferencia_generar_ingreso_automaticamente:
        form.transferencia_generar_ingreso_automaticamente,
      permitir_transferencia_con_stock: form.permitir_transferencia_con_stock,
      permitir_actualizar_precios_ultima_compra:
        form.permitir_actualizar_precios_ultima_compra,
      permitir_multiples_tarifas_medidas_superior_inferior:
        form.permitir_multiples_tarifas_medidas_superior_inferior,
      formato_precio: Number(form.formato_precio),
      formato_incluido_iva: Number(form.formato_incluido_iva),
      formato_subtotales: Number(form.formato_subtotales),
      formato_valor_iva: Number(form.formato_valor_iva),
      formato_total: Number(form.formato_total),
      formato_costo: Number(form.formato_costo),
      formato_costo_subtotales: Number(form.formato_costo_subtotales),
      formato_costo_total: Number(form.formato_costo_total),
      formato_costo_valor_iva: Number(form.formato_costo_valor_iva),
      formato_cantidad: Number(form.formato_cantidad),
      id_empresa: empresa.id_empresa,
      id_iva: form.id_iva,
      id_tipo_calculo_costo: form.id_tipo_calculo_costo,
      id_almacen: form.id_almacen,
      id_formula_calculo_precio: form.id_formula_calculo_precio,
      id_tipo_manejo_precio: form.id_tipo_manejo_precio,
      id_tipo_costo_produccion: form.id_tipo_costo_produccion,
    };

    setIsLoading(true);
    crearParametrosProductosRequest(dataRequest)
      .then((res) => {
        onReload();
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validarCampos = () => {
    const {
      formato_precio,
      formato_incluido_iva,
      formato_subtotales,
      formato_valor_iva,
      formato_total,
      formato_costo,
      formato_costo_subtotales,
      formato_costo_total,
      formato_costo_valor_iva,
      formato_cantidad,
      id_iva,
      id_tipo_calculo_costo,
      id_almacen,
      id_formula_calculo_precio,
      id_tipo_manejo_precio,
      id_tipo_costo_produccion,
    } = form;

    if (
      !Number.isFinite(Number(formato_precio)) ||
      Number(formato_precio) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato precio o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_incluido_iva)) ||
      Number(formato_incluido_iva) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato incluido IVA o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_subtotales)) ||
      Number(formato_subtotales) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato subtotales o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_valor_iva)) ||
      Number(formato_valor_iva) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato valor IVA o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (!Number.isFinite(Number(formato_total)) || Number(formato_total) <= 0) {
      setError({
        show: true,
        message:
          "Revise el Formato total o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (!Number.isFinite(Number(formato_costo)) || Number(formato_costo) <= 0) {
      setError({
        show: true,
        message:
          "Revise el Formato costo o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_costo_subtotales)) ||
      Number(formato_costo_subtotales) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato costo subtotales o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_costo_total)) ||
      Number(formato_costo_total) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato costo total o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_costo_valor_iva)) ||
      Number(formato_costo_valor_iva) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato costo valor IVA o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (
      !Number.isFinite(Number(formato_cantidad)) ||
      Number(formato_cantidad) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el Formato cantidad o verifique que no sea menor o igual a cero",
      });
      return false;
    }

    if (!id_iva) {
      setError({
        show: true,
        message: "Seleccione el IVA predeterminado para los productos",
      });
      return false;
    }

    if (!id_tipo_calculo_costo) {
      setError({
        show: true,
        message: "Seleccione el Tipo calculo costo",
      });
      return false;
    }

    if (id_almacen === null) {
      setError({
        show: true,
        message: "Seleccione el Almacen",
      });
      return false;
    }

    if (!id_formula_calculo_precio) {
      setError({
        show: true,
        message: "Seleccione la Formula calculo precios",
      });
      return false;
    }

    if (!id_tipo_manejo_precio) {
      setError({
        show: true,
        message: "Seleccione el Tipo manejo precios",
      });
      return false;
    }

    if (!id_tipo_costo_produccion) {
      setError({
        show: true,
        message: "Seleccione el Tipo costo producción",
      });
      return false;
    }

    return true;
  };

  const onReload = () => {
    setActiveForm(false);
    setReload(!reload);
  };

  const handleGuardarCambiosActualizar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios de parametros de los productos para la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      sumar_cantidad_facturacion: form.sumar_cantidad_facturacion,
      sumar_cantidad_tpv: form.sumar_cantidad_tpv,
      sumar_cantidad_proforma: form.sumar_cantidad_proforma,
      sumar_cantidad_entrega: form.sumar_cantidad_entrega,
      transferencia_generar_ingreso_automaticamente:
        form.transferencia_generar_ingreso_automaticamente,
      permitir_transferencia_con_stock: form.permitir_transferencia_con_stock,
      permitir_actualizar_precios_ultima_compra:
        form.permitir_actualizar_precios_ultima_compra,
      permitir_multiples_tarifas_medidas_superior_inferior:
        form.permitir_multiples_tarifas_medidas_superior_inferior,
      formato_precio: Number(form.formato_precio),
      formato_incluido_iva: Number(form.formato_incluido_iva),
      formato_subtotales: Number(form.formato_subtotales),
      formato_valor_iva: Number(form.formato_valor_iva),
      formato_total: Number(form.formato_total),
      formato_costo: Number(form.formato_costo),
      formato_costo_subtotales: Number(form.formato_costo_subtotales),
      formato_costo_total: Number(form.formato_costo_total),
      formato_costo_valor_iva: Number(form.formato_costo_valor_iva),
      formato_cantidad: Number(form.formato_cantidad),
      id_empresa: empresa.id_empresa,
      id_iva: form.id_iva,
      id_tipo_calculo_costo: form.id_tipo_calculo_costo,
      id_almacen: form.id_almacen,
      id_formula_calculo_precio: form.id_formula_calculo_precio,
      id_tipo_manejo_precio: form.id_tipo_manejo_precio,
      id_tipo_costo_produccion: form.id_tipo_costo_produccion,
    };

    setIsLoading(true);
    actualizarParametrosProductosRequest(
      form.id_parametros_productos,
      dataRequest
    )
      .then((res) => {
        onReload();
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Parámetros Productos",
                },
              ]}
            />
          </Row>
          <Row>
            {form.id_parametros_productos == null ? (
              activeForm ? (
                <Button
                  disabled={!checkPermisos("MPARAMETROS-PRODUCTOS").crear}
                  type="primary"
                  onClick={handleGuardarCambiosCrear}
                >
                  Guardar cambios
                </Button>
              ) : (
                <Button
                  disabled={!checkPermisos("MPARAMETROS-PRODUCTOS").crear}
                  type="primary"
                  onClick={handleCreate}
                >
                  Crear
                </Button>
              )
            ) : activeForm ? (
              <Button
                disabled={!checkPermisos("MPARAMETROS-PRODUCTOS").editar}
                type="primary"
                onClick={handleGuardarCambiosActualizar}
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                disabled={!checkPermisos("MPARAMETROS-PRODUCTOS").editar}
                type="primary"
                onClick={handleEdit}
                icon={<EditFilled />}
              >
                Editar
              </Button>
            )}
          </Row>
        </Row>
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        {/* Boby */}
        <Form disabled={!activeForm}>
          <Row style={{ marginTop: 10 }} gutter={10} justify={"space-around"}>
            <Col span={8}>
              <Row>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_formula_calculo_precio", e.target.value);
                  }}
                  value={form.id_formula_calculo_precio}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Formula calculo precios
                  </Typography>
                  <Space direction="vertical">
                    {formulasColculoPrecio.map((f) => {
                      return (
                        <Radio
                          key={f.id_formula_calculo_precio}
                          value={f.id_formula_calculo_precio}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_tipo_costo_produccion", e.target.value);
                  }}
                  value={form.id_tipo_costo_produccion}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Tipo costo producción
                  </Typography>
                  <Space direction="vertical">
                    {tiposCostoProduccion.map((f) => {
                      return (
                        <Radio
                          key={f.id_tipo_costo_produccion}
                          value={f.id_tipo_costo_produccion}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_tipo_manejo_precio", e.target.value);
                  }}
                  value={form.id_tipo_manejo_precio}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Tipo manejo precios
                  </Typography>
                  <Space direction="vertical">
                    {tiposManejoPrecio.map((f) => {
                      return (
                        <Radio
                          key={f.id_tipo_manejo_precio}
                          value={f.id_tipo_manejo_precio}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_tipo_calculo_costo", e.target.value);
                  }}
                  value={form.id_tipo_calculo_costo}
                >
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Tipo calculo costo
                  </Typography>
                  <Space direction="vertical">
                    {tiposCalculoCosto.map((f) => {
                      return (
                        <Radio
                          key={f.id_tipo_calculo_costo}
                          value={f.id_tipo_calculo_costo}
                        >
                          {f.descripcion}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Row>
            </Col>
            <Col span={8}>
              <Row justify={"space-between"}>
                <Typography>Almacen: </Typography>
                <Select
                  size="small"
                  onChange={(e) => onChangeForm("id_almacen", e)}
                  value={form.id_almacen}
                  style={{ width: 150 }}
                >
                  {almacenes.map((a) => {
                    return (
                      <Select.Option value={a.id_almacen}>
                        {a.nombre_almacen}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 3 }}>
                <Typography>IVA predeterminado</Typography>
                <Select
                  size="small"
                  onChange={(e) => onChangeForm("id_iva", e)}
                  value={form.id_iva}
                  style={{ width: 150 }}
                >
                  {tarifasIva.map((t) => {
                    return (
                      <Select.Option value={t.id_iva}>
                        {t.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Row>
                    <Checkbox
                      checked={form.sumar_cantidad_facturacion}
                      onChange={(e) =>
                        onChangeForm(
                          "sumar_cantidad_facturacion",
                          e.target.checked
                        )
                      }
                    >
                      Sumar cantidad en facturación
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={form.sumar_cantidad_tpv}
                      onChange={(e) =>
                        onChangeForm("sumar_cantidad_tpv", e.target.checked)
                      }
                    >
                      Sumar cantidad en TPV (Offline)
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={form.sumar_cantidad_proforma}
                      onChange={(e) =>
                        onChangeForm(
                          "sumar_cantidad_proforma",
                          e.target.checked
                        )
                      }
                    >
                      Sumar cantidad en proformas/pedidos
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={form.sumar_cantidad_entrega}
                      onChange={(e) =>
                        onChangeForm("sumar_cantidad_entrega", e.target.checked)
                      }
                    >
                      Sumar cantidad en entrega
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={
                        form.transferencia_generar_ingreso_automaticamente
                      }
                      onChange={(e) =>
                        onChangeForm(
                          "transferencia_generar_ingreso_automaticamente",
                          e.target.checked
                        )
                      }
                    >
                      En transferencias generar ingreso automáticamente
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={form.permitir_transferencia_con_stock}
                      onChange={(e) =>
                        onChangeForm(
                          "permitir_transferencia_con_stock",
                          e.target.checked
                        )
                      }
                    >
                      Permitir transferencia sólo si hay stock
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={form.permitir_actualizar_precios_ultima_compra}
                      onChange={(e) =>
                        onChangeForm(
                          "permitir_actualizar_precios_ultima_compra",
                          e.target.checked
                        )
                      }
                    >
                      Permitir actualizar precios ultima compra
                    </Checkbox>
                  </Row>
                  <Row style={{ marginTop: 2 }}>
                    <Checkbox
                      checked={
                        form.permitir_multiples_tarifas_medidas_superior_inferior
                      }
                      onChange={(e) =>
                        onChangeForm(
                          "permitir_multiples_tarifas_medidas_superior_inferior",
                          e.target.checked
                        )
                      }
                    >
                      Permitir múltiples tarifas por medida superior e inferior
                    </Checkbox>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ margin: 0 }}
              >
                Formato de ventas
              </Divider>
              <Row justify={"space-between"}>
                <Typography>Formato precio: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_precio}
                  onChange={(e) =>
                    onChangeForm("formato_precio", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato precio incluido IVA: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_incluido_iva}
                  onChange={(e) =>
                    onChangeForm("formato_incluido_iva", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato subtotales: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_subtotales}
                  onChange={(e) =>
                    onChangeForm("formato_subtotales", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato valor IVA: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_valor_iva}
                  onChange={(e) =>
                    onChangeForm("formato_valor_iva", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato total: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_total}
                  onChange={(e) =>
                    onChangeForm("formato_total", e.target.value)
                  }
                />
              </Row>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ margin: 0 }}
              >
                Formato de costos
              </Divider>
              <Row justify={"space-between"}>
                <Typography>Formato costo: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_costo}
                  onChange={(e) =>
                    onChangeForm("formato_costo", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato costo subtotales: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_costo_subtotales}
                  onChange={(e) =>
                    onChangeForm("formato_costo_subtotales", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato valor IVA: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_costo_valor_iva}
                  onChange={(e) =>
                    onChangeForm("formato_costo_valor_iva", e.target.value)
                  }
                />
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 1 }}>
                <Typography>Formato costo total: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_costo_total}
                  onChange={(e) =>
                    onChangeForm("formato_costo_total", e.target.value)
                  }
                />
              </Row>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ margin: 0 }}
              >
                Formato de cantidades
              </Divider>
              <Row justify={"space-between"}>
                <Typography>Formato cantidad: </Typography>
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={form.formato_cantidad}
                  onChange={(e) =>
                    onChangeForm("formato_cantidad", e.target.value)
                  }
                />
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>
    </Spin>
  );
};
