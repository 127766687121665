import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_COMPRAS_VENTAS = '/parametros-contables-compras-ventas';

export const parametrosContablesComprasVentasEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_COMPRAS_VENTAS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesComprasVentasRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_COMPRAS_VENTAS, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesComprasVentasRequest = async (id_parametros_contables_compras_ventas, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_COMPRAS_VENTAS + '/' + id_parametros_contables_compras_ventas, data);
    const response = request.data;
    return response;
}