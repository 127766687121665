import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarProveedorRequest } from "../../../../../services/api/proveedores";

export const TablaProveedores = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadProveedores } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_proveedor = record.id_proveedor;
    if (!id_proveedor) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este proveedor, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarProveedorRequest(id_proveedor).then((res) => {
      reloadProveedores();
    });
  };

  const detalleProveedor = (data) => {
    const id_proveedor = data?.id_proveedor;
    if (!id_proveedor) return;
    navigate(`./${id_proveedor}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "8%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MPROVEEDORES").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleProveedor(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MPROVEEDORES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Identificacion",
      dataIndex: "identificacion",
      key: "x",
      width: "15%",
      ellipsis: true,
      ...getColumnSearchProps("identificacion"),
    },
    {
      title: "Razon social",
      dataIndex: "razon_social",
      key: "razon_social",
      width: "30%",
      ellipsis: true,
      ...getColumnSearchProps("razon_social"),
    },
    {
      title: "Nombre comercial",
      dataIndex: "nombre_comercial",
      key: "nombre_comercial",
      width: "30%",
      ellipsis: true,
      ...getColumnSearchProps("nombre_comercial"),
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_proveedor"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
