import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip, Typography } from "antd";
import React, { memo, useState } from "react";
import { IoAdd, IoTrash } from "react-icons/io5";
import { ModalCrearProveedor } from "./ModalCrearProveedor";
import { ModalProveedor } from "./ModalProveedor";

export const ProveedorInput = memo(
  ({
    label = "",
    value,
    onChange = () => {},
    onCreate = () => {},
    spanLabel = null,
    spanInput = null,
    spanText = null,
    customGutter = null,
    spanSearch = null,
    spanDelete = null,
    disabled = false,
    create = false,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const registrarProveedor = () => {
      setIsModalCreateOpen(true);
    };

    const quitarProveedor = () => {
      onChange(null);
    };

    return (
      <Row
        gutter={customGutter ?? 10}
        justify={"flex-start"}
        align={"middle"}
        style={{
          marginTop: 5,
          backgroundColor:
            value?.id_proveedor || !value || !create
              ? "transparent"
              : "rgba(255,0,0,0.2)",
        }}
      >
        <Col span={spanLabel ?? 5}>
          <Typography>{label}</Typography>
        </Col>
        <Col span={spanInput ?? 5}>
          <Input size="small" value={value?.identificacion} disabled />
        </Col>
        <Col span={spanSearch ?? 2}>
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={showModal}
            disabled={disabled}
          />
        </Col>
        <Col span={spanText ?? 8}>
          <Typography>{value?.razon_social}</Typography>
        </Col>
        {value != null && (
          <Col span={spanDelete ?? 4}>
            <Row justify={"end"}>
              {!value?.id_proveedor && (
                <Tooltip title={"Registrar al proveedor"}>
                  <Button
                    onClick={registrarProveedor}
                    type="primary"
                    size="small"
                  >
                    <IoAdd size={18} />
                  </Button>
                </Tooltip>
              )}

              <Button
                onClick={quitarProveedor}
                danger
                type="text"
                size="small"
                disabled={disabled}
              >
                <IoTrash size={18} />
              </Button>
            </Row>
          </Col>
        )}
        <ModalProveedor
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onSelect={onChange}
        />
        {value && !value?.id_proveedor && (
          <ModalCrearProveedor
            isOpen={isModalCreateOpen}
            close={() => setIsModalCreateOpen(false)}
            identificacion={value.identificacion}
            razon_social={value.razon_social}
            nombre_comercial={value.nombre_comercial}
            direccion={value.direccion}
            contribuyente_especial={value.contribuyente_especial}
            lleva_contabilidad={value.lleva_contabilidad}
            onCreate={onCreate}
          />
        )}
      </Row>
    );
  }
);
