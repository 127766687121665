import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import {
  actualizarCuentaContableRequest,
  crearCuentaContableRequest,
  cuentaContableIdRequest,
} from "../../../../../services/api/cuentas-contables";

const { Option } = Select;

export const EditarCuentaContable = ({
  open,
  showDrawer,
  id_cuenta_contable,
  onClose,
}) => {
  const { empresa } = useContext(UserContext);
  const { reloadPlanCuentas } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    codigo_contable: "",
    nombre_cuenta_contable: "",
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && id_cuenta_contable != 0) {
      setIsLoading(true);
      cuentaContableIdRequest(id_cuenta_contable)
        .then((res) => {
          setForm({
            codigo_contable: res.codigo_contable,
            nombre_cuenta_contable: res.nombre_cuenta_contable,
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setForm({
        codigo_contable: "",
        nombre_cuenta_contable: "",
      });
    }
  }, [open, id_cuenta_contable]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { codigo_contable, nombre_cuenta_contable } = form;

    // Verificar que los campos obligatorios estén completos
    if (codigo_contable.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo contable",
      });
      return false;
    }

    if (nombre_cuenta_contable.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el nombre de la cuenta contable",
      });
      return false;
    }

    return true;
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      codigo_contable: form.codigo_contable,
      nombre_cuenta_contable: form.nombre_cuenta_contable,
    };

    setIsLoading(true);
    actualizarCuentaContableRequest(id_cuenta_contable, dataRequest)
      .then((res) => {
        reloadPlanCuentas();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar cuenta contable"
      width={500}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={editar} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Código cuenta contable" required>
            <Input
              placeholder="Código cuenta contable"
              value={form.codigo_contable}
              onChange={(e) => onChangeForm("codigo_contable", e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Nombre cuenta contable" required>
            <Input
              placeholder="Nombre cuenta contable"
              value={form.nombre_cuenta_contable}
              onChange={(e) =>
                onChangeForm("nombre_cuenta_contable", e.target.value)
              }
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
