import { Alert, Breadcrumb, Button, Col, Form, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { MdFireTruck } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import { gruposProveedoresIdEmpresaRequest } from "../../../../services/api/grupos-proveedores";
import {
  paisesRequest,
  porcentajeRetencionRequest,
  sustentoTributarioRequest,
  tipoRegimenRequest,
  tiposCompraRequest,
  tiposEntidadRequest,
  tiposFormaPagoRequest,
  tiposIdentificacionRequest,
  tiposPagoARequest,
} from "../../../../services/api/opciones";
import { parametrosContablesComprasVentasEmpresaRequest } from "../../../../services/api/parametros-contables-compras-ventas";
import { eliminarProductoRequest } from "../../../../services/api/productos";
import {
  actualizarProveedorRequest,
  eliminarProveedorRequest,
  proveedorIdRequest,
} from "../../../../services/api/proveedores";
import { FormProveedor } from "./components/FormProveedor";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const ProveedorPage = () => {
  const { id } = useParams();

  const { isProveedorChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    identificacion: "",
    activo: true,
    relacionado: false,
    razon_social: "",
    nombre_comercial: "",
    direccion: "",
    email: "",
    telefono_1: "",
    telefono_2: "",
    contribuyente_especial: false,
    lleva_contabilidad: false,
    id_tipo_identificacion: null,
    cuenta_contable_proveedor: null,
    id_grupo_proveedor: null,
    id_tipo_compra: null,
    id_tipo_entidad: null,
    id_tipo_regimen: null,
    id_sustento_tributario: null,
    id_tipo_forma_pago: null,
    id_tipo_pago_a: null,
    id_porcentaje_retencion_bienes: null,
    id_porcentaje_retencion_servicios: null,
    id_pais_pago: null,
  });

  const [proveedor, setProveedor] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setEdit(false);
    setReload(!reload);
  };

  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [cuentaContableProveedor, setCuentaContableProveedor] = useState(null);
  const [gruposProveedores, setGruposProveedores] = useState([]);
  const [tiposCompras, setTiposCompras] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [sustentoTributario, setSustentoTributario] = useState([]);
  const [tiposFormaPago, setTiposFormaPago] = useState([]);
  const [tiposPagoA, setTiposPagoA] = useState([]);
  const [porcentajeRetencion, setPorcentajeRetencion] = useState([]);
  const [paises, setPaises] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposIdentificacionRequest(),
      parametrosContablesComprasVentasEmpresaRequest(empresa.id_empresa),
      gruposProveedoresIdEmpresaRequest(empresa.id_empresa),
      tiposCompraRequest(),
      tiposEntidadRequest(),
      tipoRegimenRequest(),
      sustentoTributarioRequest(),
      tiposFormaPagoRequest(),
      tiposPagoARequest(),
      porcentajeRetencionRequest(),
      paisesRequest(),
    ])
      .then(
        ([
          tiposIdentificacionRes,
          parametrosContablesComprasVentasRes,
          gruposProveedoresIdEmpresaRes,
          tiposCompraRes,
          tiposEntidadRes,
          tiposRegimenRes,
          sustentoTributarioRes,
          tiposFormaPagoRes,
          tiposPagoARes,
          porcentajeRetencionRes,
          paisesRes,
        ]) => {
          setTiposIdentificacion(tiposIdentificacionRes);
          setGruposProveedores(gruposProveedoresIdEmpresaRes);
          setTiposCompras(tiposCompraRes);
          setTiposEntidad(tiposEntidadRes);
          setTiposRegimen(tiposRegimenRes);
          setSustentoTributario(sustentoTributarioRes);
          setTiposFormaPago(tiposFormaPagoRes);
          setTiposPagoA(tiposPagoARes);
          setPorcentajeRetencion(porcentajeRetencionRes);
          setPaises(paisesRes);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    setIsLoading(true);
    proveedorIdRequest(id)
      .then((res) => {
        setProveedor(res);
        setForm({
          ...form,
          identificacion: res.identificacion,
          activo: res.activo,
          relacionado: res.relacionado,
          razon_social: res.razon_social,
          nombre_comercial: res.nombre_comercial,
          direccion: res.direccion,
          email: res.email,
          telefono_1: res.telefono_1,
          telefono_2: res.telefono_2,
          contribuyente_especial: res.contribuyente_especial,
          lleva_contabilidad: res.lleva_contabilidad,
          id_tipo_identificacion:
            res.tipo_identificacion?.id_tipo_identificacion,
          cuenta_contable_proveedor: res.cuenta_contable_proveedor,
          id_grupo_proveedor: res.grupo_proveedor.id_grupo_proveedor,
          id_tipo_compra: res.tipo_compra?.id_tipo_compra,
          id_tipo_entidad: res.tipo_entidad?.id_tipo_entidad,
          id_tipo_regimen: res.tipo_regimen?.id_tipo_regimen,
          id_sustento_tributario:
            res.sustento_tributario?.id_sustento_tributario,
          id_tipo_forma_pago: res.tipo_forma_pago?.id_tipo_forma_pago,
          id_tipo_pago_a: res.tipo_pago_a?.id_tipo_pago_a,
          id_porcentaje_retencion_bienes:
            res.porcentaje_retencion_bienes?.id_porcentaje_retencion,
          id_porcentaje_retencion_servicios:
            res.porcentaje_retencion_servicios?.id_porcentaje_retencion,
          id_pais_pago: res.pais_pago?.id_pais ?? null,
        });
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const validarCampos = () => {
    const { identificacion, razon_social, cuenta_contable_proveedor } = form;

    if (identificacion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la identificación ",
      });
      return false;
    }

    if (razon_social.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (!cuenta_contable_proveedor?.id_cuenta_contable) {
      setError({
        show: true,
        message: "Seleccione la cuenta contable",
      });
      return false;
    }

    return true;
  };

  const guardar = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      identificacion: form.identificacion,
      activo: form.activo,
      relacionado: form.relacionado,
      razon_social: form.razon_social,
      nombre_comercial: form.nombre_comercial,
      direccion: form.direccion,
      email: form.email?.replaceAll(" ", ""),
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      contribuyente_especial: form.contribuyente_especial,
      lleva_contabilidad: form.lleva_contabilidad,
      id_tipo_identificacion: form.id_tipo_identificacion,
      id_cuenta_contable_proveedor:
        form.cuenta_contable_proveedor?.id_cuenta_contable,
      id_grupo_proveedor: form.id_grupo_proveedor,
      id_tipo_compra: form.id_tipo_compra,
      id_tipo_entidad: form.id_tipo_entidad,
      id_tipo_regimen: form.id_tipo_regimen,
      id_sustento_tributario: form.id_sustento_tributario,
      id_tipo_forma_pago: form.id_tipo_forma_pago,
      id_tipo_pago_a: form.id_tipo_pago_a,
      id_porcentaje_retencion_bienes: form.id_porcentaje_retencion_bienes,
      id_porcentaje_retencion_servicios: form.id_porcentaje_retencion_servicios,
      id_pais_pago: form.id_pais_pago,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await actualizarProveedorRequest(id, dataRequest)
      .then((res) => {
        onResetForm();
        handleReload();
      })
      .finally(() => setIsLoading(false));
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const id_proveedor = proveedor.id_proveedor;
    if (!id_proveedor) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este proveedor, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarProveedorRequest(id_proveedor).then((res) => {
      navigate(-1);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Proveedores",
                },
                {
                  title: proveedor?.razon_social,
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MPROVEEDORES").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MPROVEEDORES").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form disabled={!edit}>
          <FormProveedor
            form={form}
            onChangeForm={onChangeForm}
            tiposIdentificacion={tiposIdentificacion}
            gruposProveedores={gruposProveedores}
            tiposCompras={tiposCompras}
            tiposEntidad={tiposEntidad}
            tiposRegimen={tiposRegimen}
            sustentoTributario={sustentoTributario}
            tiposFormaPago={tiposFormaPago}
            tiposPagoA={tiposPagoA}
            porcentajeRetencion={porcentajeRetencion}
            paises={paises}
          />
        </Form>
      </Content>
    </Spin>
  );
};
