import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
} from "antd";

import { TabRol } from "./TabRol";
import { Content } from "antd/es/layout/layout";
import { useToken } from "antd/es/theme/internal";
import { IoAdd, IoCreateOutline, IoTrash } from "react-icons/io5";
import { actualizarModulosPermisosRequest } from "../../../../../services/api/modulos";
import { UserContext } from "../../../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { elimiarEmpresaRolUsuarioRequest } from "../../../../../services/api/empresas";
import { CrearEmpresaRolUsuarioDrawer } from "./CrearEmpresaRolUsuarioDrawer";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";

export const TabPermisos = ({
  empresa_sistemas = [],
  usuario = {},
  onReload = () => {},
}) => {
  const {
    checkData,
    usuario: usuarioContext,
    checkPermisos,
    empresa,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState(
    usuario?.empresa_rol_usuarios?.[0]?.empresa_rol.id_empresa_rol
  );

  const [eru, setEru] = useState({});

  const [edit, setEdit] = useState(false);
  const [empresaSistemas, setEmpresaSistemas] = useState(
    JSON.parse(JSON.stringify(empresa_sistemas))
  );
  const [crearRol, setCrearRol] = useState(false);

  useEffect(() => {
    setEru({ ...usuario?.empresa_rol_usuarios?.[0] });
  }, []);

  useEffect(() => {
    if (!edit) {
      setEmpresaSistemas(JSON.parse(JSON.stringify(empresa_sistemas)));
    }
  }, [edit]);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const onChangeEmpresaSistema = (idPermiso, tipoPermiso, checked) => {
    const updatedEmpresaSistemas = empresaSistemas.map((empresaSistema) => {
      const updatedModulos = empresaSistema?.sistema?.modulos?.map((modulo) => {
        const updatedPermisos = modulo?.modulo_permisos?.map((permiso) => {
          if (permiso.id_permiso === idPermiso) {
            return {
              ...permiso,
              [tipoPermiso]: checked,
            };
          }
          return permiso;
        });
        return {
          ...modulo,
          modulo_permisos: updatedPermisos,
        };
      });
      return {
        ...empresaSistema,
        sistema: {
          ...empresaSistema.sistema,
          modulos: updatedModulos,
        },
      };
    });

    setEmpresaSistemas(updatedEmpresaSistemas);
  };

  const handleEditPermisos = () => {
    handleEdit();
  };

  const handleEliminarRolPermisos = () => {
    const isMy = usuario?.usuario?.id_usuario == usuarioContext.id_usuario;

    const message = `¿Esta seguro de eliminar el rol junto a los permisos de este usuario?`;

    const messageAdmin = `¿Esta seguro de eliminar este rol junto a los permisos de su usuario, recuerde que esta accion puede causar complicaciones?`;

    const confirm = window.confirm(isMy ? messageAdmin : message);

    if (!confirm) return;

    const dataRequest = {
      id_empresa_rol_usuario: eru.id_empresa_rol_usuario,
    };

    elimiarEmpresaRolUsuarioRequest(dataRequest)
      .then((res) => {
        if (isMy) {
          navigate(-1);
          checkData();
        }
        onReload();
      })
      .catch()
      .finally();
  };

  const handleGuardarCambios = () => {
    const isMy = usuario?.usuario?.id_usuario == usuarioContext.id_usuario;

    const message = `¿Esta seguro de guardar los cambios de permisos?`;

    const messageAdmin = `¿Esta seguro de guardar los cambios de sus permisos, cancelar y verificar los cambios antes de guardarlos?`;

    const confirm = window.confirm(isMy ? messageAdmin : message);

    if (!confirm) return;

    let permisos = [];

    empresaSistemas.forEach((empresa_sistema) => {
      empresa_sistema.sistema?.modulos?.forEach((modulo) => {
        modulo.modulo_permisos.forEach((mp) => {
          if (
            mp.empresa_rol_usuario?.id_empresa_rol_usuario ==
            eru.id_empresa_rol_usuario
          ) {
            permisos.push(mp);
          }
        });
      });
    });

    const dataRequest = {
      permisos: permisos,
    };

    actualizarModulosPermisosRequest(dataRequest)
      .then((res) => {
        if (isMy) {
          navigate(-1);
          checkData();
        }
        onReload();
      })
      .catch()
      .finally();
  };

  return (
    <Content>
      {/* HEADER */}
      <Row
        style={{
          height: 40,
          borderBottomWidth: 1,
          borderBottomColor: "rgba(0, 0, 0, 0.1)",
          borderBottomStyle: "solid",
        }}
        align={"middle"}
      >
        {usuario?.empresa_rol_usuarios?.map((eru) => {
          const id = eru?.empresa_rol?.id_empresa_rol;
          const rol = eru?.empresa_rol?.nombre_empresa_rol;

          return (
            <Row
              key={id}
              align={"middle"}
              style={{
                height: 40,
                cursor: "pointer",
                paddingInline: 20,
                borderBottomWidth: tabActive == id && 2.5,
                borderBottomColor: tabActive == id && PRIMARY_COLOR,
                borderBottomStyle: tabActive == id && "solid",
              }}
              onClick={() => {
                setTabActive(id);
                setEru(eru);
              }}
            >
              <Typography
                style={{
                  margin: 0,
                  color: tabActive == id && PRIMARY_COLOR,
                  fontWeight: tabActive == id && "bold",
                }}
              >
                {rol}
              </Typography>
              <Row
                gutter={5}
                justify={"space-around"}
                style={{ marginLeft: 10 }}
              >
                <Col>
                  <Tooltip title={"Habilitar edicion"}>
                    <Button
                      disabled={
                        tabActive != id || !checkPermisos("MUSUARIOS").editar
                      }
                      onClick={handleEditPermisos}
                      size="small"
                      type="primary"
                      icon={<IoCreateOutline size={12} />}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title={"Eliminar rol y permisos"}>
                    <Button
                      disabled={
                        tabActive != id || !checkPermisos("MUSUARIOS").eliminar
                      }
                      onClick={handleEliminarRolPermisos}
                      danger
                      size="small"
                      type="primary"
                      icon={<IoTrash size={12} />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Row>
          );
        })}
        <Tooltip title={"Nuevo rol"}>
          <Row
            align={"middle"}
            style={{
              marginInline: 5,
              height: 25,
              width: 25,
              borderRadius: 5,
              backgroundColor: checkPermisos("MUSUARIOS").crear ? PRIMARY_COLOR : "darkgray",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {  
              const validate = checkPermisos("MUSUARIOS").crear;
              if (!validate) return;
              setCrearRol(true);
            }}
          >
            <IoAdd size={20} color="#fff" />
          </Row>
        </Tooltip>
      </Row>
      {/* BODY */}
      <Row>
        <TabRol
          eru={eru}
          empresaSistemas={empresaSistemas}
          edit={edit}
          handleGuardarCambios={handleGuardarCambios}
          onChangeEmpresaSistema={onChangeEmpresaSistema}
        />
      </Row>
      <CrearEmpresaRolUsuarioDrawer
        open={crearRol}
        onClose={() => setCrearRol(false)}
        reload={onReload}
        id_usuario={usuario.usuario?.id_usuario}
      />
    </Content>
  );
};
