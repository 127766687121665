import { Col, Input, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";

export const FormRetencionIva = ({
  form = {},
  onChangeForm = () => {},
  codigosRetenciones = [],
}) => {
  const [codigos, setCodigos] = useState([]);

  useEffect(() => {
    setCodigos(
      codigosRetenciones.map((codigoRetencion) => ({
        value: codigoRetencion.id_codigo_retencion,
        label: `${codigoRetencion.codigo}  ${codigoRetencion.descripcion}`,
      }))
    );
  }, [codigosRetenciones]);

  return (
    <Content>
      <Row>
        <Col span={5}>
          <Typography>Base:</Typography>
        </Col>
        <Col span={19}>
          <Input
            size="small"
            style={{ width: "100%" }}
            value={form.base_imponible}
            onChange={(e) => onChangeForm("base_imponible", e.target.value)}
            type="number"
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={5}>
          <Typography>Retencion Renta: </Typography>
        </Col>
        <Col span={19}>
          <Select
            size="small"
            style={{ width: "100%" }}
            value={form.id_codigo_retencion}
            onChange={(e) => onChangeForm("id_codigo_retencion", e)}
            options={codigos}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            dropdownMatchSelectWidth={false}
            placement={"topRight"}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={5}>
          <Typography>Porcentaje: </Typography>
        </Col>
        <Col span={19}>
          <Input
            size="small"
            style={{ width: "100%" }}
            value={numberFormat(form.porcentaje_retener)}
            disabled
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={5}>
          <Typography>Valor: </Typography>
        </Col>
        <Col span={19}>
          <Input
            size="small"
            style={{ width: "100%" }}
            value={numberFormat(form.valor_retenido)}
            disabled
          />
        </Col>
      </Row>
    </Content>
  );
};
