import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { MdFireTruck } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS,
  ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS,
} from "../../../../configs/constants/estados-documentos-electronicos";
import { TIPOS_DOCUMENTOS } from "../../../../configs/constants/tipos-documentos";
import { TIPOS_IDENTIFICACIONES } from "../../../../configs/constants/tipos-identificaciones";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { CODIGOS_IMPUESTOS } from "../../../../helpers/constants/codigos-impuestos";
import { dateYYYYMMDD } from "../../../../helpers/format/dates-formater";
import { useForm } from "../../../../hooks/useForm";
import { almacenesIdEmpresaRequest } from "../../../../services/api/almacenes";
import {
  actualizarCompraRequest,
  calcularTotalesRequest,
  compraIdRequest,
  eliminarCompraRequest,
} from "../../../../services/api/compras";
import {
  formasPagoSRIRequest,
  paisesRequest,
  sustentoTributarioRequest,
  tarifasIvaRequest,
  tiposDocumentoRequest,
  tiposFormaPagoRequest,
  tiposIdentificacionRequest,
  tiposPagoARequest,
  tiposParaisoFiscalRequest,
  tiposRegimenGeneralRequest,
  tiposRegimenRetencionRequest,
} from "../../../../services/api/opciones";
import {
  secuenciaIdRequest,
  secuenciasIdEmpresaRequest,
} from "../../../../services/api/secuencias";
import { OpcionesCompra, OpcionesDocumento } from "./components/OpcionesCompra";
import { TabMantenimiento } from "./components/TabMantenimiento";
import { TabFormaPago } from "./components/TabFormaPago";
import { cajasIdEmpresaRequest } from "../../../../services/api/cajas";
import { bancosIdEmpresaRequest } from "../../../../services/api/bancos";
import { UNIDAD_TIEMPO } from "../../../../helpers/constants/unidad-tiempo";
import { TagDocumentoElectronico } from "./components/TagDocumentoElectronico";
import { TabRetencion } from "./components/TabRetencion";
import { codigosRetencionesIdEmpresaRequest } from "../../../../services/api/codigos-retenciones";
import { CODIGOS_TIPO_RETENCION } from "../../../../helpers/constants/codigos-tipos-retencion";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const DetalleCompraPage = () => {
  const { id } = useParams();

  const { isProductosChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();
  const inputPasswordRef = useRef();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    fecha_emision: dayjs(new Date()),
    fecha_vence: dayjs(new Date()),
    fecha_registro: dayjs(new Date()),
    codigo_establecimiento: "",
    codigo_punto_emision: "",
    secuencia: "",
    ats: true,
    concepto: "",
    contabilizado: false,
    id_tipo_documento: null,
    id_secuencia: null,
    id_sustento_tributario: null,
    id_almacen: null,
  });

  const [compra, setCompra] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setEdit(false);
    setCompra({});
    setProductos([]);
    setFormRetencion({});
    setRetencionesIva([]);
    setRetencionesRenta([]);
    setReload(!reload);
  };

  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [secuencias, setSecuencias] = useState([]);
  const [sustentosTributarios, setSustentosTributarios] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);

  const [secuenciasRetencion, setSecuenciasRetencion] = useState([]);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [tiposPagosA, setTiposPagosA] = useState([]);
  const [tiposRegimenRetencion, setTiposRegimenRetencion] = useState([]);
  const [tiposRegimenGeneral, setTiposRegimenGeneral] = useState([]);
  const [tiposParaisoFiscal, setTiposParaisoFiscal] = useState([]);
  const [paises, setPaises] = useState([]);
  const [codigosRetencionesRenta, setCodigosRetencionesRenta] = useState([]);
  const [codigosRetencionesIva, setCodigosRetencionesIva] = useState([]);
  const [retencionesRenta, setRetencionesRenta] = useState([]);

  const [disponibleRenta, setDisponibleRenta] = useState(0);

  const [retencionesIva, setRetencionesIva] = useState([]);

  const [disponibleIva, setDisponibleIva] = useState(0);

  const {
    form: formRetencion,
    setForm: setFormRetencion,
    onChangeForm: onChangeFormRetencion,
  } = useForm({
    id_secuencia: null,
    fecha_emision: dayjs(new Date()),
    asumir_retencion: false,
    codigo_establecimiento: "",
    codigo_punto_emision: "",
    secuencia: "",
    autorizacion: "",
    iva_cero: 0,
    iva_diferente_cero: 0,
    monto_iva: 0,
    monto_ice: 0,
    id_tipo_identificacion: null,
    identificacion: "",
    parte_relacionada: false,
    id_tipo_pago_a: null,
    id_tipo_regimen_retencion: null,
    id_tipo_regimen_general: null,
    id_tipo_paraiso_fiscal: null,
    denominacion: "",
    id_pais: null,
    doble_tributacion: true,
    norma_legal: true,
  });

  const [cajas, setCajas] = useState([]);
  const [formasPagosSri, setFormasPagosSri] = useState([]);
  const [tiposFormasPagos, setTiposFormasPagos] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [ivas, setIvas] = useState([]);

  const [totales, setTotales] = useState({
    sub_total: 0,
    descuento_valor: 0,
    sub_total_neto: 0,
    sub_total_iva: 0,
    sub_total_iva_cero: 0,
    sub_total_no_objeto: 0,
    sub_total_exento: 0,
    total_ice: 0,
    total_iva: 0,
    propina: 0,
    iva_presuntivo: 0,
    ir_presuntiva: 0,
    total: 0,
    retencion_iva: 0,
    retencion_renta: 0,
    cantidad_items: 0,
    total_cantidad: 0,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [errorCalculos, setErrorCalculos] = useState({
    show: false,
    message: false,
  });

  const [errorRetencion, setErrorRetencion] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetalles, setIsLoadingDetalles] = useState(false);
  const [isLoadingTotales, setIsLoadingTotales] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    loadInitValues();
  }, [empresa, reload]);

  useEffect(() => {
    if (!edit) return;
    setForm((prev) => ({
      ...prev,
      concepto: "",
    }));
  }, [edit]);

  const loadInitValues = () => {
    setIsLoading(true);
    Promise.all([
      compraIdRequest(id),
      tiposDocumentoRequest(),
      secuenciasIdEmpresaRequest(empresa.id_empresa),
      sustentoTributarioRequest(),
      almacenesIdEmpresaRequest(empresa.id_empresa),
      formasPagoSRIRequest(),
      cajasIdEmpresaRequest(empresa.id_empresa),
      tiposFormaPagoRequest(),
      bancosIdEmpresaRequest(empresa.id_empresa),
      tarifasIvaRequest(),
      codigosRetencionesIdEmpresaRequest(empresa.id_empresa),
      tiposIdentificacionRequest(),
      tiposPagoARequest(),
      tiposRegimenRetencionRequest(),
      tiposRegimenGeneralRequest(),
      tiposParaisoFiscalRequest(),
      paisesRequest(),
    ])
      .then(
        ([
          compraRes,
          tiposDocumentosRes,
          secuenciasRes,
          sustentosTributariosRes,
          almacenesRes,
          formasPagoSRIRes,
          cajasRes,
          tiposFormasPagosRes,
          bancosRes,
          tarifasIvaRes,
          codigosRetencioneRes,
          tiposIdentificacionRes,
          tiposPagosARes,
          tiposRegimenRetencionRes,
          tiposRegimenGeneralRes,
          tiposParaisoFiscalRes,
          paisesRes,
        ]) => {

          setCompra(compraRes);
          setForm((prev) => ({
            ...prev,
            ...compraRes,
            fecha_emision: dayjs(compraRes.fecha_emision),
            fecha_vence: dayjs(compraRes.fecha_vence),
            fecha_registro: dayjs(compraRes.fecha_registro),
            codigo_establecimiento: compraRes.codigo_establecimiento,
            codigo_punto_emision: compraRes.codigo_punto_emision,
            secuencia: Number(compraRes.secuencia_text),
            ats: compraRes.ats,
            concepto: compraRes.concepto,
            contabilizado: compraRes.contabilizado,
            id_tipo_documento: compraRes.tipo_documento?.id_tipo_documento,
            id_secuencia: compraRes.secuencia?.id_secuencia,
            id_sustento_tributario:
              compraRes.sustento_tributario?.id_sustento_tributario,
            id_almacen: compraRes.almacen?.id_almacen,
          }));

          setTotales({
            sub_total: compraRes.sub_total,
            descuento_valor: compraRes.descuento_valor,
            sub_total_neto: compraRes.sub_total_neto,
            sub_total_iva: compraRes.sub_total_iva,
            sub_total_iva_cero: compraRes.sub_total_iva_cero,
            sub_total_no_objeto: compraRes.sub_total_no_objeto,
            sub_total_exento: compraRes.sub_total_exento,
            total_ice: compraRes.total_ice,
            total_iva: compraRes.total_iva,
            propina: compraRes.propina,
            iva_presuntivo: compraRes.iva_presuntivo,
            ir_presuntiva: compraRes.ir_presuntiva,
            total: compraRes.total,
            retencion_iva: compraRes.retencion_iva,
            retencion_renta: compraRes.retencion_renta,
            cantidad_items: compraRes.cantidad_items,
            total_cantidad: compraRes.total_cantidad,
          });

          const detalles = compraRes.detalles_compras.map((detalle, index) => {
            let iva = 0;
            let iva_producto = {};
            if (detalle.producto?.id_producto) {
              iva = detalle.producto?.iva_producto?.descripcion;
              iva_producto = detalle.producto?.iva_producto;
            }

            if (detalle.gasto?.id_gasto) {
              iva = detalle.gasto?.iva?.descripcion;
              iva_producto = detalle.gasto?.iva;
            }
            return {
              ...detalle,
              id_producto: detalle.producto?.id_producto,
              id_gasto: detalle.gasto?.id_gasto,
              subsidio: detalle.valor_subsidio,
              precio_unitario: detalle.costo,
              descuento: detalle.descuento_valor,
              iva: iva,
              iva_producto: iva_producto,
              index: index,
            };
          });

          setProductos(detalles);

          const pagosCompra = compraRes.pagos_compras.map((pago, index) => {
            return {
              ...pago,
              fecha: pago.fecha ? dayjs(pago.fecha) : null,
              fecha_vence: pago.fecha_vence ? dayjs(pago.fecha_vence) : null,
              index: index,
              id_tipo_forma_pago: pago.tipo_forma_pago?.id_tipo_forma_pago,
              id_forma_pago_sri: pago.forma_pago_sri?.id_forma_pago_sri,
              id_banco: pago.banco?.id_banco,
              id_caja: pago.caja?.id_caja,
            };
          });

          setPagos(pagosCompra);

          setTiposDocumentos(tiposDocumentosRes);
          setSecuencias(secuenciasRes);
          setSustentosTributarios(sustentosTributariosRes);
          setAlmacenes(almacenesRes);
          setFormasPagosSri(formasPagoSRIRes);
          setCajas(cajasRes);
          setTiposFormasPagos(tiposFormasPagosRes);
          setBancos(bancosRes);
          setIvas(tarifasIvaRes);

          if (codigosRetencioneRes.length > 0) {
            const codigosRetencionesRentaFilter = codigosRetencioneRes.filter(
              (codigoRetencion) =>
                codigoRetencion.tipo_retencion.codigo ===
                CODIGOS_TIPO_RETENCION.RETENCION_RENTA.toString()
            );
            const codigosRetencionesIvaFilter = codigosRetencioneRes.filter(
              (codigoRetencion) =>
                codigoRetencion.tipo_retencion.codigo ===
                CODIGOS_TIPO_RETENCION.RETENCION_IVA.toString()
            );
            setCodigosRetencionesRenta(codigosRetencionesRentaFilter);
            setCodigosRetencionesIva(codigosRetencionesIvaFilter);
          }

          if (secuenciasRes.length > 0) {
            const secuenciasFilter = secuenciasRes.filter(
              (secuenciaRes) =>
                secuenciaRes.tipo_documento?.codigo ===
                TIPOS_DOCUMENTOS.RETENCION
            );

            if (secuenciasFilter.length === 0) {
              setErrorRetencion({
                show: true,
                message:
                  "No se encuentra Secuencia para Comprobantes de Retencion, creelo en Ventas / Secuencias",
              });
            }

            setSecuenciasRetencion(secuenciasFilter);
            const secuencia = secuenciasFilter[0];
            if (secuencia){
              setFormRetencion((prev) => ({
                ...prev,
                id_secuencia: secuencia.id_secuencia,
              }));
            };
          }

          if (tiposIdentificacionRes.length > 0) {
            setTiposIdentificacion(tiposIdentificacionRes);
          }

          if (tiposPagosARes.length > 0) {
            setTiposPagosA(tiposPagosARes);
            const tipoPagoAFind = tiposPagosARes.find(
              (tipoPagoA) => tipoPagoA.id_tipo_pago_a === 1
            );
            if (tipoPagoAFind) {
              setFormRetencion((prev) => ({
                ...prev,
                id_tipo_pago_a: tipoPagoAFind.id_tipo_pago_a,
              }));
            }
          }

          if (tiposRegimenRetencionRes.length > 0) {
            setTiposRegimenRetencion(tiposRegimenRetencionRes);
            const tipoRegimenRetencionFind = tiposRegimenRetencionRes.find(
              (tipoRegimenRetencion) =>
                tipoRegimenRetencion.id_tipo_regimen_retencion === 1
            );
            if (tipoRegimenRetencionFind){
              setFormRetencion((prev) => ({
                ...prev,
                id_tipo_regimen_retencion:
                  tipoRegimenRetencionFind.id_tipo_regimen_retencion,
              }));
            }
          }

          if (tiposRegimenGeneralRes.length > 0) {
            setTiposRegimenGeneral(tiposRegimenGeneralRes);
            const tipoRegimenGeneralFind = tiposRegimenGeneralRes[0];
            if (tipoRegimenGeneralFind){
              setFormRetencion((prev) => ({
                ...prev,
                id_tipo_regimen_general:
                  tipoRegimenGeneralFind.id_tipo_regimen_general,
              }));
            }
          }

          if (tiposParaisoFiscalRes.length > 0) {
            setTiposParaisoFiscal(tiposParaisoFiscalRes);
            const tipoParaisoFiscalFind = tiposParaisoFiscalRes[0];
            if (tipoParaisoFiscalFind){
              setFormRetencion((prev) => ({
                ...prev,
                id_tipo_paraiso_fiscal:
                  tipoParaisoFiscalFind.id_tipo_paraiso_fiscal,
              }));
            }
          }

          if (paisesRes.length > 0) {
            setPaises(paisesRes);
            const paisFind = paisesRes[0];
            if (paisFind){
              setFormRetencion((prev) => ({
                ...prev,
                id_pais: paisFind.id_pais,
              }));
            }
          }

          const retencion = compraRes.retencion;

          if (retencion) {
            setFormRetencion((prev) => ({
              ...prev,
              id_secuencia: retencion.secuencia.id_secuencia,
              fecha_emision: dayjs(retencion.fecha_emision),
              asumir_retencion: retencion.asumir_retencion,
              codigo_establecimiento: retencion.codigo_establecimiento,
              codigo_punto_emision: retencion.codigo_punto_emision,
              secuencia: Number(retencion.secuencia_text),
              autorizacion: retencion.autorizacion,
              iva_cero: retencion.iva_cero,
              iva_diferente_cero: retencion.iva_diferente_cero,
              monto_iva: retencion.monto_iva,
              monto_ice: retencion.monto_ice,
              id_tipo_identificacion:
                retencion.tipo_identificacion?.id_tipo_identificacion,
              identificacion: retencion.identificacion,
              parte_relacionada: retencion.parte_relacionada,
              id_tipo_pago_a: retencion.tipo_pago_a?.id_tipo_pago_a,
              id_tipo_regimen_retencion:
                retencion.tipo_regimen_retencion?.id_tipo_regimen_retencion,
              id_tipo_regimen_general:
                retencion.tipo_regimen_general?.id_tipo_regimen_general,
              id_tipo_paraiso_fiscal:
                retencion.tipo_paraiso_fiscal?.id_tipo_paraiso_fiscal,
              denominacion: retencion.denominacion,
              id_pais: retencion.pais?.id_pais,
              doble_tributacion: retencion.doble_tributacion,
              norma_legal: retencion.norma_legal,

              numero_documento: retencion?.numero_documento,
              electronico: retencion?.electronico,
              estado_documento_electronico:
                retencion?.estado_documento_electronico,
              autorizacion: retencion?.autorizacion,
              clave_acceso: retencion?.clave_acceso,
            }));

            const detalles_retenciones = retencion.detalles_retenciones;

            const detalleRenta = detalles_retenciones
              .filter(
                (detalle_retencion) =>
                  detalle_retencion.codigo_impuesto_retencion ===
                  CODIGOS_TIPO_RETENCION.RETENCION_RENTA
              )
              .map((detalle_retencion, index) => ({
                index: index,
                codigo_impuesto_retencion:
                  detalle_retencion.codigo_impuesto_retencion,
                codigo: detalle_retencion.codigo,
                base_imponible: detalle_retencion.base_imponible,
                porcentaje_retener: detalle_retencion.porcentaje_retener,
                valor_retenido: detalle_retencion.valor_retenido,
                id_codigo_retencion:
                  detalle_retencion.codigo_retencion?.id_codigo_retencion,
              }));

            const detalleIva = detalles_retenciones
              .filter(
                (detalle_retencion) =>
                  detalle_retencion.codigo_impuesto_retencion ===
                  CODIGOS_TIPO_RETENCION.RETENCION_IVA
              )
              .map((detalle_retencion, index) => ({
                index: index,
                codigo_impuesto_retencion:
                  detalle_retencion.codigo_impuesto_retencion,
                codigo: detalle_retencion.codigo,
                base_imponible: detalle_retencion.base_imponible,
                porcentaje_retener: detalle_retencion.porcentaje_retener,
                valor_retenido: detalle_retencion.valor_retenido,
                id_codigo_retencion:
                  detalle_retencion.codigo_retencion?.id_codigo_retencion,
              }));

            setRetencionesRenta(detalleRenta);

            setRetencionesIva(detalleIva);
          }
        }
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (form.id_secuencia && edit) {
      setIsLoading(true);
      secuenciaIdRequest(form.id_secuencia)
        .then((res) => {
          setForm((prev) => ({
            ...prev,
            codigo_establecimiento: res.sucursal,
            codigo_punto_emision: res.punto_emision,
            secuencia: res.numero_actual,
            autorizacion: res.numero_autorizacion_sri,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [form.id_secuencia]);

  useEffect(() => {
    if (form.id_tipo_documento && tiposDocumentos.length > 0 && edit) {
      const tipoDocumento = tiposDocumentos.find(
        (tipoDocumento) =>
          tipoDocumento.id_tipo_documento === form.id_tipo_documento
      );
      if (!tipoDocumento) return;

      if (tipoDocumento.codigo === TIPOS_DOCUMENTOS.LIQUIDACIONES) {
        const secuenciasLiquidaciones = secuencias.filter(
          (secuencia) =>
            secuencia.tipo_documento.codigo === TIPOS_DOCUMENTOS.LIQUIDACIONES
        );
        if (secuenciasLiquidaciones.length === 0) {
          setError({
            show: true,
            message:
              "No hay secuencias para Liquidaciones,asegurece de haber configurado correctamente la secuencia. Revisar Ventas / Secuencias",
          });
          return;
        }

        setSecuencias(secuenciasLiquidaciones);
        setForm((prev) => ({
          ...prev,
          id_secuencia: secuenciasLiquidaciones[0].id_secuencia,
        }));
      } else {
        setForm((prev) => ({
          ...prev,
          id_secuencia: null,
          codigo_establecimiento: "",
          codigo_punto_emision: "",
          secuencia: "",
        }));
      }
    }
  }, [form.id_tipo_documento, tiposDocumentos]);

  useEffect(() => {
    if (form.proveedor && tiposDocumentos.length > 0 && edit) {
      if (
        form.proveedor.tipo_identificacion?.codigo !==
        TIPOS_IDENTIFICACIONES.RUC
      ) {
        const tipoDocumentoLiquidacion = tiposDocumentos.find(
          (tipoDocumento) =>
            tipoDocumento.codigo === TIPOS_DOCUMENTOS.LIQUIDACIONES
        );
        if (!tipoDocumentoLiquidacion) return;
        setForm((prev) => ({
          ...prev,
          id_tipo_documento: tipoDocumentoLiquidacion.id_tipo_documento,
        }));
      }
      if (
        form.proveedor.tipo_identificacion?.codigo ===
        TIPOS_IDENTIFICACIONES.RUC
      ) {
        const tipoDocumentoFactura = tiposDocumentos.find(
          (tipoDocumento) => tipoDocumento.codigo === TIPOS_DOCUMENTOS.FACTURAS
        );
        if (!tipoDocumentoFactura) return;
        setForm((prev) => ({
          ...prev,
          id_tipo_documento: tipoDocumentoFactura.id_tipo_documento,
        }));
      }
    }
  }, [form.proveedor, tiposDocumentos]);

  const seleccionarProducto = (p) => {
    const index = p.index;
    setProductoSeleccionado(index);
  };

  const eliminarProducto = (p) => {
    const temporal = productos.filter((producto, index) => index !== p.index);
    calcularTotales(
      temporal,
      totales.propina,
      totales.iva_presuntivo,
      totales.ir_presuntiva,
      totales.retencion_iva,
      totales.retencion_renta
    );
    setProductoSeleccionado(null);
  };

  const agregarProducto = (p) => {
    if (p.id_producto) {
      const find = productos.find(
        (producto) => producto.id_producto === p.id_producto
      );
      if (find) return;

      const index = productos.length;

      const detalles = [
        ...productos,
        {
          ...p,
          codigo: p.codigo,
          descripcion: p.descripcion,
          cantidad: 1,
          descuento: 0,
          precio_unitario:
            p.tarifas_productos?.find(
              (tp) => tp.medida === "interna" && tp.tarifa === "Precio 1"
            )?.precio ?? 0,
          iva: p.iva_producto?.descripcion,
          index: index,
        },
      ];

      calcularTotales(
        detalles,
        totales.propina,
        totales.iva_presuntivo,
        totales.ir_presuntiva,
        totales.retencion_iva,
        totales.retencion_renta
      );
    }

    if (p.id_gasto) {
      const index = productos.length;

      const detalles = [
        ...productos,
        {
          ...p,
          codigo: p.cuenta_contable_gasto?.codigo_contable,
          descripcion: p.cuenta_contable_gasto?.nombre_cuenta_contable,
          cantidad: 1,
          descuento: 0,
          precio_unitario: 0,
          iva: p.iva?.descripcion,
          iva_producto: p.iva,
          subsidio: 0,
          index: index,
        },
      ];

      calcularTotales(
        detalles,
        totales.propina,
        totales.iva_presuntivo,
        totales.ir_presuntiva,
        totales.retencion_iva,
        totales.retencion_renta
      );
    }
  };

  const validarCampos = () => {
    const {
      fecha_emision,
      fecha_vence,
      fecha_registro,
      proveedor,
      secuencia,
      codigo_establecimiento,
      codigo_punto_emision,
    } = form;

    if (!dayjs(fecha_emision).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha de Emision",
      });
      return false;
    }

    if (!dayjs(fecha_vence).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!dayjs(fecha_registro).isValid()) {
      setError({
        show: true,
        message: "Seleccione la Fecha en la que Vence",
      });
      return false;
    }

    if (!proveedor?.id_proveedor) {
      setError({
        show: true,
        message: "Seleccione un Proveedor",
      });
      return false;
    }

    if (
      !Number.isInteger(Number(codigo_establecimiento)) ||
      Number(codigo_establecimiento) <= 0
    ) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (
      !Number.isInteger(Number(codigo_punto_emision)) ||
      Number(codigo_punto_emision) <= 0
    ) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (!Number.isInteger(Number(secuencia)) || Number(secuencia) <= 0) {
      setError({
        show: true,
        message: "La secuencia debe ser un numero entero y no estar en cero",
      });
      return false;
    }

    if (productos.length === 0) {
      setError({
        show: true,
        message: "Agrege Detalles a la Compra",
      });
      return false;
    }

    return true;
  };

  const guardar = async () => {
    setError({ show: false, message: "" });

    const validacion = validarCampos();
    if (!validacion) return;

    const detalles_compras = productos.map((p) => {
      return {
        codigo: p.codigo,
        descripcion: p.descripcion,
        cantidad: !p.cantidad ? 0 : Number(p.cantidad),
        descuento_porcentaje: !p.descuento_porcentaje
          ? 0
          : Number(p.descuento_porcentaje),
        descuento_valor: !p.descuento ? 0 : Number(p.descuento),
        costo: !p.precio_unitario ? 0 : Number(p.precio_unitario),
        valor_subsidio: !p.valor_subsidio ? 0 : Number(p.valor_subsidio),
        precio_sin_subsidio: !p.precio_sin_subsidio
          ? 0
          : Number(p.precio_sin_subsidio),
        precio_total_sin_impuestos: !p.precio_total_sin_impuestos
          ? 0
          : Number(p.precio_total_sin_impuestos),
        codigo_iva: CODIGOS_IMPUESTOS.IVA,
        codigo_porcentaje_iva: Number(p.codigo_porcentaje_iva).toString(),
        tarifa_iva: !p.tarifa_iva ? 0 : Number(p.tarifa_iva),
        base_imponible_iva: !p.base_imponible_iva
          ? 0
          : Number(p.base_imponible_iva),
        valor_iva: !p.valor_iva ? 0 : Number(p.valor_iva),
        codigo_ice: CODIGOS_IMPUESTOS.ICE,
        codigo_porcentaje_ice: !p.codigo_porcentaje_ice
          ? null
          : Number(p.codigo_porcentaje_ice).toString(),
        tarifa_ice: !p.tarifa_ice ? 0 : Number(p.tarifa_ice),
        base_imponible_ice: !p.base_imponible_ice
          ? 0
          : Number(p.base_imponible_ice),
        valor_ice: !p.valor_ice ? 0 : Number(p.valor_ice),
        codigo_irbpnr: CODIGOS_IMPUESTOS.IRBPNR,
        codigo_porcentaje_irbpnr: !p.codigo_porcentaje_irbpnr
          ? null
          : Number(p.codigo_porcentaje_irbpnr).toString(),
        tarifa_irbpnr: !p.tarifa_irbpnr ? 0 : Number(p.tarifa_irbpnr),
        base_imponible_irbpnr: !p.base_imponible_irbpnr
          ? 0
          : Number(p.base_imponible_irbpnr),
        valor_irbpnr: !p.valor_irbpnr ? 0 : Number(p.valor_irbpnr),
        neto: !p.neto ? 0 : Number(p.neto),
        total: !p.total ? 0 : Number(p.total),
        id_producto: p.id_producto,
        id_gasto: p.id_gasto,
      };
    });

    const pagos_compras = pagos.map((pago) => {
      const fecha = pago.fecha ? pago.fecha : dayjs(new Date());
      const fecha_vence = pago.fecha_vence
        ? pago.fecha_vence
        : dayjs(new Date());
      const plazo = dayjs(form.fecha_vence).diff(form.fecha_emision, "day");

      return {
        plazo: plazo,
        unidad_tiempo: UNIDAD_TIEMPO.DIAS,
        total: Number(pago.total),
        fecha: dateYYYYMMDD(fecha),
        fecha_vence: dateYYYYMMDD(fecha_vence),
        numero_documento: pago.numero_documento,
        id_forma_pago_sri: pago.id_forma_pago_sri,
        id_tipo_forma_pago: pago.id_tipo_forma_pago,
        id_caja: pago.id_caja,
        id_banco: pago.id_banco,
      };
    });

    let retencion = null;

    if (
      (retencionesIva.length > 0 || retencionesRenta.length > 0) &&
      form.ats
    ) {
      const total_retencion_iva = totales.retencion_iva;
      const total_retencion_renta = totales.retencion_renta;

      const total = total_retencion_iva + total_retencion_renta;

      const detalles_retenciones = [
        ...retencionesRenta.map((retencionRenta) => ({
          codigo_impuesto_retencion: retencionRenta.codigo_impuesto_retencion,
          codigo: retencionRenta.codigo,
          base_imponible: retencionRenta.base_imponible,
          porcentaje_retener: retencionRenta.porcentaje_retener,
          valor_retenido: retencionRenta.valor_retenido,
          id_codigo_retencion: retencionRenta.id_codigo_retencion,
        })),
        ...retencionesIva.map((retencionIva) => ({
          codigo_impuesto_retencion: retencionIva.codigo_impuesto_retencion,
          codigo: retencionIva.codigo,
          base_imponible: retencionIva.base_imponible,
          porcentaje_retener: retencionIva.porcentaje_retener,
          valor_retenido: retencionIva.valor_retenido,
          id_codigo_retencion: retencionIva.id_codigo_retencion,
        })),
      ];

      retencion = {
        ats: form.ats,
        secuencia: formRetencion.secuencia,
        fecha_emision: dateYYYYMMDD(formRetencion.fecha_emision),
        total_retencion_iva: total_retencion_iva,
        total_retencion_renta: total_retencion_renta,
        total: total,
        asumir_retencion: formRetencion.asumir_retencion,
        parte_relacionada: formRetencion.parte_relacionada,
        denominacion: formRetencion.denominacion,
        doble_tributacion: formRetencion.doble_tributacion,
        norma_legal: formRetencion.norma_legal,
        id_secuencia: formRetencion.id_secuencia,
        id_proveedor: form.proveedor.id_proveedor,
        id_tipo_pago_a: formRetencion.id_tipo_pago_a,
        id_tipo_regimen_retencion: formRetencion.id_tipo_regimen_retencion,
        id_tipo_regimen_general: formRetencion.id_tipo_regimen_general,
        id_tipo_paraiso_fiscal: formRetencion.id_tipo_paraiso_fiscal,
        id_pais: formRetencion.id_pais,
        detalles_retenciones: detalles_retenciones,
      };
    }

    const dataRequest = {
      fecha_emision: dateYYYYMMDD(form.fecha_emision),
      fecha_vence: dateYYYYMMDD(form.fecha_vence),
      fecha_registro: dateYYYYMMDD(form.fecha_registro),
      codigo_establecimiento: form.codigo_establecimiento,
      codigo_punto_emision: form.codigo_punto_emision,
      secuencia_text: Number(form.secuencia),
      concepto: form.concepto,
      contabilizado: form.contabilizado,
      ats: form.ats,
      autorizacion: form.autorizacion,
      sub_total: !totales.sub_total ? 0 : Number(totales.sub_total),
      descuento_porcentaje: !totales.descuento_porcentaje
        ? 0
        : Number(totales.descuento_porcentaje),
      descuento_valor: !totales.descuento_valor
        ? 0
        : Number(totales.descuento_valor),
      sub_total_neto: !totales.sub_total_neto
        ? 0
        : Number(totales.sub_total_neto),
      sub_total_iva: !totales.sub_total_iva ? 0 : Number(totales.sub_total_iva),
      sub_total_iva_cero: !totales.sub_total_iva_cero
        ? 0
        : Number(totales.sub_total_iva_cero),
      sub_total_no_objeto: !totales.sub_total_no_objeto
        ? 0
        : Number(totales.sub_total_no_objeto),
      sub_total_exento: !totales.sub_total_exento
        ? 0
        : Number(totales.sub_total_exento),
      total_ice: !totales.total_ice ? 0 : Number(totales.total_ice),
      total_iva: !totales.total_iva ? 0 : Number(totales.total_iva),
      propina: !totales.propina ? 0 : Number(totales.propina),
      iva_presuntivo: !totales.iva_presuntivo
        ? 0
        : Number(totales.iva_presuntivo),
      ir_presuntiva: !totales.ir_presuntiva ? 0 : Number(totales.ir_presuntiva),
      retencion_iva: !totales.retencion_iva ? 0 : Number(totales.retencion_iva),
      retencion_renta: !totales.retencion_renta
        ? 0
        : Number(totales.retencion_renta),
      total: !totales.total ? 0 : Number(totales.total),
      cantidad_items: !totales.cantidad_items
        ? 0
        : Number(totales.cantidad_items),
      total_cantidad: !totales.total_cantidad
        ? 0
        : Number(totales.total_cantidad),
      id_empresa: empresa.id_empresa,
      id_proveedor: form.proveedor?.id_proveedor,
      id_almacen: form.id_almacen,
      id_secuencia: form?.id_secuencia,
      id_tipo_documento: form.id_tipo_documento,
      id_sustento_tributario: form.id_sustento_tributario,
      detalles_compras: detalles_compras,
      pagos_compras: pagos_compras,
      id_retencion: compra.retencion?.id_retencion,
      retencion: retencion,
    };

    setIsLoading(true);
    await actualizarCompraRequest(id, dataRequest)
      .then((res) => {
        onResetForm();
        handleReload();
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeDetalles = (data) => {
    const detalles = productos.map((producto, index) => {
      if (data.index === index) {
        return data;
      }
      return producto;
    });

    calcularTotales(
      detalles,
      totales.propina,
      totales.iva_presuntivo,
      totales.ir_presuntiva,
      totales.retencion_iva,
      totales.retencion_renta
    );
  };

  const onChangeTotales = (data) => {
    calcularTotales(
      productos,
      data.propina,
      data.iva_presuntivo,
      data.ir_presuntiva,
      data.retencion_iva,
      data.retencion_renta
    );
  };

  const calcularTotales = (
    p,
    propina,
    iva_presuntivo,
    ir_presuntiva,
    retencion_iva,
    retencion_renta
  ) => {
    setErrorCalculos({
      show: false,
      message: "",
    });

    const dataRequest = {
      detalles_documentos: p.map((producto) => {
        return {
          cantidad: Number(producto.cantidad),
          precio_unitario: Number(producto.precio_unitario),
          descuento: Number(producto.descuento),
          subsidio: Number(producto.subsidio),
          codigo_porcentaje_iva: Number(producto.iva_producto.codigo),
          tarifa_iva: Number(producto.iva_producto.valor),
          tarifa_ice: !producto.porcentaje_ice
            ? null
            : Number(producto.porcentaje_ice),
          tarifa_irbpnr: !producto.porcentaje_irbpnr
            ? null
            : Number(producto.porcentaje_irbpnr),
        };
      }),
      iva_presuntivo: Number(iva_presuntivo),
      ir_presuntiva: Number(ir_presuntiva),
      propina: Number(propina),
      retencion_iva: Number(retencion_iva),
      retencion_renta: Number(retencion_renta),
    };

    setIsLoadingDetalles(true);
    setIsLoadingTotales(true);
    calcularTotalesRequest(dataRequest)
      .then((res) => {
        const detallesRes = res.detalles_documentos.map((d, index) => ({
          ...p[index],
          ...d,
          index: index,
        }));

        setProductos(detallesRes);
        const totalesRes = {
          sub_total: res.sub_total,
          descuento_valor: res.descuento_valor,
          sub_total_neto: res.sub_total_neto,
          sub_total_iva: res.sub_total_iva,
          sub_total_iva_cero: res.sub_total_iva_cero,
          sub_total_no_objeto: res.sub_total_no_objeto,
          sub_total_exento: res.sub_total_exento,
          total_ice: res.total_ice,
          total_iva: res.total_iva,
          propina: res.propina,
          iva_presuntivo: res.iva_presuntivo,
          ir_presuntiva: res.ir_presuntiva,
          total: res.total,
          retencion_iva: res.retencion_iva,
          retencion_renta: res.retencion_renta,
          cantidad_items: res.cantidad_items,
          total_cantidad: res.total_cantidad,
        };
        setTotales(totalesRes);
      })
      .catch((err) => {
        const data = err?.response?.data;
        if (data && data.statusCode === 400) {
          if (data.message.includes("propina")) {
            setTotales({
              ...totales,
              propina: 0,
            });
          }
          setErrorCalculos({
            show: true,
            message: data.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingDetalles(false);
        setIsLoadingTotales(false);
      });
  };

  const onChangeDetalle = (d) => {
    const index = productos.findIndex((e) => e.id_producto === d.id_producto);
    const arrayTemp = productos.map((e, i) => {
      if (index === i) return { ...e, ...d };
      return e;
    });

    setProductos(arrayTemp);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const id_compra = compra.id_compra;
    if (!id_compra) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta Compra, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCompraRequest(id_compra).then((res) => {
      navigate(-1);
    });
  };

  const registrarPago = (pago) => {
    if (totales.total === 0) {
      alert("El total es 0 no se puede agregar un pago");
      return;
    }

    const tipo_forma_pago = tiposFormasPagos.find(
      (tipoFormaPago) =>
        tipoFormaPago.id_tipo_forma_pago === pago.id_tipo_forma_pago
    );

    const banco = bancos.find((banco) => banco.id_banco === pago.id_banco);

    const caja = cajas.find((caja) => caja.id_caja === pago.id_caja);

    const forma_pago_sri = formasPagosSri.find(
      (formaPagoSri) =>
        formaPagoSri.id_forma_pago_sri === pago.id_forma_pago_sri
    );

    const index = pagos.length;

    const nuevoPago = {
      ...pago,
      index: index,
      tipo_forma_pago: tipo_forma_pago,
      caja: caja,
      banco: banco,
      forma_pago_sri: forma_pago_sri,
    };

    setPagos((prev) => [...prev, nuevoPago]);
  };

  const eliminarPago = (pagoData) => {
    const pagosLista = pagos
      .filter((_, index) => index !== pagoData.index)
      .map((pago, index) => ({ ...pago, index: index }));
    setPagos(pagosLista);
  };

  const editarPago = (pagoData) => {
    const pagosLista = pagos.map((pago, index) => {
      if (pagoData.index === index) {
        return {
          ...pago,
          ...pagoData,
        };
      }
      return pago;
    });
    setPagos(pagosLista);
  };

  const onChangeRetencion = (retencion_renta, retencion_iva) => {
    calcularTotales(
      productos,
      totales.propina,
      totales.iva_presuntivo,
      totales.ir_presuntiva,
      retencion_iva,
      retencion_renta
    );
  };

  useEffect(() => {
    setFormRetencion((prev) => ({
      ...prev,
      iva_cero: totales.sub_total_iva_cero,
      iva_diferente_cero: totales.sub_total_iva,
      monto_iva: totales.total_iva,
      monto_ice: totales.total_ice,
    }));
  }, [totales]);

  useEffect(() => {
    const subtotal = Number(totales.sub_total ?? 0);
    const totalRetencionesRenta = retencionesRenta.reduce(
      (a, v) => a + Number(v.base_imponible ?? 0),
      0
    );
    setDisponibleRenta(subtotal - totalRetencionesRenta);
  }, [totales, retencionesRenta]);

  useEffect(() => {
    const total_iva = Number(totales.total_iva ?? 0);
    const totalRetencionesIva = retencionesIva.reduce(
      (a, v) => a + Number(v.base_imponible ?? 0),
      0
    );
    setDisponibleIva(total_iva - totalRetencionesIva);
  }, [totales, retencionesIva]);

  useEffect(() => {
    if (
      formRetencion.id_secuencia ===
        compra?.retencion?.secuencia?.id_secuencia &&
      compra?.retencion?.secuencia
    ) {
      setFormRetencion((prev) => ({
        ...prev,
        codigo_establecimiento: compra?.retencion?.secuencia?.sucursal,
        codigo_punto_emision: compra?.retencion?.secuencia?.punto_emision,
        secuencia: Number(compra?.retencion?.secuencia_text),
        autorizacion: compra?.retencion?.autorizacion,
      }));
      return;
    }
    if (formRetencion.id_secuencia) {
      setIsLoading(true);
      secuenciaIdRequest(formRetencion.id_secuencia)
        .then((res) => {
          setFormRetencion((prev) => ({
            ...prev,
            codigo_establecimiento: res.sucursal,
            codigo_punto_emision: res.punto_emision,
            secuencia: res.numero_actual,
            autorizacion: res.numero_autorizacion_sri,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [formRetencion.id_secuencia]);

  useEffect(() => {
    const id_tipo_identificacion =
      form.proveedor?.tipo_identificacion?.id_tipo_identificacion;
    if (!id_tipo_identificacion) return;
    setFormRetencion((prev) => ({
      ...prev,
      id_tipo_identificacion: id_tipo_identificacion,
    }));
  }, [form.proveedor]);

  useEffect(() => {
    const identificacion = form.proveedor?.identificacion;
    if (!identificacion) return;
    setFormRetencion((prev) => ({
      ...prev,
      identificacion: identificacion,
    }));
  }, [form.proveedor]);

  useEffect(() => {
    if (!edit) return;
    if (!formRetencion.id_tipo_regimen_general) return;
    const tipoRegimenGeneral = tiposRegimenGeneral.find(
      (tipoRegimenGeneral) =>
        tipoRegimenGeneral.id_tipo_regimen_general ===
        formRetencion.id_tipo_regimen_general
    );
    if (!tipoRegimenGeneral) return;
    const pais = paises.find(
      (pais) => pais.id_pais === Number(tipoRegimenGeneral.codigo)
    );
    if (!pais) return;
    setFormRetencion((prev) => ({
      ...prev,
      id_pais: pais.id_pais,
    }));
  }, [formRetencion.id_tipo_regimen_general]);

  useEffect(() => {
    if (!edit) return;
    if (!formRetencion.id_tipo_paraiso_fiscal) return;
    const paraiso = tiposParaisoFiscal.find(
      (tipoParaisoFiscal) =>
        tipoParaisoFiscal.id_tipo_paraiso_fiscal ===
        formRetencion.id_tipo_paraiso_fiscal
    );
    if (!paraiso) return;
    const pais = paises.find(
      (pais) => pais.id_pais === Number(paraiso.codigo_pais)
    );
    if (!pais) return;
    setFormRetencion((prev) => ({
      ...prev,
      id_pais: pais.id_pais,
    }));
  }, [formRetencion.id_tipo_paraiso_fiscal]);

  useEffect(() => {
    const retencion_renta = retencionesRenta.reduce(
      (a, v) => a + Number(v.valor_retenido ?? 0),
      0
    );
    const retencion_iva = retencionesIva.reduce(
      (a, v) => a + Number(v.valor_retenido ?? 0),
      0
    );
    onChangeRetencion(retencion_renta, retencion_iva);
  }, [retencionesRenta, retencionesIva]);

  useEffect(() => {
    if (!edit) return;
    if (form.ats) return;
    setRetencionesRenta([]);
    setRetencionesIva([]);
  }, [form.ats]);

  const addRetencionRenta = (r) => {
    const index = retencionesRenta.length;
    setRetencionesRenta((prev) => [
      ...prev,
      {
        index: index,
        codigo_impuesto_retencion: Number(r.codigo_impuesto_retencion),
        codigo: r.codigo,
        base_imponible: Number(r.base_imponible),
        porcentaje_retener: Number(r.porcentaje_retener),
        valor_retenido: Number(r.valor_retenido),
        id_codigo_retencion: r.id_codigo_retencion,
      },
    ]);
  };

  const removeRetencionRenta = (r) => {
    setRetencionesRenta(
      retencionesRenta
        .filter((_, index) => index !== r.index)
        .map((retencionRenta, index) => ({ ...retencionRenta, index }))
    );
  };

  const editRetencionRenta = (r) => {
    setRetencionesRenta(
      retencionesRenta.map((retencionRenta, index) => {
        if (index === r.index) {
          return {
            ...r,
          };
        }
        return retencionRenta;
      })
    );
  };

  const addRetencionIva = (r) => {
    const index = retencionesIva.length;
    setRetencionesIva((prev) => [
      ...prev,
      {
        index: index,
        codigo_impuesto_retencion: Number(r.codigo_impuesto_retencion),
        codigo: r.codigo,
        base_imponible: Number(r.base_imponible),
        porcentaje_retener: Number(r.porcentaje_retener),
        valor_retenido: Number(r.valor_retenido),
        id_codigo_retencion: r.id_codigo_retencion,
      },
    ]);
  };

  const removeRetencionIva = (r) => {
    setRetencionesIva(
      retencionesIva
        .filter((_, index) => index !== r.index)
        .map((retencionIva, index) => ({ ...retencionIva, index }))
    );
  };

  const editRetencionIva = (r) => {
    setRetencionesIva(
      retencionesIva.map((retencionIva, index) => {
        if (index === r.index) {
          return {
            ...r,
          };
        }
        return retencionIva;
      })
    );
  };

  const items = [
    {
      key: 1,
      label: `Mantenimiento`,
      children: (
        <TabMantenimiento
          edit={!edit}
          form={form}
          onChangeForm={onChangeForm}
          tiposDocumentos={tiposDocumentos}
          secuencias={secuencias}
          sustentosTributarios={sustentosTributarios}
          almacenes={almacenes}
          productos={productos}
          productoSeleccionado={productos[productoSeleccionado]}
          ivas={ivas}
          seleccionarProducto={seleccionarProducto}
          eliminarProducto={eliminarProducto}
          agregarProducto={agregarProducto}
          totales={totales}
          setTotales={setTotales}
          onChange={onChangeDetalles}
          isLoadingDetalles={isLoadingDetalles}
          isLoadingTotales={isLoadingTotales}
          onChangeTotales={onChangeTotales}
          errorCalculos={errorCalculos}
          setErrorCalculos={setErrorCalculos}
          onChangeDetalle={onChangeDetalle}
        />
      ),
    },
    {
      key: 2,
      label: `Retención`,
      disabled: !form.ats,
      children: (
        <TabRetencion
          edit={!edit}
          form={formRetencion}
          setForm={setFormRetencion}
          onChangeForm={onChangeFormRetencion}
          totales={totales}
          errorRetencion={errorRetencion}
          setErrorRetencion={setErrorRetencion}
          id_tipo_identificacion={
            form.proveedor?.tipo_identificacion?.id_tipo_identificacion
          }
          identificacion={form.proveedor?.identificacion}
          onChangeRetencion={onChangeRetencion}
          isLoading={isLoading}
          secuencias={secuenciasRetencion}
          tiposIdentificacion={tiposIdentificacion}
          tiposPagosA={tiposPagosA}
          tiposRegimenRetencion={tiposRegimenRetencion}
          tiposRegimenGeneral={tiposRegimenGeneral}
          tiposParaisoFiscal={tiposParaisoFiscal}
          paises={paises}
          retencionesRenta={retencionesRenta}
          retencionesIva={retencionesIva}
          disponibleRenta={disponibleRenta}
          disponibleIva={disponibleIva}
          codigosRetencionesRenta={codigosRetencionesRenta}
          codigosRetencionesIva={codigosRetencionesIva}
          addRetencionRenta={addRetencionRenta}
          removeRetencionRenta={removeRetencionRenta}
          editRetencionRenta={editRetencionRenta}
          addRetencionIva={addRetencionIva}
          removeRetencionIva={removeRetencionIva}
          editRetencionIva={editRetencionIva}
        />
      ),
    },
    {
      key: 3,
      label: `Forma de cobro`,
      children: (
        <TabFormaPago
          pagos={pagos}
          totales={totales}
          formasPagosSri={formasPagosSri}
          cajas={cajas}
          bancos={bancos}
          registrarPago={registrarPago}
          eliminarPago={eliminarPago}
          editarPago={editarPago}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Compras",
                },
                {
                  title: (
                    <Row gutter={10}>
                      <Col>
                        <Typography>{compra?.numero_documento}</Typography>
                      </Col>
                      <Col>
                        {(compra.electronico ||
                          compra.estado_documento_electronico ===
                            CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO) && (
                          <TagDocumentoElectronico
                            estado={compra.estado_documento_electronico}
                          />
                        )}
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <OpcionesCompra
                    compra={compra}
                    setIsLoading={setIsLoading}
                    handleReload={handleReload}
                  />
                </Col>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCOMPRAS").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MCOMPRAS").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        {compra.codigo_error_sri && compra.error_sri && (
          <Alert
            message={
              <Row align={"middle"}>
                <Typography style={{ fontSize: 12 }}>COMPRA</Typography>
                &nbsp; - &nbsp;
                <Typography style={{ fontSize: 12 }}>
                  {
                    ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS[
                      compra.estado_documento_electronico
                    ]
                  }
                </Typography>
              </Row>
            }
            description={`(${compra.codigo_error_sri}) ${compra.error_sri}`}
            type="error"
            // showIcon

            style={{ padding: 5, fontSize: 12 }}
          />
        )}
        {compra.retencion?.codigo_error_sri && compra.retencion?.error_sri && (
          <Alert
            message={
              <Row align={"middle"}>
                <Typography style={{ fontSize: 12 }}>RETENCIÓN</Typography>
                &nbsp; - &nbsp;
                <Typography style={{ fontSize: 12 }}>
                  {
                    ESTADOS_POR_CODIGO_DOCUMENTOS_ELECTRONICOS[
                      compra.retencion?.estado_documento_electronico
                    ]
                  }
                </Typography>
              </Row>
            }
            description={`(${compra.retencion?.codigo_error_sri}) ${compra.retencion?.error_sri}`}
            type="error"
            // showIcon

            style={{ padding: 5, fontSize: 12 }}
          />
        )}
        <Form disabled={!edit}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
