import API from "../../configs/api/api.config";

const URL_CATEGORIAS_PRODUCTOS = '/categorias-productos';

export const categoriasProductosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_CATEGORIAS_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const categoriaProductoIdRequest = async (id_categoria_producto) => {
    const request = await API.get(URL_CATEGORIAS_PRODUCTOS + '/' + id_categoria_producto);
    const response = request.data;
    return response;
}

export const crearCategoriaProductoRequest = async (data) => {
    const request = await API.post(URL_CATEGORIAS_PRODUCTOS, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const actualizarCategoriaProductoRequest = async (id_categoria_producto, data) => {
    const request = await API.patch(URL_CATEGORIAS_PRODUCTOS + '/' + id_categoria_producto, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const eliminarCategoriaProductoRequest = async (id_categoria_producto) => {
    const request = await API.delete(URL_CATEGORIAS_PRODUCTOS + '/' + id_categoria_producto);
    const response = request.data;
    return response;
}