import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";
import { EMAIL_REGEX } from "../../../../../configs/constants/regex";
import {
  crearEmpresaRequest,
  crearUsuarioEmpresaRequest,
  empresaRolesRequest,
} from "../../../../../services/api/empresas";
import { AuthContext } from "../../../../../context/AuthContext";
import { IoLockClosedOutline } from "react-icons/io5";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";

const { Option } = Select;

const nuevoRol = {
  value: 0,
  label: "CREAR UN NUEVO ROL",
};

export const CrearUsuarioDrawer = ({ open, showDrawer, onClose }) => {
  const { checkData, empresa } = useContext(UserContext);
  const { reloadUsuarios } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    identificacion_usuario: "",
    email_usuario: "",
    password_usuario: "",
    nombre_usuario: "",
    id_empresa_rol: "",
    nuevo_rol: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [rolesEmpresa, setRolesEmpresa] = useState([{ ...nuevoRol }]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  useEffect(() => {
    empresaRolesRequest(empresa.id_empresa).then((res) => {
      const roles = res.map((rol) => ({
        value: rol.id_empresa_rol,
        label: rol.nombre_empresa_rol,
      }));
      setRolesEmpresa([...roles, nuevoRol]);
    });
  }, [empresa]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const {
      identificacion_usuario,
      email_usuario,
      password_usuario,
      nombre_usuario,
      id_empresa_rol,
      nuevo_rol,
    } = form;

    // Verificar que los campos obligatorios estén completos
    if (
      !identificacion_usuario ||
      !email_usuario ||
      !password_usuario ||
      !nombre_usuario ||
      (id_empresa_rol === nuevoRol.value ? !nuevo_rol : !id_empresa_rol)
    ) {
      return false;
    }

    if (identificacion_usuario.length < 6) {
      alert("La identificación debe tener almenos 6 caracteres");
      return false;
    }

    const emailRegex = EMAIL_REGEX;
    if (!emailRegex.test(email_usuario)) {
      alert("Revise el formato del email");
      return false;
    }

    if (password_usuario.length < 6) {
      alert("¿La contraseña debe tener 6 caracteres minimo?");
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) {
      setError({
        show: true,
        message:
          "Por favor, revise los campos incorrectos o campos obligatorios (*) que estan vacios.",
      });
      return;
    }

    const dataRequest = {
      identificacion_usuario: form.identificacion_usuario,
      email_usuario: form.email_usuario,
      password_usuario: form.password_usuario,
      nombre_usuario: form.nombre_usuario,
      id_empresa: empresa.id_empresa,
      id_empresa_rol: form.id_empresa_rol == 0 ? null : form.id_empresa_rol,
      nuevo_rol: form.nuevo_rol,
    };

    setIsLoading(true);
    crearUsuarioEmpresaRequest(dataRequest)
      .then((res) => {
        reloadUsuarios();
        onClose();
        onResetForm();
        checkData();
      })
      .catch((err) => {
        const data = err.response?.data;

        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear usuario"
      width={450}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Col span={24}>
            <Form.Item label="Identificación usuario" required>
              <Input
                placeholder="Identificación"
                value={form.identificacion_usuario}
                onChange={(e) =>
                  onChangeForm("identificacion_usuario", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Nombre usuario" required>
              <Input
                placeholder="Nombre"
                value={form.nombre_usuario}
                onChange={(e) => onChangeForm("nombre_usuario", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email usuario" required>
              <Input
                placeholder="Email"
                value={form.email_usuario}
                onChange={(e) => onChangeForm("email_usuario", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Contraseña de usuario" required>
              <Input
                placeholder="Contraseña"
                value={form.password_usuario}
                prefix={<IoLockClosedOutline color="grey" />}
                onChange={(e) =>
                  onChangeForm("password_usuario", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Rol dentro de la empresa" required>
              <Select
                value={form.id_empresa_rol}
                onChange={(e) => onChangeForm("id_empresa_rol", e)}
                options={rolesEmpresa}
              />
            </Form.Item>
          </Col>
          {form.id_empresa_rol === 0 && (
            <Col span={24}>
              <Form.Item label="Nombre del nuevo rol" required>
                <Input
                  placeholder="Nombre del rol"
                  value={form.nuevo_rol}
                  onChange={(e) => onChangeForm("nuevo_rol", e.target.value)}
                />
              </Form.Item>
            </Col>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
};
