import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Typography
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import React, { memo, useState } from "react";
import { AiOutlineFileProtect } from "react-icons/ai";
import { IoFileTray, IoTrash } from "react-icons/io5";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { isStringEmpty } from "../../../../../helpers/functions/is-string-empty.function";
import {
  subirFirmaSecuenciaRequest,
  subirImagenSecuenciaRequest,
} from "../../../../../services/api/secuencias";

export const TabPuntoEmisionTransportistas = memo(
  ({
    form = {},
    setForm = () => {},
    onChangeForm = (e, d) => {},
    tiposEntidad = [],
    tiposRegimen = [],
    agentesRetencion = [],
    isLoadingCheck = false,
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const subirImagen = (e) => {
      const formData = new FormData();
      formData.append("file", e);
      setIsLoading(true);
      subirImagenSecuenciaRequest(formData)
        .then((res) => {
          onChangeForm("url_imagen_transporte", res);
        })
        .finally(() => setIsLoading(false));
    };

    const deleteImage = () => {
      onChangeForm("url_imagen_transporte", "");
    };

    const subirFirma = (e) => {
      const formData = new FormData();
      formData.append("file", e);
      setIsLoading(true);
      subirFirmaSecuenciaRequest(formData)
        .then((res) => {
          setForm((prevForm) => ({
            ...prevForm,
            parametros_facturacion_electronica: {
              ...prevForm,
              url_firma: res,
            },
          }));
        })
        .finally(() => setIsLoading(false));
    };

    const deleteFirma = () => {
      setForm((prevForm) => ({
        ...prevForm,
        parametros_facturacion_electronica: {
          ...prevForm,
          url_firma: "",
        },
      }));
    };

    const isFacturacionElectronica = form.electronico;

    return (
      <Content>
        <Spin spinning={isLoadingCheck}>
        <Row gutter={15}>
          <Col span={10}>
            <Row gutter={5}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo Contribuyente: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_entidad_transporte}
                      allowClear
                      onChange={(e) =>
                        onChangeForm("id_tipo_entidad_transporte", e)
                      }
                    >
                      {tiposEntidad.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_entidad}
                            value={a.id_tipo_entidad}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Identificación: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.identificacion_transporte}
                      maxLength={13}
                      onChange={(e) =>
                        onChangeForm(
                          "identificacion_transporte",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Razón Social: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.razon_social_transporte}
                      onChange={(e) =>
                        onChangeForm("razon_social_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Nombre comercial: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.nombre_comercial_transporte}
                      onChange={(e) =>
                        onChangeForm(
                          "nombre_comercial_transporte",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Direccion Matriz: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.direccion_matriz_transporte}
                      onChange={(e) =>
                        onChangeForm(
                          "direccion_matriz_transporte",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Direccion sucursal: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.direccion_sucursal_transporte}
                      onChange={(e) =>
                        onChangeForm(
                          "direccion_sucursal_transporte",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Telefono: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.telefono_transporte}
                      onChange={(e) =>
                        onChangeForm("telefono_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Email: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.email_transporte}
                      onChange={(e) =>
                        onChangeForm("email_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>No. Autorizacion SRI: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.numero_autorizacion_transporte}
                      onChange={(e) =>
                        onChangeForm(
                          "numero_autorizacion_transporte",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={10}>
                    <Typography>Obligado a llevar contabilidad: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={form.lleva_contabilidad_transporte}
                      onChange={(e) =>
                        onChangeForm("lleva_contabilidad_transporte", e)
                      }
                      style={{ width: "100%" }}
                      allowClear
                      options={[
                        {
                          value: false,
                          label: "NO",
                        },
                        {
                          value: true,
                          label: "SI",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Placa: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.placa_transporte}
                      onChange={(e) =>
                        onChangeForm("placa_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={10} style={{ marginTop: 5 }}>
              <Col span={10}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Sucursal: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.sucursal_transporte}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("sucursal_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={14}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>P. Emision: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.punto_emision_transporte}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("punto_emision_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={10}>
                    <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                      No. Actual:{" "}
                    </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.numero_actual_transporte}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("numero_actual_transporte", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo Regimen: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_regimen_transporte}
                      allowClear
                      onChange={(e) =>
                        onChangeForm("id_tipo_regimen_transporte", e)
                      }
                    >
                      {tiposRegimen.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_regimen}
                            value={a.id_tipo_regimen}
                          >
                            {a.descripcion_regimen}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Agente Retencion: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_agente_retencion_transporte}
                      allowClear
                      onChange={(e) =>
                        onChangeForm("id_agente_retencion_transporte", e)
                      }
                    >
                      {agentesRetencion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_agente_retencion}
                            value={a.id_agente_retencion}
                          >
                            {a.descripcion_agente_retencion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={14}>
            <Col span={24}>
              <Spin spinning={isLoading}>
                <Row justify={"center"}>
                  <div
                    style={{
                      height: 150,
                      width: 150,
                      position: "relative",
                    }}
                  >
                    {form.url_imagen_transporte &&
                    form.url_imagen_transporte !== "" ? (
                      <>
                        <Image src={form.url_imagen_transporte} height={150} />
                        <Button
                          type={"primary"}
                          icon={<DeleteFilled />}
                          danger
                          style={{ position: "absolute" }}
                          onClick={deleteImage}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          accept="image/*"
                          multiple={false}
                          type={"file"}
                          onChange={(e) => {
                            subirImagen(e.target.files[0]);
                          }}
                          style={{
                            position: "absolute",
                            opacity: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        />
                        <div
                          style={{
                            width: "90%",
                            height: "90%",
                            borderStyle: "dashed",
                            borderWidth: 2,
                            borderColor: PRIMARY_COLOR,
                            borderRadius: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            Subir imagen
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Row>
              </Spin>
            </Col>
            <Row>
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Facturación Electrónica
              </Typography>
            </Row>
            <Form disabled={!form.electronico}>
            <Row gutter={10} justify={"start"}>
              <Col span={12}>
                <Row>
                  <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                    Firma electrónica
                  </Typography>
                </Row>
                {!isLoading && (
                  <>
                    {!isStringEmpty(
                      form.parametros_facturacion_electronica?.url_firma
                    ) ? (
                      <Row
                        style={{
                          marginTop: 15,
                          borderRadius: 10,
                          height: 100,
                          position: "relative",
                        }}
                        justify={"center"}
                        align={"middle"}
                      >
                        <Col>
                          <Row
                            justify={"center"}
                            align={"middle"}
                            style={{ height: 60 }}
                          >
                            <AiOutlineFileProtect
                              size={40}
                              color={PRIMARY_COLOR}
                            />
                          </Row>
                          <Row>
                            <Typography>Documento subido</Typography>
                          </Row>
                        </Col>
                        <Button
                          style={{ position: "absolute", top: 0, right: 0 }}
                          type="text"
                          shape="circle"
                          onClick={deleteFirma}
                        >
                          <IoTrash size={20} color="red"/>
                        </Button>
                      </Row>
                    ) : (
                      <Row
                        style={{
                          marginTop: 15,
                          borderRadius: 10,
                          borderWidth: 2,
                          borderColor: PRIMARY_COLOR,
                          borderStyle: "dashed",
                          height: 100,
                          position: "relative",
                          opacity: !isFacturacionElectronica && 0.5,
                        }}
                        justify={"center"}
                        align={"middle"}
                      >
                        <Col>
                          <Row justify={"center"} align={"middle"}>
                            <IoFileTray size={30} color={PRIMARY_COLOR} />
                          </Row>
                          <Row>Subir archivo o arrastar archivo</Row>
                        </Col>
                        <input
                          disabled={!isFacturacionElectronica}
                          type="file"
                          onChange={(e) => {
                            const file = e.target?.files?.[0];
                            if (file) {
                              if (
                                file.type === "application/x-pkcs12" ||
                                file.name.endsWith(".p12")
                              ) {
                                subirFirma(file);
                              } else {
                                // Archivo no válido, muestra un mensaje de error o realiza alguna acción apropiada
                                alert("Solo se permiten archivos P12.");
                              }
                            }
                          }}
                          accept=".p12, application/x-pkcs12"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        />
                      </Row>
                    )}
                  </>
                )}

                <Row justify={"space-between"} style={{ marginTop: 15 }}>
                  <Typography>Clave de firma: </Typography>
                  <Input
                    size="small"
                    style={{ width: 200 }}
                    value={form.parametros_facturacion_electronica?.clave_firma}
                    onChange={(e) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        parametros_facturacion_electronica: {
                          ...prevForm.parametros_facturacion_electronica,
                          clave_firma: e.target.value,
                        },
                      }))
                    }
                  />
                </Row>

                <Row
                  justify={"space-between"}
                  align={"middle"}
                  style={{ marginTop: 15 }}
                >
                  <Typography>Fecha caduca firma: </Typography>
                  <DatePicker
                    placeholder="Seleccione fecha"
                    value={
                      form.parametros_facturacion_electronica
                        ?.fecha_vencimiento_firma
                    }
                    onChange={(e) => {
                      setForm((prevForm) => ({
                        ...prevForm,
                        parametros_facturacion_electronica: {
                          ...prevForm.parametros_facturacion_electronica,
                          fecha_vencimiento_firma: e,
                        },
                      }));
                    }}
                    style={{
                      width: 200,
                    }}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Typography>Número contribuyente especial: </Typography>
                  <Input
                    size="small"
                    value={
                      form.parametros_facturacion_electronica
                        ?.numero_contribuyente_especial
                    }
                    onChange={(e) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        parametros_facturacion_electronica: {
                          ...prevForm.parametros_facturacion_electronica,
                          numero_contribuyente_especial: e.target.value,
                        },
                      }))
                    }
                  />
                </Row>

                <Row style={{ marginTop: 15 }}>
                  <Row style={{ marginTop: 15 }}>
                    <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                      Información en Facturas Electrónicas
                    </Typography>
                    <TextArea
                      value={
                        form.parametros_facturacion_electronica
                          ?.informacion_facturas
                      }
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          parametros_facturacion_electronica: {
                            ...prevForm.parametros_facturacion_electronica,
                            informacion_facturas: e.target.value,
                          },
                        }))
                      }
                      rows={3}
                    />
                  </Row>
                </Row>
              </Col>
            </Row>
            </Form>
          </Col>
          
        </Row>
        </Spin>

      </Content>
    );
  }
);
