import { Checkbox, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export const TabTarifas = ({
  data,
  form,
  tarifas = [],
  onChange = (c, v, id) => {},
}) => {
  const columns = [
    {
      title: "Tarifas",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "60%",
    },
    {
      title: "Selección",
      dataIndex: "seleccionado",
      key: "seleccionado",
      width: "20%",
      align: "center",
      render: (record, row) => {
        return (
          <Checkbox
            checked={record}
            onChange={(e) =>
              onChange("seleccionado", e.target.checked, row.id_tarifa)
            }
          />
        );
      },
    },
    {
      title: "Principal",
      dataIndex: "principal",
      key: "principal",
      width: "20%",
      align: "center",
      render: (record, row) => {
        return (
          <Checkbox
            checked={record}
            onChange={(e) =>
              onChange("principal", e.target.checked, row.id_tarifa)
            }
          />
        );
      },
    },
  ];

  return (
    <Content>
      <Table
        size="small"
        rowKey={"id_tarifa"}
        columns={columns}
        dataSource={tarifas}
      />
    </Content>
  );
};
