import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppLayout } from "../../layouts/AppLayout";
import { InicioPage } from "../../pages/app/inicio/InicioPage";
import { AdministracionRoutes } from "./AdministracionRoutes";
import { ComprasRoutes } from "./ComprasRoute";
import { ContabilidadRoutes } from "./ContabilidadRoutes";
import { TesoreriaRoutes } from "./TesoreriaRoute";
import { VentasRoutes } from "./VentasRoute";
import { CarteraRoutes } from "./CarteraRoute";

export const AppRoutes = () => {
  return (
    <AppLayout>
      <Routes>
        <Route path="inicio" element={<InicioPage />} />
        <Route path="administracion/*" element={<AdministracionRoutes />} />
        <Route path="ventas/*" element={<VentasRoutes />} />
        <Route path="compras/*" element={<ComprasRoutes />} />
        <Route path="tesoreria/*" element={<TesoreriaRoutes />} />
        <Route path="cartera/*" element={<CarteraRoutes />} />
        <Route path="contabilidad/*" element={<ContabilidadRoutes />} />
        <Route path="/*" element={<Navigate to={"/inicio"} />} />
      </Routes>
    </AppLayout>
  );
};
