import { Modal } from "antd";
import React, { useEffect } from "react";
import { useForm } from "../../../../../hooks/useForm";
import { FormRetencionRenta } from "./FormRetencionRenta,";
import { FormRetencionIva } from "./FormRetencionIva";

export const ModalEditarRetencionIva = ({
  retencion,
  isOpen = false,
  close = () => {},
  editar = () => {},
  codigosRetenciones = [],
  disponible = 0,
}) => {
  const { form, setForm, onChangeForm, onResetForm } = useForm({
    ...retencion,
  });

  useEffect(() => {
    setForm((prev) => ({
      ...retencion,
    }));
  }, [isOpen, retencion]);

  useEffect(() => {
    const codigo_retencion = codigosRetenciones.find(
      (codigoRetencion) =>
        codigoRetencion.id_codigo_retencion === form.id_codigo_retencion
    );
    if (!codigo_retencion) return;
    setForm((prev) => ({
      ...prev,
      codigo_impuesto_retencion: codigo_retencion.tipo_retencion?.codigo,
      codigo: codigo_retencion.codigo,
      porcentaje_retener: codigo_retencion.porcentaje,
      valor_retenido:
        Number(prev.base_imponible) * Number(codigo_retencion.porcentaje / 100),
    }));
  }, [form.id_codigo_retencion]);

  useEffect(() => {
    const codigo_retencion = codigosRetenciones.find(
      (codigoRetencion) =>
        codigoRetencion.id_codigo_retencion === form.id_codigo_retencion
    );
    if (!codigo_retencion) return;
    setForm((prev) => ({
      ...prev,
      valor_retenido:
        Number(prev.base_imponible) * Number(prev.porcentaje_retener / 100),
    }));
  }, [form.base_imponible]);

  const validacionGuardar = () => {
    if (
      disponible +
        Number(retencion.base_imponible) -
        Number(form.base_imponible) <
      0
    ) {
      alert(
        `Unicamente puede aplicar una retención con Base: ${
          disponible + Number(retencion.base_imponible)
        }`
      );
      return false;
    }
    return true;
  };

  return (
    <Modal
      title={"Editar Retención IVA"}
      open={isOpen}
      closable={false}
      onCancel={close}
      onOk={() => {
        if (!validacionGuardar()) return;
        editar(form);
        close();
      }}
      okText={"Guardar Cambios"}
    >
      <FormRetencionIva
        form={form}
        onChangeForm={onChangeForm}
        codigosRetenciones={codigosRetenciones}
      />
    </Modal>
  );
};
