import { Checkbox, Col, Form, Input, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useEffect } from "react";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { EmailsInput } from "../../../../../global/components/EmailsInput";

export const TabDatos = memo(
  ({
    form = {},
    onChangeForm = (e, d) => {},
    tiposIdentificacion = [],
    vendedores = [],
    gruposClientes = [],
    cobradores = [],
    rutasClientes = [],
    zonasClientes = [],
    sexos = [],
    tiposEntidad = [],
    estadosCivil = [],
    provincias = [],
    cantones = [],
    parroquias = [],
    tarifas = [],
    tiposRegimen = [],
    tiposCompra = [],
    origenesIngresos = [],
  }) => {
    return (
      <Content>
        <Row gutter={5}>
          <Col span={14}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_identificacion}
                      onChange={(e) =>
                        onChangeForm("id_tipo_identificacion", e)
                      }
                    >
                      {tiposIdentificacion.map((a, i) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_identificacion}
                            value={a.id_tipo_identificacion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Checkbox
                    checked={form.activo}
                    onChange={(e) => onChangeForm("activo", e.target.checked)}
                  >
                    Activo
                  </Checkbox>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Identificación: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.identificacion}
                      onChange={(e) =>
                        onChangeForm("identificacion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}></Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Código: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo}
                      onChange={(e) => onChangeForm("codigo", e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}></Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <CuentaContableInput
                  label="Cuenta:"
                  value={form.cuenta_contable_cliente}
                  onChange={(c) => onChangeForm("cuenta_contable_cliente", c)}
                />
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Razón social: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.razon_social}
                      onChange={(e) =>
                        onChangeForm("razon_social", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Nombre comercial: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.nombre_comercial}
                      onChange={(e) =>
                        onChangeForm("nombre_comercial", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Dirección: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.direccion}
                      onChange={(e) =>
                        onChangeForm("direccion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Referencia: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.referencia}
                      onChange={(e) =>
                        onChangeForm("referencia", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Email: </Typography>
                  </Col>
                  <Col span={19}>
                    {/* <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.email}
                      onChange={(e) => onChangeForm("email", e.target.value)}
                    /> */}
                    <EmailsInput value={form.email} onChange={(value) => onChangeForm("email", value)} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Telefono 1: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.telefono_1}
                      onChange={(e) =>
                        onChangeForm("telefono_1", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Descuento: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.descuento}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("descuento", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Telefono 2: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.telefono_2}
                      onChange={(e) =>
                        onChangeForm("telefono_2", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Dias de crédito: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.dias_credito}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("dias_credito", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Whatsapp: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.whatsapp}
                      onChange={(e) => onChangeForm("whatsapp", e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Cupo crédito: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.cupo_credito}
                      type="number"
                      onChange={(e) =>
                        onChangeForm("cupo_credito", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Vendedor: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_agente_venta_vendedor}
                      onChange={(e) =>
                        onChangeForm("id_agente_venta_vendedor", e)
                      }
                    >
                      {vendedores.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_agente_venta}
                            value={a.id_agente_venta}
                          >
                            {a.nombres}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Grupo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_grupo_cliente}
                      onChange={(e) => onChangeForm("id_grupo_cliente", e)}
                    >
                      {gruposClientes.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_grupo_cliente}
                            value={a.id_grupo_cliente}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Cobrador: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_agente_venta_cobrador}
                      onChange={(e) =>
                        onChangeForm("id_agente_venta_cobrador", e)
                      }
                    >
                      {cobradores.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_agente_venta}
                            value={a.id_agente_venta}
                          >
                            {a.nombres}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Rutas: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_ruta_cliente}
                      onChange={(e) => onChangeForm("id_ruta_cliente", e)}
                    >
                      {rutasClientes.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_ruta_cliente}
                            value={a.id_ruta_cliente}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Zona: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_zona_cliente}
                      onChange={(e) => onChangeForm("id_zona_cliente", e)}
                    >
                      {zonasClientes.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_zona_cliente}
                            value={a.id_zona_cliente}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Sexo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_sexo}
                      onChange={(e) => onChangeForm("id_sexo", e)}
                    >
                      {sexos.map((a) => {
                        return (
                          <Select.Option key={a.id_sexo} value={a.id_sexo}>
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo de cliente: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_entidad}
                      onChange={(e) => onChangeForm("id_tipo_entidad", e)}
                    >
                      {tiposEntidad.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_entidad}
                            value={a.id_tipo_entidad}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Estado civil: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_estado_civil}
                      onChange={(e) => onChangeForm("id_estado_civil", e)}
                    >
                      {estadosCivil.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_estado_civil}
                            value={a.id_estado_civil}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Provincia: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_provincia}
                      onChange={(e) => onChangeForm("id_provincia", e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                      filterSort={(optionA, optionB) => {
                        return (optionA?.children ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.children ?? "").toLowerCase()
                          );
                      }}
                    >
                      {provincias.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_provincia}
                            value={a.id_provincia}
                          >
                            {a.nombre_provincia}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Checkbox
                    checked={form.entidad_bancaria}
                    onChange={(e) =>
                      onChangeForm("entidad_bancaria", e.target.checked)
                    }
                  >
                    Entidad Bancaria
                  </Checkbox>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Canton: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_canton}
                      onChange={(e) => onChangeForm("id_canton", e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                      filterSort={(optionA, optionB) => {
                        return (optionA?.children ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.children ?? "").toLowerCase()
                          );
                      }}
                    >
                      {cantones.map((a) => {
                        return (
                          <Select.Option key={a.id_canton} value={a.id_canton}>
                            {a.nombre_canton}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Checkbox
                    checked={form.realizar_retenciones}
                    onChange={(e) =>
                      onChangeForm("realizar_retenciones", e.target.checked)
                    }
                  >
                    Realiza retenciones
                  </Checkbox>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Parroquias: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_parroquia}
                      onChange={(e) => onChangeForm("id_parroquia", e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                      filterSort={(optionA, optionB) => {
                        return (optionA?.children ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.children ?? "").toLowerCase()
                          );
                      }}
                    >
                      {parroquias.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_parroquia}
                            value={a.id_parroquia}
                          >
                            {a.nombre_parroquia}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Checkbox
                    checked={form.contribuyente_especial}
                    onChange={(e) =>
                      onChangeForm("contribuyente_especial", e.target.checked)
                    }
                  >
                    Contribuyente Especial
                  </Checkbox>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tarifa: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tarifa}
                      onChange={(e) => onChangeForm("id_tarifa", e)}
                    >
                      {tarifas.map((a) => {
                        return (
                          <Select.Option key={a.id_tarifa} value={a.id_tarifa}>
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Régimen: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_regimen}
                      onChange={(e) => onChangeForm("id_tipo_regimen", e)}
                    >
                      {tiposRegimen.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_regimen}
                            value={a.id_tipo_regimen}
                          >
                            {a.descripcion_regimen}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Tipo Venta: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_compra}
                      onChange={(e) => onChangeForm("id_tipo_compra", e)}
                    >
                      {tiposCompra.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_compra}
                            value={a.id_tipo_compra}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Origen Ingresos: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_origen_ingreso}
                      onChange={(e) => onChangeForm("id_origen_ingreso", e)}
                    >
                      {origenesIngresos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_origen_ingreso}
                            value={a.id_origen_ingreso}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
