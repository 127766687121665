import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import {
  agenteRetecionRequest,
  tipoRegimenRequest,
  tiposDocumentoRequest,
  tiposEntidadRequest,
} from "../../../../services/api/opciones";
import { proveedorIdRequest } from "../../../../services/api/proveedores";
import {
  actualizarSecuenciaRequest,
  eliminarSecuenciaRequest,
  secuenciaIdRequest,
} from "../../../../services/api/secuencias";
import { TabSecuencias } from "./components/TabSecuencias";
import { TabPuntoEmisionTransportistas } from "./components/TabPuntoEmisionTransportistas";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";
import dayjs from "dayjs";
import { isStringEmpty } from "../../../../helpers/functions/is-string-empty.function";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined.function";
import { EMAIL_REGEX } from "../../../../configs/constants/regex";
import { handleApiError } from "../../../../global/functions/handle-api-error.function";

export const SecuenciaPage = () => {
  const { id } = useParams();

  const { isProveedorChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    descripcion: "",
    direccion: "",
    telefono: "",
    numero_autorizacion_sri: "",
    sucursal: "",
    punto_emision: "",
    numero_actual: 1,
    iniciales_numero_facturacion: "",
    cantidad_digitos_numero_facturacion: 9,
    cantidad_items_factura_fisica: null,
    activo: true,
    iva: true,
    fiscal: true,
    electronico: false,
    url_imagen: "",
    identificacion: "",
    razon_social: "",
    placa: "",
    id_tipo_documento: null,
    id_tipo_regimen: null,
    id_agente_retencion: null,
    id_tipo_entidad_transporte: null,
    identificacion_transporte: null,
    razon_social_transporte: null,
    nombre_comercial_transporte: null,
    direccion_matriz_transporte: null,
    direccion_sucursal_transporte: null,
    telefono_transporte: null,
    email_transporte: null,
    numero_autorizacion_sri_transporte: null,
    lleva_contabilidad_transporte: null,
    placa_transporte: null,
    sucursal_transporte: null,
    punto_emision_transporte: null,
    numero_actual_transporte: null,
    url_imagen_transporte: null,
    parametros_facturacion_electronica: null,
    id_tipo_regimen_transporte: null,
    id_agente_retencion_transporte: null,
  });

  const [secuencia, setSecuencia] = useState({});
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setEdit(false);
    setReload(!reload);
  };

  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [agentesRetencion, setAgentesRetencion] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposDocumentoRequest(),
      tipoRegimenRequest(),
      agenteRetecionRequest(),
      tiposEntidadRequest(),
    ])
      .then(
        ([
          tiposDocumentoRes,
          tiposRegimenRes,
          agentesRetencionRes,
          tiposEntidadRes,
        ]) => {
          setTiposDocumentos(tiposDocumentoRes);
          setTiposRegimen(tiposRegimenRes);
          setAgentesRetencion(agentesRetencionRes);
          setTiposEntidad(tiposEntidadRes);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    setIsLoading(true);
    secuenciaIdRequest(id)
      .then((res) => {
        setSecuencia(res);

        let parametros_facturacion_electronica = null;

        if (res.parametros_facturacion_electronica) {
          const { fecha_vencimiento_firma } =
            res.parametros_facturacion_electronica;
          parametros_facturacion_electronica = {
            ...res.parametros_facturacion_electronica,
            fecha_vencimiento_firma: dayjs(fecha_vencimiento_firma),
          };
        }

        setForm({
          ...form,
          descripcion: res.descripcion,
          direccion: res.direccion,
          telefono: res.telefono,
          numero_autorizacion_sri: res.numero_autorizacion_sri,
          sucursal: res.sucursal,
          punto_emision: res.punto_emision,
          numero_actual: res.numero_actual,
          iniciales_numero_facturacion: res.iniciales_numero_facturacion,
          cantidad_digitos_numero_facturacion:
            res.cantidad_digitos_numero_facturacion,
          cantidad_items_factura_fisica: res.cantidad_items_factura_fisica,
          activo: res.activo,
          iva: res.iva,
          fiscal: res.fiscal,
          electronico: res.electronico,
          url_imagen: res.url_imagen,
          identificacion: res.identificacion,
          razon_social: res.razon_social,
          placa: res.placa,
          id_tipo_documento: res.tipo_documento?.id_tipo_documento,
          id_tipo_regimen: res.tipo_regimen?.id_tipo_regimen,
          id_agente_retencion: res.agente_retencion?.id_agente_retencion,
          id_tipo_entidad_transporte:
            res.tipo_entidad_transporte?.id_tipo_entidad,
          identificacion_transporte: res.identificacion_transporte,
          razon_social_transporte: res.razon_social_transporte,
          nombre_comercial_transporte: res.nombre_comercial_transporte,
          direccion_matriz_transporte: res.direccion_matriz_transporte,
          direccion_sucursal_transporte: res.direccion_sucursal_transporte,
          telefono_transporte: res.telefono_transporte,
          email_transporte: res.email_transporte,
          numero_autorizacion_sri_transporte:
            res.numero_autorizacion_sri_transporte,
          lleva_contabilidad_transporte: !res.identificacion_transporte
            ? null
            : res.lleva_contabilidad_transporte,
          placa_transporte: res.placa_transporte,
          sucursal_transporte: res.sucursal_transporte,
          punto_emision_transporte: res.punto_emision_transporte,
          numero_actual_transporte: res.numero_actual_transporte,
          url_imagen_transporte: res.url_imagen_transporte,
          parametros_facturacion_electronica:
            parametros_facturacion_electronica,
          id_tipo_regimen_transporte:
            res.tipo_regimen_transporte?.id_tipo_regimen,
          id_agente_retencion_transporte:
            res.agente_retencion_transporte?.id_agente_retencion,
        });
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const items = [
    {
      key: 1,
      label: `Secuencias`,
      children: (
        <TabSecuencias
          form={form}
          disabled={false}
          edit={edit}
          onChangeForm={onChangeForm}
          tiposDocumentos={tiposDocumentos}
          tiposRegimen={tiposRegimen}
          agentesRetencion={agentesRetencion}
        />
      ),
    },
    {
      key: 2,
      label: `Punto Emisión Transportistas`,
      children: (
        <TabPuntoEmisionTransportistas
          form={form}
          onChangeForm={onChangeForm}
          tiposEntidad={tiposEntidad}
          tiposRegimen={tiposRegimen}
          agentesRetencion={agentesRetencion}
        />
      ),
    },
  ];

  const validarCampos = () => {
    const {
      descripcion,
      numero_autorizacion_sri,
      sucursal,
      punto_emision,
      numero_actual,
      electronico,
      id_tipo_entidad_transporte,
      identificacion_transporte,
      razon_social_transporte,
      nombre_comercial_transporte,
      direccion_matriz_transporte,
      direccion_sucursal_transporte,
      telefono_transporte,
      email_transporte,
      lleva_contabilidad_transporte,
      placa_transporte,
      numero_autorizacion_sri_transporte,
      sucursal_transporte,
      punto_emision_transporte,
      numero_actual_transporte,
      url_imagen_transporte,
      id_tipo_regimen_transporte,
      id_agente_retencion_transporte,
      parametros_facturacion_electronica,
    } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la Descripción ",
      });
      return false;
    }

    if (sucursal.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo de sucursal ",
      });
      return false;
    }

    if (punto_emision.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo del punto emisions ",
      });
      return false;
    }

    if (
      !numero_actual ||
      !Number.isInteger(Number(numero_actual)) ||
      Number(numero_actual) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el numero actual no debe estar vacio, debe ser entero y no valores negativos",
      });
      return false;
    }

    if (!electronico) {
      if (isStringEmpty(numero_autorizacion_sri)) {
        setError({
          show: true,
          message:
            "La Secuencia no es Electronica por lo cual debe ingresar el numero de Autorizacion SRI.",
        });
        return false;
      }
    }

    const datosTransportista = [
      id_tipo_entidad_transporte,
      identificacion_transporte,
      razon_social_transporte,
      nombre_comercial_transporte,
      direccion_matriz_transporte,
      direccion_sucursal_transporte,
      telefono_transporte,
      email_transporte,
      lleva_contabilidad_transporte,
      placa_transporte,
      numero_autorizacion_sri_transporte,
      sucursal_transporte,
      punto_emision_transporte,
      numero_actual_transporte,
      url_imagen_transporte,
      id_tipo_regimen_transporte,
      id_agente_retencion_transporte,
      parametros_facturacion_electronica,
    ];

    const secuenciaTransporte = datosTransportista.some(
      (datoTransportista) =>
        !isNullOrUndefined(datoTransportista) ||
        !isStringEmpty(datoTransportista)
    );

    if (secuenciaTransporte) {
      if (isNullOrUndefined(id_tipo_entidad_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Tipo de Contribuyente del Transportista",
        });
        return false;
      }
      if (
        isStringEmpty(identificacion_transporte) ||
        identificacion_transporte?.trim()?.length !== 13
      ) {
        setError({
          show: true,
          message:
            "La identificacion del Transportista debe tener 13 caracteres correspondiente al RUC",
        });
        return false;
      }
      if (isStringEmpty(razon_social_transporte)) {
        setError({
          show: true,
          message: "Ingrese la razon social del Transportista",
        });
        return false;
      }
      if (
        isStringEmpty(email_transporte) ||
        !EMAIL_REGEX.test(email_transporte?.trim())
      ) {
        setError({
          show: true,
          message: "Ingrese o verifique el formato del email del Transportista",
        });
        return false;
      }
      if (isStringEmpty(direccion_matriz_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Direccion Matriz del Transportista",
        });
        return false;
      }
      if (isStringEmpty(direccion_sucursal_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Direccion Sucursal del Transportista",
        });
        return false;
      }
      if (isNullOrUndefined(lleva_contabilidad_transporte)) {
        setError({
          show: true,
          message:
            "Seleccione si el Transportista esta Obligado a llevar Contabilidad",
        });
        return false;
      }
      if (isStringEmpty(placa_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Placa del Transportista",
        });
        return false;
      }
      if (isStringEmpty(sucursal_transporte)) {
        setError({
          show: true,
          message:
            "Ingrese el Codigo de la Sucursal del Transportista. Ejemplo 001",
        });
        return false;
      }
      if (isStringEmpty(punto_emision_transporte)) {
        setError({
          show: true,
          message:
            "Ingrese el Codigo del Punto de Emision del Transportista. Ejemplo 001",
        });
        return false;
      }
      if (
        isNullOrUndefined(numero_actual_transporte) ||
        !Number.isInteger(Number(numero_actual_transporte)) ||
        Number(numero_actual_transporte) <= 0
      ) {
        setError({
          show: true,
          message:
            "Revise el numero actual del Transportista no debe estar vacio, debe ser entero y no valores negativos",
        });
        return false;
      }
      if (isNullOrUndefined(id_tipo_regimen_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Tipo Regimen del Transportista",
        });
        return false;
      }
      if (isNullOrUndefined(id_agente_retencion_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Agente Retencion del Transportista",
        });
        return false;
      }

      if (!electronico) {
        if (isStringEmpty(numero_autorizacion_sri_transporte)) {
          setError({
            show: true,
            message: "Ingrese el Numero de Autorización SRI del Transportista",
          });
          return false;
        }
      }

      if (electronico) {
        if (isStringEmpty(parametros_facturacion_electronica?.url_firma)) {
          setError({
            show: true,
            message: "Seleccione la Firma Electronica del Transportista",
          });
          return false;
        }
        if (isStringEmpty(parametros_facturacion_electronica?.clave_firma)) {
          setError({
            show: true,
            message: "Ingrese la clave de la firma",
          });
          return false;
        }
      }
    }

    return true;
  };
  const guardar = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    let parametros_facturacion_electronica = null;

    if (form.electronico && !isStringEmpty(form.identificacion_transporte)) {
      const {
        url_firma,
        clave_firma,
        fecha_vencimiento_firma,
        numero_contribuyente_especial,
        informacion_facturas,
      } = form.parametros_facturacion_electronica;

      parametros_facturacion_electronica = {
        url_firma: url_firma,
        clave_firma: clave_firma,
        fecha_vencimiento_firma: !fecha_vencimiento_firma
          ? null
          : dayjs(fecha_vencimiento_firma).toISOString(),
        numero_contribuyente_especial: numero_contribuyente_especial ?? "",
        informacion_facturas: informacion_facturas ?? "",
      };
    }

    const dataRequest = {
      descripcion: form.descripcion,
      direccion: form.direccion,
      telefono: form.telefono,
      numero_autorizacion_sri: form.numero_autorizacion_sri,
      sucursal: form.sucursal,
      punto_emision: form.punto_emision,
      numero_actual: Number(form.numero_actual),
      iniciales_numero_facturacion: form.iniciales_numero_facturacion,
      cantidad_digitos_numero_facturacion:
        !form.cantidad_digitos_numero_facturacion
          ? null
          : Number(form.cantidad_digitos_numero_facturacion),
      cantidad_items_factura_fisica: !form.cantidad_items_factura_fisica
        ? null
        : Number(form.cantidad_items_factura_fisica),
      activo: form.activo,
      iva: form.iva,
      fiscal: form.fiscal,
      electronico: form.electronico,
      url_imagen: form.url_imagen,
      identificacion: form.identificacion,
      razon_social: form.razon_social,
      placa: form.placa,
      id_tipo_documento: form.id_tipo_documento,
      id_tipo_regimen: form.id_tipo_regimen,
      id_agente_retencion: form.id_agente_retencion,
      id_tipo_entidad: form.id_tipo_entidad,
      id_tipo_entidad_transporte: form.id_tipo_entidad_transporte,
      identificacion_transporte: form.identificacion_transporte,
      razon_social_transporte: form.razon_social_transporte,
      nombre_comercial_transporte: form.nombre_comercial_transporte,
      direccion_matriz_transporte: form.direccion_matriz_transporte,
      direccion_sucursal_transporte: form.direccion_sucursal_transporte,
      telefono_transporte: form.telefono_transporte,
      email_transporte: form.email_transporte,
      numero_autorizacion_sri_transporte:
        form.numero_autorizacion_sri_transporte,
      lleva_contabilidad_transporte: form.lleva_contabilidad_transporte,
      placa_transporte: form.placa_transporte,
      sucursal_transporte: form.sucursal_transporte,
      punto_emision_transporte: form.punto_emision_transporte,
      numero_actual_transporte: !form.numero_actual_transporte
        ? null
        : Number(form.numero_actual_transporte),
      url_imagen_transporte: form.url_imagen_transporte,
      parametros_facturacion_electronica: parametros_facturacion_electronica,
      id_tipo_regimen_transporte: form.id_tipo_regimen_transporte,
      id_agente_retencion_transporte: form.id_agente_retencion_transporte,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await actualizarSecuenciaRequest(id, dataRequest)
      .then((res) => {
        onResetForm();
        handleReload();
      })
      .catch((error) => {
        handleApiError(error, setError);
      })
      .finally(() => setIsLoading(false));
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const id_secuencia = secuencia.id_secuencia;
    if (!id_secuencia) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta secuencia, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarSecuenciaRequest(id_secuencia).then((res) => {
      navigate(-1);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Secuencias",
                },
                {
                  title: secuencia?.descripcion,
                },
              ]}
            />
          </Row>
          <Row gutter={5}>
            {edit ? (
              <ButtonAsync
                onClick={guardar}
                type="primary"
                text="Guardar cambios"
              />
            ) : (
              <>
                <Col>
                  <Button
                    icon={<EditFilled />}
                    type="primary"
                    disabled={!checkPermisos("MSECUENCIAS").editar}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    type="primary"
                    disabled={!checkPermisos("MSECUENCIAS").eliminar}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form disabled={!edit}>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
