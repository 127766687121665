import { SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import {
  buscarProveedoresRequest,
  proveedoresIdEmpresaRequest,
} from "../../../../../services/api/proveedores";
import { TablaSProveedores } from "./TablaSProveedores";

export const ModalProveedor = ({
  isModalOpen,
  handleOk,
  handleCancel,
  onSelect = () => {},
}) => {
  const { empresa } = useContext(UserContext);

  const { isProveedoresChange } = useContext(ActionContext);

  const [proveedores, setProveedores] = useState([]);
  const [proveedoresSearch, setProveedoresSearch] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    proveedoresIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setProveedores(res);
      })
      .finally(() => setIsLoading(false));
  }, [isProveedoresChange]);

  useEffect(() => {
    if (textSearch.length > 1) {
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        busqueda: textSearch,
      };
      setIsSearching(true);
      buscarProveedoresRequest(dataRequest)
        .then((res) => {
          setProveedoresSearch(res);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [textSearch, isProveedoresChange]);

  const onChangeTextSearch = (text) => {
    setTextSearch(text);
  };

  return (
    <Modal
      title="Seleccione el Proveedor"
      open={isModalOpen}
      width={1000}
      footer={[]}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row style={{ marginBlock: 10 }}>
        <Input
          placeholder="Buscar por Identificación, Razón Social o Nombre Comercial"
          value={textSearch}
          onChange={(e) => onChangeTextSearch(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </Row>
      <Row justify={"center"}>
        {isSearching && (
          <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
            Buscando ({textSearch}) ...{" "}
          </Typography.Title>
        )}
      </Row>
      {textSearch.length > 1 ? (
        <TablaSProveedores
          data={proveedoresSearch}
          onSelect={(e) => {
            onSelect(e);
            handleOk();
          }}
        />
      ) : (
        <TablaSProveedores
          data={proveedores}
          onSelect={(e) => {
            onSelect(e);
            handleOk();
          }}
        />
      )}
    </Modal>
  );
};
