import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import {
  actualizarBancoClienteRequest,
  bancoClienteIdRequest,
} from "../../../../../services/api/bancos-clientes";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";

const { Option } = Select;

export const EditarBancoCliente = ({ open, showDrawer, id, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadBancosClientes } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    banco: "",
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && id != 0) {
      setIsLoading(true);
      bancoClienteIdRequest(id)
        .then((res) => {
          setForm({
            banco: res.banco,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [open, id]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { banco } = form;

    if (banco.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el nombre del Banco",
      });
      return false;
    }

    return true;
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      banco: form.banco,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);

    actualizarBancoClienteRequest(id, dataRequest)
      .then((res) => {
        reloadBancosClientes();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar Cliente Banco"
      width={650}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={editar} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Banco" required>
            <Input
              placeholder="Banco"
              value={form.banco}
              onChange={(e) => onChangeForm("banco", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
