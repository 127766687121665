import { Breadcrumb, Button, Col, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { MdFireTruck } from "react-icons/md";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { usePagination } from "../../../../hooks/usePagination";
import { comprasIdEmpresaDatesRequest, comprasIdEmpresaRequest, comprasIdEmpresaSearchRequest } from "../../../../services/api/compras";
import { TablaCompras } from "./components/TablaCompras";
import { SearchBox } from "../../../../global/components/SearchBox";
import { DateFilter } from "../../../../global/components/DateFilter";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined.function";

export const ComprasPage = () => {
  
  const { isComprasChange } = useContext(ActionContext);

  const { handleOpen, handleClose } = useContext(SidebarContext);

  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { page, limit, onChangeTablePagination, reset } = usePagination();

  const [dates, setDates] = useState(null);
  const [search, setSearch] = useState('');

  const [compras, setCompras] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    reset();
    setDates(null);
    setSearch('');
    setCompras([]);
  }, [isComprasChange]);


  useEffect(() => {
    reset();
    setIsLoading(true);
    const pagination = {
      limit,
      page: 1,
    }
    comprasRequest(pagination)
      .then((res) => {
        setCompras(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [isComprasChange, dates, search]);

  useEffect(() => {
    if(page === 1) return;
      setIsLoading(true);
      const pagination = {
        limit,
        page,
      }
      comprasRequest(pagination)
        .then((res) => {
          setCompras((prev) => [...prev, ...res.data]);
        })
        .finally(() => setIsLoading(false));
    
  }, [page]);

  const comprasRequest = async (pagination) => {
    if(isNullOrUndefined(dates) && !search){
      return await comprasIdEmpresaRequest(empresa.id_empresa, pagination)
    }
    if(!isNullOrUndefined(dates)){
      setSearch('');
      return await comprasIdEmpresaDatesRequest(empresa.id_empresa, dates, pagination)
    }
    if(!!search){
      setDates(null);
      return await comprasIdEmpresaSearchRequest(empresa.id_empresa, search, pagination)
    }
  }

  const crear = () => {
    navigate("./crear-compra");
  };

  const cargarComprasElectronicas = () => {
    navigate("../compras-electronicas");
  };

  const onChangeDate = (value) => setDates(value);

  const onChangeSearch = (value) => setSearch(value);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <MdFireTruck style={{ marginRight: 5 }} />
                      <span>Compras</span>
                    </Row>
                  ),
                },
                {
                  title: "Compras",
                },
              ]}
            />
          </Row>
          <Row gutter={10} align={"middle"}>
          <Col>
              <SearchBox isChange={isComprasChange} onChange={onChangeSearch} />
            </Col>
            <Col>
              <DateFilter isChange={isComprasChange} onChange={onChangeDate}/>
            </Col>
            <Col>
              <Button
                disabled={!checkPermisos("MCOMPRAS").crear}
                type="primary"
                onClick={cargarComprasElectronicas}
              >
                Cargar compras electronicas
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!checkPermisos("MCOMPRAS").crear}
                type="primary"
                onClick={crear}
              >
                Crear
              </Button>
            </Col>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaCompras data={compras} onChangeTablePagination={onChangeTablePagination}/>
      </Content>
    </Spin>
  );
};
