import { useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect } from "react";
import {
  IoArrowBack,
  IoFileTray,
  IoSettingsSharp,
  IoTrash,
} from "react-icons/io5";
import { AiOutlineFileProtect } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../../../hooks/useForm";
import { EditFilled } from "@ant-design/icons";
import { UserContext } from "../../../../context/UserContext";

import {
  actualizarParametrosFacturacionElectronicaRequest,
  crearParametrosFacturacionElectronicaRequest,
  parametrosFacturacionElectronicaEmpresaRequest,
} from "../../../../services/api/parametros-facturacion-electronica";
import TextArea from "antd/es/input/TextArea";
import { PRIMARY_COLOR } from "../../../../configs/constants/colors";
import dayjs from "dayjs";
import { handleApiError } from "../../../../global/functions/handle-api-error.function";

export const ParametrosFacturacionElectronicaPage = () => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    id_parametros_facturacion_electronica: null,
    url_firma: "",
    clave_firma: "",
    fecha_vencimiento_firma: "",
    numero_contribuyente_especial: "",
    informacion_facturas: "",
    file: null,
    force: false,
  });

  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const [activeForm, setActiveForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setForm({ ...initValues });
    parametrosFacturacionElectronicaEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res.id_parametros_facturacion_electronica) {
          setForm({
            file: null,
            id_parametros_facturacion_electronica:
              res.id_parametros_facturacion_electronica,
            url_firma: res.url_firma,
            clave_firma: res.clave_firma,
            fecha_vencimiento_firma: dayjs(res.fecha_vencimiento_firma),
            numero_contribuyente_especial: res.numero_contribuyente_especial,
            informacion_facturas: res.informacion_facturas,
          });
        } else {
          setError({
            show: true,
            message: "Completar estos datos antes de empezar usar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const handleCreate = () => {
    setActiveForm(true);
  };

  const handleEdit = () => {
    setActiveForm(true);
  };

  const validarCampos = () => {
    const { file, url_firma, clave_firma } = form;

    if (!file && !url_firma) {
      setError({
        message: "Seleccione una firma",
        show: true,
      });
      return false;
    }

    if (!clave_firma) {
      setError({
        message: "Falta la clave de la firma",
        show: true,
      });
      return false;
    }

    return true;
  };

  const handleGuardarCambiosCrear = () => {
    setError({ show: false, message: "" });

    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros de facturacion electronica para la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      url_firma: form.url_firma,
      clave_firma: form.clave_firma,
      fecha_vencimiento_firma: !!form.fecha_vencimiento_firma
        ? dayjs(form.fecha_vencimiento_firma).toISOString()
        : new Date().toISOString(),
      numero_contribuyente_especial: form.numero_contribuyente_especial,
      informacion_facturas: form.informacion_facturas,
      force: form.force,
      id_empresa: empresa.id_empresa,
    };

    const formData = new FormData();

    Object.keys(dataRequest).map((key) => {
      formData.append(key, dataRequest[key]);
    });

    if (form.file) {
      formData.append("file", form.file);
    }

    setIsLoading(true);
    crearParametrosFacturacionElectronicaRequest(formData)
      .then((res) => {
        onReload();
      })
      .catch((error) => {
        handleApiError(error, setError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onReload = () => {
    setActiveForm(false);
    setReload(!reload);
  };

  const handleGuardarCambiosActualizar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios de parámetros facturación electrónica de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      url_firma: form.url_firma,
      clave_firma: form.clave_firma,
      fecha_vencimiento_firma: !!form.fecha_vencimiento_firma
        ? new Date(form.fecha_vencimiento_firma).toISOString()
        : new Date().toISOString(),
      numero_contribuyente_especial: form.numero_contribuyente_especial,
      informacion_facturas: form.informacion_facturas,
      force: form.force,
      id_empresa: empresa.id_empresa,
    };

    const formData = new FormData();

    Object.keys(dataRequest).map((key) => {
      formData.append(key, dataRequest[key]);
    });

    if (form.file) {
      formData.append("file", form.file);
    }


    setIsLoading(true);
    actualizarParametrosFacturacionElectronicaRequest(
      form.id_parametros_facturacion_electronica,
      formData
    )
      .then((res) => {
        onReload();
      })
      .catch((error) => {
        handleApiError(error, setError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Parámetros Facturación Electrónica",
                },
              ]}
            />
          </Row>
          <Row>
            {form.id_parametros_facturacion_electronica == null ? (
              activeForm ? (
                <Button
                  disabled={
                    !checkPermisos("MPARAMETROS-FACTURACION-ELECTRONICA").crear
                  }
                  type="primary"
                  onClick={handleGuardarCambiosCrear}
                >
                  Guardar cambios
                </Button>
              ) : (
                <Button
                  disabled={
                    !checkPermisos("MPARAMETROS-FACTURACION-ELECTRONICA").crear
                  }
                  type="primary"
                  onClick={handleCreate}
                >
                  Crear
                </Button>
              )
            ) : activeForm ? (
              <Button
                disabled={
                  !checkPermisos("MPARAMETROS-FACTURACION-ELECTRONICA").editar
                }
                type="primary"
                onClick={handleGuardarCambiosActualizar}
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                disabled={
                  !checkPermisos("MPARAMETROS-FACTURACION-ELECTRONICA").editar
                }
                type="primary"
                onClick={handleEdit}
                icon={<EditFilled />}
              >
                Editar
              </Button>
            )}
          </Row>
        </Row>
        {/* Error */}
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        {/* Boby */}
        <Form disabled={!activeForm}>
          <Row style={{ marginTop: 10 }} gutter={10} justify={"start"}>
            <Col span={12}>
              <Row>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Facturación Electrónica
                </Typography>
              </Row>
              <Row justify={"space-between"} style={{ marginTop: 15 }}>
                <Typography>Número contribuyente especial: </Typography>
                <Input
                  size="small"
                  style={{ width: 200 }}
                  value={form.numero_contribuyente_especial}
                  onChange={(e) =>
                    onChangeForm(
                      "numero_contribuyente_especial",
                      e.target.value
                    )
                  }
                />
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Firma electrónica
                </Typography>
              </Row>
              {!isLoading && (
                <>
                  {form.file || form.url_firma !== "" ? (
                    <Row
                      style={{
                        marginTop: 15,
                        borderRadius: 10,
                        height: 100,
                        position: "relative",
                        opacity: !activeForm && 0.5,
                      }}
                      justify={"center"}
                      align={"middle"}
                    >
                      <Col>
                        <Row
                          justify={"center"}
                          align={"middle"}
                          style={{ height: 60 }}
                        >
                          <AiOutlineFileProtect
                            size={40}
                            color={PRIMARY_COLOR}
                          />
                        </Row>
                        <Row>
                          {form.file && (
                            <Typography>{form.file?.name}</Typography>
                          )}
                          {form.url_firma != "" && (
                            <Typography>Documento subido</Typography>
                          )}
                        </Row>
                      </Col>
                      <Button
                        style={{ position: "absolute", top: 0, right: 0 }}
                        type="text"
                        shape="circle"
                        onClick={() =>
                          setForm({
                            ...form,
                            file: null,
                            url_firma: "",
                          })
                        }
                      >
                        <IoTrash size={20} color="red" />
                      </Button>
                    </Row>
                  ) : (
                    <Row
                      style={{
                        marginTop: 15,
                        borderRadius: 10,
                        borderWidth: 2,
                        borderColor: PRIMARY_COLOR,
                        borderStyle: "dashed",
                        height: 100,
                        position: "relative",
                        opacity: !activeForm && 0.5,
                      }}
                      justify={"center"}
                      align={"middle"}
                    >
                      <Col>
                        <Row justify={"center"} align={"middle"}>
                          <IoFileTray size={30} color={PRIMARY_COLOR} />
                        </Row>
                        <Row>Subir archivo o arrastar archivo</Row>
                      </Col>
                      <input
                        disabled={!activeForm}
                        type="file"
                        onChange={(e) => {
                          const file = e.target?.files?.[0];
                          if (file) {
                            if (
                              file.type === "application/x-pkcs12" ||
                              file.name.endsWith(".p12")
                            ) {
                              onChangeForm("file", file);
                            } else {
                              // Archivo no válido, muestra un mensaje de error o realiza alguna acción apropiada
                              alert("Solo se permiten archivos P12.");
                            }
                          }
                        }}
                        accept=".p12, application/x-pkcs12"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      />
                    </Row>
                  )}
                </>
              )}

              <Row justify={"space-between"} style={{ marginTop: 15 }}>
                <Typography>Clave de firma: </Typography>
                <Input
                  size="small"
                  style={{ width: 200 }}
                  value={form.clave_firma}
                  onChange={(e) => onChangeForm("clave_firma", e.target.value)}
                />
              </Row>
              <Row
                justify={"space-between"}
                align={"middle"}
                style={{ marginTop: 15 }}
              >
                <Typography>Fecha caduca firma: </Typography>
                <DatePicker
                  placeholder="Seleccione fecha"
                  value={form.fecha_vencimiento_firma}
                  onChange={(e) => {
                    onChangeForm("fecha_vencimiento_firma", e);
                  }}
                  style={{
                    width: 200,
                  }}
                />
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                  Información en Facturas Electrónicas
                </Typography>
                <TextArea
                  value={form.informacion_facturas}
                  onChange={(e) =>
                    onChangeForm("informacion_facturas", e.target.value)
                  }
                  rows={3}
                />
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Checkbox
                  checked={form.force}
                  onChange={(e) => onChangeForm("force", e.target.checked)}
                >
                  Forzar la subida con los datos proporcionados (Recuerde
                  verificar los datos antes de crear o guardar los cambios)
                </Checkbox>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>
    </Spin>
  );
};
