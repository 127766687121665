import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import {
  crearClienteRequest,
  generarCodigoClienteRequest,
} from "../../../../services/api/clientes";
import {
  cantonesRequest,
  estadosCivilRequest,
  origenesIngresosRequest,
  parroquiasRequest,
  provinciasRequest,
  sexosRequest,
  tarifasRequest,
  tipoRegimenRequest,
  tiposCompraRequest,
  tiposEntidadRequest,
  tiposIdentificacionRequest,
} from "../../../../services/api/opciones";
import { crearProductoRequest } from "../../../../services/api/productos";
import { TabDatos } from "./components/TabDatos";
import { TabImagenes } from "./components/TabImagenes";
import { parametrosContablesComprasVentasEmpresaRequest } from "../../../../services/api/parametros-contables-compras-ventas";
import { gruposClientesIdEmpresaRequest } from "../../../../services/api/grupos-clientes";
import { agentesVentasIdEmpresaRequest } from "../../../../services/api/agentes-ventas";
import { rutasClientesIdEmpresaRequest } from "../../../../services/api/rutas-clientes";
import { zonasClientesIdEmpresaRequest } from "../../../../services/api/zonas-clientes";
import { empresaIdRequest } from "../../../../services/api/empresas";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const CrearClientePage = () => {
  const { empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    identificacion: "",
    activo: true,
    codigo: "",
    clave: "",
    razon_social: "",
    nombre_comercial: "",
    direccion: "",
    referencia: "",
    email: "",
    telefono_1: "",
    telefono_2: "",
    whatsapp: "",
    descuento: "",
    dias_credito: "",
    cupo_credito: "",
    entidad_bancaria: false,
    realizar_retenciones: false,
    contribuyente_especial: false,
    imagenes: [],
    id_tipo_identificacion: null,
    cuenta_contable_cliente: null,
    id_tipo_regimen: null,
    id_tipo_compra: null,
    id_origen_ingreso: null,
    id_grupo_cliente: null,
    id_ruta_cliente: null,
    id_zona_cliente: null,
    id_provincia: null,
    id_canton: null,
    id_parroquia: null,
    id_sexo: null,
    id_estado_civil: null,
    id_agente_venta_vendedor: null,
    id_agente_venta_cobrador: null,
    id_tipo_entidad: null,
    id_tarifa: null,
  });

  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [gruposClientes, setGruposClientes] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [rutasClientes, setRutasClientes] = useState([]);
  const [zonasClientes, setZonasClientes] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);
  const [estadosCivil, setEstadosCivi] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const [parroquias, setParroquias] = useState([]);
  const [tarifas, setTarifas] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [tiposCompra, setTiposCompra] = useState([]);
  const [origenesIngresos, setOrigenesIngresos] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Promise.all([generarCodigoClienteRequest(empresa.id_empresa)]).then(
      (res) => {
        setForm({
          ...form,
          codigo: res[0],
        });
      }
    );
  }, [empresa]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposIdentificacionRequest(),
      parametrosContablesComprasVentasEmpresaRequest(empresa.id_empresa),
      agentesVentasIdEmpresaRequest(empresa.id_empresa),
      gruposClientesIdEmpresaRequest(empresa.id_empresa),
      rutasClientesIdEmpresaRequest(empresa.id_empresa),
      zonasClientesIdEmpresaRequest(empresa.id_empresa),
      sexosRequest(),
      tiposEntidadRequest(),
      estadosCivilRequest(),
      empresaIdRequest(empresa.id_empresa),
      provinciasRequest(),
      cantonesRequest({ id_provincia: null }),
      parroquiasRequest({ id_canton: null }),
      tarifasRequest(),
      tipoRegimenRequest(),
      tiposCompraRequest(),
      origenesIngresosRequest(),
    ])
      .then(
        ([
          tiposIdentificacionRes,
          parametrosContablesComprasVentasEmpresaRes,
          agentesVentasRes,
          gruposClientesRes,
          rutasClientesRes,
          zonasClientesRes,
          sexosRes,
          tiposEntidadRes,
          estadosCivilRes,
          empresaIdRes,
          provinciasRes,
          cantonesRes,
          parroquiasRes,
          tarifasRes,
          tiposRegimenRes,
          tiposCompraRes,
          origenesIngresosRes,
        ]) => {
          setTiposIdentificacion(tiposIdentificacionRes);
          setGruposClientes(gruposClientesRes);
          setRutasClientes(rutasClientesRes);
          setZonasClientes(zonasClientesRes);
          setSexos(sexosRes);
          setTiposEntidad(tiposEntidadRes);
          setEstadosCivi(estadosCivilRes);
          setProvincias(provinciasRes);
          setCantones(cantonesRes);
          setParroquias(parroquiasRes);
          setTarifas(tarifasRes);
          setTiposRegimen(tiposRegimenRes);
          setTiposCompra(tiposCompraRes);
          setOrigenesIngresos(origenesIngresosRes);

          if (tiposIdentificacionRes.length > 0) {
            const find = tiposIdentificacionRes.find(
              (e) => e.id_tipo_identificacion === 1
            );
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_tipo_identificacion: find.id_tipo_identificacion,
              }));
            }
          }

          if (parametrosContablesComprasVentasEmpresaRes) {
            const find =
              parametrosContablesComprasVentasEmpresaRes.cuenta_contable_clientes;
            if (find) {
              setForm((prev) => ({
                ...prev,
                cuenta_contable_cliente: find,
              }));
            }
          }

          if (agentesVentasRes.length > 0) {
            const filter = agentesVentasRes.filter((e) => e.vendedor);
            setVendedores(filter);
            const find = filter.find((e, index) => index === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_vendedor: find.id_agente_venta,
              }));
            }
          }

          if (gruposClientesRes.length > 0) {
            const find = gruposClientesRes.find((e) => e.predeterminado);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_grupo_cliente: find.id_grupo_cliente,
              }));
            }
          }

          if (agentesVentasRes.length > 0) {
            const filter = agentesVentasRes.filter((e) => e.cobrador);
            setCobradores(filter);
            const find = filter.find((e, index) => index === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_agente_venta_cobrador: find.id_agente_venta,
              }));
            }
          }

          if (rutasClientesRes.length > 0) {
            const find = rutasClientesRes.find((e) => e.predeterminado);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_ruta_cliente: find.id_ruta_cliente,
              }));
            }
          }

          if (zonasClientesRes.length > 0) {
            const find = zonasClientesRes.find((e) => e.predeterminado);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_zona_cliente: find.id_zona_cliente,
              }));
            }
          }

          if (sexosRes.length > 0) {
            const find = sexosRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_sexo: find.id_sexo,
              }));
            }
          }

          if (tiposEntidadRes.length > 0) {
            const find = tiposEntidadRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_tipo_entidad: find.id_tipo_entidad,
              }));
            }
          }

          if (estadosCivilRes.length > 0) {
            const find = estadosCivilRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_estado_civil: find.id_estado_civil,
              }));
            }
          }

          if (provinciasRes.length > 0 && empresaIdRes.provincia) {
            setForm((prev) => ({
              ...prev,
              id_provincia: empresaIdRes.provincia.id_provincia,
            }));
          }

          if (cantonesRes.length > 0 && empresaIdRes.canton) {
            setForm((prev) => ({
              ...prev,
              id_canton: empresaIdRes.canton.id_canton,
            }));
          }

          if (parroquiasRes.length > 0 && empresaIdRes.parroquia) {
            setForm((prev) => ({
              ...prev,
              id_parroquia: empresaIdRes.parroquia.id_parroquia,
            }));
          }

          if (tarifasRes.length > 0) {
            const find = tarifasRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_tarifa: find.id_tarifa,
              }));
            }
          }

          if (tiposRegimenRes.length > 0) {
            const find = tiposRegimenRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_tipo_regimen: find.id_tipo_regimen,
              }));
            }
          }

          if (tiposCompraRes.length > 0) {
            const find = tiposCompraRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_tipo_compra: find.id_tipo_compra,
              }));
            }
          }

          if (origenesIngresosRes.length > 0) {
            const find = origenesIngresosRes.find((e, i) => i === 0);
            if (find) {
              setForm((prev) => ({
                ...prev,
                id_origen_ingreso: find.id_origen_ingreso,
              }));
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  const items = [
    {
      key: 1,
      label: `Datos`,
      children: (
        <TabDatos
          form={form}
          onChangeForm={onChangeForm}
          tiposIdentificacion={tiposIdentificacion}
          vendedores={vendedores}
          gruposClientes={gruposClientes}
          cobradores={cobradores}
          rutasClientes={rutasClientes}
          zonasClientes={zonasClientes}
          sexos={sexos}
          tiposEntidad={tiposEntidad}
          estadosCivil={estadosCivil}
          provincias={provincias}
          cantones={cantones}
          parroquias={parroquias}
          tarifas={tarifas}
          tiposRegimen={tiposRegimen}
          tiposCompra={tiposCompra}
          origenesIngresos={origenesIngresos}
        />
      ),
    },
    {
      key: 2,
      label: `Imágenes`,
      children: <TabImagenes form={form} onChangeForm={onChangeForm} />,
    },
  ];

  const validarCampos = () => {
    const { identificacion, codigo, razon_social } = form;

    if (identificacion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (razon_social.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la razon social",
      });
      return false;
    }

    if (codigo.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese un codigo para el cliente",
      });
      return false;
    }

    return true;
  };

  const crear = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      identificacion: form.identificacion,
      activo: form.activo,
      codigo: form.codigo,
      clave: form.clave,
      razon_social: form.razon_social,
      nombre_comercial: form.nombre_comercial,
      direccion: form.direccion,
      referencia: form.referencia,
      email: form.email?.replaceAll(" ", ""),
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      whatsapp: form.whatsapp,
      descuento: !form.descuento ? null : Number(form.descuento),
      dias_credito: !form.dias_credito ? null : Number(form.dias_credito),
      cupo_credito: !form.cupo_credito ? null : Number(form.cupo_credito),
      entidad_bancaria: form.entidad_bancaria,
      realizar_retenciones: form.realizar_retenciones,
      contribuyente_especial: form.contribuyente_especial,
      imagenes: form.imagenes,
      id_tipo_identificacion: form.id_tipo_identificacion,
      id_cuenta_contable_cliente:
        form.cuenta_contable_cliente.id_cuenta_contable,
      id_tipo_regimen: form.id_tipo_regimen,
      id_tipo_compra: form.id_tipo_compra,
      id_origen_ingreso: form.id_origen_ingreso,
      id_grupo_cliente: form.id_grupo_cliente,
      id_ruta_cliente: form.id_ruta_cliente,
      id_zona_cliente: form.id_zona_cliente,
      id_provincia: form.id_provincia,
      id_canton: form.id_canton,
      id_parroquia: form.id_parroquia,
      id_sexo: form.id_sexo,
      id_estado_civil: form.id_estado_civil,
      id_agente_venta_vendedor: form.id_agente_venta_vendedor,
      id_agente_venta_cobrador: form.id_agente_venta_cobrador,
      id_tipo_entidad: form.id_tipo_entidad,
      id_tarifa: form.id_tarifa,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await crearClienteRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../clientes/${res.id_cliente}`, { replace: true });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "clientes",
                },
                {
                  title: "Crear cliente",
                },
              ]}
            />
          </Row>
          <Row>
            <ButtonAsync onClick={crear} type="primary" text="Crear" />
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
