export const handleApiError = (error, setError) => {
    
    const data = error.response?.data;
    const show = true;

    if (data && data.statusCode === 400) {
        setError({ show, message: data.message });
        return;
    }
    setError({
        show,
        message: "Ocurrio un error intentelo nuevamente",
    });
}