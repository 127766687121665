import { Col, Input, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { ProveedorInput } from "../../proveedores/components/ProveedorInput";

export const TabParametros = memo(({ form, onChangeForm }) => {
  return (
    <Content>
      <Row gutter={5}>
        <Col span={14}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Información adiconal de vehículos
          </Typography.Title>

          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Tonelaje: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    type={"number"}
                    value={form.tonelaje}
                    onChange={(e) => onChangeForm("tonelaje", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Pasajeros: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    type={"number"}
                    value={form.pasajeros}
                    onChange={(e) => onChangeForm("pasajeros", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Cilindraje: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    type={"number"}
                    value={form.cilindraje}
                    onChange={(e) => onChangeForm("cilindraje", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Origen: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.origen}
                    onChange={(e) => onChangeForm("origen", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Marca: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.marca}
                    onChange={(e) => onChangeForm("marca", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Modelo: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.modelo}
                    onChange={(e) => onChangeForm("modelo", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Lado: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.lado}
                    onChange={(e) => onChangeForm("lado", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Medidas: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.medidas}
                    onChange={(e) => onChangeForm("medidas", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col span={5}>
                  <Typography>Descripción: </Typography>
                </Col>
                <Col span={19}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.descripcion}
                    onChange={(e) =>
                      onChangeForm("descripcion", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <ProveedorInput
            label={"Proveedor Asignado:"}
            value={form.proveedor_asignado}
            onChange={(p) => onChangeForm("proveedor_asignado", p)}
          />
          <ProveedorInput
            label={"Proveedor Compra:"}
            value={form.proveedor_compra}
            onChange={(p) => onChangeForm("proveedor_compra", p)}
          />

          <Row gutter={5} style={{ marginTop: 10 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Código ICE: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.codigo_ice}
                    onChange={(e) => onChangeForm("codigo_ice", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Porcentaje ICE: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    disabled
                    type={"number"}
                    value={form.porcentaje_ice}
                    onChange={(e) =>
                      onChangeForm("porcentaje_ice", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Valor de ICE: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    type={"number"}
                    value={form.valor_ice}
                    onChange={(e) => onChangeForm("valor_ice", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={5} style={{ marginTop: 5 }}>
            <Col span={12}>
              <Row justify={"space-between"}>
                <Col span={10}>
                  <Typography>Subsidio: </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    type={"number"}
                    value={form.subsidio}
                    onChange={(e) => onChangeForm("subsidio", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
});
