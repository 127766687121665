import { Space, Typography } from "antd";
import React from "react";
import { PRIMARY_COLOR } from "../configs/constants/colors";

const AuthLayout = ({ children }) => {
  return (
    <Space
      style={{
        backgroundColor: PRIMARY_COLOR,
        height: "100vh",
        width: "100vW",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Space>
  );
};

export default AuthLayout;
