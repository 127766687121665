import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarGrupoClienteRequest } from "../../../../../services/api/grupos-clientes";
import { EditarGrupoCliente } from "./EditarGrupoCliente";

export const TablaGruposClientes = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadGruposClientes } = useContext(ActionContext);

  const [id, setId] = useState(0);

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_grupo_cliente = record.id_grupo_cliente;
    if (!id_grupo_cliente) return;
    setId(id_grupo_cliente);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_grupo_cliente = record.id_grupo_cliente;
    if (!id_grupo_cliente) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este grupo, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarGrupoClienteRequest(id_grupo_cliente).then((res) => {
      reloadGruposClientes();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MGRUPOS-CLIENTES").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MGRUPOS-CLIENTES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "90%",
      ...getColumnSearchProps("descripcion"),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_grupo_cliente"}
        columns={columns}
        dataSource={data}
        size="small"
      />
      <EditarGrupoCliente
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
