import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import {
  agenteRetecionRequest,
  tipoRegimenRequest,
  tiposDocumentoRequest,
  tiposEntidadRequest,
} from "../../../../services/api/opciones";
import {
  crearSecuenciaRequest,
  secuenciaIdEmpresaIdentificacionTransporteRequest,
} from "../../../../services/api/secuencias";
import { TabPuntoEmisionTransportistas } from "./components/TabPuntoEmisionTransportistas";
import { TabSecuencias } from "./components/TabSecuencias";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";
import { isStringEmpty } from "../../../../helpers/functions/is-string-empty.function";
import dayjs from "dayjs";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined.function";
import { EMAIL_REGEX } from "../../../../configs/constants/regex";
import { handleApiError } from "../../../../global/functions/handle-api-error.function";

export const CrearSecuenciaPage = () => {
  const { isProductosChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    descripcion: "",
    direccion: "",
    telefono: "",
    numero_autorizacion_sri: "",
    sucursal: "",
    punto_emision: "",
    numero_actual: 1,
    iniciales_numero_facturacion: "",
    cantidad_digitos_numero_facturacion: 9,
    cantidad_items_factura_fisica: null,
    activo: true,
    iva: true,
    fiscal: true,
    electronico: false,
    url_imagen: "",
    identificacion: "",
    razon_social: "",
    placa: "",
    id_tipo_documento: null,
    id_tipo_regimen: null,
    id_agente_retencion: null,
    id_tipo_entidad_transporte: null,
    identificacion_transporte: null,
    razon_social_transporte: null,
    nombre_comercial_transporte: null,
    direccion_matriz_transporte: null,
    direccion_sucursal_transporte: null,
    telefono_transporte: null,
    email_transporte: null,
    numero_autorizacion_sri_transporte: null,
    lleva_contabilidad_transporte: null,
    placa_transporte: null,
    sucursal_transporte: null,
    punto_emision_transporte: null,
    numero_actual_transporte: null,
    url_imagen_transporte: null,
    parametros_facturacion_electronica: null,
    id_tipo_regimen_transporte: null,
    id_agente_retencion_transporte: null,
  });

  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [agentesRetencion, setAgentesRetencion] = useState([]);
  const [tiposEntidad, setTiposEntidad] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tiposDocumentoRequest(),
      tipoRegimenRequest(),
      agenteRetecionRequest(),
      tiposEntidadRequest(),
    ])
      .then(
        ([
          tiposDocumentoRes,
          tiposRegimenRes,
          agentesRetencionRes,
          tiposEntidadRes,
        ]) => {
          setTiposDocumentos(tiposDocumentoRes);
          setTiposRegimen(tiposRegimenRes);
          setAgentesRetencion(agentesRetencionRes);
          setTiposEntidad(tiposEntidadRes);

          if (tiposDocumentoRes.length > 0) {
            const findTipoDocumento = tiposDocumentoRes.find(
              (e) => e.codigo === "01"
            );
            if (findTipoDocumento) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_documento: findTipoDocumento.id_tipo_documento,
              }));
            }
          }

          if (tiposRegimenRes.length > 0) {
            const findTipoRegimen = tiposRegimenRes.find(
              (e) => e.id_tipo_regimen === 1
            );
            if (findTipoRegimen) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_regimen: findTipoRegimen.id_tipo_regimen,
              }));
            }
          }

          if (agentesRetencionRes.length > 0) {
            const findAgenteRetencion = agentesRetencionRes.find(
              (e) => e.id_agente_retencion === 1
            );
            if (findAgenteRetencion) {
              setForm((prevForm) => ({
                ...prevForm,
                id_agente_retencion: findAgenteRetencion.id_agente_retencion,
              }));
            }
          }

          if (tiposEntidadRes.length > 0) {
            const findTipoEntidad = tiposEntidadRes.find(
              (e) => e.id_tipo_entidad === 1
            );
            if (findTipoEntidad) {
              setForm((prevForm) => ({
                ...prevForm,
                id_tipo_entidad: findTipoEntidad.id_tipo_entidad,
              }));
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  useEffect(() => {
    if (form.electronico) {
      setForm((prev) => ({
        ...prev,
        numero_autorizacion_sri: "",
        iniciales_numero_facturacion: "",
        cantidad_digitos_numero_facturacion: 9,
        cantidad_items_factura_fisica: "",
      }));
    }
  }, [form.electronico]);

  //
  useEffect(() => {
    const identificacion_transporte = form.identificacion_transporte?.trim();
    const timeout = setTimeout(() => {
      if (identificacion_transporte?.length === 13) {
        checkSecuenciaTransportista(identificacion_transporte);
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          id_tipo_entidad_transporte: null,
          razon_social_transporte: null,
          nombre_comercial_transporte: null,
          direccion_matriz_transporte: null,
          direccion_sucursal_transporte: null,
          telefono_transporte: null,
          email_transporte: null,
          numero_autorizacion_sri_transporte: null,
          lleva_contabilidad_transporte: null,
          placa_transporte: null,
          sucursal_transporte: null,
          punto_emision_transporte: null,
          numero_actual_transporte: null,
          url_imagen_transporte: null,
          parametros_facturacion_electronica: null,
          id_tipo_regimen_transporte: null,
          id_agente_retencion_transporte: null,
        }));
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [form.identificacion_transporte, form.electronico]);

  useEffect(() => {
    if (!form.electronico) {
      setForm((prevForm) => ({
        ...prevForm,
        parametros_facturacion_electronica: null,
      }));
    }
  }, [form.electronico]);

  const checkSecuenciaTransportista = async (identificacion_transporte) => {
    setIsLoadingCheck(true);
    const data = {
      id_empresa: empresa.id_empresa,
      identificacion_transporte: identificacion_transporte,
      electronico: form.electronico,
    };

    const secuencia = await secuenciaIdEmpresaIdentificacionTransporteRequest(
      data
    ).finally(() => setIsLoadingCheck(false));

    if (!secuencia) return;

    let parametros_facturacion_electronica = null;

    if (secuencia.parametros_facturacion_electronica && form.electronico) {
      parametros_facturacion_electronica = {
        ...secuencia.parametros_facturacion_electronica,
        fecha_vencimiento_firma: dayjs(
          secuencia.parametros_facturacion_electronica?.fecha_vencimiento_firma
        ),
      };
    }

    setForm((prevForm) => ({
      ...prevForm,
      id_tipo_entidad_transporte:
        secuencia.tipo_entidad_transporte?.id_tipo_entidad,
      identificacion_transporte: secuencia.identificacion_transporte,
      razon_social_transporte: secuencia.razon_social_transporte,
      nombre_comercial_transporte: secuencia.nombre_comercial_transporte,
      direccion_matriz_transporte: secuencia.direccion_matriz_transporte,
      direccion_sucursal_transporte: secuencia.direccion_sucursal_transporte,
      telefono_transporte: secuencia.telefono_transporte,
      email_transporte: secuencia.email_transporte,
      numero_autorizacion_sri_transporte:
        secuencia.numero_autorizacion_sri_transporte,
      lleva_contabilidad_transporte: secuencia.lleva_contabilidad_transporte,
      url_imagen_transporte: secuencia.url_imagen_transporte,
      parametros_facturacion_electronica: parametros_facturacion_electronica,
      id_tipo_regimen_transporte:
        secuencia.tipo_regimen_transporte.id_tipo_regimen,
      id_agente_retencion_transporte:
        secuencia.agente_retencion_transporte.id_agente_retencion,
    }));
  };

  const items = [
    {
      key: 1,
      label: `Secuencias`,
      children: (
        <TabSecuencias
          form={form}
          setForm={setForm}
          onChangeForm={onChangeForm}
          tiposDocumentos={tiposDocumentos}
          tiposRegimen={tiposRegimen}
          disabled={false}
          edit={true}
          agentesRetencion={agentesRetencion}
        />
      ),
    },
    {
      key: 2,
      label: `Punto Emisión Transportistas`,
      children: (
        <TabPuntoEmisionTransportistas
          form={form}
          setForm={setForm}
          onChangeForm={onChangeForm}
          tiposEntidad={tiposEntidad}
          tiposRegimen={tiposRegimen}
          agentesRetencion={agentesRetencion}
          isLoadingCheck={isLoadingCheck}
        />
      ),
    },
  ];

  const validarCampos = () => {
    const {
      descripcion,
      numero_autorizacion_sri,
      sucursal,
      punto_emision,
      numero_actual,
      electronico,
      id_tipo_entidad_transporte,
      identificacion_transporte,
      razon_social_transporte,
      nombre_comercial_transporte,
      direccion_matriz_transporte,
      direccion_sucursal_transporte,
      telefono_transporte,
      email_transporte,
      lleva_contabilidad_transporte,
      placa_transporte,
      numero_autorizacion_sri_transporte,
      sucursal_transporte,
      punto_emision_transporte,
      numero_actual_transporte,
      url_imagen_transporte,
      id_tipo_regimen_transporte,
      id_agente_retencion_transporte,
      parametros_facturacion_electronica,
    } = form;

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la Descripción ",
      });
      return false;
    }

    if (sucursal.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo de sucursal ",
      });
      return false;
    }

    if (punto_emision.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo del punto emisions ",
      });
      return false;
    }

    if (
      !numero_actual ||
      !Number.isInteger(Number(numero_actual)) ||
      Number(numero_actual) <= 0
    ) {
      setError({
        show: true,
        message:
          "Revise el numero actual no debe estar vacio, debe ser entero y no valores negativos",
      });
      return false;
    }

    if (!electronico) {
      if (isStringEmpty(numero_autorizacion_sri)) {
        setError({
          show: true,
          message:
            "La Secuencia no es Electronica por lo cual debe ingresar el numero de Autorizacion SRI.",
        });
        return false;
      }
    }

    const datosTransportista = [
      id_tipo_entidad_transporte,
      identificacion_transporte,
      razon_social_transporte,
      nombre_comercial_transporte,
      direccion_matriz_transporte,
      direccion_sucursal_transporte,
      telefono_transporte,
      email_transporte,
      lleva_contabilidad_transporte,
      placa_transporte,
      numero_autorizacion_sri_transporte,
      sucursal_transporte,
      punto_emision_transporte,
      numero_actual_transporte,
      url_imagen_transporte,
      id_tipo_regimen_transporte,
      id_agente_retencion_transporte,
      parametros_facturacion_electronica,
    ];

    const secuenciaTransporte = datosTransportista.some(
      (datoTransportista) =>
        !isNullOrUndefined(datoTransportista) ||
        !isStringEmpty(datoTransportista)
    );

    if (secuenciaTransporte) {
      if (isNullOrUndefined(id_tipo_entidad_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Tipo de Contribuyente del Transportista",
        });
        return false;
      }
      if (
        isStringEmpty(identificacion_transporte) ||
        identificacion_transporte?.trim()?.length !== 13
      ) {
        setError({
          show: true,
          message:
            "La identificacion del Transportista debe tener 13 caracteres correspondiente al RUC",
        });
        return false;
      }
      if (isStringEmpty(razon_social_transporte)) {
        setError({
          show: true,
          message: "Ingrese la razon social del Transportista",
        });
        return false;
      }
      if (
        isStringEmpty(email_transporte) ||
        !EMAIL_REGEX.test(email_transporte?.trim())
      ) {
        setError({
          show: true,
          message: "Ingrese o verifique el formato del email del Transportista",
        });
        return false;
      }
      if (isStringEmpty(direccion_matriz_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Direccion Matriz del Transportista",
        });
        return false;
      }
      if (isStringEmpty(direccion_sucursal_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Direccion Sucursal del Transportista",
        });
        return false;
      }
      if (isNullOrUndefined(lleva_contabilidad_transporte)) {
        setError({
          show: true,
          message:
            "Seleccione si el Transportista esta Obligado a llevar Contabilidad",
        });
        return false;
      }
      if (isStringEmpty(placa_transporte)) {
        setError({
          show: true,
          message: "Ingrese la Placa del Transportista",
        });
        return false;
      }
      if (isStringEmpty(sucursal_transporte)) {
        setError({
          show: true,
          message:
            "Ingrese el Codigo de la Sucursal del Transportista. Ejemplo 001",
        });
        return false;
      }
      if (isStringEmpty(punto_emision_transporte)) {
        setError({
          show: true,
          message:
            "Ingrese el Codigo del Punto de Emision del Transportista. Ejemplo 001",
        });
        return false;
      }
      if (
        isNullOrUndefined(numero_actual_transporte) ||
        !Number.isInteger(Number(numero_actual_transporte)) ||
        Number(numero_actual_transporte) <= 0
      ) {
        setError({
          show: true,
          message:
            "Revise el numero actual del Transportista no debe estar vacio, debe ser entero y no valores negativos",
        });
        return false;
      }
      if (isNullOrUndefined(id_tipo_regimen_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Tipo Regimen del Transportista",
        });
        return false;
      }
      if (isNullOrUndefined(id_agente_retencion_transporte)) {
        setError({
          show: true,
          message: "Seleccione el Agente Retencion del Transportista",
        });
        return false;
      }

      if (!electronico) {
        if (isStringEmpty(numero_autorizacion_sri_transporte)) {
          setError({
            show: true,
            message: "Ingrese el Numero de Autorización SRI del Transportista",
          });
          return false;
        }
      }

      if (electronico) {
        if (isStringEmpty(parametros_facturacion_electronica?.url_firma)) {
          setError({
            show: true,
            message: "Seleccione la Firma Electronica del Transportista",
          });
          return false;
        }
        if (isStringEmpty(parametros_facturacion_electronica?.clave_firma)) {
          setError({
            show: true,
            message: "Ingrese la clave de la firma",
          });
          return false;
        }
      }
    }

    return true;
  };

  const crear = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    let parametros_facturacion_electronica = null;

    if (form.electronico && !isStringEmpty(form.identificacion_transporte)) {
      const {
        url_firma,
        clave_firma,
        fecha_vencimiento_firma,
        numero_contribuyente_especial,
        informacion_facturas,
      } = form.parametros_facturacion_electronica;

      parametros_facturacion_electronica = {
        url_firma: url_firma,
        clave_firma: clave_firma,
        fecha_vencimiento_firma: !fecha_vencimiento_firma
          ? null
          : dayjs(fecha_vencimiento_firma).toISOString(),
        numero_contribuyente_especial: numero_contribuyente_especial ?? '',
        informacion_facturas: informacion_facturas ?? '',
      };
    }

    const dataRequest = {
      descripcion: form.descripcion,
      direccion: form.direccion,
      telefono: form.telefono,
      numero_autorizacion_sri: form.numero_autorizacion_sri,
      sucursal: form.sucursal,
      punto_emision: form.punto_emision,
      numero_actual: Number(form.numero_actual),
      iniciales_numero_facturacion: form.iniciales_numero_facturacion,
      cantidad_digitos_numero_facturacion:
        !form.cantidad_digitos_numero_facturacion
          ? null
          : Number(form.cantidad_digitos_numero_facturacion),
      cantidad_items_factura_fisica: !form.cantidad_items_factura_fisica
        ? null
        : Number(form.cantidad_items_factura_fisica),
      activo: form.activo,
      iva: form.iva,
      fiscal: form.fiscal,
      electronico: form.electronico,
      url_imagen: form.url_imagen,
      id_tipo_documento: form.id_tipo_documento,
      id_tipo_regimen: form.id_tipo_regimen,
      id_agente_retencion: form.id_agente_retencion,
      id_tipo_entidad_transporte: form.id_tipo_entidad_transporte,
      identificacion_transporte: form.identificacion_transporte,
      razon_social_transporte: form.razon_social_transporte,
      nombre_comercial_transporte: form.nombre_comercial_transporte,
      direccion_matriz_transporte: form.direccion_matriz_transporte,
      direccion_sucursal_transporte: form.direccion_sucursal_transporte,
      telefono_transporte: form.telefono_transporte,
      email_transporte: form.email_transporte,
      numero_autorizacion_sri_transporte:
        form.numero_autorizacion_sri_transporte,
      lleva_contabilidad_transporte: form.lleva_contabilidad_transporte,
      placa_transporte: form.placa_transporte,
      sucursal_transporte: form.sucursal_transporte,
      punto_emision_transporte: form.punto_emision_transporte,
      numero_actual_transporte: !form.numero_actual_transporte
        ? null
        : Number(form.numero_actual_transporte),
      url_imagen_transporte: form.url_imagen_transporte,
      parametros_facturacion_electronica: parametros_facturacion_electronica,
      id_tipo_regimen_transporte: form.id_tipo_regimen_transporte,
      id_agente_retencion_transporte: form.id_agente_retencion_transporte,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await crearSecuenciaRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../secuencias/${res.id_secuencia}`, { replace: true });
      })
      .catch((error) => {
        handleApiError(error, setError);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Secuencias",
                },
                {
                  title: "Crear Secuencia",
                },
              ]}
            />
          </Row>
          <Row>
            <ButtonAsync onClick={crear} type="primary" text="Crear" />
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form>
          <Tabs size="small" items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
