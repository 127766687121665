import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { IoTrash } from "react-icons/io5";
import { ModalCodigosRetenciones } from "./ModalCodigosRetenciones";

export const CodigoRetencionInput = ({
  label = "",
  value,
  onChange = () => {},
  spanLabel = null,
  spanInput = null,
  spanText = null,
  customGutter = null,
  spanSearch = null,
  spanDelete = null,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Row
      gutter={customGutter ?? 10}
      justify={"flex-start"}
      align={"middle"}
      style={{ marginTop: 5 }}
    >
      <Col span={spanLabel ?? 5}>
        <Typography>{label}</Typography>
      </Col>
      <Col span={spanInput ?? 5}>
        <Input size="small" value={value?.codigo} disabled />
      </Col>
      <Col span={spanSearch ?? 2}>
        <Button
          type="primary"
          size="small"
          icon={<SearchOutlined />}
          onClick={showModal}
        />
      </Col>
      <Col span={spanText ?? 10}>
        <Typography>{value?.descripcion}</Typography>
      </Col>
      {value != null && (
        <Col span={spanDelete ?? 2}>
          <Button
            onClick={() => onChange(null)}
            danger
            type="text"
            size="small"
          >
            <IoTrash size={18} />
          </Button>
        </Col>
      )}
      <ModalCodigosRetenciones
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        onSelect={onChange}
      />
    </Row>
  );
};
