import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { NAVEGACION_FACTURACION } from "../../../../../configs/constants/navegacion";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarCompraRequest } from "../../../../../services/api/compras";
import { TagDocumentoElectronico } from "./TagDocumentoElectronico";

export const TablaComprasElectronicas = ({ data = [] }) => {
  
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadCompras } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_compra = record.id_compra;
    if (!id_compra) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta compra, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarCompraRequest(id_compra).then((res) => {
      reloadCompras();
    });
  };

  const detalleCompra = (data) => {
    const id_compra = data?.id_compra;
    if (!id_compra) return;
    navigate(`../compras-electronicas/${id_compra}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MCOMPRAS").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleCompra(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MCOMPRAS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Emision",
      dataIndex: "fecha_emision",
      key: "fecha_emision",
      width: "9%",
    },
    {
      title: "Tipo Documento",
      dataIndex: "tipo_documento",
      key: "tipo_documento",
      width: "12%",
      ellipsis: true,
      render: (record) => record?.descripcion,
    },
    {
      title: "N. Documento",
      dataIndex: "numero_documento",
      key: "numero_documento",
      width: "14%",
    },
    {
      title: "Identificación",
      dataIndex: "ruc_emisor",
      key: "ruc_emisor",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Proveedor",
      dataIndex: "razon_social_emisor",
      key: "razon_social_emisor",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "10%",
      render: (record) => numberFormat(record),
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_compra"}
      columns={columns}
      dataSource={data}
      size="small"
    />
  );
};
