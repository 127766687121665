import API from "../../configs/api/api.config";

const URL_CUENTA = '/auth';

export const loginRequest = async (data) => {
    const request = await API.post(URL_CUENTA + '/login', data);
    const response = request.data;
    return response;
}

export const validarRequest = async ({ token }) => {
    const request = await API.post(URL_CUENTA + '/validar', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    const response = request.data;
    return response;
}