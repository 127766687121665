import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarClienteRequest } from "../../../../../services/api/clientes";

export const TablaClientes = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadClientes } = useContext(ActionContext);

  const navigate = useNavigate();

  const eliminar = (record) => {
    const id_cliente = record.id_cliente;
    if (!id_cliente) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este cliente, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarClienteRequest(id_cliente).then((res) => {
      reloadClientes();
    });
  };

  const detalleCliente = (data) => {
    const id_cliente = data?.id_cliente;
    if (!id_cliente) return;
    navigate(`./${id_cliente}`);
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      ellipsis: true,
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Detalles"}>
            <Button
              disabled={!checkPermisos("MCLIENTES").leer}
              shape="default"
              size="small"
              icon={<FileTextOutlined />}
              onClick={() => detalleCliente(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MCLIENTES").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Identificación",
      dataIndex: "identificacion",
      key: "identificacion",
      width: "20%",
      ellipsis: true,
      ...getColumnSearchProps("identificacion"),
    },
    {
      title: "Razón social",
      dataIndex: "razon_social",
      key: "razon_social",
      width: "35%",
      ellipsis: true,
      ...getColumnSearchProps("razon_social"),
    },
    {
      title: "Nombre comercial",
      dataIndex: "nombre_comercial",
      key: "nombre_comercial",
      width: "35%",
      ellipsis: true,
      ...getColumnSearchProps("nombre_comercial"),
    },
  ];

  return (
    <Table
      scroll={{
        y: 390,
      }}
      rowKey={"id_cliente"}
      columns={columns}
      dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
      size="small"
    />
  );
};
