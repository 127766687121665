import { Col, Row, Tabs, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useEffect, useState } from "react";
import { TIPOS_FORMAS_PAGO } from "../../../../../configs/constants/tipos-formas-pagos";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { TabPagoCheque } from "./TabPagoCheque";
import { TabPagoCredito } from "./TabPagoCredito";
import { TabPagoEfectivo } from "./TabPagoEfectivo";
import { TablaPagos } from "./TablaPagos";
import { TabPagoDeposito } from "./TabPagoDeposito";

export const TabFormaPago = memo(
  ({
    pagos = [],
    totales,
    cajas = [],
    bancos = [],
    bancosClientes = [],
    formasPagosSri = [],
    registrarPago = () => {},
    editarPago = () => {},
    eliminarPago = () => {},
  }) => {
    const [pendiente, setPendiente] = useState(0);

    useEffect(() => {
      const pendiente =
        Number(totales.total) -
        pagos.reduce((a, c) => Number(a) + Number(c.total ?? 0), 0);
      setPendiente(pendiente);
    }, [totales, pagos]);

    const items = [
      {
        key: TIPOS_FORMAS_PAGO.EFECTIVO,
        label: "Efectivo",

        children: (
          <TabPagoEfectivo
            pendiente={pendiente}
            cajas={cajas}
            formasPagosSri={formasPagosSri}
            registrarPago={registrarPago}
          />
        ),
      },
      {
        key: TIPOS_FORMAS_PAGO.CREDITO,
        label: "Crédito",
        children: (
          <TabPagoCredito
            pendiente={pendiente}
            formasPagosSri={formasPagosSri}
            registrarPago={registrarPago}
          />
        ),
      },
      {
        key: TIPOS_FORMAS_PAGO.CHEQUE,
        label: "Cheque",
        children: (
          <TabPagoCheque
            pendiente={pendiente}
            cajas={cajas}
            bancosClientes={bancosClientes}
            formasPagosSri={formasPagosSri}
            registrarPago={registrarPago}
          />
        ),
      },
      {
        key: TIPOS_FORMAS_PAGO.DEPOSITO,
        label: "Deposito",
        children: (
          <TabPagoDeposito
            pendiente={pendiente}
            bancos={bancos}
            formasPagosSri={formasPagosSri}
            registrarPago={registrarPago}
          />
        ),
      },
    ];

    return (
      <Content>
        <Row gutter={5}>
          <Col span={12}>
            <Tabs items={items} />
          </Col>
          <Col span={12}>
            <TablaPagos
              data={pagos}
              eliminar={eliminarPago}
              editar={editarPago}
              cajas={cajas}
              bancos={bancos}
              bancosClientes={bancosClientes}
              formasPagosSri={formasPagosSri}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}></Col>
          <Col>
            <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
              Total: ${numberFormat(totales.total)}
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: "bold", color: "red" }}
            >
              Pendiente: ${numberFormat(pendiente)}
            </Typography>
          </Col>
        </Row>
      </Content>
    );
  }
);
