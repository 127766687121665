import React, { useEffect } from "react";
import { useForm } from "../../../../../hooks/useForm";
import { Modal, Row, Typography } from "antd";
import { TIPOS_FORMAS_PAGO } from "../../../../../configs/constants/tipos-formas-pagos";
import { FormPagoEfectivo } from "./FormPagoEfectivo";
import { FormPagoCredito } from "./FormPagoCredito";
import { FormPagoCheque } from "./FormPagoCheque";
import { FormPagoDeposito } from "./FormPagoDeposito";

export const ModalEditarPago = ({
  pago = {},
  isOpen = false,
  close = () => {},
  editar = () => {},
  cajas = [],
  bancos = [],
  bancosClientes = [],
  formasPagosSri = [],
}) => {
  const { form, setForm, onChangeForm } = useForm({
    ...pago,
  });

  useEffect(() => {
    setForm((prev) => ({
      ...pago,
    }));
  }, [isOpen, pago]);

  return (
    <Modal
      title={"Pago"}
      open={isOpen}
      closable={false}
      onCancel={close}
      onOk={() => {
        close();
        editar(form);
      }}
      okText={"Guardar Cambios"}
    >
      <Row>
        {form.tipo_forma_pago?.id_tipo_forma_pago ===
          TIPOS_FORMAS_PAGO.EFECTIVO && (
          <FormPagoEfectivo
            onChangeForm={onChangeForm}
            form={form}
            edit={true}
            cajas={cajas}
            formasPagosSri={formasPagosSri}
          />
        )}
        {form.tipo_forma_pago?.id_tipo_forma_pago ===
          TIPOS_FORMAS_PAGO.CREDITO && (
          <FormPagoCredito
            onChangeForm={onChangeForm}
            form={form}
            edit={true}
            formasPagosSri={formasPagosSri}
          />
        )}
        {form.tipo_forma_pago?.id_tipo_forma_pago ===
          TIPOS_FORMAS_PAGO.CHEQUE && (
          <FormPagoCheque
            onChangeForm={onChangeForm}
            form={form}
            edit={true}
            bancosClientes={bancosClientes}
            cajas={cajas}
            formasPagosSri={formasPagosSri}
          />
        )}
        {form.tipo_forma_pago?.id_tipo_forma_pago ===
          TIPOS_FORMAS_PAGO.DEPOSITO && (
          <FormPagoDeposito
            onChangeForm={onChangeForm}
            form={form}
            edit={true}
            bancos={bancos}
            formasPagosSri={formasPagosSri}
          />
        )}
      </Row>
    </Modal>
  );
};
