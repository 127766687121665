import { SearchOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { tiposContabilizacionRequest } from "../../../../../services/api/opciones";
import {
  crearParametrosContablesProductosRequest,
  editarParametrosContablesProductosRequest,
  parametrosContablesProductosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-productos";
import { toast } from "sonner";
import { ModalCuentasContables } from "./ModalCuentasContables";
import { CuentaContableInput } from "./CuentaContableInput";
import {
  crearParametrosContablesCobrosRequest,
  editarParametrosContablesCobrosRequest,
  parametrosContablesCobrosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-cobros";
import { tiposContabilidadRequest } from "../../../../../services/api/opciones";

export const CobrosTab = ({ onReload = () => {}, activeForm, reload }) => {

  const { empresa, checkPermisos } = useContext(UserContext);

  const { form, onChangeForm, setForm } = useForm({
    cuenta_contable_anticipos: null,
    cuenta_contable_transitoria_cruce: null,
    cuenta_contable_retencion_atrasada: null,
    id_tipo_contabilidad_cobros: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposContabilidad, setTiposContabilidad] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    tiposContabilidadRequest().then((res) => setTiposContabilidad(res));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    parametrosContablesCobrosEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res) {
          setForm({
            id_parametros_contables_cobros: res.id_parametros_contables_cobros,
            cuenta_contable_anticipos: res.cuenta_contable_anticipos,
            cuenta_contable_transitoria_cruce:
              res.cuenta_contable_transitoria_cruce,
            cuenta_contable_retencion_atrasada:
              res.cuenta_contable_retencion_atrasada,
            id_tipo_contabilidad_cobros:
              res.tipo_contabilidad_cobros?.id_tipo_contabilidad,
          });
        } else {
          setError({
            show: true,
            message:
              "Crear los parametros contables cobros antes de utilizar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const validarCampos = () => {
    const {
      cuenta_contable_anticipos,
      cuenta_contable_transitoria_cruce,
      cuenta_contable_retencion_atrasada,
      id_tipo_contabilidad_cobros,
    } = form;

    if (!cuenta_contable_anticipos) {
      setError({
        show: true,
        message: "Seleccione C.C. Anticipos:",
      });
      return false;
    }

    if (!cuenta_contable_transitoria_cruce) {
      setError({
        show: true,
        message: "Seleccione C.C. Transitoria Cruce:",
      });
      return false;
    }

    if (!cuenta_contable_retencion_atrasada) {
      setError({
        show: true,
        message: "Seleccione C.C. Ret. Atrasada:",
      });
      return false;
    }

    if (!id_tipo_contabilidad_cobros) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilidad cobros",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros contables cobros de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_anticipos:
        form.cuenta_contable_anticipos.id_cuenta_contable,
      id_cuenta_contable_transitoria_cruce:
        form.cuenta_contable_transitoria_cruce.id_cuenta_contable,
      id_cuenta_contable_retencion_atrasada:
        form.cuenta_contable_retencion_atrasada.id_cuenta_contable,
      id_tipo_contabilidad_cobros: form.id_tipo_contabilidad_cobros,
      id_empresa: empresa.id_empresa,
    };
    setIsLoading(true);
    crearParametrosContablesCobrosRequest(dataRequest)
      .then((res) => {
        onReload();
        toast.success("Parametros contables cobros creados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios en parametros contables cobros de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_anticipos:
        form.cuenta_contable_anticipos.id_cuenta_contable,
      id_cuenta_contable_transitoria_cruce:
        form.cuenta_contable_transitoria_cruce.id_cuenta_contable,
      id_cuenta_contable_retencion_atrasada:
        form.cuenta_contable_retencion_atrasada.id_cuenta_contable,
      id_tipo_contabilidad_cobros: form.id_tipo_contabilidad_cobros,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    editarParametrosContablesCobrosRequest(
      form.id_parametros_contables_cobros,
      dataRequest
    )
      .then((res) => {
        onReload();
        toast.success("Parametros contables cobros guardados correctamente !");
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={10} justify={"space-between"}>
        <Col span={17}>
          {/* ERROR */}
          <Row>
            {error.show && (
              <Alert
                description={error.message}
                type="error"
                closable
                onClose={() => setError({ show: false, message: "" })}
              />
            )}
          </Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ margin: 0, fontSize: 14 }}
          >
            Cuentas Contables Anticipo
          </Divider>
          <CuentaContableInput
            label="C.C. Anticipos:"
            value={form.cuenta_contable_anticipos}
            onChange={(v) => onChangeForm("cuenta_contable_anticipos", v)}
          />
          <CuentaContableInput
            label="C.C. Transitoria Cruce:"
            value={form.cuenta_contable_transitoria_cruce}
            onChange={(v) =>
              onChangeForm("cuenta_contable_transitoria_cruce", v)
            }
          />
          <CuentaContableInput
            label="C.C. Ret. Atrasada:"
            value={form.cuenta_contable_retencion_atrasada}
            onChange={(v) =>
              onChangeForm("cuenta_contable_retencion_atrasada", v)
            }
          />
        </Col>
        <Col span={7}>
          {/* BOTONES */}
          <Row style={{ marginBottom: 10 }}>
            {form.id_parametros_contables_cobros ? (
              <Button
                type="primary"
                onClick={editar}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").editar || !activeForm
                }
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={crear}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").crear || !activeForm
                }
              >
                Crear
              </Button>
            )}
          </Row>
          <Row>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilidad_cobros", e.target.value);
              }}
              value={form.id_tipo_contabilidad_cobros}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo Contabilidad Cobros
              </Typography>
              <Space direction="vertical">
                {tiposContabilidad.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilidad}
                      value={f.id_tipo_contabilidad}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
