import { Button, Input, Space } from "antd";
import { useState, useRef } from "react";
import Highlighter from "react-highlight-words";
import { IoSearch } from "react-icons/io5";

const useColumnSearch = () => {

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInputRef = useRef(null);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const checkNestedChildren = (record, dataIndex, value) => {
    if (record[dataIndex] && checkValue(record[dataIndex], value)) {
      return true;
    }

    if (record.children) {
      for (const child of record.children) {
        if (checkNestedChildren(child, dataIndex, value)) {
          return true;
        }
      }
    }

    return false;
  };

  const checkValue = (text, value) =>
    text.toString().toLowerCase().includes(value.toLowerCase());

  const getColumnSearchProps = (dataIndex) => ({

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInputRef}
          placeholder={`Buscar ${dataIndex.replaceAll("_", " ")}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <IoSearch
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return checkNestedChildren(record, dataIndex, value);
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });


  const clearSearchInput = () => {
    setSearchText("");
    setSearchedColumn("");
    searchInputRef.current && searchInputRef.current.focus();
  };

  return { searchText, searchedColumn, handleSearch, handleReset, getColumnSearchProps, clearSearchInput };
};

export default useColumnSearch;