import API from "../../configs/api/api.config";

const URL_PARAMETROS_CONTABLES_GENERALES = '/parametros-contables-generales';

export const parametrosContablesGeneralesEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_GENERALES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosContablesGeneralesRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_CONTABLES_GENERALES, data);
    const response = request.data;
    return response;
}

export const editarParametrosContablesGeneralesRequest = async (id_parametros_contables_generales, data) => {
    const request = await API.patch(URL_PARAMETROS_CONTABLES_GENERALES + '/' + id_parametros_contables_generales, data);
    const response = request.data;
    return response;
}