import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { crearCodigoRetencionRequest } from "../../../../../services/api/codigos-retenciones";
import { tiposRetencionesRequest } from "../../../../../services/api/opciones";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";

const { Option } = Select;

export const CrearCodigoRetencion = ({ open, showDrawer, onClose }) => {
  const { empresa } = useContext(UserContext);
  const { reloadCodigosRetenciones } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    codigo: "",
    porcentaje: 0,
    descripcion: "",
    estado: true,
    porcentaje_variable: false,
    cuenta_contable_ventas: null,
    cuenta_contable_compras: null,
    id_tipo_retencion: 1,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposRetenciones, setTiposRetenciones] = useState([]);

  useEffect(() => {
    tiposRetencionesRequest().then((res) => setTiposRetenciones(res));
  }, []);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { id_tipo_retencion, codigo, porcentaje, descripcion } = form;

    // Verificar que los campos obligatorios estén completos
    if (codigo.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese el codigo",
      });
      return false;
    }

    if (porcentaje === "") {  
      setError({
        show: true,
        message: "Ingrese el porcentaje",
      });
      return false;
    }

    if (descripcion.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese la descripcion",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      codigo: form.codigo,
      porcentaje: Number(form.porcentaje),
      descripcion: form.descripcion,
      estado: form.estado,
      porcentaje_variable: form.porcentaje_variable,
      id_cuenta_contable_ventas:
        form.cuenta_contable_ventas?.id_cuenta_contable,
      id_cuenta_contable_compras:
        form.cuenta_contable_compras?.id_cuenta_contable,
      id_tipo_retencion: form.id_tipo_retencion,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearCodigoRetencionRequest(dataRequest)
      .then((res) => {
        reloadCodigosRetenciones();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Crear Código de Retencion"
      width={600}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Form.Item label="Tipo Retencion">
            <Radio.Group
              value={form.id_tipo_retencion}
              onChange={(e) =>
                onChangeForm("id_tipo_retencion", e.target.value)
              }
            >
              {tiposRetenciones.map((t) => {
                return (
                  <Radio key={t.id_tipo_retencion} value={t.id_tipo_retencion}>
                    {" "}
                    {t.descripcion}{" "}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Código" required>
            <Row gutter={10}>
              <Col span={12}>
                <Input
                  placeholder="Código"
                  value={form.codigo}
                  onChange={(e) => onChangeForm("codigo", e.target.value)}
                />
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.estado}
                  onChange={(e) => onChangeForm("estado", e.target.checked)}
                >
                  Estado
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Porcentaje" required>
            <Row gutter={10}>
              <Col span={12}>
                <Input
                  placeholder="Porcentaje"
                  value={form.porcentaje}
                  type={"number"}
                  onChange={(e) => onChangeForm("porcentaje", e.target.value)}
                />
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.porcentaje_variable}
                  onChange={(e) =>
                    onChangeForm("porcentaje_variable", e.target.checked)
                  }
                >
                  Porcentaje variable
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Descripción" required>
            <Input
              placeholder="Descripción"
              value={form.descripcion}
              onChange={(e) => onChangeForm("descripcion", e.target.value)}
            />
          </Form.Item>
          <CuentaContableInput
            label="Cuenta Ventas:"
            value={form.cuenta_contable_ventas}
            onChange={(c) => onChangeForm("cuenta_contable_ventas", c)}
          />

          <CuentaContableInput
            label="Cuenta Compras:"
            value={form.cuenta_contable_compras}
            onChange={(c) => onChangeForm("cuenta_contable_compras", c)}
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
