import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { IoImage, IoTrash } from "react-icons/io5";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import {
  actualizarLineaProductoRequest,
  lineaProductoIdRequest,
} from "../../../../../services/api/lineas-productos";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";
import { CodigoRetencionInput } from "../../../contabilidad/codigos-retenciones/components/CodigoRetencionInput";
import {
  tarifasIvaRequest,
  tiposGastosRequest,
} from "../../../../../services/api/opciones";
import {
  actualizarGastoRequest,
  gastoIdRequest,
  gastosIdEmpresaRequest,
} from "../../../../../services/api/gastos";

const { Option } = Select;

export const EditarGasto = ({ open, showDrawer, id, onClose }) => {
  
  const { empresa } = useContext(UserContext);
  const { reloadGastos } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    activo: true,
    cuenta_contable_gasto: null,
    codigo_retencion: null,
    id_iva: 2,
    id_tipo_gasto: 1,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [tiposGastos, setTiposGastos] = useState([]);
  const [tarifasIva, setTarifasIva] = useState([]);

  useEffect(() => {
    tiposGastosRequest().then(setTiposGastos);
  }, []);

  useEffect(() => {
    tarifasIvaRequest().then(setTarifasIva);
  }, []);

  useEffect(() => {
    if (open && id != 0) {
      setIsLoading(true);
      gastoIdRequest(id)
        .then((res) => {
          setForm((prev) => ({
            ...prev,
            activo: res.activo,
            cuenta_contable_gasto: res.cuenta_contable_gasto,
            codigo_retencion: res.codigo_retencion,
            id_iva: res.iva?.id_iva,
            id_tipo_gasto: res.tipo_gasto?.id_tipo_gasto,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [open, id]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { cuenta_contable_gasto, codigo_retencion } = form;

    if (!cuenta_contable_gasto?.id_cuenta_contable) {
      setError({
        show: true,
        message: "Seleccione la Cuenta",
      });
      return false;
    }

    if (!codigo_retencion?.id_codigo_retencion) {
      setError({
        show: true,
        message: "Seleccione la Retención",
      });
      return false;
    }

    return true;
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      activo: form.activo,
      id_cuenta_contable_gasto: form.cuenta_contable_gasto?.id_cuenta_contable,
      id_codigo_retencion: form.codigo_retencion?.id_codigo_retencion,
      id_iva: form.id_iva,
      id_tipo_gasto: form.id_tipo_gasto,
      id_empresa: empresa.id_empresa,
    };


    setIsLoading(true);
    actualizarGastoRequest(id, dataRequest)
      .then((res) => {
        reloadGastos();
        onClose();
        onResetForm();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Editar Gastos"
      width={650}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={editar} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <CuentaContableInput
            label="Cuenta: "
            value={form.cuenta_contable_gasto}
            onChange={(c) => onChangeForm("cuenta_contable_gasto", c)}
          />
          <CodigoRetencionInput
            label="Retencion: "
            value={form.codigo_retencion}
            onChange={(c) => onChangeForm("codigo_retencion", c)}
          />
          <Row justify={"space-between"} style={{ marginTop: 10 }}>
            <Col span={10}>
              <Form.Item label={"Tarifa IVA: "}>
                <Select
                  value={form.id_iva}
                  onChange={(e) => onChangeForm("id_iva", e)}
                >
                  {tarifasIva.map((t) => {
                    return (
                      <Select.Option key={t.id_iva} value={t.id_iva}>
                        {t.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={"Tipo Gasto: "}>
                <Radio.Group
                  onChange={(e) => {
                    onChangeForm("id_tipo_gasto", e.target.value);
                  }}
                  value={form.id_tipo_gasto}
                >
                  {tiposGastos.map((f) => {
                    return (
                      <Radio key={f.id_tipo_gasto} value={f.id_tipo_gasto}>
                        {f.descripcion}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Checkbox
              checked={form.activo}
              onChange={(e) => onChangeForm("activo", e.target.checked)}
            >
              Activo
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
