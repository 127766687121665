import React, { useContext, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { Button, Row, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";
import { EditarSubCategoriaProducto } from "./EditarSubCategoriaProducto";
import { IoImage } from "react-icons/io5";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { eliminarSubCategoriaProductoRequest } from "../../../../../services/api/sub-categorias-productos";

export const TablaSubCategoriasProductos = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadSubCategoriasProductos } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_sub_categoria_producto = record.id_sub_categoria_producto;
    if (!id_sub_categoria_producto) return;
    setId(id_sub_categoria_producto);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_sub_categoria_producto = record.id_sub_categoria_producto;
    if (!id_sub_categoria_producto) return;
    if (record.predeterminado) {
      alert("No puede eliminar el predeterminado");
      return;
    }
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta sub categoria de producto, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarSubCategoriaProductoRequest(id_sub_categoria_producto).then((res) => {
      reloadSubCategoriasProductos();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MSUB-CATEGORIAS-PRODUCTOS").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MSUB-CATEGORIAS-PRODUCTOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Imagen",
      dataIndex: "url_imagen",
      key: "url_imagen",
      width: "10%",
      render: (record) => {
        if (!record) {
          return <IoImage color={PRIMARY_COLOR} size={20} />;
        }
        return <img src={record} style={{ height: 30 }} />;
      },
      align: "center",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "60%",
      ...getColumnSearchProps("descripcion"),
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_sub_categoria_producto"}
        columns={columns}
        dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
        size="small"
      />
      <EditarSubCategoriaProducto
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
