import { Checkbox, Col, Input, Row, Select, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { TablaTarifas, TablaTarifasServicio } from "./TablaTarifasServicio";
import { CuentaContableInput } from "../../../administracion/parametros-contables/components/CuentaContableInput";

export const TabDatosBasicos = memo(
  ({
    form = {},
    onChangeForm = (e, d) => {},
    lineasProductos = [],
    tarifasIva = [],
    medidasProducto = [],
    tiposEcommerceProducto = [],
    tarifas = [],
    onEdit = () => {},
    isLoadingTarifas = false,
  }) => {
    return (
      <Content>
        <Row gutter={5}>
          <Col span={16}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Código: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo}
                      onChange={(e) => onChangeForm("codigo", e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>IVA: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_iva_producto}
                      onChange={(e) => onChangeForm("id_iva_producto", e)}
                    >
                      {tarifasIva.map((a) => {
                        return (
                          <Select.Option key={a.id_iva} value={a.id_iva}>
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Descripción: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.descripcion}
                      onChange={(e) =>
                        onChangeForm("descripcion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 0 }}>
              <Col span={24}>
                <CuentaContableInput
                  label="Cuenta"
                  value={form.cuenta_contable_ventas}
                  onChange={(e) => onChangeForm("cuenta_contable_ventas", e)}
                />
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Linea: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_linea_producto}
                      onChange={(e) => onChangeForm("id_linea_producto", e)}
                    >
                      {lineasProductos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_linea_producto}
                            value={a.id_linea_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"} gutter={5}>
                  <Col span={12}>
                    <Checkbox
                      checked={form.controla_precio}
                      onChange={(e) =>
                        onChangeForm("controla_precio", e.target.checked)
                      }
                    >
                      Controla Precios
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox
                      checked={form.compuesto}
                      onChange={(e) =>
                        onChangeForm("compuesto", e.target.checked)
                      }
                    >
                      Compuesto
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Costo estándar: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.costo_estandar}
                      onChange={(e) =>
                        onChangeForm("costo_estandar", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={8}>
                    <Typography>Ecommerce: </Typography>
                  </Col>
                  <Col span={16}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_ecommerce_producto}
                      onChange={(e) =>
                        onChangeForm("id_tipo_ecommerce_producto", e)
                      }
                    >
                      {tiposEcommerceProducto.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_ecommerce_producto}
                            value={a.id_tipo_ecommerce_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Medida: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_medida_interna}
                      onChange={(e) => onChangeForm("id_medida_interna", e)}
                    >
                      {medidasProducto.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_medida_producto}
                            value={a.id_medida_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={5} style={{ marginTop: 10 }}>
          <Col span={16}>
            <Row gutter={5}>
              <Col span={12}>
                <Spin spinning={isLoadingTarifas}>
                  <TablaTarifasServicio
                    data={tarifas}
                    form={form}
                    medidasProducto={medidasProducto}
                    onEdit={onEdit}
                  />
                </Spin>
              </Col>
              <Col span={12}>
                <Row>
                  <Checkbox
                    checked={form.activo}
                    onChange={(e) => onChangeForm("activo", e.target.checked)}
                  >
                    Activo
                  </Checkbox>
                </Row>

                <Row justify={"space-between"} style={{ marginTop: 5 }}>
                  <Col span={12}>
                    <Typography>Código ICE: </Typography>
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo_ice}
                      onChange={(e) =>
                        onChangeForm("codigo_ice", e.target.value)
                      }
                    />
                  </Col>
                </Row>

                <Row justify={"space-between"} style={{ marginTop: 5 }}>
                  <Col span={12}>
                    <Typography>Porcentaje ICE: </Typography>
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      disabled
                      type={"number"}
                      value={form.porcentaje_ice}
                      onChange={(e) =>
                        onChangeForm("porcentaje_ice", e.target.value)
                      }
                    />
                  </Col>
                </Row>

                <Row justify={"space-between"} style={{ marginTop: 5 }}>
                  <Col span={12}>
                    <Typography>Valor de ICE: </Typography>
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.valor_ice}
                      onChange={(e) =>
                        onChangeForm("valor_ice", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
