import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { memo, useEffect } from "react";
import { useForm } from "../../../../../hooks/useForm";

const { TextArea } = Input;

export const ModalObservacionAdicional = memo(
  ({ value, onChangeValue = (d) => {}, isModalOpen, handleCancel }) => {
    const { form, setForm, onChangeForm, onResetForm } = useForm({
      observacion_adicional: value.observacion_adicional,
    });

    useEffect(() => {
      if (isModalOpen) {
        setForm((prev) => ({
          ...prev,
          observacion_adicional: value.observacion_adicional,
        }));
      } else {
        onResetForm();
      }
    }, [isModalOpen]);

    return (
      <Modal
        title="Observación Adicional"
        open={isModalOpen}
        width={500}
        onCancel={() => {
          handleCancel();
        }}
        onOk={() => {
          onChangeValue({ ...value, ...form });
          handleCancel();
        }}
        okText={"Guardar Cambios"}
      >
        <Row style={{ marginBlock: 10, marginBottom: 20 }}>
          <Col span={24}>
            <TextArea
              showCount
              maxLength={300}
              rows={6}
              value={form.observacion_adicional}
              onChange={(e) =>
                onChangeForm("observacion_adicional", e.target.value)
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
);
