import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Typography } from "antd";
import React, { memo, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { ModalClientes } from "./ModalClientes";

export const ClienteInput = memo(
  ({
    label = "",
    value,
    onChange = () => {},
    spanLabel = null,
    spanInput = null,
    spanText = null,
    customGutter = null,
    spanSearch = null,
    spanDelete = null,
    disabled = false,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <Row
        gutter={customGutter ?? 10}
        justify={"flex-start"}
        align={"middle"}
        style={{ marginTop: 5 }}
      >
        <Col span={spanLabel ?? 5}>
          <Typography>{label}</Typography>
        </Col>
        <Col span={spanInput ?? 5}>
          <Input size="small" value={value?.identificacion} disabled />
        </Col>
        <Col span={spanSearch ?? 2}>
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={showModal}
            disabled={disabled}
          />
        </Col>
        <Col span={spanText ?? 10}>
          <Typography>{value?.razon_social}</Typography>
        </Col>
        {value != null && (
          <Col span={spanDelete ?? 2}>
            <Button
              onClick={() => onChange(null)}
              danger
              type="text"
              size="small"
              disabled={disabled}
            >
              <IoTrash size={18} />
            </Button>
          </Col>
        )}
        <ModalClientes
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onSelect={onChange}
        />
      </Row>
    );
  }
);
