import API from "../../configs/api/api.config";

const URL_GRUPOS_PROVEEDORES = '/grupos-proveedores';

export const gruposProveedoresIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_GRUPOS_PROVEEDORES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const grupoProveedorIdRequest = async (id_grupo_proveedor) => {
    const request = await API.get(URL_GRUPOS_PROVEEDORES + '/' + id_grupo_proveedor);
    const response = request.data;
    return response;
}

export const crearGrupoProveedorRequest = async (data) => {
    const request = await API.post(URL_GRUPOS_PROVEEDORES, data);
    const response = request.data;
    return response;
}

export const actualizarGrupoProveedorRequest = async (id_grupo_proveedor, data) => {
    const request = await API.patch(URL_GRUPOS_PROVEEDORES + '/' + id_grupo_proveedor, data);
    const response = request.data;
    return response;
}

export const eliminarGrupoProveedorRequest = async (id_grupo_proveedor) => {
    const request = await API.delete(URL_GRUPOS_PROVEEDORES + '/' + id_grupo_proveedor);
    const response = request.data;
    return response;
}
