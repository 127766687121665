import API from "../../configs/api/api.config";

const URL_PARAMETROS_FACTURACION_ELECTRONICA = '/parametros-facturacion-electronica';

export const parametrosFacturacionElectronicaEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_FACTURACION_ELECTRONICA + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosFacturacionElectronicaRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_FACTURACION_ELECTRONICA, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const actualizarParametrosFacturacionElectronicaRequest = async (id_parametros_facturacion, data) => {
    const request = await API.patch(URL_PARAMETROS_FACTURACION_ELECTRONICA + '/' + id_parametros_facturacion, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}