import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";
import {
  buscarGastosRequest,
  gastosIdEmpresaRequest,
} from "../../../../services/api/gastos";
import { CrearGasto } from "./components/CrearGasto";
import { TablaGastos } from "./components/TablaGastos";
import { SearchOutlined } from "@ant-design/icons";

export const GastosPage = () => {
  const { isGastosChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [gastos, setGastos] = useState([]);
  const [gastosSearch, setGastosSearch] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    gastosIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setGastos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isGastosChange]);

  const onChangeTextSearch = (text) => {
    setTextSearch(text);
  };

  useEffect(() => {
    if (textSearch.length > 1) {
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        busqueda: textSearch,
      };
      setIsSearching(true);
      buscarGastosRequest(dataRequest)
        .then((res) => setGastosSearch(res))
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [textSearch, isGastosChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Col>
            <Row align={"middle"}>
              <Tooltip title={"Regresar"}>
                <Button
                  onClick={() => navigate(-1)}
                  type="text"
                  shape="circle"
                  icon={<IoArrowBack size={20} />}
                  style={{ marginRight: 10 }}
                />
              </Tooltip>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <Row align={"middle"}>
                        <MdFireTruck style={{ marginRight: 5 }} />
                        <span>Compras</span>
                      </Row>
                    ),
                  },
                  {
                    title: "Gastos",
                  },
                ]}
              />
            </Row>
          </Col>
          <Col span={12}>
            <Row align={"middle"} justify={"space-between"}>
              <Col span={20}>
                <Input
                  placeholder="Buscar por C. Cuenta, Cuenta, C. Retención y Retención"
                  value={textSearch}
                  onChange={(e) => onChangeTextSearch(e.target.value)}
                  prefix={<SearchOutlined />}
                />
              </Col>

              <Button
                disabled={!checkPermisos("MGASTOS").crear}
                type="primary"
                onClick={showDrawer}
              >
                Crear
              </Button>
            </Row>
          </Col>
        </Row>
        {/* Tabla */}
        {/* Tabla */}
        <Row justify={"center"}>
          {isSearching && (
            <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
              Buscando ({textSearch}) ...{" "}
            </Typography.Title>
          )}
        </Row>
        {textSearch.length > 1 ? (
          <TablaGastos data={gastosSearch} />
        ) : (
          <TablaGastos data={gastos} />
        )}
        {/* Crear Drawer */}
        <CrearGasto open={open} showDrawer={showDrawer} onClose={onClose} />
      </Content>
    </Spin>
  );
};
