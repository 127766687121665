import { Typography } from "antd";
import React, { useContext } from "react";
import { PRIMARY_COLOR } from "../../../configs/constants/colors";
import { UserContext } from "../../../context/UserContext";

export const InicioPage = () => {
  const { usuario, empresa } = useContext(UserContext);

  return (
    <div className="inicio">
      <div
        style={{
          backgroundColor: PRIMARY_COLOR,
          opacity: 0.4,
          height: "100%",
          width: "100%",
        }}
      />

      <div
        style={{
          top: 0,
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          padding: 30,
          paddingLeft: 50,
        }}
      >
        <Typography
          style={{ marginTop: 0, paddingTop: 0, color: "white", fontSize: 60 }}
        >
          Bienvenid@ {usuario.nombre_usuario}
        </Typography>
        <Typography
          style={{ marginTop: 0, paddingTop: 0, color: "white", fontSize: 30 }}
        >
          Empresa{" "}
          {!empresa.nombre_comercial_empresa
            ? empresa.razon_social_empresa
            : empresa.nombre_comercial_empresa}
        </Typography>
      </div>
    </div>
  );
};
