import API from "../../configs/api/api.config";

const URL_AGENTES_VENTAS = '/agentes-ventas';

export const agentesVentasIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_AGENTES_VENTAS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const agenteVentaIdRequest = async (id_agente_venta) => {
    const request = await API.get(URL_AGENTES_VENTAS + '/' + id_agente_venta);
    const response = request.data;
    return response;
}

export const crearAgenteVentaRequest = async (data) => {
    const request = await API.post(URL_AGENTES_VENTAS, data);
    const response = request.data;
    return response;
}

export const validarFacturadorRequest = async (data) => {
    const request = await API.post(URL_AGENTES_VENTAS + '/validar-facturador', data);
    const response = request.data;
    return response;
}

export const actualizarAgenteVentaRequest = async (id_agente_venta, data) => {
    const request = await API.patch(URL_AGENTES_VENTAS + '/' + id_agente_venta, data);
    const response = request.data;
    return response;
}

export const eliminarAgenteVentaRequest = async (id_agente_venta) => {
    const request = await API.delete(URL_AGENTES_VENTAS + '/' + id_agente_venta);
    const response = request.data;
    return response;
}
