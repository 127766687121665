import API from "../../configs/api/api.config";

const URL_BANCOS = '/bancos';

export const bancosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_BANCOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const bancoIdRequest = async (id_banco) => {
    const request = await API.get(URL_BANCOS + '/' + id_banco);
    const response = request.data;
    return response;
}

export const crearBancoRequest = async (data) => {
    const request = await API.post(URL_BANCOS, data);
    const response = request.data;
    return response;
}

export const actualizarBancoRequest = async (id_banco, data) => {
    const request = await API.patch(URL_BANCOS + '/' + id_banco, data);
    const response = request.data;
    return response;
}

export const eliminarBancoRequest = async (id_banco) => {
    const request = await API.delete(URL_BANCOS + '/' + id_banco);
    const response = request.data;
    return response;
}