import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Input, Modal, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import {
  buscarProductosGastosRequest,
  productosGastosRequest,
} from "../../../../../services/api/compras";
import { TablaProductosGastos } from "./TablaProductosGastos";

export const ModalProductoGasto = ({
  isModalOpen,
  handleOk,
  handleCancel,
  onSelect = () => {},
  link = false,
  value = null,
  selected = null,
}) => {
  const { empresa } = useContext(UserContext);
  const { isProductosGastosChange } = useContext(ActionContext);

  const [productos, setProductos] = useState([]);
  const [productosSearch, setProductosSearch] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    productosGastosRequest({ id_empresa: empresa.id_empresa })
      .then((res) => {
        setProductos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isProductosGastosChange]);

  useEffect(() => {
    if (textSearch.length > 1) {
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        busqueda: textSearch,
      };
      setIsSearching(true);
      buscarProductosGastosRequest(dataRequest)
        .then((res) => {
          setProductosSearch(res);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [textSearch, isProductosGastosChange]);

  const onChangeTextSearch = (text) => {
    setTextSearch(text);
  };

  useEffect(() => {
    setTextSearch("");
    setProductosSearch([]);
  }, [isModalOpen]);

  return (
    <Modal
      title="Seleccione el Producto o Gasto"
      open={isModalOpen}
      width={1200}
      footer={[]}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {link && (
        <Row gutter={10}>
          <Col span={12}>
            <Card title="Detalle" size="small">
              <Typography>{value?.codigo}</Typography>
              <Typography>{value?.descripcion}</Typography>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Producto o Gasto Vinculado" size="small">
              {selected?.id_producto && (
                <>
                  <Typography>{selected?.codigo}</Typography>
                  <Typography>{selected?.descripcion}</Typography>
                </>
              )}
              {selected?.id_gasto && (
                <>
                  <Typography>
                    {selected?.cuenta_contable_gasto?.codigo_contable}
                  </Typography>
                  <Typography>
                    {selected?.cuenta_contable_gasto?.nombre_cuenta_contable}
                  </Typography>
                </>
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Row style={{ marginBlock: 10 }}>
        <Input
          placeholder="Buscar por Código o la Descripción"
          value={textSearch}
          onChange={(e) => onChangeTextSearch(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </Row>
      <Row justify={"center"}>
        {isSearching && (
          <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
            Buscando ({textSearch}) ...{" "}
          </Typography.Title>
        )}
      </Row>
      {textSearch.length > 1 ? (
        <TablaProductosGastos
          data={productosSearch}
          onSelect={(e) => {
            onSelect(e);
            handleOk();
          }}
        />
      ) : (
        <TablaProductosGastos
          data={productos}
          onSelect={(e) => {
            onSelect(e);
            handleOk();
          }}
        />
      )}
    </Modal>
  );
};
