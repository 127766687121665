import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Spin,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { EditarSucursalDrawer } from "./components/EditarSucursalDrawer";
import { UserContext } from "../../../../context/UserContext";
import { ActionContext } from "../../../../context/ActionContext";
import {
  eliminarSucursalRequest,
  sucursalIdRequest,
} from "../../../../services/api/sucursales";

export const SucursalPage = () => {
  const { checkPermisos } = useContext(UserContext);
  const { reloadSucursales } = useContext(ActionContext);

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [sucursal, setSucursal] = useState({});
  const [reload, setReload] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setSucursal({});
    sucursalIdRequest(id)
      .then((res) => {
        setSucursal(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reload]);

  const onReload = () => setReload(!reload);

  const eliminar = () => {
    const id_sucursal = sucursal.id_sucursal;
    if (!id_sucursal) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar la sucursal, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarSucursalRequest(id_sucursal).then((res) => {
      reloadSucursales();
      navigate(-1);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoSettingsSharp style={{ marginRight: 5 }} />
                      <span>Administración</span>
                    </Row>
                  ),
                },
                {
                  title: "Sucursales",
                },
                {
                  title: sucursal.sucursal,
                },
              ]}
            />
          </Row>
          <Row gutter={10} align={"middle"} justify={"space-around"}>
            <Col>
              <Button
                type="primary"
                onClick={showDrawer}
                disabled={!checkPermisos("MSUCURSALES").editar}
                icon={<EditFilled />}
              >
                Editar
              </Button>
            </Col>
            <Col>
              <Button
                danger
                type="primary"
                disabled={!checkPermisos("MSUCURSALES").eliminar}
                onClick={eliminar}
                icon={<DeleteFilled />}
              >
                Eliminar
              </Button>
            </Col>
          </Row>
        </Row>
        {/* Datos */}
        <Card
          title="Información de Sucursal"
          style={{ marginTop: 10 }}
          size="small"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Sucursal">
                  {sucursal.sucursal}
                </Descriptions.Item>
                <Descriptions.Item label="Dirección">
                  {sucursal.direccion}
                </Descriptions.Item>
                <Descriptions.Item label="Identificación">
                  {sucursal.identificacion}
                </Descriptions.Item>
                <Descriptions.Item label="Razon social">
                  {sucursal.razon_social}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo de sucursal">
                  {sucursal?.tipo_sucursal?.descripcion}
                </Descriptions.Item>
                <Descriptions.Item label="Estado sucursal">
                  {sucursal?.estado_sucursal?.descripcion}
                </Descriptions.Item>
                <Descriptions.Item label="Canton">
                  {sucursal?.canton?.nombre_canton}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Telefono 1">
                  {sucursal.telefono_1}
                </Descriptions.Item>
                <Descriptions.Item label="Telefono 2">
                  {sucursal.telefono_2}
                </Descriptions.Item>
                <Descriptions.Item label="Meta de venta">
                  {sucursal.meta_venta}
                </Descriptions.Item>
                <Descriptions.Item label="Comisión producción">
                  {sucursal.comision_producciion}
                </Descriptions.Item>
                <Descriptions.Item label="Comisión distribución">
                  {sucursal.comision_distribucion}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
        {sucursal.id_sucursal && (
          <EditarSucursalDrawer
            open={open}
            showDrawer={showDrawer}
            onClose={onClose}
            sucursal={{
              ...sucursal,
              id_estado_sucursal: sucursal.estado_sucursal?.id_estado_sucursal,
              id_tipo_sucursal: sucursal.tipo_sucursal?.id_tipo_sucursal,
              id_canton: sucursal.canton?.id_canton,
            }}
            reload={onReload}
          />
        )}
      </Content>
    </Spin>
  );
};
