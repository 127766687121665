import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";
import { ModalCrearRetencionIva } from "./ModalCrearRetencionIva";
import { ModalCrearRetencionRenta } from "./ModalCrearRetencionRenta";
import { TablaRetencionIva } from "./TablaRetencionIva";
import { TablaRetencionRenta } from "./TablaRetencionRenta";
import { TIPOS_PAGOS_A } from "../../../../../configs/constants/tipos-pagos-a";
import { TIPOS_REGIMEN_RETENCION } from "../../../../../configs/constants/tipos-regimen-retencion";
import { TagDocumentoElectronico } from "./TagDocumentoElectronico";
import { CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS } from "../../../../../configs/constants/estados-documentos-electronicos";

export const TabRetencion = memo(
  ({
    edit = false,
    form = {},
    onChangeForm = {},
    errorRetencion,
    setErrorRetencion = {},
    isLoading = false,
    secuencias = [],
    tiposIdentificacion = [],
    tiposPagosA = [],
    tiposRegimenRetencion = [],
    tiposRegimenGeneral = [],
    tiposParaisoFiscal = [],
    paises = [],
    codigosRetencionesRenta = [],
    codigosRetencionesIva = [],
    retencionesRenta = [],
    retencionesIva = [],
    disponibleRenta = 0,
    disponibleIva = 0,
    addRetencionRenta = (r) => {},
    removeRetencionRenta = (r) => {},
    editRetencionRenta = (r) => {},
    addRetencionIva = (r) => {},
    removeRetencionIva = (r) => {},
    editRetencionIva = (r) => {},
  }) => {
    const [isOpenModalCrearRetencionRenta, setIsOpenModalCrearRetencionRenta] =
      useState(false);

    const [isOpenModalCrearRetencionIva, setIsOpenModalCrearRetencionIva] =
      useState(false);

    const openModalCrearRetencionRenta = () =>
      setIsOpenModalCrearRetencionRenta(true);

    const openModalCrearRetencionIva = () =>
      setIsOpenModalCrearRetencionIva(true);

    return (
      <Content>
        <Row>
          {errorRetencion.show && (
            <Alert
              description={errorRetencion.message}
              type="error"
              closable
              onClose={() => setErrorRetencion({ show: false, message: "" })}
            />
          )}
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Row align={"middle"}>
              <Typography style={{ fontWeight: "bold" }}>
                Datos de Retención
              </Typography>
              <Row gutter={10} style={{ marginLeft: 5 }}>
                <Col>
                  <Typography>{form?.numero_documento}</Typography>
                </Col>
                <Col>
                  {(form.electronico ||
                    form.estado_documento_electronico ===
                      CODIGOS_ESTADOS_DOCUMENTOS_ELECTRONICOS.ANULADO) && (
                    <TagDocumentoElectronico
                      estado={form.estado_documento_electronico}
                    />
                  )}
                </Col>
              </Row>
            </Row>

            <Row style={{ marginTop: 3 }}>
              <Col span={5}>
                <Typography>Retencion: </Typography>
              </Col>
              <Col span={19}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_secuencia}
                  onChange={(e) => onChangeForm("id_secuencia", e)}
                >
                  {secuencias.map((a) => {
                    return (
                      <Select.Option
                        key={a.id_secuencia}
                        value={a.id_secuencia}
                      >
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={12}>
                <Row>
                  <Col span={10}>Emision: </Col>
                  <Col span={14}>
                    <DatePicker
                      size="small"
                      value={form.fecha_emision}
                      style={{ width: "100%" }}
                      onChange={(e) => onChangeForm("fecha_emision", e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={form.asumir_retencion}
                  onChange={(e) =>
                    onChangeForm("asumir_retencion", e.target.checked)
                  }
                >
                  {" "}
                  Asumir Retención
                </Checkbox>
              </Col>
            </Row>

            <Row style={{ marginTop: 3 }}>
              <Col span={5}>Secuencia: </Col>
              <Col span={14}>
                <Row gutter={10}>
                  <Col span={6}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo_establecimiento}
                      onChange={(e) =>
                        onChangeForm("codigo_establecimiento", e.target.value)
                      }
                      disabled
                    />
                  </Col>
                  <Col span={6}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo_punto_emision}
                      onChange={(e) =>
                        onChangeForm("codigo_punto_emision", e.target.value)
                      }
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.secuencia}
                      onChange={(e) =>
                        onChangeForm("secuencia", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 3 }}>
              <Col span={5}>
                <Typography>Autorizacion: </Typography>
              </Col>
              <Col span={19}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.autorizacion}
                  onChange={(e) => onChangeForm("autorizacion", e.target.value)}
                  disabled
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 3 }}>
              <Col span={5}>
                <Typography>Clave de acceso: </Typography>
              </Col>
              <Col span={19}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={form.clave_acceso}
                  onChange={(e) => onChangeForm("clave_acceso", e.target.value)}
                  disabled
                />
              </Col>
            </Row>

            <Row gutter={5} style={{ marginTop: 3 }}>
              <Col span={6}>
                <Typography>IVA 0</Typography>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={numberFormat(form.iva_cero)}
                  disabled
                />
              </Col>
              <Col span={6}>
                <Typography>IVA diferente 0</Typography>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={numberFormat(form.iva_diferente_cero)}
                  disabled
                />
              </Col>
              <Col span={6}>
                <Typography>Monto IVA</Typography>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={numberFormat(form.monto_iva)}
                  disabled
                />
              </Col>
              <Col span={6}>
                <Typography>Monto ICE</Typography>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  value={numberFormat(form.monto_ice)}
                  disabled
                />
              </Col>
            </Row>

            <Divider style={{ margin: 15 }} />

            <Row>
              <Typography style={{ fontWeight: "bold" }}>
                Datos del Pago
              </Typography>
            </Row>

            <Row style={{ marginTop: 3 }} gutter={10}>
              <Col span={16}>
                <Row>
                  <Col span={12}>
                    <Typography>Tipo Identificación: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_identificacion}
                      disabled
                    >
                      {tiposIdentificacion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_identificacion}
                            value={a.id_tipo_identificacion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Identificación: </Typography>
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.identificacion}
                      disabled
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Es parte relacionada: </Typography>
                  </Col>
                  <Col span={12}>
                    <Radio.Group
                      value={form.parte_relacionada}
                      onChange={(e) =>
                        onChangeForm("parte_relacionada", e.target.value)
                      }
                    >
                      <Radio value={true}> Si </Radio>
                      <Radio value={false}> No </Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Pago a: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_pago_a}
                      onChange={(e) => onChangeForm("id_tipo_pago_a", e)}
                      dropdownMatchSelectWidth={false}
                      placement={"bottomLeft"}
                    >
                      {tiposPagosA.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_pago_a}
                            value={a.id_tipo_pago_a}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Tipo Regimen: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_regimen_retencion}
                      onChange={(e) =>
                        onChangeForm("id_tipo_regimen_retencion", e)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE
                      }
                      dropdownMatchSelectWidth={false}
                      placement={"bottomLeft"}
                    >
                      {tiposRegimenRetencion.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_tipo_regimen_retencion}
                            value={a.id_tipo_regimen_retencion}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Regimen General: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_regimen_general}
                      onChange={(e) =>
                        onChangeForm("id_tipo_regimen_general", e)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE ||
                        tiposRegimenRetencion.find(
                          (tipoRegimenRetencion) =>
                            tipoRegimenRetencion.id_tipo_regimen_retencion ===
                            form.id_tipo_regimen_retencion
                        )?.codigo !== TIPOS_REGIMEN_RETENCION.REGIMEN_GENERAL
                      }
                      dropdownMatchSelectWidth={false}
                      placement={"bottomLeft"}
                      options={tiposRegimenGeneral.map(
                        (tipoRegimenGeneral) => ({
                          value: tipoRegimenGeneral.id_tipo_regimen_general,
                          label: tipoRegimenGeneral.descripcion,
                        })
                      )}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Paraíso Fiscal: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_tipo_paraiso_fiscal}
                      onChange={(e) =>
                        onChangeForm("id_tipo_paraiso_fiscal", e)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE ||
                        tiposRegimenRetencion.find(
                          (tipoRegimenRetencion) =>
                            tipoRegimenRetencion.id_tipo_regimen_retencion ===
                            form.id_tipo_regimen_retencion
                        )?.codigo !== TIPOS_REGIMEN_RETENCION.PARAISO_FISCAL
                      }
                      dropdownMatchSelectWidth={false}
                      placement={"bottomLeft"}
                      options={tiposParaisoFiscal.map((tipoParaisoFiscal) => ({
                        value: tipoParaisoFiscal.id_tipo_paraiso_fiscal,
                        label: tipoParaisoFiscal.descripcion,
                      }))}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Denominación: </Typography>
                  </Col>
                  <Col span={12}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.denominacion}
                      onChange={(e) =>
                        onChangeForm("denominacion", e.target.value)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE ||
                        tiposRegimenRetencion.find(
                          (tipoRegimenRetencion) =>
                            tipoRegimenRetencion.id_tipo_regimen_retencion ===
                            form.id_tipo_regimen_retencion
                        )?.codigo !==
                          TIPOS_REGIMEN_RETENCION.REGIMEN_FISCAL_PREFERENTE_JURISDICCION
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 3 }}>
                  <Col span={12}>
                    <Typography>Pais: </Typography>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_pais}
                      onChange={(e) => onChangeForm("id_pais", e)}
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE ||
                        tiposRegimenRetencion.find(
                          (tipoRegimenRetencion) =>
                            tipoRegimenRetencion.id_tipo_regimen_retencion ===
                            form.id_tipo_regimen_retencion
                        )?.codigo !==
                          TIPOS_REGIMEN_RETENCION.REGIMEN_FISCAL_PREFERENTE_JURISDICCION
                      }
                      dropdownMatchSelectWidth={false}
                      placement={"bottomLeft"}
                      options={paises.map((pais) => ({
                        value: pais.id_pais,
                        label: pais.nombre,
                      }))}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Typography>
                      ¿Aplica convenio de doble tributación?
                    </Typography>
                    <Radio.Group
                      value={form.doble_tributacion}
                      onChange={(e) =>
                        onChangeForm("doble_tributacion", e.target.value)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE
                      }
                    >
                      <Radio value={true}> Si </Radio>
                      <Radio value={false}> No </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Typography>
                      ¿Pago sujeto a retención en aplicación de la norma legal?
                    </Typography>
                    <Radio.Group
                      value={form.norma_legal}
                      onChange={(e) =>
                        onChangeForm("norma_legal", e.target.value)
                      }
                      disabled={
                        tiposPagosA.find(
                          (tipoPagoA) =>
                            tipoPagoA.id_tipo_pago_a === form.id_tipo_pago_a
                        )?.codigo === TIPOS_PAGOS_A.RESIDENTE
                      }
                    >
                      <Radio value={true}> Si </Radio>
                      <Radio value={false}> No </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Col span={24}>
              <Row justify={"space-between"}>
                <Typography style={{ fontWeight: "bold" }}>
                  Retención Renta
                </Typography>
                <Button
                  size="small"
                  type="primary"
                  icon={<IoAdd />}
                  onClick={openModalCrearRetencionRenta}
                />
              </Row>
              <TablaRetencionRenta
                data={retencionesRenta}
                eliminar={removeRetencionRenta}
                editar={editRetencionRenta}
                codigosRetenciones={codigosRetencionesRenta}
              />
              <Row justify={"space-between"} style={{ marginBlock: 5 }}>
                <Col span={5}>
                  <Input
                    style={{ width: 100, fontWeight: "bold" }}
                    size="small"
                    value={numberFormat(
                      retencionesRenta.reduce(
                        (a, v) => a + Number(v.base_imponible ?? 0),
                        0
                      )
                    )}
                    disabled={true}
                  />
                </Col>
                <Col span={5}>
                  <Input
                    style={{ width: 100, fontWeight: "bold" }}
                    size="small"
                    value={numberFormat(
                      retencionesRenta.reduce(
                        (a, v) => a + Number(v.valor_retenido ?? 0),
                        0
                      )
                    )}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Divider />
            <Col span={24}>
              <Row justify={"space-between"}>
                <Typography style={{ fontWeight: "bold" }}>
                  Retención IVA
                </Typography>
                <Button
                  size="small"
                  type="primary"
                  icon={<IoAdd />}
                  onClick={openModalCrearRetencionIva}
                />
              </Row>
              <TablaRetencionIva
                data={retencionesIva}
                eliminar={removeRetencionIva}
                editar={editRetencionIva}
                codigosRetenciones={codigosRetencionesIva}
              />
              <Row justify={"space-between"} style={{ marginBlock: 5 }}>
                <Col span={5}>
                  <Input
                    style={{ width: 100, fontWeight: "bold" }}
                    size="small"
                    value={numberFormat(
                      retencionesIva.reduce(
                        (a, v) => a + Number(v.base_imponible ?? 0),
                        0
                      )
                    )}
                    disabled={true}
                  />
                </Col>
                <Col span={5}>
                  <Input
                    style={{ width: 100, fontWeight: "bold" }}
                    size="small"
                    value={numberFormat(
                      retencionesIva.reduce(
                        (a, v) => a + Number(v.valor_retenido ?? 0),
                        0
                      )
                    )}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>

        <ModalCrearRetencionRenta
          isOpen={isOpenModalCrearRetencionRenta}
          close={() => setIsOpenModalCrearRetencionRenta(false)}
          add={addRetencionRenta}
          codigosRetenciones={codigosRetencionesRenta}
          disponible={disponibleRenta}
        />
        <ModalCrearRetencionIva
          isOpen={isOpenModalCrearRetencionIva}
          close={() => setIsOpenModalCrearRetencionIva(false)}
          add={addRetencionIva}
          codigosRetenciones={codigosRetencionesIva}
          disponible={disponibleIva}
        />
      </Content>
    );
  }
);
