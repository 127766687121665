import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import {
  IoArrowBack,
  IoInformationCircle,
  IoSettingsSharp,
  IoShield,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import {
  eliminarUsuarioEmpresaRequest,
  usuarioEmpresaRequest,
} from "../../../../services/api/empresas";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { UserContext } from "../../../../context/UserContext";
import { ActionContext } from "../../../../context/ActionContext";
import { dateDDMMYYYYHHmm } from "../../../../helpers/format/dates-formater";
import { EditarUsuarioDrawer } from "./components/EditarUsuarioDrawer";
import { TabUsuario } from "./components/TabUsuario";
import { TabPermisos } from "./components/TabPermisos";

export const UsuarioPage = () => {
  const {
    checkPermisos,
    checkData,
    empresa,
    usuario: usuarioContext,
  } = useContext(UserContext);
  const { reloadEmpresas } = useContext(ActionContext);

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [usuario, setUsuario] = useState({});
  const [empresaSistemas, setEmpresaSistemas] = useState([]);
  const [reload, setReload] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setUsuario({});
    setEmpresaSistemas({});
    usuarioEmpresaRequest({
      id_empresa: empresa.id_empresa,
      id_usuario: +id,
    })
      .then((res) => {
        const empresa_usuario = res.empresa_usuarios[0];
        if (!empresa_usuario) return;
        setUsuario(empresa_usuario);
        setEmpresaSistemas(res.empresa_sistemas);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reload]);

  const onReload = () => setReload(!reload);

  const eliminar = () => {
    const message = `¿Esta seguro de eliminar este usuario?`;
    const messageValidate = `¿Esta seguro de eliminar su usuario de la empresa ${empresa.nombre_comercial_empresa}?`;

    const confirm = window.confirm(
      usuario.usuario?.id_usuario == usuarioContext.id_usuario
        ? messageValidate
        : message
    );
    if (!confirm) return;

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      id_usuario: +id,
    };

    eliminarUsuarioEmpresaRequest(dataRequest).then((res) => {
      checkData();
      navigate(-1);
    });
  };

  const items = [
    {
      key: 1,
      label: (
        <Row align={"middle"} style={{ paddingInline: 15 }}>
          <IoInformationCircle size={20} style={{ marginRight: 5 }} />
          Información
        </Row>
      ),
      children: <TabUsuario usuario={usuario} />,
    },
    {
      key: 2,
      label: (
        <Row align={"middle"} style={{ paddingInline: 15 }}>
          <IoShield size={20} style={{ marginRight: 5 }} />
          Permisos de usuario
        </Row>
      ),
      children: <TabPermisos empresa_sistemas={empresaSistemas} usuario={usuario} onReload={onReload}/>,
    },
  ];

  return (
    <Content>
      {/* Header */}
      <Row
        align={"middle"}
        style={{ marginBottom: 10 }}
        justify={"space-between"}
      >
        <Row align={"middle"}>
          <Tooltip title={"Regresar"}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              shape="circle"
              icon={<IoArrowBack size={20}/>}
              style={{ marginRight: 10 }}
            />
          </Tooltip>
          <Breadcrumb
            items={[
              {
                title: (
                  <Row align={"middle"}>
                    <IoSettingsSharp style={{ marginRight: 5 }} />
                    <span>Administración</span>
                  </Row>
                ),
              },
              {
                title: "Usuarios",
              },
              {
                title: usuario.usuario?.nombre_usuario,
              },
            ]}
          />
        </Row>
        <Row gutter={10} align={"middle"} justify={"space-around"}>
          <Col>
            <Button
              type="primary"
              onClick={showDrawer}
              disabled={!checkPermisos("MUSUARIOS").editar}
              icon={<EditFilled />}
            >
              Editar
            </Button>
          </Col>
          <Col>
            <Button
              danger
              type="primary"
              disabled={!checkPermisos("MUSUARIOS").eliminar}
              onClick={eliminar}
              icon={<DeleteFilled />}
            >
              Eliminar
            </Button>
          </Col>
        </Row>
      </Row>
      {/* Datos */}
      <Spin spinning={isLoading}>
        <Tabs defaultActiveKey="1" items={items} />
      </Spin>
      {usuario.usuario?.id_usuario && (
        <EditarUsuarioDrawer
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
          usuario={usuario.usuario}
          reload={onReload}
        />
      )}
    </Content>
  );
};
