import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Row, Table, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarCajaRequest } from "../../../../../services/api/cajas";
import { EditarBanco, EditarCaja } from "./EditarBanco";
import { eliminarBancoRequest } from "../../../../../services/api/bancos";

export const TablaBancos = ({ data }) => {
  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos } = useContext(UserContext);
  const { reloadBancos } = useContext(ActionContext);

  const [id, setId] = useState(0);

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_banco = record.id_banco;
    if (!id_banco) return;
    setId(id_banco);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_banco = record.id_banco;
    if (!id_banco) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar esta banco, esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarBancoRequest(id_banco).then((res) => {
      reloadBancos();
    });
  };

  const columns = [
    {
      title: "Opciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MBANCOS").editar}
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MBANCOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Banco",
      dataIndex: "banco",
      key: "banco",
      width: "30%",
      ...getColumnSearchProps("banco"),
    },
    {
      title: "Activo",
      dataIndex: "activo",
      key: "activo",
      width: "10%",
      align: "center",
      render: (record) => {
        return <Checkbox checked={record} />;
      },
    },
    {
      title: "Código",
      dataIndex: "cuenta_contable_banco",
      key: "cuenta_contable_banco",
      width: "20%",
      render: (record) => {
        return record?.codigo_contable;
      },
    },
    {
      title: "Cuenta Contable",
      dataIndex: "cuenta_contable_banco",
      key: "cuenta_contable_banco",
      width: "20%",
      render: (record) => {
        return record?.nombre_cuenta_contable;
      },
    },
  ];

  return (
    <>
      <Table
        scroll={{
          y: 390,
        }}
        rowKey={"id_banco"}
        columns={columns}
        dataSource={data}
        size="small"
      />
      <EditarBanco
        open={openEdit}
        showDrawer={showDrawerEdit}
        onClose={onCloseEdit}
        id={id}
      />
    </>
  );
};
