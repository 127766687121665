import { SearchOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import { tiposContabilizacionRequest } from "../../../../../services/api/opciones";
import {
  crearParametrosContablesProductosRequest,
  editarParametrosContablesProductosRequest,
  parametrosContablesProductosEmpresaRequest,
} from "../../../../../services/api/parametros-contables-productos";
import { toast } from "sonner";
import { ModalCuentasContables } from "./ModalCuentasContables";
import { CuentaContableInput } from "./CuentaContableInput";
import {
  crearParametrosContablesComprasVentasRequest,
  editarParametrosContablesComprasVentasRequest,
  parametrosContablesComprasVentasEmpresaRequest,
} from "../../../../../services/api/parametros-contables-compras-ventas";

export const ComprasVentasTab = ({
  onReload = () => {},
  activeForm,
  reload,
}) => {
  const { empresa, checkPermisos } = useContext(UserContext);

  const { form, onChangeForm, setForm } = useForm({
    id_parametros_contables_compras_ventas: null,
    cuenta_contable_cajas: null,
    cuenta_contable_bancos: null,
    cuenta_contable_clientes: null,
    cuenta_contable_proveedores: null,
    cuenta_contable_recepciones: null,
    cuenta_contable_iva_compras: null,
    cuenta_contable_iva_presuntivo: null,
    cuenta_contable_ir_presuntivo: null,
    cuenta_contable_ice: null,
    cuenta_contable_asume_retencion: null,
    cuenta_contable_iva_ventas: null,
    cuenta_contable_ice_ventas: null,
    cuenta_contable_propinas_ventas: null,
    id_tipo_contabilizacion_cajas: null,
    id_tipo_contabilizacion_bancos: null,
    id_tipo_contabilizacion_compras: null,
    id_tipo_contabilizacion_ventas: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tiposContabilizacion, setTiposContabilizacion] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    tiposContabilizacionRequest().then((res) => setTiposContabilizacion(res));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    parametrosContablesComprasVentasEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        if (res) {
          setForm({
            id_parametros_contables_compras_ventas:
              res.id_parametros_contables_compras_ventas,
            cuenta_contable_cajas: res.cuenta_contable_cajas,
            cuenta_contable_bancos: res.cuenta_contable_bancos,
            cuenta_contable_clientes: res.cuenta_contable_clientes,
            cuenta_contable_proveedores: res.cuenta_contable_proveedores,
            cuenta_contable_recepciones: res.cuenta_contable_recepciones,
            cuenta_contable_iva_compras: res.cuenta_contable_iva_compras,
            cuenta_contable_iva_presuntivo: res.cuenta_contable_iva_presuntivo,
            cuenta_contable_ir_presuntivo: res.cuenta_contable_ir_presuntivo,
            cuenta_contable_ice: res.cuenta_contable_ice,
            cuenta_contable_asume_retencion:
              res.cuenta_contable_asume_retencion,
            cuenta_contable_iva_ventas: res.cuenta_contable_iva_ventas,
            cuenta_contable_ice_ventas: res.cuenta_contable_ice_ventas,
            cuenta_contable_propinas_ventas:
              res.cuenta_contable_propinas_ventas,
            id_tipo_contabilizacion_cajas:
              res.tipo_contabilizacion_cajas?.id_tipo_contabilizacion,
            id_tipo_contabilizacion_bancos:
              res.tipo_contabilizacion_bancos?.id_tipo_contabilizacion,
            id_tipo_contabilizacion_compras:
              res.tipo_contabilizacion_compras?.id_tipo_contabilizacion,
            id_tipo_contabilizacion_ventas:
              res.tipo_contabilizacion_ventas?.id_tipo_contabilizacion,
          });
        } else {
          setError({
            show: true,
            message:
              "Crear los parametros contable compras y ventas antes de utilizar el sistema",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa, reload]);

  const validarCampos = () => {
    const {
      cuenta_contable_cajas,
      cuenta_contable_bancos,
      cuenta_contable_clientes,
      cuenta_contable_proveedores,
      cuenta_contable_recepciones,
      cuenta_contable_iva_compras,
      cuenta_contable_iva_presuntivo,
      cuenta_contable_ir_presuntivo,
      cuenta_contable_ice,
      cuenta_contable_asume_retencion,
      cuenta_contable_iva_ventas,
      cuenta_contable_ice_ventas,
      cuenta_contable_propinas_ventas,
      id_tipo_contabilizacion_cajas,
      id_tipo_contabilizacion_bancos,
      id_tipo_contabilizacion_compras,
      id_tipo_contabilizacion_ventas,
    } = form;

    if (!cuenta_contable_cajas) {
      setError({
        show: true,
        message: "Seleccione C.C. Cajas",
      });
      return false;
    }

    if (!cuenta_contable_bancos) {
      setError({
        show: true,
        message: "Seleccione C.C. Bancos:",
      });
      return false;
    }

    if (!cuenta_contable_clientes) {
      setError({
        show: true,
        message: "Seleccione C.C. Clientes:",
      });
      return false;
    }

    if (!cuenta_contable_proveedores) {
      setError({
        show: true,
        message: "Seleccione C.C. Proveedores:",
      });
      return false;
    }

    if (!cuenta_contable_recepciones) {
      setError({
        show: true,
        message: "Seleccione C.C. Recepciones:",
      });
      return false;
    }

    if (!cuenta_contable_iva_compras) {
      setError({
        show: true,
        message: "Seleccione C.C. IVA Compras:",
      });
      return false;
    }

    if (!cuenta_contable_iva_presuntivo) {
      setError({
        show: true,
        message: "Seleccione C.C. IVA Presuntivo:",
      });
      return false;
    }

    if (!cuenta_contable_ir_presuntivo) {
      setError({
        show: true,
        message: "Seleccione C.C. IR Presuntivo:",
      });
      return false;
    }

    if (!cuenta_contable_ice) {
      setError({
        show: true,
        message: "Seleccione C.C. ICE:",
      });
      return false;
    }

    if (!cuenta_contable_asume_retencion) {
      setError({
        show: true,
        message: "Seleccione C.C. Asume Ret.:",
      });
      return false;
    }

    if (!cuenta_contable_iva_ventas) {
      setError({
        show: true,
        message: "Seleccione C.C. IVA Ventas:",
      });
      return false;
    }

    if (!cuenta_contable_ice_ventas) {
      setError({
        show: true,
        message: "Seleccione C.C. ICE Ventas:",
      });
      return false;
    }

    if (!cuenta_contable_propinas_ventas) {
      setError({
        show: true,
        message: "Seleccione C.C. Propina Ventas:",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_cajas) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de cajas",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_bancos) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de bancos",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_compras) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de compras",
      });
      return false;
    }

    if (!id_tipo_contabilizacion_ventas) {
      setError({
        show: true,
        message: "Seleccione el tipo de contabilizacion de ventas",
      });
      return false;
    }

    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de crear los parametros contables compras y ventas de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_cajas: form.cuenta_contable_cajas.id_cuenta_contable,
      id_cuenta_contable_bancos: form.cuenta_contable_bancos.id_cuenta_contable,
      id_cuenta_contable_clientes:
        form.cuenta_contable_clientes.id_cuenta_contable,
      id_cuenta_contable_proveedores:
        form.cuenta_contable_proveedores.id_cuenta_contable,
      id_cuenta_contable_recepciones:
        form.cuenta_contable_recepciones.id_cuenta_contable,
      id_cuenta_contable_iva_compras:
        form.cuenta_contable_iva_compras.id_cuenta_contable,
      id_cuenta_contable_iva_presuntivo:
        form.cuenta_contable_iva_presuntivo.id_cuenta_contable,
      id_cuenta_contable_ir_presuntivo:
        form.cuenta_contable_ir_presuntivo.id_cuenta_contable,
      id_cuenta_contable_ice: form.cuenta_contable_ice.id_cuenta_contable,
      id_cuenta_contable_asume_retencion:
        form.cuenta_contable_asume_retencion.id_cuenta_contable,
      id_cuenta_contable_iva_ventas:
        form.cuenta_contable_iva_ventas.id_cuenta_contable,
      id_cuenta_contable_ice_ventas:
        form.cuenta_contable_ice_ventas.id_cuenta_contable,
      id_cuenta_contable_propinas_ventas:
        form.cuenta_contable_propinas_ventas.id_cuenta_contable,
      id_tipo_contabilizacion_cajas: form.id_tipo_contabilizacion_cajas,
      id_tipo_contabilizacion_bancos: form.id_tipo_contabilizacion_bancos,
      id_tipo_contabilizacion_compras: form.id_tipo_contabilizacion_compras,
      id_tipo_contabilizacion_ventas: form.id_tipo_contabilizacion_ventas,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    crearParametrosContablesComprasVentasRequest(dataRequest)
      .then((res) => {
        onReload();
        toast.success(
          "Parametros contables compras y ventas creados correctamente !"
        );
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editar = () => {
    setError({ show: false, message: "" });
    const validate = validarCampos();
    if (!validate) return;

    const confirm = window.confirm(
      `¿Esta seguro de guardar los cambios en parametros contables compras y ventas de la empresa ${empresa.nombre_comercial_empresa}?`
    );

    if (!confirm) return;

    const dataRequest = {
      id_cuenta_contable_cajas: form.cuenta_contable_cajas.id_cuenta_contable,
      id_cuenta_contable_bancos: form.cuenta_contable_bancos.id_cuenta_contable,
      id_cuenta_contable_clientes:
        form.cuenta_contable_clientes.id_cuenta_contable,
      id_cuenta_contable_proveedores:
        form.cuenta_contable_proveedores.id_cuenta_contable,
      id_cuenta_contable_recepciones:
        form.cuenta_contable_recepciones.id_cuenta_contable,
      id_cuenta_contable_iva_compras:
        form.cuenta_contable_iva_compras.id_cuenta_contable,
      id_cuenta_contable_iva_presuntivo:
        form.cuenta_contable_iva_presuntivo.id_cuenta_contable,
      id_cuenta_contable_ir_presuntivo:
        form.cuenta_contable_ir_presuntivo.id_cuenta_contable,
      id_cuenta_contable_ice: form.cuenta_contable_ice.id_cuenta_contable,
      id_cuenta_contable_asume_retencion:
        form.cuenta_contable_asume_retencion.id_cuenta_contable,
      id_cuenta_contable_iva_ventas:
        form.cuenta_contable_iva_ventas.id_cuenta_contable,
      id_cuenta_contable_ice_ventas:
        form.cuenta_contable_ice_ventas.id_cuenta_contable,
      id_cuenta_contable_propinas_ventas:
        form.cuenta_contable_propinas_ventas.id_cuenta_contable,
      id_tipo_contabilizacion_cajas: form.id_tipo_contabilizacion_cajas,
      id_tipo_contabilizacion_bancos: form.id_tipo_contabilizacion_bancos,
      id_tipo_contabilizacion_compras: form.id_tipo_contabilizacion_compras,
      id_tipo_contabilizacion_ventas: form.id_tipo_contabilizacion_ventas,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    editarParametrosContablesComprasVentasRequest(
      form.id_parametros_contables_compras_ventas,
      dataRequest
    )
      .then((res) => {
        onReload();
        toast.success(
          "Parametros contables compras y ventas guardados correctamente !"
        );
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={10} justify={"space-between"} style={{ paddingBottom: 100 }}>
        <Col span={17}>
          {/* ERROR */}
          <Row>
            {error.show && (
              <Alert
                description={error.message}
                type="error"
                closable
                onClose={() => setError({ show: false, message: "" })}
              />
            )}
          </Row>
          <CuentaContableInput
            label="C.C. Cajas:"
            onChange={(v) => onChangeForm("cuenta_contable_cajas", v)}
            value={form.cuenta_contable_cajas}
          />
          <CuentaContableInput
            label="C.C. Bancos:"
            onChange={(v) => onChangeForm("cuenta_contable_bancos", v)}
            value={form.cuenta_contable_bancos}
          />
          <CuentaContableInput
            label="C.C. Clientes:"
            onChange={(v) => onChangeForm("cuenta_contable_clientes", v)}
            value={form.cuenta_contable_clientes}
          />
          <CuentaContableInput
            label="C.C. Proveedores:"
            onChange={(v) => onChangeForm("cuenta_contable_proveedores", v)}
            value={form.cuenta_contable_proveedores}
          />
          <CuentaContableInput
            label="C.C. Recepciones:"
            onChange={(v) => onChangeForm("cuenta_contable_recepciones", v)}
            value={form.cuenta_contable_recepciones}
          />

          <Row style={{ marginTop: 10 }}></Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ margin: 0, fontSize: 14 }}
          >
            Cuentas contables compras
          </Divider>
          <CuentaContableInput
            label="C.C. IVA Compras:"
            onChange={(v) => onChangeForm("cuenta_contable_iva_compras", v)}
            value={form.cuenta_contable_iva_compras}
          />
          <CuentaContableInput
            label="C.C. IVA Presuntivo:"
            onChange={(v) => onChangeForm("cuenta_contable_iva_presuntivo", v)}
            value={form.cuenta_contable_iva_presuntivo}
          />
          <CuentaContableInput
            label="C.C. IR Presuntivo:"
            onChange={(v) => onChangeForm("cuenta_contable_ir_presuntivo", v)}
            value={form.cuenta_contable_ir_presuntivo}
          />
          <CuentaContableInput
            label="C.C. ICE:"
            onChange={(v) => onChangeForm("cuenta_contable_ice", v)}
            value={form.cuenta_contable_ice}
          />
          <CuentaContableInput
            label="C.C. Asume Ret.:"
            onChange={(v) => onChangeForm("cuenta_contable_asume_retencion", v)}
            value={form.cuenta_contable_asume_retencion}
          />
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ margin: 0, fontSize: 14 }}
          >
            Cuentas contables ventas
          </Divider>
          <CuentaContableInput
            label="C.C. IVA Ventas:"
            onChange={(v) => onChangeForm("cuenta_contable_iva_ventas", v)}
            value={form.cuenta_contable_iva_ventas}
          />
          <CuentaContableInput
            label="C.C. ICE Ventas:"
            onChange={(v) => onChangeForm("cuenta_contable_ice_ventas", v)}
            value={form.cuenta_contable_ice_ventas}
          />
          <CuentaContableInput
            label="C.C. Propina Ventas:"
            onChange={(v) => onChangeForm("cuenta_contable_propinas_ventas", v)}
            value={form.cuenta_contable_propinas_ventas}
          />
        </Col>
        <Col span={7}>
          {/* BOTONES */}
          <Row style={{ marginBottom: 10 }}>
            {form.id_parametros_contables_compras_ventas ? (
              <Button
                type="primary"
                onClick={editar}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").editar || !activeForm
                }
              >
                Guardar cambios
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={crear}
                disabled={
                  !checkPermisos("MPARAMETROS-CONTABLES").crear || !activeForm
                }
              >
                Crear
              </Button>
            )}
          </Row>
          <Row>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilizacion_cajas", e.target.value);
              }}
              value={form.id_tipo_contabilizacion_cajas}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo Contabilización Cajas
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilizacion_bancos", e.target.value);
              }}
              value={form.id_tipo_contabilizacion_bancos}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo Contabilización Bancos
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          {/* <Row style={{ marginTop: 10 }}>
            <Button type="primary">Asignar cuentas</Button>
          </Row> */}

          <Row style={{ marginTop: 20 }}>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilizacion_compras", e.target.value);
              }}
              value={form.id_tipo_contabilizacion_compras}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo Contabilización Compras
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Radio.Group
              onChange={(e) => {
                onChangeForm("id_tipo_contabilizacion_ventas", e.target.value);
              }}
              value={form.id_tipo_contabilizacion_ventas}
            >
              <Typography style={{ marginBlock: 2, fontWeight: "bold" }}>
                Tipo Contabilización Ventas
              </Typography>
              <Space direction="vertical">
                {tiposContabilizacion.map((f) => {
                  return (
                    <Radio
                      key={f.id_tipo_contabilizacion}
                      value={f.id_tipo_contabilizacion}
                    >
                      {f.descripcion}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
