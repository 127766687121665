import { IoHome } from "react-icons/io5";

export const DefaultMenu = [
  {
    key: "SINICIO",
    label: "Inicio",
    icon: <IoHome />,
    navigate: '/inicio'
  },
];
