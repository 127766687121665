import API from "../../configs/api/api.config";

const URL_USUARIOS = '/usuarios';

export const usuarioRequest = async (id_usuario) => {
    const request = await API.get(URL_USUARIOS + `/${id_usuario}`);
    const response = request.data;
    return response;
}

const URL_EMPRESAS = '/empresas';

export const usuariosEmpresaRequest = async (id_empresa) => {
    const request = await API.get(URL_EMPRESAS + `/usuarios_empresa/${id_empresa}`);
    const response = request.data;
    return response;
}