import { Button } from "antd";
import React, { useState } from "react";

export const ButtonAsync = ({
  onClick = async () => {},
  text = "",
  type = "primary",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickAsync = async () => {
    setIsLoading(true);
    await onClick().finally(() => setIsLoading(false));
  };

  return (
    <Button type={type} onClick={onClickAsync} loading={isLoading}>
      {text}
    </Button>
  );
};
