import API from "../../configs/api/api.config";

const URL_RUTAS_CLIENTES = '/rutas-clientes';

export const rutasClientesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_RUTAS_CLIENTES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const rutaClienteIdRequest = async (id_ruta_cliente) => {
    const request = await API.get(URL_RUTAS_CLIENTES + '/' + id_ruta_cliente);
    const response = request.data;
    return response;
}

export const crearRutaClienteRequest = async (data) => {
    const request = await API.post(URL_RUTAS_CLIENTES, data);
    const response = request.data;
    return response;
}

export const actualizarRutaClienteRequest = async (id_ruta_cliente, data) => {
    const request = await API.patch(URL_RUTAS_CLIENTES + '/' + id_ruta_cliente, data);
    const response = request.data;
    return response;
}

export const eliminarRutaClienteRequest = async (id_ruta_cliente) => {
    const request = await API.delete(URL_RUTAS_CLIENTES + '/' + id_ruta_cliente);
    const response = request.data;
    return response;
}