import { Checkbox, Col, Input, Row, Select, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import { TablaTarifas } from "./TablaTarifas";

export const TabDatosBasicos = memo(
  ({
    form = {},
    onChangeForm = (e, d) => {},
    lineasProductos = [],
    categoriasProductos = [],
    tarifasIva = [],
    subCategoriasProductos = [],
    subGruposProductos = [],
    medidasProducto = [],
    tarifas = [],
    onEdit = () => {},
    isLoadingTarifas = false 
  }) => {
    return (
      <Content>
        <Row gutter={5}>
          <Col span={14}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Código: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo}
                      onChange={(e) => onChangeForm("codigo", e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Código de barras: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.codigo_barras}
                      onChange={(e) =>
                        onChangeForm("codigo_barras", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={5}>
                    <Typography>Descripción: </Typography>
                  </Col>
                  <Col span={19}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      value={form.descripcion}
                      onChange={(e) =>
                        onChangeForm("descripcion", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Linea: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_linea_producto}
                      onChange={(e) =>
                        onChangeForm("id_linea_producto", e)
                      }
                    >
                      {lineasProductos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_linea_producto}
                            value={a.id_linea_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Existencia actual: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input disabled size="small" style={{ width: "100%" }} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Categoría: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_categoria_producto}
                      onChange={(e) => onChangeForm("id_categoria_producto", e)}
                    >
                      {categoriasProductos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_categoria_producto}
                            value={a.id_categoria_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>IVA: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_iva_producto}
                      onChange={(e) => onChangeForm("id_iva_producto", e)}
                    >
                      {tarifasIva.map((a) => {
                        return (
                          <Select.Option key={a.id_iva} value={a.id_iva}>
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Sub Categoría: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_sub_categoria_producto}
                      onChange={(e) =>
                        onChangeForm("id_sub_categoria_producto", e)
                      }
                    >
                      {subCategoriasProductos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_sub_categoria_producto}
                            value={a.id_sub_categoria_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 1 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Sub Grupo: </Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={form.id_sub_grupo_producto}
                      onChange={(e) => onChangeForm("id_sub_grupo_producto", e)}
                    >
                      {subGruposProductos.map((a) => {
                        return (
                          <Select.Option
                            key={a.id_sub_grupo_producto}
                            value={a.id_sub_grupo_producto}
                          >
                            {a.descripcion}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={5}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Costo actual: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.costo_actual}
                      onChange={(e) =>
                        onChangeForm("costo_actual", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Costo compra: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.costo_compra}
                      onChange={(e) =>
                        onChangeForm("costo_compra", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={5} style={{ marginTop: 5 }}>
              <Col span={12}>
                <Row justify={"space-between"}>
                  <Col span={10}>
                    <Typography>Costo estándar: </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      size="small"
                      style={{ width: "100%" }}
                      type={"number"}
                      value={form.costo_estandar}
                      onChange={(e) =>
                        onChangeForm("costo_estandar", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={5} style={{ marginTop: 1 }}>
          <Col span={16}>
            <Row gutter={5}>
              <Col span={4}>
                <Checkbox
                  checked={form.activo}
                  onChange={(e) => onChangeForm("activo", e.target.checked)}
                >
                  Activo
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={form.controla_negativos}
                  onChange={(e) =>
                    onChangeForm("controla_negativos", e.target.checked)
                  }
                >
                  Controla Negativos
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  checked={form.compuesto}
                  onChange={(e) => onChangeForm("compuesto", e.target.checked)}
                >
                  Compuesto
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  checked={form.series}
                  onChange={(e) => onChangeForm("series", e.target.checked)}
                >
                  Series
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox
                  checked={form.lotes}
                  onChange={(e) => onChangeForm("lotes", e.target.checked)}
                >
                  Lotes
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={5}>
              <Col span={4}>
                <Checkbox
                  checked={form.combustible}
                  onChange={(e) =>
                    onChangeForm("combustible", e.target.checked)
                  }
                >
                  Combustible
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={form.controla_precio}
                  onChange={(e) =>
                    onChangeForm("controla_precio", e.target.checked)
                  }
                >
                  Controla Precios
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  checked={form.materia_prima}
                  onChange={(e) =>
                    onChangeForm("materia_prima", e.target.checked)
                  }
                >
                  Materia Prima
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox>Vehiculo</Checkbox>
              </Col>
              <Col span={4}></Col>
            </Row>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row gutter={5} style={{ marginTop: 2 }}>
          <Col span={16}>
            <Spin spinning={isLoadingTarifas} >
            <TablaTarifas
              data={tarifas}
              form={form}
              medidasProducto={medidasProducto}
              onEdit={onEdit}
              
            />
            </Spin>
          </Col>
          <Col span={8}>
            <Row justify={"start"}>
              <Col span={8}>
                <Typography>Medida Superior: </Typography>
              </Col>
              <Col span={8}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_medida_superior}
                  onChange={(e) => onChangeForm("id_medida_superior", e)}
                >
                  <Select.Option key={null} value={null}>
                    Sin definir
                  </Select.Option>
                  {medidasProducto.map((a) => {
                    return (
                      <Select.Option
                        key={a.id_medida_producto}
                        value={a.id_medida_producto}
                        disabled={
                          a.id_medida_producto == form.id_medida_interna ||
                          a.id_medida_producto == form.id_medida_inferior
                        }
                      >
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row justify={"start"} style={{ marginTop: 5 }}>
              <Col span={8}>
                <Typography>Factor Superior: </Typography>
              </Col>
              <Col span={8}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  type={"number"}
                  disabled={!form.id_medida_superior}
                  value={form.factor_superior}
                  onChange={(e) =>
                    onChangeForm("factor_superior", e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row justify={"start"} style={{ marginTop: 30 }}>
              <Col span={8}>
                <Typography>Medida Interna: </Typography>
              </Col>
              <Col span={8}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_medida_interna}
                  onChange={(e) => onChangeForm("id_medida_interna", e)}
                >
                  {medidasProducto.map((a) => {
                    return (
                      <Select.Option
                        key={a.id_medida_producto}
                        value={a.id_medida_producto}
                        disabled={
                          a.id_medida_producto == form.id_medida_superior ||
                          a.id_medida_producto == form.id_medida_inferior
                        }
                      >
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row justify={"start"} style={{ marginTop: 30 }}>
              <Col span={8}>
                <Typography>Medida Inferior: </Typography>
              </Col>
              <Col span={8}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={form.id_medida_inferior}
                  onChange={(e) => onChangeForm("id_medida_inferior", e)}
                >
                  <Select.Option key={null} value={null}>
                    Sin definir
                  </Select.Option>
                  {medidasProducto.map((a) => {
                    return (
                      <Select.Option
                        key={a.id_medida_producto}
                        value={a.id_medida_producto}
                        disabled={
                          a.id_medida_producto == form.id_medida_superior ||
                          a.id_medida_producto == form.id_medida_interna
                        }
                      >
                        {a.descripcion}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row justify={"start"} style={{ marginTop: 5 }}>
              <Col span={8}>
                <Typography>Factor Inferior: </Typography>
              </Col>
              <Col span={8}>
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  type={"number"}
                  disabled={!form.id_medida_inferior}
                  value={form.factor_inferior}
                  onChange={(e) =>
                    onChangeForm("factor_inferior", e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
);
