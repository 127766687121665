import { Button, Col, DatePicker, Input, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export const FormPagoCheque = ({
  form = {},
  onChangeForm = (p, v) => {},
  cajas = [],
  bancosClientes = [],
  formasPagosSri = [],
  valorRef,
  registrar = () => {},
  edit = false,
}) => {
  return (
    <Content>
      <Row align={"middle"} justify={"start"}>
        <Col span={10}>
          <Typography>Valor: </Typography>
        </Col>
        <Col span={14}>
          <Input
            ref={valorRef}
            value={form.total}
            type={"number"}
            onChange={(e) => onChangeForm("total", e.target.value)}
          />
        </Col>
      </Row>
      <Row align={"middle"} justify={"start"} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography>Caja: </Typography>
        </Col>
        <Col span={14}>
          <Select
            value={form.id_caja}
            onChange={(e) => onChangeForm("id_caja", e)}
            style={{ width: "100%" }}
          >
            {cajas.map((caja) => {
              return (
                <Select.Option key={caja.id_caja} value={caja.id_caja}>
                  {caja.descripcion}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row align={"middle"} justify={"start"} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography>Banco: </Typography>
        </Col>
        <Col span={14}>
          <Select
            value={form.id_banco_cliente}
            onChange={(e) => onChangeForm("id_banco_cliente", e)}
            style={{ width: "100%" }}
          >
            {bancosClientes.map((bancoCliente) => {
              return (
                <Select.Option
                  key={bancoCliente.id_banco_cliente}
                  value={bancoCliente.id_banco_cliente}
                >
                  {bancoCliente.banco}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row align={"middle"} justify={"start"} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography>Fecha vence: </Typography>
        </Col>
        <Col span={14}>
          <DatePicker
            value={form.fecha_vence}
            onChange={(e) => onChangeForm("fecha_vence", e)}
          />
        </Col>
      </Row>
      <Row align={"middle"} justify={"start"} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography>Numero documento: </Typography>
        </Col>
        <Col span={14}>
          <Input
            value={form.numero_documento}
            onChange={(e) => onChangeForm("numero_documento", e.target.value)}
          />
        </Col>
      </Row>
      <Row align={"middle"} justify={"start"} style={{ marginTop: 10 }}>
        <Col span={10}>
          <Typography>Forma Pago SRI: </Typography>
        </Col>
        <Col span={14}>
          <Select
            value={form.id_forma_pago_sri}
            onChange={(e) => onChangeForm("id_forma_pago_sri", e)}
            style={{ width: "100%" }}
          >
            {formasPagosSri.map((formaPagoSri) => {
              return (
                <Select.Option
                  key={formaPagoSri.id_forma_pago_sri}
                  value={formaPagoSri.id_forma_pago_sri}
                >
                  {formaPagoSri.descripcion}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {!edit && (
        <Row style={{ marginTop: 10 }} justify={"center"}>
          <Button type="primary" onClick={registrar}>
            Registrar
          </Button>
        </Row>
      )}
    </Content>
  );
};
