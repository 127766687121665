import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "../../../../../hooks/useForm";

import { EMAIL_REGEX } from "../../../../../configs/constants/regex";

import { AuthContext } from "../../../../../context/AuthContext";
import { IoLockClosedOutline } from "react-icons/io5";
import {
  actualizarUsuarioEmpresaRequest,
  crearEmpresaRolUsuarioRequest,
  empresaRolesRequest,
} from "../../../../../services/api/empresas";
import { UserContext } from "../../../../../context/UserContext";
import { ActionContext } from "../../../../../context/ActionContext";

const { Option } = Select;

const nuevoRol = {
  value: 0,
  label: "CREAR UN NUEVO ROL",
};

export const CrearEmpresaRolUsuarioDrawer = ({
  open,
  showDrawer,
  onClose,
  id_usuario,
  reload = () => {},
}) => {
  const { checkAuth } = useContext(AuthContext);
  const {
    checkData,
    empresa,
    usuario: usuarioContext,
  } = useContext(UserContext);

  const { reloadUsuarios } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    id_empresa_rol: "",
    nuevo_rol: "",
  });
  const [rolesEmpresa, setRolesEmpresa] = useState([{ ...nuevoRol }]);

  useEffect(() => {
    empresaRolesRequest(empresa.id_empresa).then((res) => {
      const roles = res.map((rol) => ({
        value: rol.id_empresa_rol,
        label: rol.nombre_empresa_rol,
      }));
      setRolesEmpresa([...roles, nuevoRol]);
    });
  }, [empresa]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const { id_empresa_rol, nuevo_rol } = form;

    // Verificar que los campos obligatorios estén completos
    if (id_empresa_rol === nuevoRol.value ? !nuevo_rol : !id_empresa_rol) {
      return false;
    }

    return true;
  };

  const create = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) {
      setError({
        show: true,
        message:
          "Por favor, revise los campos incorrectos o campos obligatorios (*) que estan vacios.",
      });
      return;
    }

    const dataRequest = {
      id_empresa: empresa.id_empresa,
      id_usuario: id_usuario,
      id_empresa_rol: form.id_empresa_rol == 0 ? null : form.id_empresa_rol,
      nuevo_rol: form.nuevo_rol,
    };

    setIsLoading(true);
    crearEmpresaRolUsuarioRequest(dataRequest)
      .then((res) => {
        if (id_usuario == usuarioContext.id_usuario) {
          checkData();
        }
        onResetForm();
        onClose();
        reload();
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title="Nuevo rol"
      width={450}
      size="default"
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={create} disabled={isLoading} type="primary">
            Guardar cambios
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Col span={24}>
            <Form.Item label="Rol dentro de la empresa" required>
              <Select
                value={form.id_empresa_rol}
                onChange={(e) => onChangeForm("id_empresa_rol", e)}
                options={rolesEmpresa}
              />
            </Form.Item>
          </Col>
          {form.id_empresa_rol === 0 && (
            <Col span={24}>
              <Form.Item label="Nombre del nuevo rol" required>
                <Input
                  placeholder="Nombre del rol"
                  value={form.nuevo_rol}
                  onChange={(e) => onChangeForm("nuevo_rol", e.target.value)}
                />
              </Form.Item>
            </Col>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
};
