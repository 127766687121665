import API from "../../configs/api/api.config";

const URL_BANCOS_CLIENTES = '/bancos-clientes';

export const bancosClientesIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_BANCOS_CLIENTES + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const bancoClienteIdRequest = async (id_banco_cliente) => {
    const request = await API.get(URL_BANCOS_CLIENTES + '/' + id_banco_cliente);
    const response = request.data;
    return response;
}

export const crearBancoClienteRequest = async (data) => {
    const request = await API.post(URL_BANCOS_CLIENTES, data);
    const response = request.data;
    return response;
}

export const actualizarBancoClienteRequest = async (id_banco_cliente, data) => {
    const request = await API.patch(URL_BANCOS_CLIENTES + '/' + id_banco_cliente, data);
    const response = request.data;
    return response;
}

export const eliminarBancoClienteRequest = async (id_banco_cliente) => {
    const request = await API.delete(URL_BANCOS_CLIENTES + '/' + id_banco_cliente);
    const response = request.data;
    return response;
}