import { Breadcrumb, Button, Row, Spin, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

import { VscGraph } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../context/ActionContext";
import { UserContext } from "../../../../context/UserContext";

import { codigosRetencionesIdEmpresaRequest } from "../../../../services/api/codigos-retenciones";
import { CrearCodigoRetencion, CrearCuentaContable } from "./components/CrearCodigoRetencion";
import { TablaCodigosRetenciones } from "./components/TablaCodigosRetenciones";

export const CodigosRetencionesPage = () => {
  const { isCodigosRetencionesChange } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [codigosRetenciones, setCodigosRetenciones] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    codigosRetencionesIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setCodigosRetenciones(res);
      })
      .finally(() => setIsLoading(false));
  }, [isCodigosRetencionesChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <VscGraph style={{ marginRight: 5 }} />
                      <span>Contabilidad</span>
                    </Row>
                  ),
                },
                {
                  title: "Códigos de Retenciones",
                },
              ]}
            />
          </Row>
          <Row align={"middle"}>
            <Button
              disabled={!checkPermisos("MCODIGOS-RETENCIONES").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row>
        </Row>
        {/* Tabla */}
        <TablaCodigosRetenciones data={codigosRetenciones} />
        {/* Crear Drawer */}
        <CrearCodigoRetencion
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
        />
      </Content>
    </Spin>
  );
};
