import { Col, Input, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";

const { TextArea } = Input;

export const TabFichaTecnica = memo(({ form, onChangeForm }) => {
  return (
    <Content>
      <Row gutter={5}>
        <Col span={24}>
          <Row gutter={5}>
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col span={4}>
                  <Typography>Ficha Técnica: </Typography>
                </Col>
                <Col span={20}>
                  <TextArea
                    size="small"
                    style={{ width: "100%" }}
                    rows={5}
                    value={form.ficha_tecnica}
                    onChange={(e) =>
                      onChangeForm("ficha_tecnica", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify={"space-between"} style={{ marginTop: 10 }}>
                <Col span={4}>
                  <Typography>Observaciones: </Typography>
                </Col>
                <Col span={20}>
                  <TextArea
                    size="small"
                    style={{ width: "100%" }}
                    rows={5}
                    value={form.observaciones}
                    onChange={(e) =>
                      onChangeForm("observaciones", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
});
