import { DeleteFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { EMAIL_REGEX } from "../../../../../configs/constants/regex";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import { useForm } from "../../../../../hooks/useForm";
import {
  crearEmpresaRequest,
  subirImagenEmpresaRequest,
} from "../../../../../services/api/empresas";
import {
  agenteRetecionRequest,
  cantonesRequest,
  parroquiasRequest,
  provinciasRequest,
  tipoRegimenRequest,
} from "../../../../../services/api/opciones";

const { Option } = Select;

const CrearEmpresaDrawer = ({ open, showDrawer, onClose }) => {
  const { checkData } = useContext(UserContext);
  const { reloadEmpresas } = useContext(ActionContext);

  const { form, onChangeForm, onResetForm, setForm } = useForm({
    logo_empresa: "",
    identificacion_empresa: "",
    razon_social_empresa: "",
    nombre_comercial_empresa: "",
    direccion_empresa: "",
    email_empresa: "",
    representante_legal: null,
    identificacion_representante_legal: null,
    nombre_contador: null,
    identificacion_contador: null,
    telefono_1: null,
    telefono_2: null,
    telefono_3: null,
    realiza_ats: false,
    lleva_contabilidad: null,
    id_agente_retencion: null,
    id_tipo_regimen: null,
    id_provincia: null,
    id_canton: null,
    id_parroquia: null,
  });

  const [error, setError] = useState({
    show: false,
    message: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [tiposRegimen, setTiposRegimen] = useState([]);
  const [agentesRetencion, setAgentesRetencion] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const [parroquias, setParroquias] = useState([]);

  //TIPOS REGIMEN
  useEffect(() => {
    tipoRegimenRequest().then((res) =>
      setTiposRegimen(
        res.map((r) => ({
          value: r.id_tipo_regimen,
          label: r.descripcion_regimen,
        }))
      )
    );
  }, []);

  //AGENTES RETENCION
  useEffect(() => {
    agenteRetecionRequest().then((res) =>
      setAgentesRetencion(
        res.map((r) => ({
          value: r.id_agente_retencion,
          label: r.descripcion_agente_retencion,
        }))
      )
    );
  }, []);

  //PROVINCIAS
  useEffect(() => {
    provinciasRequest().then((res) =>
      setProvincias(
        res.map((r) => ({
          value: r.id_provincia,
          label: r.nombre_provincia,
        }))
      )
    );
  }, []);

  //CANTONES
  useEffect(() => {
    cantonesRequest({ id_provincia: form.id_provincia }).then((res) =>
      setCantones(
        res.map((r) => ({
          value: r.id_canton,
          label: r.nombre_canton,
        }))
      )
    );
  }, [form.id_provincia]);

  //PARROQUIAS
  useEffect(() => {
    parroquiasRequest({ id_canton: form.id_canton }).then((res) =>
      setParroquias(
        res.map((r) => ({
          value: r.id_parroquia,
          label: r.nombre_parroquia,
        }))
      )
    );
  }, [form.id_canton]);

  useEffect(() => {
    setForm({
      ...form,
      id_canton: null,
      id_parroquia: null,
    });
  }, [form.id_provincia]);

  useEffect(() => {
    setForm({
      ...form,
      id_parroquia: null,
    });
  }, [form.id_canton]);

  const cancelar = () => {
    const confirm = window.confirm(
      "¿Esta seguro de cancelar no se guardaran los datos?"
    );
    if (!confirm) return;
    onResetForm();
    onClose();
  };

  const validarCampos = () => {
    const {
      identificacion_empresa,
      razon_social_empresa,
      direccion_empresa,
      email_empresa,
      lleva_contabilidad,
      id_tipo_regimen,
      id_agente_retencion,
    } = form;

    // Verificar que los campos obligatorios estén completos
    if (
      !identificacion_empresa ||
      !razon_social_empresa ||
      !direccion_empresa ||
      !email_empresa ||
      lleva_contabilidad === null ||
      !id_tipo_regimen ||
      !id_agente_retencion
    ) {
      return false;
    }

    if (identificacion_empresa.length !== 13) {
      return false;
    }

    const emailRegex = EMAIL_REGEX;
    if (!emailRegex.test(email_empresa)) {
      return false;
    }
    return true;
  };

  const crear = () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) {
      setError({
        show: true,
        message:
          "Por favor, revise los campos incorrectos o campos obligatorios (*) que estan vacios.",
      });
      return;
    }

    const dataRequest = {
      logo_empresa: form.logo_empresa,
      identificacion_empresa: form.identificacion_empresa,
      razon_social_empresa: form.razon_social_empresa,
      nombre_comercial_empresa: form.nombre_comercial_empresa,
      direccion_empresa: form.direccion_empresa,
      representante_legal: form.representante_legal,
      identificacion_representante_legal:
        form.identificacion_representante_legal,
      nombre_contador: form.nombre_contador,
      identificacion_contador: form.identificacion_contador,
      email_empresa: form.email_empresa,
      telefono_1: form.telefono_1,
      telefono_2: form.telefono_2,
      telefono_3: form.telefono_3,
      realiza_ats: form.realiza_ats,
      lleva_contabilidad: form.lleva_contabilidad,
      id_agente_retencion: form.id_agente_retencion,
      id_tipo_regimen: form.id_tipo_regimen,
      id_provincia: form.id_provincia,
      id_canton: form.id_canton,
      id_parroquia: form.id_parroquia,
    };

    setIsLoading(true);
    crearEmpresaRequest(dataRequest)
      .then((res) => {
        reloadEmpresas();
        onClose();
        onResetForm();
        checkData();
      })
      .catch((err) => {
        const data = err.response?.data;
        if (data.statusCode == 400 && data.message?.includes("existe")) {
          setError({ show: true, message: data.message });
          return;
        }
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente !",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const subirImagen = (e) => {
    const formData = new FormData();
    formData.append("file", e);
    setIsLoadingImage(true);
    subirImagenEmpresaRequest(formData)
      .then((res) => {
        onChangeForm("logo_empresa", res);
      })
      .finally(() => setIsLoadingImage(false));
  };

  const deleteImage = () => {
    onChangeForm("logo_empresa", "");
  };

  return (
    <Drawer
      title="Crear empresa"
      width={800}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 20, paddingTop: 10 }}
      extra={
        <Space>
          <Button onClick={cancelar} disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={crear} disabled={isLoading} type="primary">
            Crear
          </Button>
        </Space>
      }
    >
      {error.show && (
        <Alert
          description={error.message}
          type="error"
          closable
          onClose={() => setError({ show: false, message: "" })}
        />
      )}
      <Spin spinning={isLoading}>
        <Form layout="vertical" disabled={isLoading}>
          <Row gutter={16} justify={"center"}>
            <Spin spinning={isLoadingImage}>
              <Row justify={"center"}>
                <div
                  style={{
                    height: 120,
                    width: 120,
                    position: "relative",
                  }}
                >
                  {form.logo_empresa && form.logo_empresa != "" ? (
                    <>
                      <Image src={form.logo_empresa} height={120} />
                      <Button
                        type={"primary"}
                        icon={<DeleteFilled />}
                        danger
                        style={{ position: "absolute" }}
                        onClick={deleteImage}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        accept="image/*"
                        multiple={false}
                        type={"file"}
                        onChange={(e) => {
                          subirImagen(e.target.files[0]);
                        }}
                        style={{
                          position: "absolute",
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      />
                      <div
                        style={{
                          width: "90%",
                          height: "90%",
                          borderStyle: "dashed",
                          borderWidth: 2,
                          borderColor: PRIMARY_COLOR,
                          borderRadius: 10,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          Subir Logo
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </Spin>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Identificación empresa" required>
                <Input
                  placeholder="RUC"
                  value={form.identificacion_empresa}
                  onChange={(e) =>
                    onChangeForm("identificacion_empresa", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Razon social empresa" required>
                <Input
                  placeholder="Razon social"
                  value={form.razon_social_empresa}
                  onChange={(e) =>
                    onChangeForm("razon_social_empresa", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Nombre comercial Empresa">
                <Input
                  placeholder="Nombre comercial"
                  value={form.nombre_comercial_empresa}
                  onChange={(e) =>
                    onChangeForm("nombre_comercial_empresa", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Dirección empresa" required>
                <Input
                  placeholder="Dirección"
                  value={form.direccion_empresa}
                  onChange={(e) =>
                    onChangeForm("direccion_empresa", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align={"bottom"}>
            <Col span={12}>
              <Form.Item label="Email empresa" required>
                <Input
                  placeholder="Email"
                  value={form.email_empresa}
                  onChange={(e) =>
                    onChangeForm("email_empresa", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={form.realiza_ats}
                  onChange={(e) =>
                    onChangeForm("realiza_ats", e.target.checked)
                  }
                >
                  Realiza ATS
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Obligado a llevar contabilidad" required>
                <Select
                  value={form.lleva_contabilidad}
                  onChange={(e) => onChangeForm("lleva_contabilidad", e)}
                  options={[
                    {
                      value: false,
                      label: "NO",
                    },
                    {
                      value: true,
                      label: "SI",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Regimen" required>
                <Select
                  value={form.id_tipo_regimen}
                  onChange={(e) => onChangeForm("id_tipo_regimen", e)}
                  options={tiposRegimen}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Agente retencion" required>
                <Select
                  value={form.id_agente_retencion}
                  onChange={(e) => onChangeForm("id_agente_retencion", e)}
                  options={agentesRetencion}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Identificación representante legal">
                <Input
                  placeholder="Identificación"
                  value={form.identificacion_representante_legal}
                  onChange={(e) =>
                    onChangeForm(
                      "identificacion_representante_legal",
                      e.target.value
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nombre representante legal">
                <Input
                  placeholder="Nombre"
                  value={form.representante_legal}
                  onChange={(e) =>
                    onChangeForm("representante_legal", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Identificación del contador">
                <Input
                  placeholder="Identificación"
                  value={form.identificacion_contador}
                  onChange={(e) =>
                    onChangeForm("identificacion_contador", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nombre contador">
                <Input
                  placeholder="Nombre"
                  value={form.nombre_contador}
                  onChange={(e) =>
                    onChangeForm("nombre_contador", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Teléfono 1">
                <Input
                  placeholder="Teléfono 1"
                  value={form.telefono_1}
                  onChange={(e) => onChangeForm("telefono_1", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Teléfono 2">
                <Input
                  placeholder="Teléfono 2"
                  value={form.telefono_2}
                  onChange={(e) => onChangeForm("telefono_2", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Teléfono 3">
                <Input
                  placeholder="Teléfono 3"
                  value={form.telefono_3}
                  onChange={(e) => onChangeForm("telefono_3", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Provincia">
                <Select
                  value={form.id_provincia}
                  onChange={(e) => onChangeForm("id_provincia", e)}
                  options={provincias}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cantón">
                <Select
                  disabled={!form.id_provincia}
                  value={form.id_canton}
                  onChange={(e) => onChangeForm("id_canton", e)}
                  options={cantones}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Parroquia">
                <Select
                  disabled={!form.id_canton}
                  value={form.id_parroquia}
                  onChange={(e) => onChangeForm("id_parroquia", e)}
                  options={parroquias}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default CrearEmpresaDrawer;
