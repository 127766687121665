import { Alert, Button, Col, Modal, Radio, Row, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { PRIMARY_COLOR } from "../../../../../configs/constants/colors";
import { IoCloseOutline } from "react-icons/io5";
import { UserContext } from "../../../../../context/UserContext";
import { subirComprasXMLRequest } from "../../../../../services/api/compras";
import { ActionContext } from "../../../../../context/ActionContext";

export const ModalSubirComprasElectronicas = ({
  isOpen = false,
  close = () => {},
}) => {
  const { empresa } = useContext(UserContext);
  const { reloadCompras } = useContext(ActionContext);

  const [fileType, setFileType] = useState("XML");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const [xml, setXml] = useState([]);
  const [txt, setTxt] = useState([]);

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files ?? [];
    let files = [];
    for (const file of filesInput) {
      files.push({ file: file });
    }
    if (fileType === "XML") {
      setXml((prev) => [...prev, ...files]);
    }
    if (fileType === "TXT") {
      setTxt((prev) => [...prev, ...files]);
    }
  };

  useEffect(() => {
    setTxt([]);
    setXml([]);
  }, [fileType]);

  const removeFile = (index) => {
    if ("XML") {
      const temp = xml.filter((_, i) => i !== index);
      setXml(temp);
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const subir = () => {
    scrollUp();
    setError({
      show: false,
      message: "",
    });
    const formData = new FormData();
    formData.append("id_empresa", empresa.id_empresa);
    xml.forEach((file) => {
      formData.append("files", file.file);
    });
    setIsLoading(true);
    subirComprasXMLRequest(formData)
      .then((res) => {
        const data = xml.map((file, index) => {
          const resFile = res[index];

          let error = null;

          if (resFile.repetido) {
            error = "Y se encuentra registrado";
          }

          if (!resFile.formato_valido) {
            error = "El formato es invalido";
          }

          if (!resFile.ruc_valido) {
            error = `El RUC (${resFile?.compra?.identificador_comprador}) de comprador no corresponde a su RUC (${empresa.identificacion_empresa})`;
          }

          return {
            ...file,
            error: error,
            enviado: true,
          };
        });
        setXml(data);
      })
      .catch((err) => {
        setError({
          show: true,
          message: "Ocurrio un error intentelo nuevamente",
        });
      })
      .finally(() => {
        setIsLoading(false);
        reloadCompras();
      });
  };

  return (
    <Modal
      title={"Seleccione sus archivos"}
      open={isOpen}
      close={close}
      closable={true}
      onCancel={() => {
        setXml([]);
        setTxt([]);
        close();
      }}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      style={{
        maxHeight: "100hv",
        overflow: "auto",
      }}
    >
      <Content>
        <Row align={"middle"} justify={"center"}>
          <Radio.Group
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button type="primary" value="XML">
              XML
            </Radio.Button>
            <Radio.Button
              type="primary"
              value="TXT"
              onClick={() => alert("Trabajando para añadir esta funcionalidad")}
            >
              TXT
            </Radio.Button>
          </Radio.Group>
        </Row>
        <Spin spinning={isLoading}>
          <Row align={"middle"} justify={"center"} style={{ marginBlock: 20 }}>
            <div
              style={{
                height: 150,
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: PRIMARY_COLOR,
                borderStyle: "dashed",
                borderWidth: 2,
                borderRadius: 10,
              }}
            >
              <input
                type="file"
                onChange={onChangeInputFile}
                accept=".xml"
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                multiple
                value={""}
              />
              <Typography
                style={{ position: "absolute", color: PRIMARY_COLOR }}
              >
                Seleccione o arrastre aqui los documentos.
              </Typography>
            </div>
          </Row>
        </Spin>
        <Row>
          {error.show && (
            <Alert
              description={error.message}
              type="error"
              closable
              onClose={() => setError({ show: false, message: "" })}
            />
          )}
        </Row>
        {xml.map((file, index) => {
          return (
            <Row
              align={"middle"}
              style={
                file.enviado
                  ? {
                      marginTop: 3,
                      backgroundColor: file.error
                        ? "rgba(255, 0, 0, 0.3)"
                        : "rgba(0, 255, 0, 0.3)",
                    }
                  : {
                      marginTop: 3,
                    }
              }
            >
              <Col span={22}>
                {file.error && <Typography>{file.error}</Typography>}
                <Typography.Text ellipsis>{file?.file?.name}</Typography.Text>
              </Col>
              <Col>
                <Button
                  type="link"
                  onClick={() => removeFile(index)}
                  icon={<IoCloseOutline />}
                />
              </Col>
            </Row>
          );
        })}
        {txt}
        <Row align={"middle"} justify={"center"}>
          <Button type="primary" onClick={subir}>
            Subir
          </Button>
        </Row>
      </Content>
    </Modal>
  );
};
