import API from "../../configs/api/api.config";

const URL_LINEAS_PRODUCTOS = '/lineas-productos';

export const lineasProductosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_LINEAS_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const lineaProductoIdRequest = async (id_linea_producto) => {
    const request = await API.get(URL_LINEAS_PRODUCTOS + '/' + id_linea_producto);
    const response = request.data;
    return response;
}

export const crearLineaProductoRequest = async (data) => {
    const request = await API.post(URL_LINEAS_PRODUCTOS, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const actualizarLineaProductoRequest = async (id_linea_producto, data) => {
    const request = await API.patch(URL_LINEAS_PRODUCTOS + '/' + id_linea_producto, data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const eliminarLineaProductoRequest = async (id_linea_producto) => {
    const request = await API.delete(URL_LINEAS_PRODUCTOS + '/' + id_linea_producto);
    const response = request.data;
    return response;
}