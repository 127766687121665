import API from "../../configs/api/api.config";

const URL_GASTOS = '/gastos';

export const gastosIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_GASTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const gastoIdRequest = async (id_gasto) => {
    const request = await API.get(URL_GASTOS + '/' + id_gasto);
    const response = request.data;
    return response;
}

export const buscarGastosRequest = async (data) => {
    const request = await API.post(URL_GASTOS + '/buscar', data);
    const response = request.data;
    return response;
}

export const crearGastoRequest = async (data) => {
    const request = await API.post(URL_GASTOS, data);
    const response = request.data;
    return response;
}

export const actualizarGastoRequest = async (id_gasto, data) => {
    const request = await API.patch(URL_GASTOS + '/' + id_gasto, data);
    const response = request.data;
    return response;
}

export const eliminarGastoRequest = async (id_gasto) => {
    const request = await API.delete(URL_GASTOS + '/' + id_gasto);
    const response = request.data;
    return response;
}