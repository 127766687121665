import API from "../../configs/api/api.config";

const URL_COMPRAS = '/compras';

export const comprasIdEmpresaRequest = async (id_empresa, paginacion) => {
    const request = await API.get(`${URL_COMPRAS}/empresa/${id_empresa}`, { params: paginacion });
    const response = request.data;
    return response;
}

export const comprasIdEmpresaDatesRequest = async (id_empresa, dates, pagination) => {
    const request = await API.get(`${URL_COMPRAS}/empresa/${id_empresa}/start/${dates.start}/end/${dates.end}`, { params: pagination });
    const response = request.data;
    return response;
}

export const comprasIdEmpresaSearchRequest = async (id_empresa, search, pagination) => {
    const request = await API.get(`${URL_COMPRAS}/empresa/${id_empresa}/search/${search}`, { params: pagination });
    const response = request.data;
    return response;
}

export const comprasElectronicasIdEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_COMPRAS + '/compras-electronicas', { id_empresa });
    const response = request.data;
    return response;
}

export const compraIdRequest = async (id_compra) => {
    const request = await API.get(URL_COMPRAS + '/' + id_compra);
    const response = request.data;
    return response;
}

export const crearCompraRequest = async (data) => {
    const request = await API.post(URL_COMPRAS, data);
    const response = request.data;
    return response;
}

export const productosGastosRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/productos-gastos', data);
    const response = request.data;
    return response;
}

export const buscarProductosGastosRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/productos-gastos/buscar', data);
    const response = request.data;
    return response;
}

export const calcularTotalesRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/calcular-totales', data);
    const response = request.data;
    return response;
}

export const actualizarCompraRequest = async (id_compra, data) => {
    const request = await API.patch(URL_COMPRAS + '/' + id_compra, data);
    const response = request.data;
    return response;
}
export const eliminarCompraRequest = async (id_compra) => {
    const request = await API.delete(URL_COMPRAS + '/' + id_compra);
    const response = request.data;
    return response;
}


export const subirComprasXMLRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + "/subir-compras-electronicas-xml", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const response = request.data;
    return response;
}

export const registrarProveedorComprasRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/registrar-proveedor', data);
    const response = request.data;
    return response;
}

export const registrarProductoComprasRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/registrar-producto', data);
    const response = request.data;
    return response;
}

export const registrarGastoComprasRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/registrar-gasto', data);
    const response = request.data;
    return response;
}

export const registrarCompraElectronicaRequest = async (data) => {
    const request = await API.post(URL_COMPRAS + '/registrar-compra-electronica', data);
    const response = request.data;
    return response;
}

export const autorizarCompraRequest = async (id_compra) => {
    const request = await API.post(URL_COMPRAS + '/autorizar' + '/' + id_compra);
    const response = request.data;
    return response;
}

export const anularCompraRequest = async (id_compra) => {
    const request = await API.post(URL_COMPRAS + '/anular' + '/' + id_compra);
    const response = request.data;
    return response;
}

export const pdfCompraRequest = async (id_compra) => {
    const request = await API.post(URL_COMPRAS + '/pdf' + '/' + id_compra, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const xmlCompraRequest = async (id_compra) => {
    const request = await API.post(URL_COMPRAS + '/xml' + '/' + id_compra, {}, {
        responseType: 'blob'
    });
    const response = request.data;
    return response;
}

export const sendEmailCompraRequest = async (id_compra) => {
    const request = await API.post(URL_COMPRAS + '/email' + '/' + id_compra);
    const response = request.data;
    return response;
}