import API from "../../configs/api/api.config";

const URL_PARAMETROS_PRODUCTOS = '/parametros-productos';

export const parametrosProductosEmpresaRequest = async (id_empresa) => {
    const request = await API.post(URL_PARAMETROS_PRODUCTOS + '/empresa', { id_empresa });
    const response = request.data;
    return response;
}

export const crearParametrosProductosRequest = async (data) => {
    const request = await API.post(URL_PARAMETROS_PRODUCTOS, data);
    const response = request.data;
    return response;
}

export const actualizarParametrosProductosRequest = async (id_parametros_productos, data) => {
    const request = await API.patch(URL_PARAMETROS_PRODUCTOS + '/' + id_parametros_productos, data);
    const response = request.data;
    return response;
}