import React from "react";
import { Route, Routes } from "react-router-dom";

import { AgenteVentaPage } from "../../pages/app/ventas/agentes-ventas/AgenteVentaPage";
import { AgentesVentasPage } from "../../pages/app/ventas/agentes-ventas/AgentesVentasPage";
import { CrearAgenteVentaPage } from "../../pages/app/ventas/agentes-ventas/CrearAgenteVentaPage";
import { ClientePage } from "../../pages/app/ventas/clientes/ClientePage";
import { ClientesPage } from "../../pages/app/ventas/clientes/ClientesPage";
import { CrearClientePage } from "../../pages/app/ventas/clientes/CrearClientePage";
import { CrearFacturaPage } from "../../pages/app/ventas/facturacion/CrearFacturaPage";
import { DetalleFacturaPage } from "../../pages/app/ventas/facturacion/DetalleFacturaPage";
import { FacturacionPage } from "../../pages/app/ventas/facturacion/FacturacionPage";
import { GruposClientesPage } from "../../pages/app/ventas/grupos-clientes/GruposClientesPage";
import { RutasClientesPage } from "../../pages/app/ventas/rutas-clientes/RutasClientesPage";
import { CrearSecuenciaPage } from "../../pages/app/ventas/secuencias/CrearSecuenciaPage";
import { SecuenciaPage } from "../../pages/app/ventas/secuencias/SecuenciaPage";
import { SecuenciasPage } from "../../pages/app/ventas/secuencias/SecuenciasPage";
import { ZonasClientesPage } from "../../pages/app/ventas/zonas-clientes/ZonasClientesPage";
import { CrearNotaCreditoPage } from "../../pages/app/ventas/facturacion/CrearNotaCreditoPage";
import { DetalleNotaCreditoPage } from "../../pages/app/ventas/facturacion/DetalleNotaCreditoPage";
import { FacturacionTransportePage } from "../../pages/app/ventas/facturacion-transportista/FacturacionTrasportePage";
import { CrearFacturaTransportePage } from "../../pages/app/ventas/facturacion-transportista/CrearFacturaTransportePage";
import { DetalleFacturaTransportePage } from "../../pages/app/ventas/facturacion-transportista/DetalleFacturaTransportePage";
import { SecuenciasTransportePage } from "../../pages/app/ventas/secuencias-transportista/SecuenciasTransportePage";
import { SecuenciaTransportePage } from "../../pages/app/ventas/secuencias-transportista/SecuenciaTransportePage";

export const VentasRoutes = () => {
  return (
    <Routes>
      <Route path="/facturacion" element={<FacturacionPage />} />
      <Route path="/facturacion/crear-factura" element={<CrearFacturaPage />} />
      <Route
        path="/facturacion/facturas/:id"
        element={<DetalleFacturaPage />}
      />
      <Route path="/facturacion-transportista" element={<FacturacionTransportePage />} />
      <Route path="/facturacion-transportista/crear-factura" element={<CrearFacturaTransportePage />} />
      <Route
        path="/facturacion-transportista/facturas/:id"
        element={<DetalleFacturaTransportePage />}
      />
      <Route
        path="/facturacion/crear-nota-credito/:id"
        element={<CrearNotaCreditoPage />}
      />
      <Route
        path="/facturacion/notas-creditos/:id"
        element={<DetalleNotaCreditoPage />}
      />

      <Route path="/clientes" element={<ClientesPage />} />
      <Route path="/clientes/crear-cliente" element={<CrearClientePage />} />
      <Route path="/clientes/:id" element={<ClientePage />} />

      <Route path="/grupos-clientes" element={<GruposClientesPage />} />

      <Route path="/zonas-clientes" element={<ZonasClientesPage />} />

      <Route path="/rutas-clientes" element={<RutasClientesPage />} />

      <Route path="/secuencias" element={<SecuenciasPage />} />
      <Route
        path="/secuencias/crear-secuencia"
        element={<CrearSecuenciaPage />}
      />
      <Route path="/secuencias/:id" element={<SecuenciaPage />} />

      <Route path="/secuencias-transportista" element={<SecuenciasTransportePage />} />
 

      <Route path="/secuencias-transportista/:id" element={<SecuenciaTransportePage />} />

      <Route path="/agentes-ventas" element={<AgentesVentasPage />} />
      <Route
        path="/agentes-ventas/crear-agente-venta"
        element={<CrearAgenteVentaPage />}
      />
      <Route path="/agentes-ventas/:id" element={<AgenteVentaPage />} />
    </Routes>
  );
};
