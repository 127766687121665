import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { IoAdd, IoArrowBack, IoSettingsSharp } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { empresasUsuarioRequest } from "../../../../services/api/empresas";
import { ActionContext } from "../../../../context/ActionContext";
import { TablaPlanCuentas } from "./components/TablaPlanCuentas";
import {
  busquedaCuentasContablesRequest,
  cargarPlanCuentasEmpresaBaseRequest,
  cuentasContablesIdEmpresaRequest,
} from "../../../../services/api/cuentas-contables";
import { CrearCuentaContable } from "./components/CrearCuentaContable";
import { EditarCuentaContable } from "./components/EditarCuentaContable";
import { SearchOutlined } from "@ant-design/icons";
import { VscGraph } from "react-icons/vsc";

export const PlanCuentasPage = () => {
  const { isPlanCuentasChange, reloadPlanCuentas } = useContext(ActionContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [cuentasContables, setCuentasContables] = useState([]);
  const [cuentasContablesSearch, setCuentasContablesSearch] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    cuentasContablesIdEmpresaRequest(empresa.id_empresa)
      .then((res) => {
        setCuentasContables(res);
      })
      .finally(() => setIsLoading(false));
  }, [isPlanCuentasChange]);

  const cargarPlanBase = () => {
    const confirm = window.confirm(
      `¿Esta seguro de cargar EL PLAN DE CUENTAS BASE a la empresa ${empresa.nombre_comercial_empresa}?`
    );
    if (!confirm) return;

    setIsLoading(true);
    cargarPlanCuentasEmpresaBaseRequest({
      id_empresa: empresa.id_empresa,
    })
      .then((res) => {
        reloadPlanCuentas();
      })
      .catch((err) => {
        alert("Ocurrio un error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeTextSearch = (text) => {
    setTextSearch(text);
  };

  useEffect(() => {
    if (textSearch.length > 1) {
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        busqueda: textSearch,
      };
      setIsSearching(true);
      busquedaCuentasContablesRequest(dataRequest)
        .then((res) => setCuentasContablesSearch(res))
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [textSearch, isPlanCuentasChange]);

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <VscGraph style={{ marginRight: 5 }} />
                      <span>Contabilidad</span>
                    </Row>
                  ),
                },
                {
                  title: "Plan de cuentas",
                },
              ]}
            />
          </Row>
          <Row align={"middle"}>
            <Row style={{ marginRight: 10, width: 300 }}>
              <Input
                placeholder="Buscar por código o nombre"
                value={textSearch}
                onChange={(e) => onChangeTextSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </Row>
            <Button
              disabled={
                !checkPermisos("MPLAN-CUENTAS").crear ||
                cuentasContables.length >= 2
              }
              type="primary"
              onClick={cargarPlanBase}
              style={{ marginRight: 15 }}
            >
              Cargar plan de cuentas base
            </Button>
            <Button
              disabled={!checkPermisos("MPLAN-CUENTAS").crear}
              type="primary"
              onClick={showDrawer}
            >
              Crear
            </Button>
          </Row>
        </Row>
        {/* Tabla */}
        <Row justify={"center"}>
          {isSearching && (
            <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
              Buscando ({textSearch}) ...{" "}
            </Typography.Title>
          )}
        </Row>
        {textSearch.length > 1 ? (
          <TablaPlanCuentas data={cuentasContablesSearch} />
        ) : (
          <TablaPlanCuentas data={cuentasContables} />
        )}
        {/* Crear Drawer */}
        <CrearCuentaContable
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
        />
      </Content>
    </Spin>
  );
};
