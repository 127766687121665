import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack, IoCart } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { useForm } from "../../../../hooks/useForm";
import { crearAgenteVentaRequest } from "../../../../services/api/agentes-ventas";
import { almacenesIdEmpresaRequest } from "../../../../services/api/almacenes";
import { cajasIdEmpresaRequest } from "../../../../services/api/cajas";
import {
  tarifasRequest,
  tiposFormaPagoRequest,
} from "../../../../services/api/opciones";
import {
  secuenciasIdEmpresaRequest
} from "../../../../services/api/secuencias";
import { TabAlmacenes } from "./components/TabAlmacenes";
import { TabCajas } from "./components/TabCajas";
import { TabFormaPago } from "./components/TabFormaPago";
import { TabGeneral } from "./components/TabGeneral";
import { TabSecuencias } from "./components/TabSecuencias";
import { TabTarifas } from "./components/TabTarifas";
import { ButtonAsync } from "../../../../global/components/ButtonAsync";

export const CrearAgenteVentaPage = () => {
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const { form, setForm, onChangeForm, onResetForm, initValues } = useForm({
    nombres: "",
    clave: "",
    verificar_clave: "",
    activo: true,
    meta_venta: 0,
    facturador: false,
    vendedor: false,
    cobrador: false,
    despachador: false,
    permitir_modificar_precios: false,
    permitir_seleccionar_precios: false,
    permitir_modificar_descuentos: false,
    administrador: false,
    permitir_modificar_secuencias_facturas: false,
    permitir_seleccionar_secuencia: false,
    permitir_seleccionar_almacenes: false,
    permitir_modificar_iva: false,
    permitir_seleccionar_vendedores: false,
    permitir_realizar_pedido: false,
    permitir_realizar_proforma: false,
    descuento_permitido: 0,
    email: "",
    telefono: "",
    almacenes: [],
    cajas: [],
    formas_pago: [],
    secuencias: [],
    tarifas: [],
    id_tarifa: null,
  });

  const [tarifas, setTarifas] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      tarifasRequest(),
      almacenesIdEmpresaRequest(empresa.id_empresa),
      cajasIdEmpresaRequest(empresa.id_empresa),
      tiposFormaPagoRequest(),
      secuenciasIdEmpresaRequest(empresa.id_empresa),
      tarifasRequest(),
    ])
      .then(
        ([
          tarifasRes,
          almacenesRes,
          cajasRes,
          tiposFormaPagoRes,
          secuenciasRes,
        ]) => {
          setTarifas(tarifasRes);

          if (tarifasRes.length > 0) {
            const findTarifa = tarifasRes.find((e, i) => i === 0);
            setForm((prev) => ({
              ...prev,
              id_tarifa: findTarifa.id_tarifa,
            }));
          }

          if (almacenesRes.length > 0) {
            setForm((prev) => ({
              ...prev,
              almacenes: almacenesRes.map((a, index) => ({
                id_almacen: a.id_almacen,
                nombre_almacen: a.nombre_almacen,
                seleccionado: a.predeterminado,
                principal: a.predeterminado,
              })),
            }));
          }

          if (cajasRes.length > 0) {
            setForm((prev) => ({
              ...prev,
              cajas: cajasRes.map((a, index) => ({
                id_caja: a.id_caja,
                descripcion: a.descripcion,
                seleccionado: index === 0,
                principal: index === 0,
              })),
            }));
          }

          if (tiposFormaPagoRes.length > 0) {
            setForm((prev) => ({
              ...prev,
              formas_pago: tiposFormaPagoRes
                .filter((a, i) => i <= 4)
                .map((a, index) => ({
                  id_tipo_forma_pago: a.id_tipo_forma_pago,
                  descripcion: a.descripcion,
                  seleccionado: index === 0,
                  principal: index === 0,
                })),
            }));
          }

          if (secuenciasRes.length > 0) {
            const codigos_documentos = ["01", "02", "03", "04", "05"];

            setForm((prev) => ({
              ...prev,
              secuencias: secuenciasRes
                .filter((a, i) =>
                  codigos_documentos.some((e) => e === a.tipo_documento?.codigo)
                )
                .map((a, index) => ({
                  id_secuencia: a.id_secuencia,
                  descripcion: a.descripcion,
                  seleccionado: index === 0,
                  principal: index === 0,
                })),
            }));
          }

          if (tarifasRes.length > 0) {
            setForm((prev) => ({
              ...prev,
              tarifas: tarifasRes.map((a, index) => ({
                id_tarifa: a.id_tarifa,
                descripcion: a.descripcion,
                seleccionado: index === 0,
                principal: index === 0,
              })),
            }));
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [empresa]);

  const onChangeAlmacenes = (checkbox, value, id) => {
    const almacenesTemp = form.almacenes.map((a) => {
      if (checkbox === "principal") {
        if (!value && id === a.id_almacen) {
          return a;
        }

        if (value) {
          a.principal = false;
        }

        if (id === a.id_almacen) {
          a.principal = value;
        }

        if (id === a.id_almacen && value) {
          a.seleccionado = true;
        }

        return a;
      }
      if (checkbox === "seleccionado") {
        if (id === a.id_almacen && !a.principal) {
          a.seleccionado = value;
        }

        return a;
      }

      return a;
    });

    onChangeForm("almacenes", almacenesTemp);
  };

  const onChangeCajas = (checkbox, value, id) => {
    const cajasTemp = form.cajas.map((a) => {
      if (checkbox === "principal") {
        if (!value && id === a.id_caja) {
          return a;
        }

        if (value) {
          a.principal = false;
        }

        if (id === a.id_caja) {
          a.principal = value;
        }

        if (id === a.id_caja && value) {
          a.seleccionado = true;
        }

        return a;
      }
      if (checkbox === "seleccionado") {
        if (id === a.id_caja && !a.principal) {
          a.seleccionado = value;
        }

        return a;
      }

      return a;
    });

    onChangeForm("cajas", cajasTemp);
  };

  const onChangeFormaPago = (checkbox, value, id) => {
    const formasPagoTemp = form.formas_pago.map((a) => {
      if (checkbox === "principal") {
        if (!value && id === a.id_tipo_forma_pago) {
          return a;
        }

        if (value) {
          a.principal = false;
        }

        if (id === a.id_tipo_forma_pago) {
          a.principal = value;
        }

        if (id === a.id_tipo_forma_pago && value) {
          a.seleccionado = true;
        }

        return a;
      }
      if (checkbox === "seleccionado") {
        if (id === a.id_tipo_forma_pago && !a.principal) {
          a.seleccionado = value;
        }

        return a;
      }

      return a;
    });

    onChangeForm("formas_pago", formasPagoTemp);
  };

  const onChangeSecuencias = (checkbox, value, id) => {
    const secuenciasTemp = form.secuencias.map((a) => {
      if (checkbox === "principal") {
        if (!value && id === a.id_secuencia) {
          return a;
        }

        if (value) {
          a.principal = false;
        }

        if (id === a.id_secuencia) {
          a.principal = value;
        }

        if (id === a.id_secuencia && value) {
          a.seleccionado = true;
        }

        return a;
      }
      if (checkbox === "seleccionado") {
        if (id === a.id_secuencia && !a.principal) {
          a.seleccionado = value;
        }

        return a;
      }

      return a;
    });

    onChangeForm("secuencias", secuenciasTemp);
  };

  const onChangeTarifas = (checkbox, value, id) => {
    const tarifasTemp = form.tarifas.map((a) => {
      if (checkbox === "principal") {
        if (!value && id === a.id_tarifa) {
          return a;
        }

        if (value) {
          a.principal = false;
        }

        if (id === a.id_tarifa) {
          a.principal = value;
        }

        if (id === a.id_tarifa && value) {
          a.seleccionado = true;
        }

        return a;
      }

      if (checkbox === "seleccionado") {
        if (id === a.id_tarifa && !a.principal) {
          a.seleccionado = value;
        }

        return a;
      }

      return a;
    });

    onChangeForm("tarifas", tarifasTemp);
  };

  const items = [
    {
      key: 1,
      label: `General`,
      children: (
        <TabGeneral form={form} onChangeForm={onChangeForm} tarifas={tarifas} />
      ),
    },
    {
      key: 2,
      label: `Almacenes`,
      children: (
        <TabAlmacenes
          form={form}
          onChangeForm={onChangeForm}
          almacenes={form.almacenes}
          onChange={onChangeAlmacenes}
        />
      ),
    },
    {
      key: 3,
      label: `Cajas`,
      children: (
        <TabCajas
          form={form}
          onChangeForm={onChangeForm}
          cajas={form.cajas}
          onChange={onChangeCajas}
        />
      ),
    },
    {
      key: 4,
      label: `Forma de Pago`,
      children: (
        <TabFormaPago
          form={form}
          onChangeForm={onChangeForm}
          formaPago={form.formas_pago}
          onChange={onChangeFormaPago}
        />
      ),
    },
    {
      key: 5,
      label: `Secuencias`,
      children: (
        <TabSecuencias
          form={form}
          onChangeForm={onChangeForm}
          secuencias={form.secuencias}
          onChange={onChangeSecuencias}
        />
      ),
    },
    {
      key: 6,
      label: `Tarifas`,
      children: (
        <TabTarifas
          form={form}
          onChangeForm={onChangeForm}
          tarifas={form.tarifas}
          onChange={onChangeTarifas}
        />
      ),
    },
  ];

  const validarCampos = () => {
    const {
      nombres,
      facturador,
      vendedor,
      cobrador,
      despachador,
      clave,
      verificar_clave,
    } = form;

    if (nombres.trim().length == 0) {
      setError({
        show: true,
        message: "Ingrese los nombres ",
      });
      return false;
    }

    if (!facturador && !vendedor && !cobrador && !despachador) {
      setError({
        show: true,
        message: "Seleccione el Tipo Agente de Venta",
      });
      return false;
    }

    if ((facturador || cobrador || despachador) && !clave) {
      setError({
        show: true,
        message: "Ingrese una contraseña",
      });
      return false;
    }

    if (
      (facturador || cobrador || despachador) &&
      !(clave === verificar_clave)
    ) {
      setError({
        show: true,
        message: "Las contraseñas no coinciden",
      });
      return false;
    }

    return true;
  };

  const crear = async () => {
    setError({ show: false, message: "" });
    const validacion = validarCampos();

    if (!validacion) return;

    const dataRequest = {
      nombres: form.nombres,
      clave: form.clave,
      activo: form.activo,
      meta_venta: !form.meta_venta ? 0 : Number(form.meta_venta),
      facturador: form.facturador,
      vendedor: form.vendedor,
      cobrador: form.cobrador,
      despachador: form.despachador,
      permitir_modificar_precios: form.permitir_modificar_precios,
      permitir_seleccionar_precios: form.permitir_seleccionar_precios,
      permitir_modificar_descuentos: form.permitir_modificar_descuentos,
      administrador: form.administrador,
      permitir_modificar_secuencias_facturas:
        form.permitir_modificar_secuencias_facturas,
      permitir_seleccionar_secuencia: form.permitir_seleccionar_secuencia,
      permitir_seleccionar_almacenes: form.permitir_seleccionar_almacenes,
      permitir_modificar_iva: form.permitir_modificar_iva,
      permitir_seleccionar_vendedores: form.permitir_seleccionar_vendedores,
      permitir_realizar_pedido: form.permitir_realizar_pedido,
      permitir_realizar_proforma: form.permitir_realizar_proforma,
      descuento_permitido: !form.descuento_permitido
        ? 0
        : Number(form.descuento_permitido),
      email: form.email,
      telefono: form.telefono,
      almacenes: form.almacenes,
      cajas: form.cajas,
      formas_pago: form.formas_pago,
      secuencias: form.secuencias,
      tarifas: form.tarifas,
      id_tarifa: form.id_tarifa,
      id_empresa: empresa.id_empresa,
    };

    setIsLoading(true);
    await crearAgenteVentaRequest(dataRequest)
      .then((res) => {
        onResetForm();
        navigate(`../agentes-ventas/${res.id_agente_venta}`, { replace: true });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Row align={"middle"}>
            <Tooltip title={"Regresar"}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                shape="circle"
                icon={<IoArrowBack size={20} />}
                style={{ marginRight: 10 }}
              />
            </Tooltip>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Row align={"middle"}>
                      <IoCart style={{ marginRight: 5 }} />
                      <span>Ventas</span>
                    </Row>
                  ),
                },
                {
                  title: "Agentes de Ventas",
                },
                {
                  title: "Crear Agente de Venta",
                },
              ]}
            />
          </Row>
          <Row>
            <ButtonAsync onClick={crear} type="primary" text="Crear" />
          </Row>
        </Row>
        {error.show && (
          <Alert
            description={error.message}
            type="error"
            closable
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Form>
          <Row>
            <Col span={12}>
              <Row style={{ marginTop: 5 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Tipo Agente de Venta
                </Typography>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Checkbox
                  checked={form.facturador}
                  onChange={(e) => onChangeForm("facturador", e.target.checked)}
                >
                  Facturador
                </Checkbox>
                <Checkbox
                  checked={form.vendedor}
                  onChange={(e) => onChangeForm("vendedor", e.target.checked)}
                >
                  Vendedor
                </Checkbox>
                <Checkbox
                  checked={form.cobrador}
                  onChange={(e) => onChangeForm("cobrador", e.target.checked)}
                >
                  Cobrador
                </Checkbox>
                <Checkbox
                  checked={form.despachador}
                  onChange={(e) =>
                    onChangeForm("despachador", e.target.checked)
                  }
                >
                  Despachador
                </Checkbox>
              </Row>

              <Row
                gutter={5}
                justify={"space-between"}
                style={{ marginTop: 20 }}
              >
                <Col span={5}>
                  <Typography>Nombres: </Typography>
                </Col>
                <Col span={19}>
                  <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={form.nombres}
                    onChange={(e) => onChangeForm("nombres", e.target.value)}
                  />
                </Col>
              </Row>

              <Row
                gutter={5}
                justify={"space-between"}
                style={{ marginTop: 5 }}
              >
                <Col span={12}>
                  <Row>
                    <Col span={10}>
                      <Typography>Contraseña: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input.Password
                        size="small"
                        style={{ width: "100%" }}
                        value={form.clave}
                        type={"password"}
                        disabled={
                          !(
                            form.facturador ||
                            form.cobrador ||
                            form.despachador
                          )
                        }
                        onChange={(e) => onChangeForm("clave", e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Checkbox
                    checked={form.activo}
                    onChange={(e) => onChangeForm("activo", e.target.checked)}
                  >
                    Activo
                  </Checkbox>
                </Col>
              </Row>

              <Row
                gutter={5}
                justify={"space-between"}
                style={{ marginTop: 5 }}
              >
                <Col span={12}>
                  <Row>
                    <Col span={10}>
                      <Typography>Verificar: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input.Password
                        size="small"
                        style={{ width: "100%" }}
                        type={"password"}
                        value={form.verificar_clave}
                        disabled={
                          !(
                            form.facturador ||
                            form.cobrador ||
                            form.despachador
                          )
                        }
                        onChange={(e) =>
                          onChangeForm("verificar_clave", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}></Col>
              </Row>

              <Row
                gutter={5}
                justify={"space-between"}
                style={{ marginTop: 5 }}
              >
                <Col span={12}>
                  <Row>
                    <Col span={10}>
                      <Typography>Meta Venta: </Typography>
                    </Col>
                    <Col span={14}>
                      <Input
                        size="small"
                        style={{ width: "100%" }}
                        type={"number"}
                        value={form.meta_venta}
                        onChange={(e) =>
                          onChangeForm("meta_venta", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Col>
          </Row>
          <Tabs items={items} />
        </Form>
      </Content>
    </Spin>
  );
};
